import React from 'react'
import PropTypes from 'prop-types'
import ParentDetail from './detail.js'

const { arrayOf, bool, func, object } = PropTypes

class ParentDetailsSet extends React.Component {
  static propTypes = {
    admin: bool.isRequired,
    parents: arrayOf(object).isRequired,
    member: object.isRequired,
    onParentArchive: func,
    onParentUpdate: func,
    editPassword: bool,
  }

  static defaultProps = {
    admin: false,
    editPassword: false,
  }

  render() {
    const {
      admin,
      parents,
      member,
      onParentArchive,
      onParentUpdate,
      preview,
      editPassword,
    } = this.props

    return (
      <div>
        {_.map(parents, parent => (
          <ParentDetail
            key={parent.id}
            admin={admin}
            editPassword={editPassword}
            parent={parent}
            allParents={parents}
            member={member}
            onParentArchive={onParentArchive}
            onParentUpdate={onParentUpdate}
            preview={preview}
          />
        ))}
      </div>
    )
  }
}

export default ParentDetailsSet
