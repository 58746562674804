/* eslint-disable new-cap */
import React from 'react'
import { Router, Route, IndexRoute } from 'react-router'

import { getAsyncInjectors } from './utils/asyncInjectors'
// import { getHooks } from './utils/hooks';
import sagas from './sagas'

import { logoutRequest } from './actions/authentication'

import AppWrapper from './containers/App'
import AppWrapperWithoutSidebar from './containers/App/app-without-sidebar'
import PageNotFound from './containers/App/Common/NotFound'
import RequiredDocumentIndex from './containers/App/Common/RequiredDocument'

import NotificationsContainer from './containers/App/notifications-container'

import AdminRoutes from 'routes/admin/index.js'
import ParentRoutes from 'routes/parent/index.js'
import MemberRoutes from 'routes/member/index.js'
import SuperRoutes from 'routes/super/index.js'
import UnauthenticatedRoutes from 'routes/unauthenticated/index.js'
import FederationRoutes from 'routes/federation/index.js'
import AlumniRoutes from 'routes/alumni/index.js'

import { onAuthRequired, onAuthAndOrganizationRequired, onHomePage } from './routes/helpers.js'

/* const errorLoading = (err) => {
  console.error('Dynamic page loading failed', err); // eslint-disable-line no-console
}; */

export default function createRoutes(store) {
  const { /* injectReducer, */ injectSagas } = getAsyncInjectors(store)
  // const hooks = getHooks(store);
  const { dispatch } = store

  injectSagas(sagas)

  return (
    <Router>
      <Route component={NotificationsContainer}>
        <Route path="/" component={AppWrapper}>
          <Route onEnter={onAuthAndOrganizationRequired.bind(this, store)}>
            <IndexRoute onEnter={onHomePage.bind(this, store)} />

            {MemberRoutes(store)}

            {AdminRoutes(store)}

            {ParentRoutes(store)}

            {AlumniRoutes(store)}
          </Route>

          <Route onEnter={onAuthRequired.bind(this, store)}>
            {SuperRoutes(store)}

            {FederationRoutes(store)}
          </Route>
        </Route>

        <Route path="/" component={AppWrapperWithoutSidebar}>
          <Route onEnter={onAuthAndOrganizationRequired.bind(this, store)}>
            <Route path="required-documents" component={RequiredDocumentIndex} />
          </Route>
        </Route>

        {UnauthenticatedRoutes()}

        <Route path="/" component={AppWrapper} onEnter={onAuthRequired.bind(this, store)}>
          <Route path="logout" onEnter={() => dispatch(logoutRequest())} />
          <Route path="*" component={PageNotFound} />
        </Route>
      </Route>
    </Router>
  )
}
