import React from 'react';
import PropTypes from 'prop-types';

import { Alert } from 'react-bootstrap';
import { Date } from 'components/utilities';

const { shape, string } = PropTypes;

const RegistrationClosed = ({ description, event }) => (
  <Alert bsStyle="warning">
    <strong>
      {description} on <Date date={event.registration_closes_on} />.
    </strong>
  </Alert>
);

RegistrationClosed.propTypes = {
  description: string.isRequired,
  event: shape({
    registration_closes_on: string.isRequired,
  }).isRequired,
};

export default RegistrationClosed;
