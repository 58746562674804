import { apiRequest } from 'actions/use'
import apiWrapper, { generatePayload, list } from './helpers'

export default class OrganizationTransaction extends apiWrapper('/organization_transactions') {
  static multipleCreate({ data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'POST',
      uri: '/api/organization_transactions/multiple_create',
      data: generatePayload({ data, query }),
      on: {
        201: onSuccess,
      },
      onFailure,
    })
  }

  static pending({ data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'GET',
      uri: '/api/organization_transactions/pending',
      data: generatePayload({ data, query }),
      onSuccess,
      onFailure,
    })
  }

  static approve({ id, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'PUT',
      uri: `/api/organization_transactions/${id}/approve`,
      data: generatePayload({ query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    })
  }

  static cancel({ id, data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'PUT',
      uri: `/api/organization_transactions/${id}/cancel`,
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    })
  }

  static inState({ data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'GET',
      uri: '/api/organization_transactions/in_state',
      data: generatePayload({ data, query }),
      onSuccess,
      onFailure,
    })
  }

  static outstanding(opts) {
    list('/organization_transactions/outstanding', opts)
  }

  static outstandingBalances(opts) {
    list('/organization_transactions/outstanding_balances', opts)
  }

  static overview(opts) {
    list('/organization_transactions/overview', opts)
  }

  static outstandingTransfer({ data, onSuccess, onFailure }) {
    apiRequest({
      method: 'POST',
      uri: '/api/organization_transactions/outstanding_transfer',
      data,
      on: {
        201: onSuccess,
      },
      onFailure,
    })
  }
}
