import React from 'react';
import { object } from 'prop-types';
import { Row } from 'react-bootstrap';
import { connect } from 'helpers';
import { Parent } from 'resources';
import Container from 'container';

import { Form, Field, SubmitButton } from 'components/form';

class ParentNameForm extends Container {

  static propTypes = {
    objUser: object.isRequired,
  }

  onParentUpdate = () => {
    const { actions: { notify, refreshUser } } = this.props;

    notify({
      message: 'Your profile has been updated!',
    });

    refreshUser();
  }

  render = () => {
    const { objUser } = this.props;

    return (
      <Form
        id={objUser.parents[0].id}
        action={Parent.update}
        onSuccess={this.onParentUpdate}
        autoComplete="off"
      >
        <hr className="spacer-xs" />

        <Field
          name="parent.user.first_name"
          defaultValue={objUser.first_name}
          label="First Name"
          autoFocus
        />

        <Field
          name="parent.user.last_name"
          defaultValue={objUser.last_name}
          label="Last Name"
        />

        <Row>
          <SubmitButton offset={5}>
            Update profile
          </SubmitButton>
        </Row>
      </Form>
    );
  }

}

export default connect(ParentNameForm);
