import React from 'react'
import PropTypes from 'prop-types'
import scriptLoader from 'react-async-script-loader'
import { modal } from 'helpers'
import { Parent } from 'resources'

import { Modal, Col, FormGroup } from 'react-bootstrap'
import { AddressInput } from 'components/utilities'
import { Form, Field, SubmitButton, hasErrors } from 'components/form'

class EditParentModal extends React.Component {
  static propTypes = {
    isScriptLoadSucceed: PropTypes.bool.isRequired,
    parent: PropTypes.object.isRequired,
    onParentUpdate: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.state = {
      errors: {},
      address: props.parent.user.address,
    }
  }

  componentWillReceiveProps = () => this.setState({ errors: {} })

  onParentUpdate = ({ data }) => {
    const { closeModal, notify } = this.props.actions
    const { onParentUpdate } = this.props

    onParentUpdate({ data })
    closeModal()
    notify({
      message: `${data.user.first_name} was updated!`,
    })
  }

  onValidationErrors = errors => this.setState({ errors })

  onAddressSelect = address => this.setState({ address })

  render() {
    const { parent } = this.props
    const { errors, address } = this.state

    if (!this.props.isScriptLoadSucceed) {
      return null
    }

    const data = {
      parent: {
        user: { address },
      },
    }

    return (
      <Modal show onHide={this.props.actions.closeModal}>
        <Form
          action={Parent.update}
          id={parent.id}
          query={`
            parent {
              send_email_bills
              send_physical_bills
              send_sms_reminders

              data { member_count }

              user {
                first_name
                last_name
                phone_number
                email

                address {
                  address
                  address_2
                  city
                  state
                  postal_code
                  country
                }
              }
              member {
                user {
                  first_name
                  last_name
                }
              }
            }
          `}
          data={data}
          onValidationErrors={this.onValidationErrors}
          onSuccess={this.onParentUpdate}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update parent profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <hr className="spacer-xs" />

            <FormGroup
              validationState={
                hasErrors(errors, 'parent.user', 'first_name', 'last_name') ? 'error' : null
              }
            >
              <label htmlFor="parent.user.first_name" className="col-md-3 control-label">
                Name
              </label>
              <Col md={4}>
                <input
                  id="parent.user.first_name"
                  name="parent.user.first_name"
                  defaultValue={_.get(parent, 'user.first_name')}
                  type="text"
                  placeholder="First"
                  autoFocus
                  className="form-control"
                />
              </Col>
              <Col md={5}>
                <input
                  id="parent.user.last_name"
                  name="parent.user.last_name"
                  defaultValue={_.get(parent, 'user.last_name')}
                  type="text"
                  placeholder="Last"
                  className="form-control"
                />
              </Col>
            </FormGroup>

            <Field
              name="parent.user.email"
              label="Email"
              inputSize={9}
              defaultValue={_.get(parent, 'user.email')}
            />

            <Field
              name="parent.user.phone_number"
              defaultValue={_.get(parent, 'user.phone_number')}
              label="Phone number"
              mask="(999) 999 - 9999"
              inputSize={9}
            />

            <Field
              label="Address"
              validationState={hasErrors(errors, 'parent.user', 'address') ? 'error' : null}
              inputSize={9}
              className="places-autocomplete"
            >
              <AddressInput
                address={parent.user.address}
                namePrefix="parent.user"
                onAddressSelect={this.onAddressSelect}
              />
            </Field>
          </Modal.Body>
          <Modal.Footer>
            <SubmitButton offset={5}>Update parent</SubmitButton>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

export default scriptLoader(
  `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_PLACES_API_KEY}&libraries=places`
)(modal('EditParent', EditParentModal))
