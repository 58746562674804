/* eslint-disable new-cap */
import React from 'react'
import { Route, IndexRoute, IndexRedirect } from 'react-router'

export default function federationMemberRoutes() {
  const Federation = path => (_, cb) =>
    require.ensure([], require => cb(null, require('chunks/federation')[path]))

  return (
    <Route path="members/:id" getComponent={Federation('Member')}>
      <IndexRedirect to="profile" />
      <Route path="profile" getComponent={Federation('Member/Profile')} />
      <Route path="parents" getComponent={Federation('Member/Parents')} />
      <Route path="events" getComponent={Federation('Member/Events')} />
      <Route path="forms" getComponent={Federation('Member/Forms')} />
      <Route
        path="executive-board-positions"
        getComponent={Federation('Member/ExecutiveBoardPositions')}
      />
      <Route path="committee-positions" getComponent={Federation('Member/CommitteePositions')} />
      <Route path="associations" getComponent={Federation('Member/Associations')} />
      <Route path="donations" getComponent={Federation('Member/Donations')} />
      <Route path="notes" getComponent={Federation('Member/Notes')} />
      <Route path="contact-events">
        <IndexRoute getComponent={Federation('Member/ContactEvents')} />
        <Route path="page/:page" getComponent={Federation('Member/ContactEvents')} />
      </Route>
      <Route path="chapter" getComponent={Federation('Member/Chapter')} />
      <Route path="messaging">
        <IndexRoute getComponent={Federation('Member/Messaging')} />
        <Route path="page/:page" getComponent={Federation('Member/Messaging')} />
        <Route path="new" getComponent={Federation('Member/Messaging/new')} />
        <Route path=":message_id" getComponent={Federation('Member/Messaging/show')} />
      </Route>
      <Route path="external-ref" getComponent={Federation('Member/ExternalRef')} />
      <Route path="attendance" getComponent={Federation('Member/Attendance')} />
    </Route>
  )
}
