import apiWrapper from './helpers';
import queryString from 'query-string';
import { apiRequest } from 'actions/use';

export default class ExecutiveBoardMember extends apiWrapper('/executive_board_members') {

  static delete({ data, onSuccess, onFailure }) {
    apiRequest({
      method: 'DELETE',
      uri: `/api/executive_board_members?${queryString.stringify(data)}`,
      on: {
        204: onSuccess,
      },
      onFailure,
    });
  }

}
