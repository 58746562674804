import T from '../actions/types';

export default (state = null, action) => {
  switch (action.type) {
    case T.SET_CUSTOM_WRAPPER_STYLES:
      return { ...action.styles };

    default:
      return state;
  }
};
