import React from 'react';
import PropTypes from 'prop-types';

const { shape, string } = PropTypes;

const PaymentMethod = ({ paymentMethod, className }) => (
  <span className={className}>
    {paymentMethod.method === 'bank' ? (
      `${paymentMethod.company} *${paymentMethod.last_four}`
    ) : (
      `${paymentMethod.company} ending in ${paymentMethod.last_four}`
    )}
  </span>
);

PaymentMethod.propTypes = {
  paymentMethod: shape({
    company: string.isRequired,
    method: string.isRequired,
    last_four: string.isRequired,
  }).isRequired,
  className: string,
};

export default PaymentMethod;
