import React from 'react';
import { bool, func } from 'prop-types';

import { Button } from 'react-bootstrap';

const AcceptButton = ({ isReady, isSaving, doSubmit }) => (
  <Button
    onClick={doSubmit}
    disabled={!isReady || isSaving}
    bsStyle="secondary"
    className="pull-right"
  >
    {isSaving ? 'Please wait...' : 'Accept'}
  </Button>
);

AcceptButton.propTypes = {
  isReady: bool.isRequired,
  isSaving: bool.isRequired,
  doSubmit: func.isRequired,
};

export default AcceptButton;
