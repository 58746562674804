import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect, GCM_ENVIRONMENT } from 'helpers';

import HeaderNav from './header-nav';

const logo = require('images/tmp/old-logo.png');
const { object, string, number, shape } = PropTypes;

class WrapperWithoutSidebar extends React.Component {

  static contextTypes = {
    router: object.isRequired,
    user: shape({
      role: string.isRequired,
      first_name: string.isRequired,
      last_name: string.isRequired,
      parent: shape({
        member: shape({
          id: number.isRequired,
        }),
      }),
      member: shape({
        id: number.isRequired,
      }),
      federation: shape({
        id: number.isRequired,
      }),
      avatar: shape({
        id: number.isRequired,
      }),
    }),
  }

  render = () => {
    const { user } = this.context;

    const production = GCM_ENVIRONMENT === 'production';

    return (
      <div id="wrapper" className={`without-sidebar ${user.role} ${production ? 'live' : 'dev'}`}>

        {!production && <div className="is-not-production" />}

        <div className="navbar navbar-default hidden-print" role="navigation">
          <div className="container">

            <div className="navbar-header">
              <div className="navbar-brand">
                <Link to="/" className="logo">
                  <img src={logo} height={50} role="presentation" />
                </Link>
              </div>
            </div>

            <HeaderNav
              key={_.get(user, 'avatar.id')}
            />
          </div>
        </div>

        <div className="content-page">
          {this.props.children}
        </div>

        <div className="mobile-footer visible-xs hidden-print">
          <hr />
          Greek Capital Management
        </div>
      </div>
    );
  }
}

export default connect(WrapperWithoutSidebar);
