import React, { Fragment } from 'react';
import { connect } from 'helpers';
import Container from 'container';
import { RequiredDocument, RequiredDocumentAcceptance } from 'resources';

import { Col } from 'react-bootstrap';
import { Portlet, Body, Loading } from 'components/utilities';

import Questions from './_questions';
import PaymentOption from './_payment-option';
import PaymentMethod from './_payment-method';
import Signature from './_signature';
import AcceptButton from './_accept-button';

class RequiredDocumentIndex extends Container {

  static initialState = {
    acceptance: {
      payment_plan_option: 0,
      has_signature: false,
      answers: null,
    },
    errors: [],
    isSaving: false,
  }

  constructor(props) {
    super(props, RequiredDocumentIndex.initialState);
  }

  resources = {
    list: RequiredDocument,
  }

  listQuery = `
    required_documents {
      title
      body
      custom_questions
      acceptance_type

      payment_schedule {
        options
      }
    }
  `

  afterResourceList = () => this.initCurrentDocument()

  initCurrentDocument = () => {
    const { refreshUser } = this.props.actions;
    const document = this.getDocument();

    if (!document) {
      refreshUser({ redirect: true });
      return;
    }

    this.setState(Object.assign({}, RequiredDocumentIndex.initialState, {
      acceptance: {
        ...RequiredDocumentIndex.initialState.acceptance,
        answers: document.custom_questions,
      },
    }));
  }

  getDocument = () => this.getList() && this.getList()[0]

  onUpdateAcceptance = (key, val) => {
    const { acceptance: a } = this.state;
    const acceptance = _.chain(a).cloneDeep().set(key, val).value();
    this.setState({ acceptance, errors: [] });
  }

  isReadyToSubmit = () => {
    const { acceptance } = this.state;

    if (document.payment_schedule) {
      if (!acceptance.payment_method_id) {
        return false;
      }

      const option = _.get(document, `payment_schedule.options[${acceptance.payment_plan_option}]`);
      if (!option) {
        return false;
      }
    }

    if (document.acceptance_type === 'signature' && !acceptance.has_signature) {
      return false;
    }

    return true;
  }

  doSubmit = () => {
    const document = this.getDocument();
    const { acceptance } = this.state;

    this.setState({ isSaving: true });

    RequiredDocumentAcceptance.create({
      data: {
        required_document_acceptance: {
          ...acceptance,
          required_document_id: document.id,
        },
      },
      query: 'required_document_acceptance { id }',
      onSuccess: () => {
        this.listRemove({ id: document.id });

        this.initCurrentDocument();
        this.setState({ isSaving: false });
        window.scrollTo(0, 0);

        const verb = document.acceptance_type === 'signature' ? 'signing' : 'accepting';
        this.notify(`Thank you for ${verb}!`);
      },
      onFailure: ({ data }) => {
        const errors = _.get(data, 'required_document_acceptance', {});
        this.setState({ errors, isSaving: false });
      },
    });
  }

  render() {
    const { user } = this.context;
    const document = this.getDocument();
    const { acceptance, errors, isSaving } = this.state;

    if (!document) {
      return <Loading />;
    }

    const isReady = this.isReadyToSubmit();

    const props = {
      document,
      acceptance,
      errors,
      isReady,
      isSaving,
      onUpdateAcceptance: this.onUpdateAcceptance,
    };

    return (
      <Col key={document.id} md={8} lg={6} mdOffset={2} lgOffset={3} className="content required-document-content">

        <div className="page-header">
          <h3 className="page-title">{document.title}</h3>
        </div>

        <Portlet boxed>
          <Body>
            <div
              dangerouslySetInnerHTML={{ __html: document.body }}
              className="required-document-preview-wrapper"
            />
          </Body>
        </Portlet>

        {document.custom_questions.length > 0 && (
          <Questions {...props} />
        )}

        {document.payment_schedule && !document.was_already_paid && (
          <Fragment>
            <PaymentOption {...props} />
            <PaymentMethod
              {...props}
              hasUpcharge={_.get(user, 'member.organization.cc_upcharge', false)}
              userId={user.id}
            />
          </Fragment>
        )}

        {document.acceptance_type === 'signature' ? (
          <Signature {...props} doSubmit={this.doSubmit} />
        ) : (
          <AcceptButton {...props} doSubmit={this.doSubmit} />
        )}

      </Col>
    );
  }

}

export default connect(RequiredDocumentIndex);
