import queryString from 'query-string';
import { apiRequest } from 'actions/use';
import { default as apiWrapper, updateMethod } from './helpers';

export default class HourRecord extends apiWrapper('/hour_records') {

  static approve = updateMethod('/hour_records', 'approve')

  static deny = updateMethod('/hour_records', 'deny')

  static memberMap = ({ data, onSuccess, onFailure }) => {
    apiRequest({
      method: 'GET',
      uri: `/api/hour_records/member_map?${queryString.stringify(data)}`,
      on: {
        200: onSuccess,
      },
      onFailure,
    });
  };

}
