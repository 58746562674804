import { apiRequest } from 'actions/use'
import apiWrapper, { getMethod, updateMethod } from './helpers'

export default class IssuedCard extends apiWrapper('/issued_cards') {
  static summary = getMethod('/issued_cards/summary')
  static deactivate = updateMethod('/issued_cards', 'deactivate')
  static reactivate = updateMethod('/issued_cards', 'reactivate')
  static budget = updateMethod('/issued_cards', 'budget')

  static ledger({ id, data, onSuccess }) {
    const theId = id || data.id

    apiRequest({
      method: 'GET',
      uri: `/api/issued_cards/${theId}/ledger?${_.toQueryString(data)}`,
      on: {
        200: onSuccess,
      },
    })
  }
}
