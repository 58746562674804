import React from 'react';
import { number, oneOf, shape, string } from 'prop-types';

import {
  AlreadyRegistered,
  RegistrationClosed,
  PendingApprovalAlert,
  NoMoreTickets,
  isTicketsExist,
  isRegistrationClosed,
} from 'components/event';

class RegistrationAlert extends React.Component {

  static propTypes = {
    event: shape({
      purpose: oneOf(['event', 'form']).isRequired,
      registration_closes_on: string,
      maximum_tickets: number,
    }).isRequired,
    eventRegistration: shape({
      approved_at: string,
      created_at: string.isRequired,
    }),
  }

  render() {
    const { event, eventRegistration } = this.props;

    if (eventRegistration) {
      if (event.is_approval_required && !eventRegistration.approved_at) {
        return (
          <PendingApprovalAlert
            event={event}
            eventRegistration={eventRegistration}
          />
        );
      }

      return (
        <AlreadyRegistered
          event={event}
          eventRegistration={eventRegistration}
        />
      );
    }

    if (isRegistrationClosed(event)) {
      return (
        <RegistrationClosed
          description="Registration closed"
          event={event}
        />
      );
    }

    if (!isTicketsExist(event)) {
      return (
        <NoMoreTickets
          description={`All ${event.maximum_tickets} tickets were reserved for this event`}
        />
      );
    }

    return null;
  }

}

export default RegistrationAlert;
