import { apiRequest } from 'actions/use';
import apiWrapper, { generatePayload } from './helpers';

export default class HourRequirement extends apiWrapper('/hour_requirements') {
  static unarchive({ id, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'PUT',
      uri: `/api/hour_requirements/${id}/unarchive`,
      data: generatePayload({ query }),
      onSuccess,
      onFailure,
    });
  }
}
