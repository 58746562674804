import T from './types'
import queryString from 'query-string'

export function pdfBill(memberId) {
  window.openRef = window.open
  return {
    type: T.DOWNLOAD_PDF_BILL,
    memberId,
  }
}

export function downloadRequiredDocumentAcceptance(id) {
  window.openRef = window.open
  return {
    type: T.DOWNLOAD_REQUIRED_DOCUMENT_ACCEPTANCE_PDF,
    id,
  }
}

export function downloadBudgetPDF(organizationId, termId) {
  window.openRef = window.open
  return {
    type: T.DOWNLOAD_BUDGET_PDF,
    organizationId,
    termId,
  }
}

export function downloadOrganizationReport(organizationId, startDate, endDate) {
  window.openRef = window.open
  return {
    type: T.DOWNLOAD_ORGANIZATION_REPORT,
    organizationId,
    startDate,
    endDate,
  }
}

export function downloadAllAdjustments(startDate, endDate) {
  window.openRef = window.open

  return {
    type: T.DOWNLOAD_ALL_ADJUSTMENTS,
    startDate,
    endDate,
  }
}

export function downloadAllBillPays(startDate, endDate) {
  window.openRef = window.open

  return {
    type: T.DOWNLOAD_ALL_BILL_PAYS,
    startDate,
    endDate,
  }
}

export function downloadAllTransfers(federationId) {
  window.openRef = window.open
  return {
    type: T.DOWNLOAD_ALL_TRANSFERS,
    federationId,
  }
}

export function downloadSingleTransfer(federationTransferId) {
  window.openRef = window.open
  return {
    type: T.DOWNLOAD_SINGLE_TRANSFER,
    federationTransferId,
  }
}

export function downloadFederationMembershipReport(reportSubmissionId) {
  window.openRef = window.open
  return {
    type: T.DOWNLOAD_FEDERATION_MEMBERSHIP_REPORT,
    reportSubmissionId,
  }
}

export function downloadSalesforceRoster(sfId) {
  window.openRef = window.open
  return {
    type: T.DOWNLOAD_SALESFORCE_ROSTER,
    sfId,
  }
}

export function organizationInvoicesPDF(organizationId, defaultDueOn) {
  window.openRef = window.open
  return {
    type: T.DOWNLOAD_ORGANIZATION_INVOICES_PDF,
    organizationId,
    defaultDueOn,
  }
}

export function registrationCSV({ organizationId, id }) {
  window.openRef = window.open
  return {
    type: T.DOWNLOAD_REGISTRATION_CSV,
    organizationId,
    id,
  }
}

const TRANSACTIONS_LIMIT = 10 * 1000

export function transactionsCSV(options) {
  const txnTypeOptions = _.isNil(options.txn_type)
    ? {}
    : { txn_type: _.castArray(options.txn_type).join(',') }
  const createdByRoleOptions = _.isNil(options.created_by_role)
    ? {}
    : { created_by_role: _.castArray(options.created_by_role).join(',') }

  const processedOptions = Object.assign({}, options, txnTypeOptions, createdByRoleOptions, {
    page_size: TRANSACTIONS_LIMIT,
    page: 1,
  })

  const serializedQuery = queryString.stringify(processedOptions)

  return {
    type: T.DOWNLOAD_TRANSACTIONS_CSV,
    serializedQuery,
  }
}

export function membersCSV(options) {
  const serializedQuery = queryString.stringify(options)

  return {
    type: T.DOWNLOAD_MEMBERS_CSV,
    serializedQuery,
  }
}

export function donationsCSV() {
  return {
    type: T.DOWNLOAD_DONATIONS_CSV,
  }
}

export function organizationMembersCSV(options) {
  const serializedQuery = queryString.stringify(options)

  return {
    type: T.DOWNLOAD_ORGANIZATION_MEMBERS_CSV,
    serializedQuery,
  }
}

export function requiredDocumentCSV(requiredDocumentId) {
  window.openRef = window.open
  return {
    type: T.DOWNLOAD_REQUIRED_DOCUMENT_CSV,
    requiredDocumentId,
  }
}

export function reconcileOrganizationCSV(organizationId) {
  window.openRef = window.open
  return {
    type: T.DOWNLOAD_RECONCILE_ORGANIZATION_CSV,
    organizationId,
  }
}

export function openNewBrowserTab(url) {
  window.openRef = window.open
  return {
    type: T.DOWNLOAD_OPEN_NEW_BROWSER_TAB,
    url,
  }
}
