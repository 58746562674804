/* eslint-disable new-cap */
import React from 'react'
import { Route, IndexRoute, IndexRedirect } from 'react-router'

export default function superFederationRoutes() {
  const Super = path => (_, cb) =>
    require.ensure([], require => cb(null, require('chunks/super')[path]))

  return (
    <Route path="federations">
      <IndexRoute getComponent={Super('Federations')} />
      <Route path=":id" getComponent={Super('Federation')}>
        <IndexRedirect to="organizations" />
        <Route path="organizations" getComponent={Super('Federation/Organizations')} />
        <Route path="billing-categories" getComponent={Super('Federation/BillingCategories')} />
        <Route path="accounts" getComponent={Super('Federation/Accounts')} />
        <Route path="adjustments" getComponent={Super('Federation/FederationAdjustments')} />
        <Route
          path="deactivation-reasons"
          getComponent={Super('Federation/StatusUpdateReasons/deactivation_reasons')}
        />
        <Route
          path="reactivation-reasons"
          getComponent={Super('Federation/StatusUpdateReasons/reactivation_reasons')}
        />
        <Route path="reports" getComponent={Super('Federation/Reports')} />
        <Route path="reports/new" getComponent={Super('Federation/Reports/new')} />
        <Route path="reports/:reportId" getComponent={Super('Federation/Reports/show')} />
        <Route path="staff-positions" getComponent={Super('Federation/StaffPositions')} />
        <Route path="statistics" getComponent={Super('Federation/Statistics')} />
        <Route path="settings" getComponent={Super('Federation/Settings')} />
        <Route path="users" getComponent={Super('Federation/Users')} />
        <Route path="permissions" getComponent={Super('Federation/Permissions')} />
        <Route path="sf-roster" getComponent={Super('Federation/SalesforceRoster')} />
      </Route>
    </Route>
  )
}
