import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from 'react-bootstrap';
import moment from 'moment';

const { func, string, bool, oneOfType, object } = PropTypes;

import Calendar from '../date-range/Calendar';

class DatePickerPopoverInput extends Component {
  static propTypes = {
    date: oneOfType([object, func, string]),
    minDate: oneOfType([object, func, string]),
    maxDate: oneOfType([object, func, string]),
    hideClearDateButton: bool,
    onDateSelected: func.isRequired,
    close: func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      date: props.date,
    };
  }

  onChange = date => {
    this.setState({ date });
    const { onDateSelected } = this.props;
    onDateSelected(date);
    this.props.close();
  }

  doClearDate = () => {
    this.onChange(null);
  }

  render() {
    const { minDate, maxDate, hideClearDateButton } = this.props;
    const { date } = this.state;

    return (
      <div>
        <Calendar
          date={date || moment().utcOffset(-4).add(1, 'day')}
          minDate={minDate}
          maxDate={maxDate}
          onChange={this.onChange}
        />

        {hideClearDateButton || (
          <ButtonGroup className="m-t-05">
            <Button bsSize="sm" className="pull-left" onClick={this.doClearDate}>Clear date</Button>
          </ButtonGroup>
        )}
      </div>);
  }
}

export default DatePickerPopoverInput;
