import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable global-require */
const iconMap = {
  visa: require('images/credit-cards/visa.svg'),
  'master-card': require('images/credit-cards/master-card.svg'),
  'american-express': require('images/credit-cards/american-express.svg'),
  discover: require('images/credit-cards/discover.svg'),
  dinersclub: require('images/credit-cards/dinersclub.svg'),
  unknown: require('images/credit-cards/unknown.svg'),
};
/* eslint-disable global-require */

export default class PaymentMethodIcon extends React.Component {

  static propTypes = {
    icon: PropTypes.string,
    size: PropTypes.number.isRequired,
  }

  render = () => {
    const { icon, size } = this.props;

    let displayIcon = 'unknown';
    if (icon in iconMap) {
      displayIcon = icon;
    }

    return (
      <img
        src={iconMap[displayIcon]}
        width={size}
        height={size}
        role="presentation"
        className="credit-card-logo"
      />
    );
  }

}
