import T from './types';

export function ensureHalfYears() {
  return {
    type: T.ENSURE_HALF_YEARS,
  };
}

export function setHalfYears(halfYears) {
  return {
    type: T.SET_HALF_YEARS,
    halfYears,
  };
}
