import React from 'react'
import { authorized } from 'helpers'
import { App } from 'containers/App'
import { WrapperWithoutSidebar } from 'components/layout'

class AppWithoutSidebar extends App {
  render = () => {
    if (!this.isLoaded()) return super.render()

    return <WrapperWithoutSidebar {...this.props} />
  }
}

export default authorized(AppWithoutSidebar)
