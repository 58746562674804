/* eslint-disable new-cap */
import React from 'react'
import { Route, IndexRedirect, IndexRoute } from 'react-router'
import { ensureRole, ensureFeature } from '../helpers.js'
import FederationOrganizationRoutes from './organization.js'
import FederationMemberRoutes from './member.js'
import FederationEntityRoutes from './entity.js'

import {
  FEDERATION_BILLPAY,
  FINANCES_FEATURE,
  CALENDAR_FEATURE,
  DOCUMENTS_FEATURE,
  MESSAGE_BOARD_FEATURE,
  MESSAGING_FEATURE,
  ALUMNI_GROUPS_FEATURE,
  VOTING_FEATURE,
  GRANT_PERMISSION,
} from 'resources/feature'

export default function federationRoutes(store) {
  const Federation = path => (_, cb) =>
    require.ensure([], require => cb(null, require('chunks/federation')[path]))

  return (
    <Route path="federation" onEnter={ensureRole(store, 'federation')}>
      {FederationOrganizationRoutes(store)}
      {FederationMemberRoutes(store)}
      {FederationEntityRoutes(store)}

      <Route path="alumni/:id" getComponent={Federation('Alumni')}>
        <IndexRedirect to="profile" />
        <Route path="donations" getComponent={Federation('Alumni/Donations')} />
        <Route path="designations" getComponent={Federation('Alumni/Designations')} />
        <Route path="profile" getComponent={Federation('Alumni/Profile')} />
        <Route path="parents" getComponent={Federation('Member/Parents')} />
        <Route path="events" getComponent={Federation('Member/Events')} />
        <Route path="forms" getComponent={Federation('Member/Forms')} />
        <Route
          path="executive-board-positions"
          getComponent={Federation('Member/ExecutiveBoardPositions')}
        />
        <Route path="notes" getComponent={Federation('Alumni/Notes')} />
        <Route path="contact-events">
          <IndexRoute getComponent={Federation('Alumni/ContactEvents')} />
          <Route path="page/:page" getComponent={Federation('Alumni/ContactEvents')} />
        </Route>
        <Route path="subscription" getComponent={Federation('Alumni/Subscription')} />
        <Route path="messaging">
          <IndexRoute getComponent={Federation('Member/Messaging')} />
          <Route path="new" getComponent={Federation('Member/Messaging/new')} />
          <Route path=":message_id" getComponent={Federation('Member/Messaging/show')} />
          <Route path="page/:page" getComponent={Federation('Member/Messaging')} />
        </Route>
      </Route>

      <Route onEnter={ensureFeature(store, FINANCES_FEATURE)}>
        <Route path="billing-categories" getComponent={Federation('BillingCategories')} />
        <Route
          path="billing-categories/overview"
          getComponent={Federation('BillingCategories/overview')}
        />
        <Route
          path="billing-categories/:id/outstanding"
          getComponent={Federation('BillingCategories/outstanding')}
        />
        <Route path="billing-categories/:id" getComponent={Federation('BillingCategories/show')} />
        <Route path="budgets" getComponent={Federation('Budgets')} />
        <Route path="budgets/:id" getComponent={Federation('Budgets/show')} />
        <Route path="grants" getComponent={Federation('Grants')} />
        <Route path="grants/:id" getComponent={Federation('Grants/show')} />
        <Route onEnter={ensureFeature(store, FEDERATION_BILLPAY)}>
          <Route path="bill-pay" getComponent={Federation('BillPay')} />
          <Route path="bill-pay/:id" getComponent={Federation('BillPay/show')} />
        </Route>
        <Route path="donations" getComponent={Federation('Donations')} />
        <Route path="taxes" getComponent={Federation('Taxes')} />
        <Route path="transfers" getComponent={Federation('Transfers')} />
        <Route path="transfers/:page" getComponent={Federation('Transfers')} />
      </Route>

      <Route
        path="calendar"
        getComponent={Federation('Calendar')}
        onEnter={ensureFeature(store, CALENDAR_FEATURE)}
      />
      <Route path="committees" getComponent={Federation('Committees')} />
      <Route path="committees/:id" getComponent={Federation('Committees/show')} />
      <Route path="contributions" getComponent={Federation('ContributionTrackers')} />
      <Route path="contributions/new" getComponent={Federation('ContributionTrackers/new')} />
      <Route path="contributions/new/:step" getComponent={Federation('ContributionTrackers/new')} />
      <Route path="contributions/:id" getComponent={Federation('ContributionTrackers/show')} />
      <Route path="contributions/:id/edit" getComponent={Federation('ContributionTrackers/edit')} />
      <Route
        path="contributions/:id/edit/:step"
        getComponent={Federation('ContributionTrackers/edit')}
      />
      <Route
        path="contributions/:id/:orgId"
        getComponent={Federation('ContributionTrackers/organization')}
      />
      <Route path="attendance" getComponent={Federation('Attendance')} />
      <Route path="attendance/:id" getComponent={Federation('Attendance/show')} />
      <Route path="documents/new" getComponent={Federation('Documents/new')} />
      <Route path="documents/delete" getComponent={Federation('Documents/delete')} />
      <Route path="documents/search/:query" getComponent={Federation('Documents/search')} />
      <Route path="donations/:page" getComponent={Federation('Donations')} />
      <Route path="executive-boards" getComponent={Federation('ExecutiveBoards')} />
      <Route
        path="executive-boards/document-folders"
        getComponent={Federation('ExecutiveBoards/DocumentFolders')}
      />
      <Route
        path="executive-boards/document-folders/:id"
        getComponent={Federation('ExecutiveBoards/DocumentFolders/show')}
      />
      <Route path="executive-boards/:id" getComponent={Federation('ExecutiveBoards/show')} />
      <Route path="event-registrations/:id" getComponent={Federation('EventRegistrations/show')} />
      <Route path="events" getComponent={Federation('Events')} />
      <Route path="events/new" getComponent={Federation('Events/new')} />
      <Route path="events/new/:step" getComponent={Federation('Events/new')} />
      <Route path="events/:id/edit" getComponent={Federation('Events/edit')} />
      <Route path="events/:id/edit/:step" getComponent={Federation('Events/edit')} />
      <Route path="events/:id" getComponent={Federation('Events/show')} />
      <Route path="forms" getComponent={Federation('Forms')} />
      <Route path="forms/new" getComponent={Federation('Forms/new')} />
      <Route path="forms/new/:step" getComponent={Federation('Forms/new')} />
      <Route path="forms/:id/edit" getComponent={Federation('Forms/edit')} />
      <Route path="forms/:id/edit/:step" getComponent={Federation('Forms/edit')} />
      <Route path="forms/:id" getComponent={Federation('Forms/show')} />

      <Route path="groups" onEnter={ensureFeature(store, ALUMNI_GROUPS_FEATURE)}>
        <IndexRoute getComponent={Federation('Groups')} />
        <Route path=":id/page/:page" getComponent={Federation('Groups/show')} />
        <Route path=":id" getComponent={Federation('Groups/show')} />
      </Route>

      <Route path="hour-tracker" getComponent={Federation('HourTracker')} />
      <Route path="hour-tracker/:id" getComponent={Federation('HourTracker/show')} />
      <Route path="members" getComponent={Federation('Members')} />
      <Route path="members/page/:page" getComponent={Federation('Members')} />
      <Route path="entities" getComponent={Federation('Entities')} />
      <Route path="incident-reports" getComponent={Federation('IncidentReports')} />
      <Route path="incident-reports/:id" getComponent={Federation('IncidentReports/show')} />

      <Route path="messaging" onEnter={ensureFeature(store, MESSAGING_FEATURE)}>
        <IndexRoute getComponent={Federation('Messaging')} />
        <Route path="new" getComponent={Federation('Messaging/new')} />
        <Route path="page/:page" getComponent={Federation('Messaging')} />
        <Route path=":id" getComponent={Federation('Messaging/show')} />
      </Route>
      <Route path="reports">
        <IndexRoute getComponent={Federation('Reports')} />
        <Route
          path=":id/organizations/:organization_id"
          getComponent={Federation('Reports/show')}
        />
        <Route
          path=":id/organizations/:organization_id/history"
          getComponent={Federation('Reports/history')}
        />
        <Route
          path=":id/organizations/:organization_id/:date"
          getComponent={Federation('Reports/show')}
        />
      </Route>
      <Route path="volunteers-and-staff" getComponent={Federation('VolunteersAndStaff')} />
      <Route
        path="volunteers-and-staff/:id"
        getComponent={Federation('VolunteersAndStaff/details')}
      />
      <Route path="deactivated-members" getComponent={Federation('DeactivatedMembers')} />
      <Route path="deactivated-members/:page" getComponent={Federation('DeactivatedMembers')} />
      <Route path="statistics" getComponent={Federation('Statistics')}>
        <IndexRedirect to="chapter-sizes" />
        <Route path="chapter-sizes" getComponent={Federation('Statistics/ChapterSizes')} />
        <Route path="membership-report" getComponent={Federation('Statistics/MembershipReport')} />
        <Route path="initiation-report" getComponent={Federation('Statistics/InitiationReport')} />
        <Route
          path="new-member-retention"
          getComponent={Federation('Statistics/NewMemberRetention')}
        />
        <Route path="member-retention" getComponent={Federation('Statistics/MemberRetention')} />
        <Route path="disaffiliation" getComponent={Federation('Statistics/Disaffiliation')} />
        <Route
          path="grade-distribution"
          getComponent={Federation('Statistics/GradeDistribution')}
        />
        <Route path="pledge-classes" getComponent={Federation('Statistics/PledgeClasses')} />
        <Route
          path="pledge-conversions"
          getComponent={Federation('Statistics/PledgeConversions')}
        />
      </Route>

      <Route path="message-board" onEnter={ensureFeature(store, MESSAGE_BOARD_FEATURE)}>
        <IndexRoute getComponent={Federation('MessageCategories')} />
        <Route path=":id">
          <IndexRoute getComponent={Federation('MessageCategories/show')} />
          <Route path="folders/:folder_id" getComponent={Federation('MessageCategories/folder')} />
          <Route path="threads/:thread_id" getComponent={Federation('MessageThreads/show')} />
        </Route>
      </Route>

      <Route path="settings" getComponent={Federation('Settings')}>
        <IndexRedirect to="member-info" />
        <Route path="payment-categories" getComponent={Federation('Settings/PaymentCategories')} />
        <Route
          path="committee-positions"
          getComponent={Federation('Settings/CommitteePositions')}
        />
        <Route
          path="executive-boards"
          getComponent={Federation('Settings/ExecutiveBoardPositions')}
        />
        <Route path="member-info" getComponent={Federation('Settings/MemberInfo')} />
        <Route path="staff-positions" getComponent={Federation('Settings/StaffPositions')} />
        <Route path="donation-emails" getComponent={Federation('Settings/DonationEmails')} />
        <Route path="alumni-info" getComponent={Federation('Settings/AlumniInfo')} />
        <Route path="organization-info" getComponent={Federation('Settings/OrganizationInfo')} />
        <Route
          path="organization-roster"
          getComponent={Federation('Settings/OrganizationRoster')}
        />
        <Route
          path="volunteers-and-staff"
          getComponent={Federation('Settings/VolunteersAndStaff')}
        />
        <Route path="distribution-lists">
          <IndexRoute getComponent={Federation('Settings/DistributionLists')} />
          <Route path="new" getComponent={Federation('Settings/DistributionLists/new')} />
          <Route path=":id" getComponent={Federation('Settings/DistributionLists/edit')} />
        </Route>
        <Route path="lifetime-tiers" getComponent={Federation('Settings/LifetimeTiers')} />
        <Route
          onEnter={ensureFeature(store, GRANT_PERMISSION)}
          path="permissions"
          getComponent={Federation('Settings/Permissions')}
        />
      </Route>

      <Route path="document-folders" onEnter={ensureFeature(store, DOCUMENTS_FEATURE)}>
        <IndexRoute getComponent={Federation('DocumentFolders')} />
        <Route path="new" getComponent={Federation('DocumentFolders/new')} />
        <Route path=":id/access" getComponent={Federation('DocumentFolders/access')} />
        <Route path=":id" getComponent={Federation('DocumentFolders/show')} />
      </Route>

      <Route path="voting" onEnter={ensureFeature(store, VOTING_FEATURE)}>
        <IndexRoute getComponent={Federation('Voting')} />
        <Route path="new" getComponent={Federation('Voting/new')} />
        <Route path=":id" getComponent={Federation('Voting/show')} />
      </Route>

      <Route path="required-documents">
        <IndexRoute getComponent={Federation('RequiredDocuments')} />
        <Route path=":id" getComponent={Federation('RequiredDocuments/show')} />
      </Route>
    </Route>
  )
}
