import { LOCATION_CHANGE, push } from 'react-router-redux';

export default function () {
  return (store) => next => action => {
    const { dispatch } = store;

    if (action.type === LOCATION_CHANGE && _.get(store.getState().get('auth'), 'user.data.has_required_documents')) {
      if (action.payload.pathname !== '/required-documents' && action.payload.pathname !== '/logout') {
        dispatch(push('/required-documents'));

        return null;
      }
    }

    return next(action);
  };
}
