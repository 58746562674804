/* global braintree, Rollbar */
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { modal } from 'helpers'
import { Payment, PaymentMethod } from 'resources'

import { Row, Col, Modal, Button, FormGroup } from 'react-bootstrap'
import { Toggle, Loading, PaymentMethodIcon, MoneyInput, Money } from 'components/utilities'
import { hasErrors } from 'components/form'
import { Form as BraintreeForm, BraintreeBadge } from 'components/braintree'

const { bool, func, number, shape, string } = PropTypes

class ChargeCreditCardModal extends React.Component {
  static contextTypes = {
    user: shape({
      role: string.isRequired,
    }).isRequired,
  }

  static propTypes = {
    memberId: number.isRequired,
    ccUpcharge: bool.isRequired,
    onPaymentCreate: func.isRequired,
  }

  constructor() {
    super()

    this.state = {
      ready: false,
      cardType: null,
      amount: null,
      isLoading: false,
      validityErrors: {},
    }
  }

  componentDidMount() {
    this.mounted = true

    PaymentMethod.authorization({
      onSuccess: this.onPaymentAuthorizationCreate,
    })
  }

  componentWillUnmount() {
    this.mounted = false
  }

  onPaymentAuthorizationCreate = ({ data: { authorization } }) => this.setState({ authorization })

  onBraintreeReady = () => this.setState({ ready: true })

  onCardTypeChange = cardType => this.setState({ cardType })

  onError = errors => this.setState({ errors, isLoading: false })

  onValidityErrorChange = (field, validity) => {
    const { validityErrors } = this.state

    if (validity) {
      delete validityErrors[field]
    } else {
      validityErrors[field] = true
    }

    this.setState({ validityErrors })
  }

  onProcessPayment = ({ nonce }) => {
    const { user } = this.context
    const { memberId } = this.props

    this.setState({ isLoading: true })

    Payment.creditCard({
      data: {
        payment: {
          amount: this.getChargedAmount(),
          source: `web/${user.role}`,
          member_id: memberId,
          braintree_nonce: nonce,
        },
      },
      onSuccess: this.onPaymentCreate,
      onFailure: this.onPaymentCreateFailure,
    })
  }

  onPaymentCreate = ({ data }) => {
    const { closeModal, notify } = this.props.actions

    closeModal()
    notify('The card has been successfully charged.')

    this.props.onPaymentCreate(data)

    this.setState({ isLoading: false })
  }

  onPaymentCreateFailure = ({ data: errors }) => {
    this.setState({
      errors,
      isLoading: false,
    })
  }

  onAmountChange = formatted => {
    const amount = formatted.replace(/[^0-9.]+/, '')
    this.setState({ amount })
  }

  setState(...args) {
    if (this.mounted) {
      super.setState(...args)
    }
  }

  getChargedAmount = () => {
    const { ccUpcharge } = this.props
    const { amount } = this.state

    if (!ccUpcharge) {
      return +amount
    }

    const truncated = (amount / 0.97).toFixed(2)
    return parseFloat(truncated)
  }

  render() {
    const { ccUpcharge } = this.props
    const { authorization, cardType, amount, errors, validityErrors, isLoading } = this.state

    let disabled = isLoading
    if (!this.state.ready) disabled = true
    if (cardType === 'american-express') disabled = true
    if (_.size(validityErrors) > 0) disabled = true
    if (amount <= 0) disabled = true

    return (
      <Modal show onHide={this.props.actions.closeModal} dialogClassName="credit-card-modal">
        <BraintreeForm
          authorization={authorization}
          onBraintreeReady={this.onBraintreeReady}
          onError={this.onError}
          onProcessPayment={this.onProcessPayment}
          onCardTypeChange={this.onCardTypeChange}
          onValidityErrorChange={this.onValidityErrorChange}
        >
          <Modal.Header closeButton>
            <Modal.Title>Charge their credit card</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <hr className="spacer-xs" />

            {this.state.ready ? null : (
              <div className="loading">
                <Loading />
              </div>
            )}

            <div className={cx({ hidden: !this.state.ready })}>
              <FormGroup
                validationState={
                  hasErrors(errors, 'payment', 'number') || validityErrors.number ? 'error' : null
                }
              >
                <Row>
                  <Col sm={12}>
                    <div id="number" className="form-control" />

                    <PaymentMethodIcon icon={cardType || 'unknown'} size={36} />

                    <Toggle show={cardType === 'american-express'}>
                      <span className="help-block text-danger m-l-1">
                        Sorry, we don&apos;t accept American Express
                      </span>
                    </Toggle>
                  </Col>
                </Row>
              </FormGroup>

              <Row>
                <Col sm={4}>
                  <FormGroup
                    validationState={
                      hasErrors(errors, 'payment', 'expirationDate') ||
                      validityErrors.expirationDate
                        ? 'error'
                        : null
                    }
                  >
                    <div id="expirationDate" className="form-control" />
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup
                    validationState={
                      hasErrors(errors, 'payment', 'cvv') || validityErrors.cvv ? 'error' : null
                    }
                  >
                    <div id="cvv" className="form-control" />
                  </FormGroup>
                </Col>
                <Col sm={5}>
                  <FormGroup
                    validationState={
                      hasErrors(errors, 'payment', 'avs') || validityErrors.avs ? 'error' : null
                    }
                  >
                    <div id="postalCode" className="form-control" />
                  </FormGroup>
                </Col>
              </Row>

              {hasErrors(errors, 'payment', '_') ? (
                <Row className="m-b-2">
                  <Col sm={12} className="text-danger fw-semibold">
                    {_.get(errors, 'payment._')[0]}
                  </Col>
                </Row>
              ) : null}

              <MoneyInput
                name="payment.amount"
                placeholder="Amount to pay"
                onChange={this.onAmountChange}
                groupClassName={{
                  'm-t-1': true,
                  'has-error': hasErrors(errors, 'payment', 'amount'),
                }}
              />

              {hasErrors(errors, 'payment', 'amount') ? (
                <Row>
                  <Col sm={12} className="text-danger m-t-05 m-b-05">
                    Please enter a valid amount
                  </Col>
                </Row>
              ) : null}

              {ccUpcharge ? (
                <em className="help-block">
                  The card will be charged <Money amount={this.getChargedAmount()} />.
                </em>
              ) : null}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <BraintreeBadge height={28} className="pull-left" />

            <Button
              type="submit"
              bsStyle="primary"
              disabled={disabled}
              className={cx({
                'btn-loading': isLoading,
              })}
            >
              Make payment
            </Button>
          </Modal.Footer>
        </BraintreeForm>
      </Modal>
    )
  }
}

export default modal('ChargeCreditCard', ChargeCreditCardModal)
