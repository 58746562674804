const pages = new Map();
let history;

const SessionPageState = {
  setHistory(_history) {
    history = _history;

    let previousPageState =
      SessionPageState.getPageState((window.history.state || {}).key);

    history.listen(location => {
      SessionPageState.location = location;

      if (previousPageState) {
        SessionPageState.setPageState(location.key, previousPageState);
        previousPageState = null;
      }
    });
  },

  getValue(key) {
    const { location, getPageState } = SessionPageState;
    const state = getPageState(location.key);

    return state[key];
  },

  setValue(key, value) {
    const { location, getPageState, savePageState } = SessionPageState;
    const state = getPageState(location.key);

    state[key] = value;
    savePageState(location.key);
  },

  savePageState(key) {
    const page = pages.get(key);

    try {
      if (page) {
        sessionStorage.setItem(`page.${key}`, JSON.stringify(page));
      } else {
        sessionStorage.removeIt(`page.${key}`);
      }
    /* eslint-disable no-empty */
    } catch (e) {}
  },

  setPageState(key, state) {
    pages.set(key, state);

    try {
      sessionStorage.setItem(`page.${key}`, JSON.stringify(state));
    /* eslint-disable no-empty */
    } catch (e) {}
  },

  getPageState(key) {
    if (pages.has(key)) {
      return pages.get(key);
    }

    let value;

    try {
      value = sessionStorage.getItem(`page.${key}`);
    } catch (e) {
      return value;
    }

    if (value) {
      value = JSON.parse(value);
    } else {
      value = {};
    }

    pages.set(key, value);
    return value;
  },
};


export default SessionPageState;
