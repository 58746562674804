import { apiRequest } from 'actions/use';
import apiWrapper, { generatePayload, getMethod } from './helpers';

export default class Document extends apiWrapper('/documents') {

  static uploadUrl({ organization_id }) {
    return new Promise((resolve, reject) => {
      apiRequest({
        method: 'POST',
        uri: '/api/documents/upload_url',
        data: { organization_id },
        on: {
          201: resolve,
        },
        onFailure: reject,
      });
    });
  }

  static taxes({ data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'GET',
      uri: '/api/documents/taxes',
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    });
  }

  static createByOwners({ data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'POST',
      uri: '/api/documents/create_by_owners',
      data: generatePayload({ data, query }),
      on: {
        201: onSuccess,
      },
      onFailure,
    });
  }

  static listCollapsed = getMethod('/documents/list_collapsed')

  static archiveByKey({ data, onSuccess, onFailure }) {
    apiRequest({
      method: 'DELETE',
      uri: '/api/documents/0/archive_by_key',
      data: generatePayload({ data }),
      on: {
        204: onSuccess,
      },
      onFailure,
    });
  }

  static labels({ id, data, onSuccess, onFailure }) {
    apiRequest({
      method: 'PUT',
      uri: `/api/documents/${id}/labels`,
      data,
      on: {
        200: onSuccess,
      },
      onFailure,
    });
  }

  static search({ data, onSuccess, onFailure }) {
    apiRequest({
      method: 'GET',
      uri: '/api/documents/search',
      data,
      on: {
        200: onSuccess,
      },
      onFailure,
    });
  }

}
