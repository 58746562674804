export default function parseMetadata({ hash }) {
  if (!hash) {
    return {};
  }

  return unserializeMetadata(hash.substring(1));
}

export function unserializeMetadata(string) {
  const parts = string.split('/');
  return _.reduce(parts, (memo, part) => {
    const pieces = part.split(':', 2);
    return _.assign({
      [pieces[0]]: pieces[1],
    }, memo);
  }, {});
}
