import { put, select } from 'redux-saga/effects';
import { fetchTerms as doFetchTerms, setTerms } from 'actions/terms';
import api from './api';

export function* ensureTerms({ organizationId }) {

  const auth = yield select(state => state.get('auth'));

  if (!organizationId) {
    // eslint-disable-next-line no-param-reassign
    organizationId = _.get(auth, 'user.member.organization_id');
  }

  if (!organizationId) {
    // eslint-disable-next-line no-param-reassign
    organizationId = _.get(auth, 'user.parents[0].member.organization_id');
  }

  const terms = yield select(state => state.get('terms'));
  if (_.get(terms, 'organizationId') !== organizationId) {
    yield put(doFetchTerms(organizationId));
  }

}

export function* fetchTerms({ organizationId }) {
  const { data: terms } = yield api({
    method: 'GET',
    uri: '/api/terms',
    data: {
      organization_id: organizationId,
      query: `
        term {
          name
          started_on
        }
      `,
    },
  });

  yield put(setTerms(organizationId, terms));
}

export function* addTerm({ term }) {
  const { organizationId, terms } = yield select(state => state.get('terms'));
  const appendedTerms = _.concat(terms, term);
  yield put(setTerms(organizationId, appendedTerms));
}

export function* updateTerm({ term }) {
  const { organizationId, terms } = yield select(state => state.get('terms'));
  const updatedTerms = _.map(terms, t => (t.id === term.id ? term : t));
  yield put(setTerms(organizationId, updatedTerms));
}

export function* removeTerm({ id }) {
  const { organizationId, terms } = yield select(state => state.get('terms'));
  const remainingTerms = _.reject(terms, { id });
  yield put(setTerms(organizationId, remainingTerms));
}
