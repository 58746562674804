import apiWrapper, { getMethod, updateMethod, list, create } from './helpers';

export default class Alumni extends apiWrapper('/alumnis') {

  static byOrganization = getMethod('/alumnis/by_organization')

  static approve = updateMethod('/alumnis', 'approve')

  static deny = updateMethod('/alumnis', 'deny')

  static createByFederation = (opts) => create('/alumnis/create_by_federation', opts)

  static search(opts) {
    list('/alumnis/search', opts);
  }

  static autocomplete = getMethod('/alumnis/autocomplete')

  static createFromMember = (opts) => create('/alumnis/create_from_member', opts)

}
