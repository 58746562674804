import React from 'react';

import { Grid } from 'react-bootstrap';
import { Content, Loading } from 'components/utilities';

export default function (props) {
  if (props.processing) {
    return (
      <Content>
        <Grid>
          <Loading />
        </Grid>
      </Content>
    );
  }

  const classes = ['portlet'];
  if ('className' in props) { classes.push(props.className); }
  if ('boxed' in props) { classes.push('portlet-boxed'); }

  return <div className={classes.join(' ')}>{props.children}</div>;
}

export function Body(props) {
  const classes = ['portlet-body'];
  if ('className' in props) { classes.push(props.className); }
  if ('table' in props) { classes.push('table-responsive'); }
  if ('pagination' in props) { classes.push('with-actions'); }

  return <div className={classes.join(' ')}>{props.children}</div>;
}
