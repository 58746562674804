import { apiRequest } from 'actions/use';
import apiWrapper, { generatePayload } from './helpers';

export default class PaymentPlan extends apiWrapper('/payment_plans') {

  static approve({ id, query, onSuccess }) {
    apiRequest({
      uri: `/api/payment_plans/${id}/approve`,
      data: generatePayload({ query }),
      method: 'PUT',
      onSuccess,
    });
  }

  static deny({ id, data, query, onSuccess }) {
    apiRequest({
      uri: `/api/payment_plans/${id}/deny`,
      method: 'PUT',
      data: generatePayload({ data, query }),
      onSuccess,
    });
  }

  static proposeBySchedule({ data, query, onSuccess }) {
    apiRequest({
      uri: '/api/payment_plans/propose_by_schedule',
      method: 'POST',
      data: generatePayload({ data, query }),
      onSuccess,
    });
  }

  static accept({ id, data, query, onSuccess }) {
    apiRequest({
      uri: `/api/payment_plans/${id}/accept`,
      method: 'PUT',
      data: generatePayload({ data, query }),
      onSuccess,
    });
  }

  static reject({ id, query, onSuccess }) {
    apiRequest({
      uri: `/api/payment_plans/${id}/reject`,
      method: 'PUT',
      data: generatePayload({ query }),
      onSuccess,
    });
  }

}
