import React from 'react'
import PropTypes from 'prop-types'

import { FormGroup, Col, FormControl, InputGroup } from 'react-bootstrap'
import { MoneyInput, FilenameInput, MaskedInput } from 'components/utilities'

export function Input(props) {
  const { name } = props

  if ('mask' in props) {
    return <MaskedInput id={name} className="form-control" {...props} />
  }

  const propsSubset = _.omit(props, ['textarea'])

  if ('textarea' in props) {
    return (
      <FormControl
        componentClass="textarea"
        rows={props.textarea === true ? null : props.textarea}
        id={name}
        {...propsSubset}
      />
    )
  }

  return <FormControl type="text" id={name} {...propsSubset} />
}

export default function Field(props, context) {
  const {
    name,
    label,
    labelFor,
    validationState,
    children,
    errorKey,
    showAllErrors,
    className,
    inputWrapClassName,
  } = props
  const inputSize = _.defaultTo(props.inputSize, 7)
  const labelSize = _.defaultTo(props.labelSize, 3)
  const labelClassName = _.defaultTo(props.labelClassName, `col-md-${labelSize} control-label`)

  const propsSubset = _.omit(props, [
    'label',
    'labelFor',
    'labelClassName',
    'labelSize',
    'children',
    'inputSize',
    'prefix',
    'suffix',
    'hint',
    'validationState',
    'noFeedbackControl',
    'money',
    'disabled',
    'filename',
    'errorKey',
    'showAllErrors',
    'className',
  ])

  let input = children
  if (!input) {
    if ('prefix' in props || 'suffix' in props) {
      input = (
        <InputGroup>
          {'prefix' in props ? <InputGroup.Addon>{props.prefix}</InputGroup.Addon> : null}
          <Input {...propsSubset} />
          {'suffix' in props ? <InputGroup.Addon>{props.suffix}</InputGroup.Addon> : null}
        </InputGroup>
      )
    } else if ('money' in props && props.money) {
      input = <MoneyInput {...propsSubset} />
    } else if ('filename' in props) {
      input = <FilenameInput {...propsSubset} />
    } else {
      input = <Input {...propsSubset} />
    }
  }

  let hint = _.isString(props.hint) ? [props.hint] : []
  if (React.isValidElement(props.hint)) {
    hint = [props.hint]
  }
  const error = _.get(context.errors || {}, errorKey || name)
  if (error) {
    hint = showAllErrors ? error : [error[0]]
  }
  hint =
    hint.length > 0
      ? hint.map(h => (
        <span key={h} className="help-block">
          {h}
        </span>
      ))
      : null

  const validState = validationState || (error ? 'error' : null)

  return (
    <FormGroup
      validationState={validState}
      className={`clearfix ${props.type === 'hidden' && 'hidden'} ${className || ''}`}
    >
      {label ? (
        <label htmlFor={labelFor || name} className={labelClassName}>
          {label}
        </label>
      ) : null}

      <Col md={inputSize} className={inputWrapClassName}>
        {input}
        {'noFeedbackControl' in props ? null : <FormControl.Feedback />}
        {hint}
      </Col>
    </FormGroup>
  )
}

Field.contextTypes = {
  errors: PropTypes.object,
}
