export function getStaffPositionMember(organization, position) {
  return _.find(
    organization.staff_members,
    m => m.staff_position.title.toUpperCase() === position.toUpperCase()
  )
}

export function formatStaffPositionMemberName(member) {
  if (member.last_name && member.first_name) {
    return `${member.last_name}, ${member.first_name}`
  }

  if (member.last_name) return member.last_name
  if (member.first_name) return member.first_name

  return ''
}

export function getStaffPositionMemberName(organization, position) {
  const member = getStaffPositionMember(organization, position)

  if (member) {
    return formatStaffPositionMemberName(member)
  }

  return null
}

export function isNationalOrganization(organization) {
  return _.includes([1, 6, 7, 10, 41, 44, 46, 54, 61], organization.federation.id)
}
