import React from 'react';
import { Grid, ButtonToolbar, Button } from 'react-bootstrap';
import Search from './search';
import { FaButton } from './font-awesome';

export function Action(props) {
  if (props.icon) {
    return <FaButton {...props} />;
  }
  return (
    <Button bsStyle="secondary" {...props} />
  );
}

export function ActionGroup({ children, className }) {
  return <ButtonToolbar className={className}>{children}</ButtonToolbar>;
}

function pageHeader({ disabled, preActions, search, actions, children }) {
  const hasActions = search || actions;
  return (
    <Grid className="page-header">
      <h3 className="page-title">{children}</h3>
      {hasActions ? (
        <div className="page-actions hidden-print">
          <div>
            {preActions && preActions()}
            {!search || <Search disabled={disabled} />}
            {actions}
          </div>
        </div>
      ) : null}
    </Grid>
  );
}

function portletHeader({ disabled, search, actions, children }) {
  return (
    <div className="portlet-header clearfix">
      <h4 className="portlet-title">{children}</h4>
      <div className="pull-right hidden-print">
        {!search || <Search className={!actions || 'm-r-1'} disabled={disabled} />}
        {actions}
      </div>
    </div>
  );
}

export default function (props) {
  if ('portlet' in props) {
    return portletHeader(props);
  }
  return pageHeader(props);
}
