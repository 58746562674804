export default {

  container: {
    position: 'relative',
    maxWidth: 250,
  },

  calendarContainer: {
    backgroundColor: '#FFF',
    padding: 6.5,
    position: 'absolute',
    bottom: 43,
    left: -11,
    right: -470,
    border: '1px solid #ddd',
    borderRadius: '5px 5px 0 5px',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.35)',
    zIndex: 9999,
  },

  calendarContainerTopAligned: {
    bottom: -323,
    right: -7,
    left: -469,
  },

  calendarContainerRightAligned: {
    left: -475,
    right: 0,
  },

  inputContainer: {
    backgroundColor: '#FFF',
  },

  input: {
    cursor: 'pointer',
    backgroundColor: '#FFF',
    textAlign: 'center',
    borderTopLeftRadius: 0,
  },

  inputAddOn: {
    borderTopRightRadius: 0,
    cursor: 'pointer',
  },

};
