/* eslint-disable new-cap */
import React from 'react'
import { Route } from 'react-router'
import UnauthenticatedWrapper from '../../containers/App/Unauthenticated'

export default function unauthenticatedRoutes() {
  const Unauthenticated = path => (_, cb) =>
    require.ensure([], require => cb(null, require('chunks/unauthenticated')[path]))

  return (
    <Route path="/" component={UnauthenticatedWrapper}>
      <Route path="login" getComponent={Unauthenticated('Login')} />
      <Route path="forgot-password" getComponent={Unauthenticated('ForgotPassword')} />
      <Route path="reset-password/:code" getComponent={Unauthenticated('ResetPassword')} />
      <Route path="register/:id" getComponent={Unauthenticated('Register')} />
      <Route
        path="register/:registration_id/confirmation"
        getComponent={Unauthenticated('Register/confirmation')}
      />
      <Route path="submit/:id" getComponent={Unauthenticated('Submit')} />
      <Route
        path="submit/:registration_id/confirmation"
        getComponent={Unauthenticated('Submit/confirmation')}
      />
      <Route path="donate/:recipient/:slug" getComponent={Unauthenticated('Donate')} />
      <Route
        path="donate/:recipient/:slug/confirmation"
        getComponent={Unauthenticated('Donate/confirmation')}
      />
      <Route path="alumni-registration" getComponent={Unauthenticated('AlumniRegistration')} />
      <Route
        path="alumni-registration/confirmation"
        getComponent={Unauthenticated('AlumniRegistration/confirmation')}
      />
      <Route path="unsubscribe" getComponent={Unauthenticated('Unsubscribe')} />
      <Route path="xfer" getComponent={Unauthenticated('Xfer')} />
    </Route>
  )
}
