import React from 'react';
import PropTypes from 'prop-types';

import { Alert } from 'react-bootstrap';
import { Date } from 'components/utilities';

const { oneOf, shape, string } = PropTypes;

const AlreadyRegistered = ({ event, eventRegistration }) => (
  <Alert bsStyle="info">
    <strong>
      You {event.purpose === 'event' ? 'registered' : 'submitted this form'} on <Date date={eventRegistration.created_at} />
    </strong>
  </Alert>
);


AlreadyRegistered.propTypes = {
  event: shape({
    purpose: oneOf(['event', 'form']).isRequired,
  }).isRequired,
  eventRegistration: shape({
    created_at: string.isRequired,
  }).isRequired,
};

export default AlreadyRegistered;
