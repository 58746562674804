import moment from 'moment';

const formats = {
  time12format: 'h:mm a',
  time24format: 'H:mm',
  numbers: 'M/D',
  extraShort: 'MMM D',
  short: 'MMM D, YYYY',
  long: 'MMM D, YYYY [at] h:mma',
  human: 'MMM Do [at] h:mma',
  humanShort: 'MMM Do, YYYY',
  humanLong: 'MMMM Do, YYYY',
};

export default (date, format) => moment(date).format(formats[format]);
