import React from 'react';
import Notifications from 'components/notifications';

export default function NotificationsContainer({ children }) {
  return (
    <div>
      {children}
      <Notifications />
    </div>
  );
}
