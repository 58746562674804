import React from 'react';

export default function ({ object, length, display }) {
  if (_.isNull(object)) {
    const chars = Array(length).join('-');
    return <span className="blokk">{chars}</span>;
  }
  const children = display();
  return _.isString(children) ? <span>{children}</span> : children;
}
