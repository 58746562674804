/* eslint-disable new-cap */
import React from 'react'
import { IndexRoute, Route } from 'react-router'
import { ensureRole } from '../helpers.js'
import SuperFederationRoutes from './federation.js'
import SuperMemberRoutes from './member.js'
import SuperOrganizationRoutes from './organization.js'

export default function superRoutes(store) {
  const Super = path => (_, cb) =>
    require.ensure([], require => cb(null, require('chunks/super')[path]))

  return (
    <Route path="super" onEnter={ensureRole(store, 'root')}>
      {SuperOrganizationRoutes()}
      {SuperMemberRoutes()}
      {SuperFederationRoutes()}
      <Route path="accounts" getComponent={Super('Accounts')} />
      <Route path="alumni" getComponent={Super('Alumni')} />
      <Route path="alumni/page/:page" getComponent={Super('Alumni')} />
      <Route path="alumni/:id" getComponent={Super('Alumni/show')} />
      <Route path="bill-pay" getComponent={Super('BillPay')} />
      <Route path="bill-pay/:page" getComponent={Super('BillPay')} />
      <Route path="collections" getComponent={Super('Collections')} />
      <Route path="gcm-fees" getComponent={Super('GCMFees')} />
      <Route path="end-of-semester" getComponent={Super('EndOfSemester')} />
      <Route path="federation-balances" getComponent={Super('FederationBalances')} />
      <Route path="federation-balances/:id" getComponent={Super('FederationBalances/show')} />
      <Route path="federation-transfers/:id" getComponent={Super('FederationTransfers/show')} />
      <Route path="g-money" getComponent={Super('G-Money')} />
      <Route path="import/:id" getComponent={Super('Import')} />
      <Route path="messaging" getComponent={Super('Messaging')} />
      <Route path="terms" getComponent={Super('Terms')} />
      <Route path="transactions" getComponent={Super('Transactions')} />
      <Route path="transfers" getComponent={Super('Transfers')} />
      <Route path="transfers/:page" getComponent={Super('Transfers')} />
      <Route path="search/:term" getComponent={Super('Search')} />
      <Route path="taxes" getComponent={Super('Taxes')} />
      <Route path="required_documents">
        <IndexRoute getComponent={Super('RequiredDocuments')} />
        <Route path="new" getComponent={Super('RequiredDocuments/new')} />
        <Route path="archived" getComponent={Super('RequiredDocuments/archived')} />
        <Route path=":id" getComponent={Super('RequiredDocuments/show')} />
        <Route path=":id/edit" getComponent={Super('RequiredDocuments/edit')} />
        <Route
          path=":id/payment-schedule"
          getComponent={Super('RequiredDocuments/payment_schedule')}
        />
      </Route>
      <Route path="payment-plans">
        <IndexRoute getComponent={Super('PaymentPlans')} />
        <Route path="page/:page" getComponent={Super('PaymentPlans')} />
      </Route>
    </Route>
  )
}
