import React from 'react';
import PropTypes from 'prop-types';

import { Alert } from 'react-bootstrap';
const { string } = PropTypes;

const NoMoreTickets = ({ description }) => (
  <Alert bsStyle="warning">
    <strong>
      {description}
    </strong>
  </Alert>
);

NoMoreTickets.propTypes = {
  description: string.isRequired,
};

export default NoMoreTickets;
