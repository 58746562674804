import React from 'react';
import { connect } from 'helpers';
import { Account } from 'resources';

import { Row, Col, Modal, Button } from 'react-bootstrap';
import { Money, Percentage } from 'components/utilities';

class ReportingOverviewModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (!this.viewed()) {
      this.doFetchAccountsOverview();
    }
  }

  doFetchAccountsOverview = () => {
    Account.overview({
      data: {
        owner_id: this.context.organization.id,
        owner_type: 'Organization',
      },
      query: `
        account {
          name
        }
      `,
      onSuccess: ({ data }) => this.setState({ accountsOverview: data }),
    });
  }

  storageKey = 'hideReportingOverview'

  viewed = () => sessionStorage.getItem(this.storageKey)

  closeModal = () => {
    sessionStorage.setItem(this.storageKey, true);
    this.forceUpdate();
  }

  render() {
    if (this.viewed()) {
      return null;
    }

    const { accountsOverview } = this.state;

    if (_.isNull(accountsOverview)) {
      return null;
    }

    const totalBilled = _.sumBy(accountsOverview, 'data.billed');
    const totalCollected = _.sumBy(accountsOverview, 'data.collected');
    const collectionRate = totalCollected / totalBilled;

    if (_.isNaN(collectionRate)) {
      return null;
    }

    return (
      <Modal show bsSize="sm" onHide={this.closeModal.bind(this)}>
        <Modal.Header closeButton>
          <Modal.Title>Overview</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h1 className="text-center m-b-0 m-t-05">
            <Percentage value={collectionRate} decimalPlaces={1} />
          </h1>
          <p className="text-center m-b-2">collection rate</p>

          <Row className="m-b-05 fs-125">
            <Col sm={6} className="text-right">
              <Money amount={totalBilled} className="semi-bold" />
            </Col>
            <Col sm={6}>
              billed
            </Col>
          </Row>

          <Row className="fs-125">
            <Col sm={6} className="text-right">
              <Money amount={totalCollected} className="semi-bold" />
            </Col>
            <Col sm={6}>
              collected
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={this.closeModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}

export default connect(ReportingOverviewModal);
