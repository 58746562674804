import cx from 'classnames'
import { PayPalPaymentWrapper } from 'components/braintree'
import { Button, Col, Radio } from 'react-bootstrap'
import { QuickPay } from 'resources'
import PaymentContainer from './payment-container'

export default class PaymentContainerWithPayPal extends PaymentContainer {
  constructor(props) {
    super(props, {
      isPayPalLoading: true,
    })
  }

  onPayPalPaymentCreate = ({ nonce }) => {
    QuickPay.creditCard({
      data: {
        payment: {
          amount: parseFloat(this.getChargedAmount()),
          source: 'web/member',
          member_id: this.getMember().id,
          braintree_nonce: nonce,
        },
      },
      onSuccess: this.onPaymentCreate,
      onFailure: ({ data }) =>
        this.setState({
          errors: data,
          isPayPalLoading: false,
        }),
    })
  }

  onPayPalError = errors => {
    this.setState({
      isPayPalLoading: false,
      errors,
    })
  }

  onPayPalSubmit = () => {
    const { submitPayPalPayment } = this.state
    this.setState({ isPayPalLoading: true })
    submitPayPalPayment({ payment: { amount: this.getChargedAmount() } })
  }

  onPayPalCheckoutInstanceReady = ({ submitPayment }) => {
    this.setState({
      isPayPalLoading: false,
      submitPayPalPayment: submitPayment,
    })
  }

  getMember = () => {
    const { user, activeMember } = this.context
    return activeMember || user.member
  }

  getRootError = () => {
    const { errors } = this.state
    if (!errors) return null

    if (this.isPayPalForm()) {
      return _.get(
        errors,
        'details.originalError.details.originalError.paymentResource.errorDetails.0.issue',
        false
      )
    }

    return _.get(errors, 'payment._.0', false)
  }

  isPayPalForm() {
    const { selectedPaymentMethod } = this.state

    return _.get(selectedPaymentMethod, 'method') === 'paypal'
  }

  renderPayPalPaymentMethodButton = () => (
    <Button
      onClick={this.doSelectPaymentMethod.bind(this, { method: 'paypal' })}
      bsSize="sm"
      active={this.isPayPalForm()}
    >
      Pay with PayPal
    </Button>
  )

  renderPayPalPaymentMethodRadio = () => (
    <Radio
      name="payment.payment_method_id"
      value="paypal"
      checked={this.isPayPalForm()}
      onChange={this.doSelectPaymentMethod.bind(this, { method: 'paypal' })}
    >
      Pay with PayPal
    </Radio>
  )

  renderPayPalForm = formContent => (
    <PayPalPaymentWrapper
      loadAuthorization
      onError={this.onPayPalError}
      onCheckoutInstanceReady={this.onPayPalCheckoutInstanceReady}
      onProcessPayment={this.onPayPalPaymentCreate}
    >
      <div className="form-horizontal">{formContent}</div>
    </PayPalPaymentWrapper>
  )

  renderPayPalSubmitButton() {
    const { isPayPalLoading } = this.state

    return (
      <Col md={7} mdPush={0} className="m-l-0">
        <Button
          offset={0}
          bsStyle="success"
          className={cx('m-l-0', { 'btn-loading': isPayPalLoading })}
          disabled={isPayPalLoading}
          onClick={this.onPayPalSubmit}
        >
          Make payment
        </Button>
      </Col>
    )
  }
}
