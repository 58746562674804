import React from 'react'
import { shape, string } from 'prop-types'

import { Date } from 'components/utilities'
import { Photos, EventDate } from 'components/event'

const EventDetails = ({ event }) => (
  <div>
    <p dangerouslySetInnerHTML={{ __html: _.get(event, 'description') }} className="m-b-2" />

    {(event.start_date || event.end_date) && (
      <EventDate startDate={event.start_date} endDate={event.end_date} />
    )}

    {event.registration_closes_on && (
      <p>
        Registration closes on <Date date={event.registration_closes_on} humanShort />.
      </p>
    )}

    <Photos event={event} />
  </div>
)

EventDetails.propTypes = {
  event: shape({
    description: string.isRequired,
    registration_closes_on: string,
    start_date: string,
    end_date: string,
  }).isRequired,
}

export default EventDetails
