import apiWrapper, { generatePayload } from './helpers';
import { apiRequest } from 'actions/use';

export default class Reimbursement extends apiWrapper('/reimbursements') {

  static approve({ id, data, query, onSuccess }) {

    apiRequest({
      uri: `/api/reimbursements/${id}/approve`,
      method: 'PUT',
      data: generatePayload({ data, query }),
      onSuccess,
    });
  }

  static deny({ id, data, query, onSuccess }) {

    apiRequest({
      uri: `/api/reimbursements/${id}/deny`,
      method: 'PUT',
      data: generatePayload({ data, query }),
      onSuccess,
    });
  }

}
