import T from './types';

export function morph(id) {
  return {
    type: T.MORPH,
    id,
  };
}

export function unmorph(uri) {
  return {
    type: T.UNMORPH,
    uri,
  };
}
