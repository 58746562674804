import React from 'react';
import { modal } from 'helpers';
import { Member } from 'resources';

import { Modal } from 'react-bootstrap';
import { Form, RemoteSelect, SubmitButton } from 'components/form';

class SelectMemberModal extends React.Component {

  mapListBy = {
    name: (_, { user }) => `${user.last_name}, ${user.first_name}`,
  }

  render() {
    const { closeModal } = this.props.actions;
    const { title, organizationId, onSuccess } = this.props;

    const formProps = _.pick(this.props, ['method', 'action', 'onSuccess']);

    return (
      <Modal show onHide={closeModal}>
        <Form {...formProps}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <RemoteSelect
              resource={Member}
              scope={{ organization_id: organizationId }}
              name="name"
              display="name"
              onChange={onSuccess}
              placeholder="Select a member&hellip;"
              mapListBy={this.mapListBy}
            />

          </Modal.Body>
          <Modal.Footer>
            <SubmitButton offset={5}>Select member</SubmitButton>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }

}

export default modal('SelectMember', SelectMemberModal);
