import React, { Component, Fragment } from 'react';
import { array, string } from 'prop-types';
import { connect } from 'helpers';
import { Checkbox } from 'react-bootstrap';

import { Field } from 'components/form';

class EmailOnSubmission extends Component {

  static propTypes = {
    name: string.isRequired,
    value: array,
  }

  static defaultProps = {
    isEmailSubmissionChecked: false,
  }

  constructor(props) {
    super(props);

    const { value } = props;
    const isEmailSubmissionChecked = !_.isEmpty(value);

    this.state = { isEmailSubmissionChecked };
  }

  render = () => {
    const { name, value } = this.props;
    const { isEmailSubmissionChecked } = this.state;

    return (
      <div className={isEmailSubmissionChecked ? 'p-b-025' : null}>

        <Field
          label="Email on submission?"
          name={`${name}.email_submission`}
        >
          <Checkbox
            id={`${name}.email_submission`}
            name={`${name}.email_submission`}
            defaultChecked={isEmailSubmissionChecked}
            onChange={e => this.setState({ isEmailSubmissionChecked: e.target.checked })}
          />
        </Field>

        {isEmailSubmissionChecked && (
          <Fragment>
            <Field name={`${name}.email_addresses`} label="Email addresses" defaultValue={value} inputSize={9} textarea={5} />
            <em className="text-muted m-t-neg-1 d-block col-md-9 col-md-push-3">
              Separate the email addresses by either commas or new lines.
            </em>
          </Fragment>
        )}

      </div>
    );
  }

}

export default connect(EmailOnSubmission);
