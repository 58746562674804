import React from 'react';
import PropTypes from 'prop-types';

import { ProgressBar } from 'react-bootstrap';

const { bool, func, shape, string } = PropTypes;

export default class S3ProgressBar extends React.Component {

  static propTypes = {
    s3: shape({
      url: string.isRequired,
    }).isRequired,
    showComplete: bool,
    onSuccess: func.isRequired,
  }

  constructor() {
    super();

    this.minWidth = 30;

    this.state = {
      status: 'preparing...',
      percentage: null,
    };
  }

  componentDidMount() {
    this.doUpload();

    /*
    const { file, sendDimensions } = this.props;
    if (sendDimensions) {
      const pDimensions = this.getDimensions(file.preview);
      pDimensions.then(dimensions => {
        this.doUpload({
          'X-WIDTH': dimensions.width,
          'X-HEIGHT': dimensions.height,
        });
      });
    } else {
      this.doUpload();
    }
    */
  }

  onUploadProgress(e) {
    const percentage = 100.0 * (e.loaded / e.total);
    const status = percentage < 100 ? `uploading... (${percentage.toFixed(0)}%)` : 'processing...';
    this.setState({ status, percentage });
  }

  onUploadFailed() {
    this.setState({
      status: 'failed',
    });
  }

  doUpload = () => {
    const { s3, file, showComplete } = this.props;
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', s3.url);
    xhr.onerror = this.onUploadFailed;
    xhr.upload.onprogress = this.onUploadProgress.bind(this);
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) { return; }
      if (xhr.status === 200) {
        const status = showComplete ? 'complete' : 'saving...';
        this.setState({ status }, this.props.onSuccess);
      }
    };
    xhr.send(file);
  }

  /*
  getDimensions(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        document.getElementsByTagName('body')[0].appendChild(img);
        const dimensions = {
          width: img.clientWidth,
          height: img.clientHeight,
        };
        img.parentNode.removeChild(img);
        resolve(dimensions);
      };
      img.onError = reject;
      img.style.visibility = 'hidden';
      img.style.position = 'absolute';
      img.src = url;
    });
  }
  */

  render() {
    const { percentage, status } = this.state;

    if (status === 'failed') {
      return <ProgressBar bsStyle="danger" now={100} label="failed :(" />;
    }

    if (status === 'complete') {
      return <ProgressBar bsStyle="success" now={100} label="✔ done" />;
    }

    return (
      <ProgressBar
        bsStyle="secondary"
        active
        now={Math.max(this.minWidth, percentage)}
        label={status}
      />
    );
  }

}
