import { apiRequest } from 'actions/use';
import apiWrapper, { list } from './helpers';

export default class AccountPayment extends apiWrapper('/account_payments') {

  static listByIds(opts) {
    list('/account_payments/list_by_ids', opts);
  }

  static changeAccount({ data, onSuccess, onFailure }) {
    apiRequest({
      uri: '/api/account_payments/0/change_account',
      method: 'PUT',
      data,
      on: {
        200: onSuccess,
      },
      onFailure,
    });
  }

}
