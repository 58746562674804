import ActionCable from 'actioncable';
import T from '../actions/types';
import { subscriptionNotice } from 'actions/subscriptions';

export default function () {

  let token;
  let consumer;
  const subscriptions = [];

  return ({ dispatch }) => next => action => {
    let identifiers;
    const result = next(action);

    switch (action.type) {
      case T.BOOTSTRAP:
        token = localStorage.getItem('token');
        consumer = ActionCable.createConsumer(`/cable/socket?token=${token}`);
        break;
      case T.SUBSCRIBE_REQUEST:
        result.subscriptions.forEach((subscriptionConfig) => {
          // Do subscription on action cable events
          const subscription = consumer.subscriptions.create(subscriptionConfig, {
            // Dispatch received action cable event
            received: data => {
              dispatch(subscriptionNotice(data));
            },
          });
          subscriptions.push(subscription);
        });
        break;
      case T.UNSUBSCRIBE_REQUEST:
        identifiers = result.subscriptions.map(s => JSON.stringify(s));
        _.remove(subscriptions, s => identifiers.includes(s.identifier))
          .forEach(s => s.unsubscribe());
        if (subscriptions.length === 0) {
          consumer.disconnect();
        }
        break;
      default:
    }

    return result;
  };
}
