export const CC_UPCHARGE = 3.0; // Percent
export const ACH_UPCHARGE = 1.5; // Dollars
export const ACH_NO_UPCHARGE = 1.5; // Dollars

export function getUpchargeAmount(amount, { method, ccUpcharge }) {
  if (amount === 0) return 0;

  if (['card', 'paypal'].includes(method)) {
    if (ccUpcharge) {
      return amount * ((100 / (100.0 - CC_UPCHARGE)) - 1);
    }
    return 0;
  }

  if (method === 'bank') {
    if (ccUpcharge) {
      return ACH_UPCHARGE;
    }
    return ACH_NO_UPCHARGE;
  }

  throw new Error(`Unable to determine upcharge: ${method}`);
}
