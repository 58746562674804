import { put, select } from 'redux-saga/effects';
import { setRequiredActions, clearRequiredActions } from 'actions/required-actions';
import api from './api';

export function* fetchRequiredActions() {
  try {

    const { user } = yield select(state => state.get('auth'));

    let uri;
    switch (user.role) {
      case 'member':
      case 'admin':
        uri = `members/${user.member.id}`;
        break;
      case 'alumni':
        uri = `alumnis/${user.alumni.id}`;
        break;
      default:
        uri = `users/${user.id}`;
    }

    const { data: requiredActions } = yield api({
      method: 'GET',
      uri: `/api/${uri}/required_actions`,
    });

    yield put(setRequiredActions(requiredActions));

  } catch (error) {
    yield put(clearRequiredActions());
  }
}
