import React from 'react'
import PropTypes from 'prop-types'
import actionProps from 'actions'
import { connect } from 'react-redux'

const { func, object, string, oneOfType } = PropTypes

class Form extends React.Component {
  static propTypes = {
    action: oneOfType([func, string]).isRequired,
    data: object,
    query: string,
    onInput: func,
    onSubmitStart: func,
    onSubmitEnd: func,
    onSuccess: func.isRequired,
    onValidationErrors: func,
    doSubmit: func,
    formRef: func,
    className: string,
    validBefore: func,
    fieldsWrapper: func,
  }

  static defaultProps = {
    data: {},
  }

  static childContextTypes = {
    form: PropTypes.object,
    errors: PropTypes.object,
  }

  constructor(props) {
    super(props)

    this.identifier = _.uniqueId()
    this.submits = []

    this.state = {
      data: {},
      status: 'loading|ready|submitting|success|failure',
      errors: {},
    }

    if (this.props.doSubmit) {
      this.props.doSubmit(this.doSubmit)
    }
  }

  getChildContext() {
    return {
      form: {
        addSubmit: submitComponent => {
          this.submits.push(submitComponent)
        },
        removeSubmit: submitComponent => {
          const index = this.submits.indexOf(submitComponent)

          if (index !== -1) {
            this.submits.splice(submitComponent, 1)
          }
        },
      },
      errors: this.state.errors,
    }
  }

  onInput = e => {
    if (!_.isEmpty(this.state.errors)) {
      this.setState({
        errors: {},
      })
      if (_.isFunction(this.props.onValidationErrors)) {
        this.props.onValidationErrors({})
      }
    }
    if (this.props.onInput) {
      this.props.onInput(e)
    }
  }

  onSubmit(e) {
    e.preventDefault()

    if ((this.props.validBefore && !this.props.validBefore()) || e.target !== this.form) {
      return
    }

    const data = _.reduce(
      e.target.elements,
      (result, element) => {
        if (element.name === '' || element.disabled) return result
        if (element.type === 'radio' && !element.checked) {
          return result
        }
        const value = this.formatValue(element)
        let elementName = element.name
        const elementNameMatches = elementName.match(/(.+)\[\]$/)
        if (elementNameMatches) {
          elementName = elementNameMatches[1]
        }
        _.setWith(result, elementName, value, Object)
        return result
      },
      this.props.data
    )

    if ('query' in this.props) {
      data.query = this.props.query
    }

    const { apiRequest } = this.props.actions
    const { action, onSubmitStart } = this.props
    let { onSuccess } = this.props
    const onFailure = this.onSubmitEnd.bind(this, 'failure', this.onFailure.bind(this))

    if (typeof onSuccess !== 'function' && typeof onSuccess !== 'string') {
      onSuccess = () => { }
    }

    if (typeof onSuccess === 'function') {
      onSuccess = this.onSubmitEnd.bind(this, 'success', onSuccess)
    }

    this.submits.forEach(submit => {
      submit.disable()
    })

    if (typeof onSubmitStart === 'function') {
      onSubmitStart()
    }

    if (_.isFunction(action)) {
      const { id } = this.props
      action({ id, data, onSuccess, onFailure })
    } else {
      apiRequest({
        identifier: this.identifier,
        method: this.props.method,
        uri: action,
        data,
        onFailure,
        onSuccess,
      })
    }
  }

  onSubmitEnd(state, next, ...args) {
    this.submits.forEach(submit => {
      submit.enable()
    })

    if (state === 'success' && 'resetOnSuccess' in this.props) {
      this.form.reset()
    }

    const { onSubmitEnd } = this.props

    if (typeof onSubmitEnd === 'function') {
      onSubmitEnd()
    }

    return next(...args)
  }

  onFailure({ status, data }) {
    this.setState({ errors: data })
    if (_.isFunction(this.props.onValidationErrors)) {
      this.props.onValidationErrors(data, status)
    }
  }

  doSubmit = () => {
    this.form.dispatchEvent(new Event('submit'))
  }

  formatValue(element) {
    let formatted = element.value || null

    const booleans = ['true', 'false']
    if (booleans.indexOf(formatted) !== -1) {
      formatted = formatted === 'true'
    }

    if (element.tagName === 'SELECT') {
      const selected = [...element.options]
        .filter(o => o.selected)
        .map(o => parseInt(o.value, 10) || o.value)
      if (element.type === 'select-multiple') {
        formatted = selected
      } else if (selected[0]) {
        formatted = selected[0]
      }
    }

    if (element.type === 'checkbox') {
      formatted = element.checked
      if (element.getAttribute('data-inverse')) {
        formatted = !formatted
      }
    }

    if (element.name.match(/_id$/) && !element.name.match(/salesforce_id/)) {
      formatted = parseInt(formatted, 10)
    }

    if (element.hasAttribute('data-numeric')) {
      formatted = parseFloat((formatted || '').replace(/[^0-9.]+/g, ''))
    }

    return formatted
  }

  createFormRef = form => {
    const { formRef } = this.props

    this.form = form

    if (formRef) {
      formRef(form)
    }
  }

  render() {
    const { fieldsWrapper: FieldsWrapper } = this.props
    const propsSubset = _.pick(this.props, ['action', 'method', 'accept'])
    if (typeof propsSubset.action === 'function') delete propsSubset.action

    let { children } = this.props

    if (FieldsWrapper) children = <FieldsWrapper children={children} />

    const className = this.props.className ? this.props.className : 'form-horizontal'

    return (
      <form
        {...propsSubset}
        children={children}
        ref={this.createFormRef}
        onInput={this.onInput.bind(this)}
        onSubmit={this.onSubmit.bind(this)}
        className={className}
        autoComplete="off"
      />
    )
  }
}

export default connect(null, actionProps)(Form)
