import React from 'react';
import cx from 'classnames';
import moment from 'moment';
import Container from 'container';
import { Event } from 'resources';

import { Row, Col, InputGroup, FormGroup, FormControl, Checkbox, Radio, Button, Alert } from 'react-bootstrap';
import { Portlet, Body, FaIcon } from 'components/utilities';
import { Form, Field, FieldError, EmailOnSubmission } from 'components/form';
import { DatePickerModal, UploadFileModal, ConfirmModal } from 'components/modals';
import { AdminCustomEventQuestions, AdminEventPhotos, CustomizePublicEvent, EventOptionalAddons } from 'components/event';
import QuillEditor from 'components/quill';

class ManageEventContainer extends Container {

  formRef = null;

  constructor(props, context = {}) {
    const customFieldCount = 3;

    super(props, {
      data: { isLoading: false },
      event: {
        visibility: 'federation_members',
        cost_type: 'fixed',
      },
      photos: [],
    });

    this.context = context;
    this.state.customFields = _.times(customFieldCount, this.createNewField);
  }

  doAddField = () => {
    const newField = this.createNewField();

    const customFields = _.clone(this.state.customFields);

    this.setState({
      customFields: customFields.concat(newField),
    });
  }

  onChangeFieldOptions = (index) => (e) => {
    const { value } = e.target;
    const options = _.reject(value.split(/ *, */), _.isEmpty);
    const customFields = _.clone(this.state.customFields);
    _.set(customFields, `[${index}].options`, options);
    this.setState({ customFields });
  }

  onCustomFieldChange = (e, name, index) => {
    const value = (e.target.type === 'checkbox') ?
      e.target.checked : e.target.value;

    this.setState((state) => {
      const newState = _.clone(state);
      newState.customFields[index][name] = value;

      if (name === 'select') {
        newState.customFields[index].options = [''];
      }

      newState.event.event_questions = newState.customFields.filter(el => !!el.question);
      return newState;
    });
  }

  onChangeOptionalAddons = (optionalAddons) => {
    const { event } = this.state;
    event.optional_addons = optionalAddons;

    this.setState({ event });
  }

  onDateSelected = (date) => {
    this.setStateEvent('registration_closes_on', date && moment(date).endOf('day'));
  }

  onSubmitStart = () => {
    this.setState({
      disabled: true,
    });
  }

  onSubmitEnd = () => {
    this.setState({
      disabled: false,
    });
  }

  onTicketsChange = (e) => {
    const value = Number(e.target.value.replace(/[^\d]+/, ''));
    this.setStateEvent('maximum_tickets', value);
  }

  onCheckboxChange = (e) => {
    const value = e.target.checked ? 0 : null;
    this.setStateEvent('maximum_tickets', value);
  }

  onApprovalRequiredChange = (e) => {
    const { event } = this.state;
    event.is_approval_required = e.target.value;
    this.setState({ event });
  }

  setStateEvent = (name, val) => {
    const event = _.clone(this.state.event);
    event[name] = val;
    this.setState({ event });
  }

  createNewField = () => {
    const { user } = this.context;

    return {
      question: '',
      mandatory: false,
      with_image: (user.role === 'federation') ? false : undefined,
    };
  }

  renderObjectModals = () => {
    const { event, photos } = this.state;

    return (
      <div>

        <DatePickerModal
          date={event.registration_closes_on || moment().utcOffset(-4).add(1, 'day')}
          minDate={moment().utcOffset(-4).add(1, 'day')}
          onDateSelected={this.onDateSelected}
        />

        <UploadFileModal
          key={(photos || []).length}
          noun="photo"
          onSuccess={(data) => this.onPhotoUpload(data)}
          {...(
            this.scope === 'organization' ?
            { organizationId: this.context.organization.id } :
            { federationId: this.context.user.id }
          )}
        />

        <ConfirmModal
          id={`close:${event.id}`}
          onConfirm={this.doEndRegistration || (() => {})}
          prompt={`Are you sure you'd like to end registration for '${event.name}'?`}
          yes="end registration"
        />

        <ConfirmModal
          id={event.id}
          onConfirm={this.doArchiveEvent || (() => {})}
          prompt={`Are you sure you'd like to delete '${event.name}'?`}
          yes="delete it"
        />

      </div>
    );
  }

  doUpdateVisibility = (e) => {
    const { event } = this.state;
    event.visibility = e.target.value;
    this.setState({ event });
  }

  renderWhoCanSubmit = () => {
    const { event } = this.state;

    return (
      <FormGroup>
        <label htmlFor="type" className="col-md-3 control-label">
          Who can {this.purpose === 'event' ? 'register' : 'submit the form'}?
        </label>
      </FormGroup>
    );
  }

  renderMultipleSubmissions = () => null

  renderAlerts = () => {
    const { event } = this.state;

    if (!event) {
      return null;
    }

    const registrationIsClosed = moment(event.registration_closes_on).isBefore(moment());

    return registrationIsClosed && event.registration_closes_on && (
      <Alert bsStyle="warning">
        Registration is closed for this event.
      </Alert>
    );
  }

  getData() {
    const { event } = this.state;

    const registrationClosesOn = event.registration_closes_on ? (
      moment(event.registration_closes_on).utc().format()
    ) : null;

    const scopeOpt = (this.scope === 'owned_by_organization') ? {
      owner_id: this.context.organization.id,
      owner_type: 'Organization',
    } : {
      owner_id: this.context.user.federation.id,
      owner_type: 'Federation',
    };

    if (this.scope === 'owned_by_organization') {
      if (event.visibility === 'organization_members') {
        event.permitted_organization_ids = [this.context.organization.id];
      }
    }

    return {
      event: _
        .chain(event)
        .pick('description', 'event_questions', 'maximum_tickets', 'permitted_organization_ids', 'optional_addons')
        .merge({
          purpose: this.purpose,
          registration_closes_on: registrationClosesOn,
        })
        .merge(scopeOpt)
        .value(),
    };
  }

  renderContent() {
    const { event } = this.state;

    const data = this.getData();

    return (
      <Portlet boxed>
        <Body>

          <Form
            action={Event[this.formAction]}
            data={data}
            query={`
              event {
                purpose
                visibility
                name
                cost
                cost_type
                description
                maximum_tickets
                registration_closes_on
                event_questions

                organization {
                  name
                }

                document_folder {
                  documents
                  url
                }
              }
            `}
            onSuccess={this.onSuccess}
            onSubmitStart={() => this.onSubmitStart()}
            onSubmitEnd={() => this.onSubmitEnd()}
            {...(event.id ? { id: event.id } : {})}
            formRef={formRef => this.formRef = formRef}
          >

            <Field
              label={`Name of ${this.purpose}`}
              name="event.name"
              defaultValue={event.name}
            />

            <Field
              label="Description"
            >
              <QuillEditor
                onChange={d => this.setStateEvent('description', d)}
                defaultValue={event.description}
              />
              <FieldError name="event.description" />
            </Field>

            <Field
              inputSize={3}
              label={
                <label htmlFor="last-day-to-register">
                  Last day to {this.purpose === 'event' ? 'register' : 'submit'} <br />
                  <span className="text-muted">(optional)</span>
                </label>
              }

            >
              <InputGroup
                onClick={this.openModal('DatePicker')}
                style={{ cursor: 'pointer' }}
              >
                <FormControl
                  id="last-day-to-register"
                  value={event.registration_closes_on ? moment(event.registration_closes_on).format('MMM D, YYYY') : ''}
                  type="text"
                  readOnly
                  style={{ cursor: 'pointer' }}
                />
                <InputGroup.Addon className="bg-white">
                  <FaIcon calendar />
                </InputGroup.Addon>
              </InputGroup>
            </Field>

            {this.purpose === 'event' && (
              <Field
                label="Event photos"
              >
                <AdminEventPhotos
                  photos={this.state.photos || []}
                  doRemovePhoto={this.doRemovePhoto}
                />

                <Button
                  onClick={this.openModal('UploadFile')}
                >
                  Add photo
                </Button>
              </Field>
            )}

            {this.purpose === 'event' && (
              <React.Fragment>
                <FormGroup>
                  <label htmlFor="event.cost" className="col-md-3 control-label">
                    Cost to attend <br />
                    <span className="text-muted">(optional)</span>
                  </label>
                  <Col md={7}>
                    <Radio
                      defaultChecked={event.cost_type === 'variable'}
                      name="event.cost_type"
                      value="variable"
                      onChange={(e) => { this.onChangeOptionalAddons(null); this.updateStateFromInput('event.cost_type')(e); }}
                    >
                      People who are signing up can choose the amount to give.
                    </Radio>

                    <Radio
                      defaultChecked={event.cost_type === 'fixed'}
                      name="event.cost_type"
                      value="fixed"
                      onChange={this.updateStateFromInput('event.cost_type')}
                    >
                      Specify the amount which people who are signing up should give.
                    </Radio>
                    {event.cost_type === 'fixed' && (
                      <React.Fragment>
                        <Field
                          className="m-t-1"
                          inputSize={4}
                          placeholder="free"
                          name="event.cost"
                          defaultValue={event.cost}
                          money
                        />
                        <EventOptionalAddons
                          optionalAddons={event.optional_addons}
                          onChangeOptionalAddons={this.onChangeOptionalAddons}
                        />
                      </React.Fragment>
                    )}
                  </Col>
                </FormGroup>
              </React.Fragment>
            )}

            {this.purpose === 'event' && (
              <Field
                label="Tickets"
              >

                <Checkbox
                  id="tickets"
                  onChange={this.onCheckboxChange}
                  checked={!_.isNil(event.maximum_tickets)}
                >
                  People who register can get multiple tickets
                </Checkbox>
              </Field>
            )}

            {this.purpose === 'event' && (
              <Field
                label="Maximum tickets sold"
                id="maximum-tickets"
                labelFor="maximum-tickets"
                errorKey="event.maximum_tickets"
                onChange={this.onTicketsChange}
                value={
                  _.isNil(event.maximum_tickets) || Number(event.maximum_tickets) === 0 ? '' :
                  event.maximum_tickets
                }
                disabled={_.isNil(event.maximum_tickets)}
                placeholder="Leave blank if an unlimited number of tickets can be sold to this event"
              />
            )}

            {this.renderWhoCanSubmit()}

            {this.renderMultipleSubmissions()}

            {event.visibility === 'open_to_public' && (
              <CustomizePublicEvent
                backgroundColor={event.background_color}
                headerColor={event.header_color}
              />
            )}

            <FormGroup>
              <label htmlFor="event.cost" className="col-md-3 control-label">
                Is approval required?
              </label>
              <Col md={7}>
                <Radio
                  defaultChecked={event.is_approval_required}
                  name="event.is_approval_required"
                  value
                  onChange={this.onApprovalRequiredChange}
                >
                  Yes, submissions need to be approved
                </Radio>

                <Radio
                  defaultChecked={!event.is_approval_required}
                  name="event.is_approval_required"
                  value={false}
                  onChange={this.onApprovalRequiredChange}
                >
                  No, submissions are automatically approved
                </Radio>
              </Col>
            </FormGroup>

            <hr />

            <Row>
              <Col md={9} mdPush={3} className="m-b-1 lh-18">
                When someone {this.purpose === 'event' ? 'registers' : 'submits the form'}, any questions you add here will be asked.
                <br />
                {event.visibility === 'open_to_public' && 'GCM asks everyone that is signing up to fill out their name and email address.'}
              </Col>
            </Row>

            <AdminCustomEventQuestions
              customFields={this.state.customFields}
              onChangeEventQuestions={this.onCustomFieldChange}
              onChangeFieldOptions={this.onChangeFieldOptions}
            />

            <Row>
              <Col md={7} mdPush={3}>
                <Button
                  bsSize="sm"
                  onClick={this.doAddField}
                >
                  Add a field
                </Button>
              </Col>
            </Row>

            <hr />

            <EmailOnSubmission name="event" value={event.email_addresses} />

            <hr />

            <FormGroup>
              <Col md={7} mdPush={3}>
                <Button
                  bsStyle="primary"
                  type="submit"
                  disabled={this.state.disabled}
                  className={cx({
                    'btn-loading': this.state.disabled,
                  })}
                >
                  {_.startCase(this.formAction)} {this.purpose}
                </Button>
              </Col>
            </FormGroup>

          </Form>
        </Body>

      </Portlet>
    );
  }

}

export default ManageEventContainer;
