import React from 'react'
import { connect } from 'react-redux'
import actionProps from 'actions'

export default function (Component, mapState) {
  class AuthorizedComponent extends React.Component {
    shouldComponentUpdate = nextProps => !!nextProps.auth
    render = () => <Component {...this.props} />
  }

  const mapStateToProps = (state, ownProps) => {
    const props = {
      auth: state.get('auth'),
      organization: state.get('organization'),
    }

    if (typeof mapState === 'function') {
      return _.assign({}, mapState(state, ownProps), props)
    }

    return props
  }

  return connect(mapStateToProps, actionProps)(AuthorizedComponent)
}
