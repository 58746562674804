import { apiRequest } from 'actions/use'
import apiWrapper from './helpers'

export default class Stripe extends apiWrapper('/stripes') {
  static createAccountUrl({ onSuccess, onFailure }) {
    apiRequest({
      method: 'POST',
      uri: '/api/stripes/create_account_url',
      on: {
        201: onSuccess,
      },
      onFailure,
    })
  }

  static createBankAccountIntent({ onSuccess, onFailure }) {
    apiRequest({
      method: 'POST',
      uri: '/api/stripes/create_bank_account_intent',
      on: {
        201: onSuccess,
      },
      onFailure,
    })
  }

  static createBankAccount(data) {
    return new Promise((resolve, reject) => {
      apiRequest({
        method: 'POST',
        uri: '/api/stripes/create_bank_account',
        data,
        on: {
          201: resolve,
        },
        onFailure: reject,
      })
    })
  }

  static uploadVerificationInfo({ data }) {
    return new Promise((resolve, reject) => {
      apiRequest({
        method: 'POST',
        uri: '/api/stripes/verification_info',
        data,
        on: {
          201: resolve,
        },
        onFailure: reject,
      })
    })
  }
}
