import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { Avatar } from 'components/utilities';

const { func, object, string } = PropTypes;

class Thumbnail extends React.Component {

  static propTypes = {
    avatar: object.isRequired,
    popoverTitle: string.isRequired,
    popoverId: string.isRequired,
    onImageClick: func,
  }

  render() {
    const { avatar, popoverTitle, popoverId, onImageClick, children } = this.props;

    const popover = (
      <Popover
        id={popoverId}
        title={popoverTitle}
      >
        <Avatar
          user={avatar}
          width={128}
          height={128}
          className="avatar"
        />
      </Popover>
    );

    return (
      <span>
        <OverlayTrigger
          trigger={['focus', 'hover']}
          placement="left"
          overlay={popover}
        >
          <div className={cx('d-inline-block c-pointer', { 'm-r-1': children })}>
            <Avatar
              user={avatar}
              width={32}
              height={32}
              onClick={onImageClick}
            />
          </div>
        </OverlayTrigger>

        {children}
      </span>
    );
  }
}

export default Thumbnail
