import notificationHandler from './notification-handler';
import { notify } from 'actions/notifications';

@notificationHandler
export default class MessageNotificationHandler {
  static type = 'message';

  static handle(dispatch, action) {
    const { payload } = action;

    dispatch(notify({
      message: payload.message,
      type: payload.status,
    }));
  }
}
