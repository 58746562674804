import React from 'react';
import { Units } from 'components/utilities';

export default function ({ votes }) {
  return votes && votes.needed > 0 ? (
    <Units
      count={votes.needed}
      noun="vote needed"
      plural="votes needed"
    />
  ) : null;
}
