/* eslint-disable new-cap */
import React from 'react'
import { isGranted } from 'helpers'
import { Route, IndexRoute } from 'react-router'
import { ensureFeature } from '../helpers.js'

import { FINANCES_FEATURE } from 'resources/feature'

export default function federationOrganizationRoutes(store) {
  const Federation = path => (_, cb) =>
    require.ensure([], require => cb(null, require('chunks/federation')[path]))

  return (
    <Route path="organizations">
      <IndexRoute getComponent={Federation('Organizations')} />
      <Route path=":id" getComponent={Federation('Organization')}>
        <IndexRoute
          onEnter={((_, nextState, replace) => {
            const user = store.getState().get('auth').user
            const page = isGranted(user, FINANCES_FEATURE) ? 'financials' : 'members'
            const pathname = store
              .getState()
              .get('route')
              .get('locationBeforeTransitions')
              .get('pathname')
            replace(`${pathname}/${page}`)
          }).bind(this, store)}
        />

        <Route onEnter={ensureFeature(store, FINANCES_FEATURE)}>
          <Route path="financials" getComponent={Federation('Organization/Financials')} />
          <Route path="transactions" getComponent={Federation('Organization/Transactions')} />
          <Route
            path="transactions/:billing_category_id"
            getComponent={Federation('Organization/Transactions')}
          />
          <Route
            path="national-balance"
            getComponent={Federation('Organization/NationalBalance')}
          />
          <Route path="taxes" getComponent={Federation('Organization/Taxes')} />
          <Route path="bill-pay" getComponent={Federation('Organization/BillPay')} />
          <Route
            path="bill-pay/:account_id"
            getComponent={Federation('Organization/BillPay/show')}
          />
        </Route>

        <Route path="advisors" getComponent={Federation('Organization/Advisors')} />
        <Route path="committee" getComponent={Federation('Organization/Committee')} />
        <Route path="details" getComponent={Federation('Organization/Details')} />
        <Route path="executive-board" getComponent={Federation('Organization/ExecutiveBoard')} />
        <Route path="members" getComponent={Federation('Organization/Members')} />
        <Route path="documents" getComponent={Federation('Organization/Documents')} />
        <Route
          path="volunteers-and-staff"
          getComponent={Federation('Organization/VolunteersAndStaff')}
        />
        <Route path="visitations" getComponent={Federation('Organization/Visitations')} />
        <Route path="addresses" getComponent={Federation('Organization/Addresses')} />
        <Route path="donations" getComponent={Federation('Organization/Donations')} />
        <Route path="donations/:page" getComponent={Federation('Organization/Donations/show')} />
        <Route path="statistics" getComponent={Federation('Organization/Statistics')} />
        <Route
          path="report-submissions"
          getComponent={Federation('Organization/ReportSubmissions')}
        />
        <Route
          path="report-submissions/:submission_id"
          getComponent={Federation('Organization/ReportSubmissions/show')}
        />
        <Route
          path="payment-plan"
          getComponent={Federation('Organization/OrganizationPaymentPlans')}
        />
      </Route>
    </Route>
  )
}
