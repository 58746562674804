export default function memoizeByProps(...properties) {
  return (target, name, descriptor) => {
    const func = _.memoize(descriptor.value);

    // eslint-disable-next-line
    descriptor.value = function () {
      return func.call(this, ...properties.map(property => {
        const value = _.get(this, property);
        return typeof value === 'function' ? value.call(this) : value;
      }));
    };

    return descriptor;
  };
}
