import moment from 'moment';
import T from './types';

export function ensureTerms(organizationId) {
  return {
    type: T.ENSURE_TERMS,
    organizationId,
  };
}

export function fetchTerms(organizationId) {
  return {
    type: T.FETCH_TERMS,
    organizationId,
  };
}

export function setTerms(organizationId, unsortedTerms) {

  const terms = _
    .chain(unsortedTerms)
    .sortBy(t => moment(t.started_on).unix())
    .reverse()
    .value();

  // Add ended_on
  _.each(terms, (term, i) => {
    let endedOn = null;
    if (i === 0) {
      endedOn = moment();
    } else {
      endedOn = moment(terms[i - 1].started_on).subtract(1, 'day');
    }

    // eslint-disable-next-line no-param-reassign
    term.ended_on = endedOn.format('YYYY-MM-DD');
  });

  return {
    type: T.SET_TERMS,
    organizationId,
    terms,
  };
}

export function addTerm(term) {
  return {
    type: T.ADD_TERM,
    term,
  };
}

export function updateTerm(term) {
  return {
    type: T.UPDATE_TERM,
    term,
  };
}

export function removeTerm(id) {
  return {
    type: T.REMOVE_TERM,
    id,
  };
}

export function clearTerms() {
  return {
    type: T.CLEAR_TERMS,
  };
}
