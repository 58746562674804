import { put } from 'redux-saga/effects';
import { setOrganization, clearOrganization } from 'actions/organization';
import { push } from 'react-router-redux';
import api from './api';
import { generatePayload } from '../resources/helpers';
import { MEMBER_GLOBAL_QUERY } from '../resources/organization';

export function* fetchOrganization({ organizationId, location }) {
  try {
    const { data: organization } = yield api({
      method: 'GET',
      uri: `/api/organizations/${organizationId}`,
      data: generatePayload({
        query: MEMBER_GLOBAL_QUERY,
      }),
    });

    yield put(setOrganization(organization));

    if (location) {
      yield put(push(location));
    }

  } catch (error) {
    yield put(clearOrganization());
  }
}
