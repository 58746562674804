import React from 'react'
import PropTypes from 'prop-types'
import Header from './header'
import Item from './item'

const { shape, number } = PropTypes

export default class RootMenu extends React.Component {
  static contextTypes = {
    user: shape({
      id: number.isRequired,
    }).isRequired,
  }

  /*
   * This is to collapse the root menu for demos
   */
  constructor(props) {
    super(props)

    this.state = {
      show: true, // context.user.id !== 5
    }
  }

  isSuperDuper() {
    const { user } = this.context

    return [2, 3, 4].indexOf(user.id) !== -1
  }

  render = () => {
    const { show } = this.state

    if (!show) {
      return (
        <ul className="sidebar-menu">
          <Header>Super</Header>
          <li>
            <a
              onClick={() =>
                this.setState(state => ({
                  show: !state.show,
                }))
              }
              className="c-pointer"
            >
              <i className="fa fa-arrows-v"></i>Expand
            </a>
          </li>
        </ul>
      )
    }

    return (
      <ul className="sidebar-menu">
        <Header>Super</Header>
        <Item url="/super/organizations" icon="list">
          Chapters
        </Item>
        <Item url="/super/federations" icon="bank">
          Nationals
        </Item>
        <Item url="/super/transactions" icon="money">
          Transactions
        </Item>
        <Item url="/super/transfers" icon="exchange">
          Transfers
        </Item>
        <Item url="/super/accounts" icon="table">
          Account balances
        </Item>
        <Item url="/super/federation-balances" icon="table">
          National balances
        </Item>
        <Item url="/super/bill-pay" icon="usd" backgroundIcon="file">
          BillPay
        </Item>
        <Item url="/super/g-money" icon="credit-card">
          gMoney
        </Item>
        <Item url="/super/collections" icon="gavel">
          Collections
        </Item>
        <Item url="/super/taxes" icon="institution" id="Taxes">
          Tax information
        </Item>
        <Item url="/super/alumni" icon="users" id="AlumniList">
          Alumni
        </Item>
        <Item url="/super/end-of-semester" icon="book">
          End of semester
        </Item>
        {this.isSuperDuper() && (
          <Item url="/super/gcm-fees" icon="thumbs-up">
            GCM Fees
          </Item>
        )}
        <Item url="/super/messaging" icon="comment">
          Messaging
        </Item>
        <Item url="/super/required_documents" icon="file-text">
          Required documents
        </Item>
        <Item url="/super/payment-plans" icon="calculator">
          Failed payments plan
        </Item>
      </ul>
    )
  }
}
