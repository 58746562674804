import React from 'react';
import PropTypes from 'prop-types';

import { Date, Money } from 'components/utilities';
import Preview from 'components/documents/preview';

const { array, arrayOf, number, oneOf, shape, string } = PropTypes;

const RegistrationDetails = ({ event, eventRegistrations }) => (
  <div>
    {_.map(eventRegistrations, (eventRegistration, i) => (
      <div key={eventRegistration.id} data-id={eventRegistration.id}>

        <dl className="dl-horizontal dl-thick list-spaced">

          <dt>{event.purpose === 'event' ? 'Registration' : 'Submission'} date</dt>
          <dd>
            <Date date={eventRegistration.created_at} />
          </dd>

          {(eventRegistration.tickets > 1 || eventRegistration.amount > 0) && (
            <div>
              <dt>Tickets</dt>
              <dd>{eventRegistration.tickets || 1}x <Money amount={event.cost_type === 'variable' ? 0 : event.cost} /></dd>
            </div>
          )}

          { _.map(eventRegistration.event_optional_addons, (item) => (
            <div key={item.name}>
              <dt>{item.name}</dt>
              <dd>
                {item.quantity || 0}x <Money amount={item.cost} />
              </dd>
            </div>
          ))}

          {eventRegistration.amount > 0 && (
            <div>
              <dt>{event.cost_type === 'variable' ? 'Contributed' : 'Total cost'}</dt>
              <dd>
                <Money amount={eventRegistration.amount} />
              </dd>
            </div>
          )}

          {_.map(eventRegistration.event_answers, (ans, index) => (
            <div key={index} className="event-registration-answer">
              <dt title={ans.question}>{ans.question}</dt>
              <dd>
                {_.isArray(ans.answer) ? ans.answer.join(', ') : ans.answer }
                {ans.document && (
                  <div className="p-t-1">
                    <Preview document={ans.document} />
                  </div>
                )}
              </dd>
            </div>
          ))}

        </dl>

        {(i + 1) < eventRegistrations.length && <hr />}

      </div>
    ))}
  </div>
);

RegistrationDetails.propTypes = {
  event: shape({
    cost_type: oneOf(['free', 'fixed', 'variable']).isRequired,
  }).isRequired,
  eventRegistrations: arrayOf(shape({
    tickets: number,
    amount: number.isRequired,
    event_answers: array.isRequired,
    created_at: string.isRequired,
  })),
};

export default RegistrationDetails;
