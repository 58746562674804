import { Quill } from 'react-quill';

const BlockEmbed = Quill.import('blots/block/embed');

class S3ImageBlot extends BlockEmbed {
  static create(data) {
    const node = super.create(data);

    node.setAttribute('src', data.src);
    node.setAttribute('data-s3-path', data.s3Path);

    return node;
  }
  static value(domNode) {
    return {
      src: domNode.getAttribute('src'),
      s3Path: domNode.getAttribute('data-s3-path'),
    };
  }
}

S3ImageBlot.blotName = 's3Image';
S3ImageBlot.tagName = 'img';

Quill.register({ 'formats/s3Image': S3ImageBlot });
