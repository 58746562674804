import React from 'react'
import ReactQuill from 'react-quill'
import ImageHandler from './image-handler'
import { memoizeByProps } from 'helpers'
import { func, string, number, bool } from 'prop-types'

import 'react-quill/dist/quill.snow.css'
import './styles.less'

class QuillEditor extends React.Component {
  static propTypes = {
    value: string,
    enableImages: bool,
    defaultValue: string,
    placeholder: string,
    minHeight: number,
    onChange: func.isRequired,
  }

  static formats = ['bold', 'italic', 'underline', 'list', 'bullet', 'link', 's3Image']

  constructor(props) {
    super(props)
    this.reactEditorRef = React.createRef()

    this.state = {
      rawInput: props.value || props.defaultValue || '',
    }
  }

  @memoizeByProps('props.enableImages')
  getModules() {
    const { enableImages } = this.props

    return {
      toolbar: {
        container: _.concat(
          [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], ['link']],
          enableImages ? [['image']] : [],
          [['clean']]
        ),
        handlers: { image: () => this.state.handleImage() },
      },
    }
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  onChange = rawInput => {
    const { onChange } = this.props

    this.setState({ rawInput })
    onChange(rawInput)
  }

  render() {
    const { placeholder, minHeight, enableImages } = this.props
    const { rawInput } = this.state

    return (
      <React.Fragment>
        {this.reactEditorRef.current && (
          <ImageHandler
            editor={this.reactEditorRef.current.getEditor()}
            onReady={({ handle }) => {
              if (this.mounted) {
                this.setState({ handleImage: handle })
              }
            }}
          />
        )}

        <ReactQuill
          ref={this.reactEditorRef}
          theme="snow"
          modules={this.getModules(enableImages)}
          formats={QuillEditor.formats}
          placeholder={placeholder}
          onChange={this.onChange}
          style={{ minHeight }}
          bounds=".form-group"
          value={rawInput}
        />
      </React.Fragment>
    )
  }
}

export default QuillEditor
