import React from 'react'
import { Money } from 'components/utilities'
import Container from 'container'
import { payments } from 'helpers'
import Humanize from 'humanize-plus'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'

const { array, number, shape, bool } = PropTypes

export default class PaymentContainer extends Container {
  static contextTypes = {
    organization: shape({
      cc_upcharge: bool.isRequired,
    }),
  }

  static propTypes = {
    balance: number.isRequired,
    paymentMethods: array.isRequired,
  }

  constructor(props, state = {}) {
    const fullState = _.assign(state, {
      amount: Math.max(props.balance, 0),
      selectedPaymentMethod: _.first(props.paymentMethods),
      errors: null,
    })

    super(props, fullState)
  }

  componentWillReceiveProps(nextProps) {
    const { balance } = nextProps
    if (this.props.balance !== balance) {
      this.setState({
        amount: Math.max(balance, 0),
      })
    }

    super.componentWillReceiveProps(nextProps)
  }

  applyUpcharge = () => {
    const { organization } = this.context
    return organization && organization.cc_upcharge
  }

  getUpchargeAmount() {
    const { amount, selectedPaymentMethod } = this.state
    if (!selectedPaymentMethod) return 0
    const { method } = selectedPaymentMethod

    return payments.getUpchargeAmount(amount, {
      method,
      ccUpcharge: this.applyUpcharge(),
    })
  }

  getChargedAmount = () => {
    const { amount } = this.state
    return (amount + this.getUpchargeAmount()).toFixed(2)
  }

  renderUpCharge = () => {
    if (this.getUpchargeAmount() === 0) {
      return null
    }

    const { selectedPaymentMethod } = this.state
    if (!selectedPaymentMethod) {
      return null
    }

    const { balance } = this.props
    const settleAmount = Math.max(0, balance)

    return (
      <div>
        <Row>
          <Col md={9} mdPush={3}>
            <em>
              Your {selectedPaymentMethod.method === 'card' ? 'card' : 'account'} will be{' '}
              {selectedPaymentMethod.method === 'card' ? 'charged' : 'debited'}{' '}
              <Money amount={this.getChargedAmount()} />.
            </em>
          </Col>
        </Row>
        {settleAmount > 0 ? (
          <Row>
            <Col md={9} mdPush={3} className="m-t-05">
              <em>
                Paying by {this.applyUpcharge() ? 'cash or check' : 'credit card, cash, or check'}{' '}
                will settle your balance for <Money amount={settleAmount} />.
              </em>
            </Col>
          </Row>
        ) : null}
      </div>
    )
  }

  doSelectPaymentMethod(selectedPaymentMethod) {
    this.setState({
      selectedPaymentMethod,
      errors: false,
    })
  }

  onAmountChange = formatted => {
    const amount = parseFloat(formatted.replace(/[^0-9.]+/, '')) || 0
    this.setState({ amount, errors: null })
  }

  onPaymentCreate = ({ data }) => {
    const amount = _.toNumber(data.data.member_credit)

    /* global fbq */
    if ('fbq' in window) {
      fbq('trackCustom', 'Payment', { amount })
    }

    const amountFormatted = Humanize.formatNumber(amount, 2)
    this.notify(`Your $${amountFormatted} payment was successful!`)
    this.closeModal({ closeAll: true })
    this.props.onSuccess(data)
  }

  onPaymentCreateFailure = ({ payment }) => {
    this.setState({
      error: payment._[0],
    })
  }

  onSubmitStart = () => this.setState({ error: null })

  onError = errors => this.setState({ errors })
}
