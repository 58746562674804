import { LOCATION_CHANGE } from 'react-router-redux';

export default function (store, action, routeMatcher) {
  const prevLocation = store.getState().get('route').get('locationBeforeTransitions');

  if (action.type === LOCATION_CHANGE) {
    const prev = prevLocation ? prevLocation.get('pathname') : '';
    const next = action.payload.pathname;
    if (prev !== next) {
      // when sub route isn't changing
      if (routeMatcher && prev.match(routeMatcher) && next.match(routeMatcher)) {
        return false;
      }

      return {
        prev: prevLocation ? prevLocation.toJS() : {},
        next: action.payload,
      };
    }
  }

  return false;
}
