import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect, orgHelpers } from 'helpers';
import Container from 'container';
import { Event, Report } from 'resources';

import Header from './header';
import Item from './item';
import ContributionTrackers from './contribution-trackers';
import KappaSigma from './kappa-sigma';

const { shape, number, string } = PropTypes;

class NationalMenu extends Container {

  static contextTypes = {
    user: shape({
      role: string.isRequired,
    }),
    organization: shape({
      id: number.isRequired,
      federation: shape({
        id: number.isRequired,
      }),
    }),
  }

  resources = {
    list: Event,
  }

  listResourceAction = 'listByUser'

  listQuery = `
    event {
      name
      purpose
      owner_type
    }
  `

  sortListBy = 'purpose'

  listScope = () => {
    const { user } = this.context;

    return {
      user_id: user.id,
      only_open: true,
    };
  }

  filterListBy = event => event.owner_type !== 'Organization'

  componentDidMount() {
    super.componentDidMount();

    const { user, organization } = this.context;

    if (user.role === 'admin') {
      Report.list({
        data: {
          start_date: moment().format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD'),
          federation_id: organization.federation.id,
        },
        query: `
          report {
            title
          }
        `,
        onSuccess: this.updateState('reports'),
      });
    }
  }

  renderEvents = (type, url) => {
    const data = this.getList();
    const filteredData = _.filter(data, (e) => e.purpose === type);
    const icon = type === 'event' ? 'calendar' : 'file-text-o';

    if (filteredData.length > 6) {
      return <Item url={url} icon={icon}>{`View all ${filteredData.length} ${type}s here`}</Item>;
    }

    return _.map(filteredData, ({ id, name }) => (
      <Item
        key={id}
        url={`/event-registrations/${id}/new`}
        icon={icon}
      >
        {name}
      </Item>
    ));
  }


  render() {
    const { reports } = this.state;
    const { organization } = this.context;

    return (
      <ul className="sidebar-menu">
        <Header>{organization.federation.name}</Header>
        <KappaSigma />
        {_.map(reports, ({ id, title }) => (
          <Item
            key={id}
            url={`/admin/reports/${id}`}
            icon="file-text-o"
          >
            {title}
          </Item>
        ))}
        {this.renderEvents('event', '/event-registrations/federation')}
        {this.renderEvents('form', '/form-submissions')}
        <ContributionTrackers />
        {orgHelpers.isNationalOrganization(organization) && (
          <Item url="/important-contacts" icon="address-book">
            Important Contacts
          </Item>
        )}
      </ul>
    );
  }

}

export default connect(NationalMenu);
