import React from 'react';
import PropTypes from 'prop-types';

import { Name } from 'components/utilities';
import formatPhone from 'utils/format-phone';

import Header from './header';
import Item from './item';

const { shape, string } = PropTypes;

const PrimaryAdmin = ({ organization }) => {
  if (!organization || !organization.primary_admin) {
    return null;
  }

  const { user } = organization.primary_admin;

  const phoneNumber = user.phone_number && (
    <Item url={`tel:${user.phone_number}`} icon="phone">{formatPhone(user.phone_number)}</Item>
  );

  return (
    <ul className="sidebar-menu">
      <Header>Have questions? Need help?</Header>
      <Item url={`tel:${user.phone_number}`} icon="user">
        <Name user={user} natural />
      </Item>
      {phoneNumber}
      <Item url={`mail:${user.email}`} icon="envelope">{user.email}</Item>
    </ul>
  );
};

PrimaryAdmin.propTypes = {
  organization: shape({
    primary_admin: shape({
      user: shape({
        phone_number: string,
        email: string,
      }),
    }),
  }).isRequired,
};

export default PrimaryAdmin;
