import T from './types'

export function setActiveParent(id) {
  return {
    type: T.SET_ACTIVE_PARENT,
    id,
  }
}

export function setParents(parents) {
  return {
    type: T.SET_PARENTS,
    parents,
  }
}
