import apiWrapper, { getMethod, updateMethod } from './helpers';

export default class ReportSubmission extends apiWrapper('/report_submissions') {

  static approve = updateMethod('/report_submissions', 'approve')

  static reject = updateMethod('/report_submissions', 'reject')

  static membershipReports = getMethod('/report_submissions/membership_reports')

}
