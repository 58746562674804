import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, InputGroup, FormControl, Button } from 'react-bootstrap';

const { string, bool } = PropTypes;

export default class Search extends React.Component {

  static contextTypes = {
    onSearch: PropTypes.func.isRequired,
  }

  static propTypes = {
    className: string,
    disabled: bool,
  }

  onSearch = e => this.context.onSearch(e.target.value);

  render = () => {
    const { className, disabled } = this.props;

    return (
      <form
        onSubmit={e => e.preventDefault()}
        onInput={this.onSearch.bind(this)}
        className={`search-bar hidden-print ${className}`}
      >
        <FormGroup style={{ marginBottom: 0 }}>
          <InputGroup>
            <FormControl placeholder="Search..." disabled={disabled} />
            <InputGroup.Button>
              <Button className="btn-md" disabled={disabled}>
                <i className="fa fa-search" />
              </Button>
            </InputGroup.Button>
          </InputGroup>
        </FormGroup>
      </form>
    );
  }

}
