import { apiRequest } from 'actions/use'
import apiWrapper, { updateMethod } from './helpers'

export default class Meeting extends apiWrapper('/meetings') {
  static access = updateMethod('/meetings', 'access')

  static members = ({ data: { id }, onSuccess, onFailure }) =>
    apiRequest({
      method: 'GET',
      uri: `/api/meetings/${id}/members`,
      on: {
        200: onSuccess,
      },
      onFailure,
    })
}
