import ReactDropzone from 'react-dropzone';

export default class Dropzone extends ReactDropzone {

  // Override parent method in order to disable validation
  allFilesAccepted(files) {
    if (this.dragTargets.length) {
      return files;
    }

    return super.allFilesAccepted(files);
  }
}
