import React from 'react';
import { oneOfType, object, array, func, shape } from 'prop-types';
import { connect } from 'helpers';

import { Portlet, Body } from 'components/utilities';
import { CustomEventQuestions } from 'components/event';

const Questions = ({ document, onUpdateAcceptance, actions, errors, acceptance }) => {

  const onChangeCustomAnswer = (value, index, field = 'answer') => {
    const { answers } = acceptance;
    _.set(answers, `[${index}].${field}`, value);
    onUpdateAcceptance('answers', answers);
  };

  const onChangeCustomCheckboxAnswer = (target, index) => {
    const { answers } = acceptance;
    const newValue = target.checked ?
        [..._.get(answers, `[${index}].answer`, []), target.value] :
        _.remove(_.get(answers, `[${index}].answer`, []), ans => ans !== target.value);
    _.set(answers, `[${index}].answer`, newValue);
    onUpdateAcceptance('answers', answers);
  };

  const documentWithQuestions = _
    .chain(document)
    .merge({ event_questions: document.custom_questions })
    .value();

  return (

    <Portlet boxed>
      <Body>

        <CustomEventQuestions
          event={documentWithQuestions}
          errors={_.get(errors, 'document.answers.[0]')}
          onChangeEventAnswer={onChangeCustomAnswer}
          onChangeEventCheckboxAnswer={onChangeCustomCheckboxAnswer}
          openModal={actions.openModal}
          closeModal={actions.closeModal}
        />

        {'answers' in errors && (
        <p>
          <strong className="text-danger">{errors.answers}</strong>
        </p>
        )}

      </Body>
    </Portlet>
  );
};

Questions.propTypes = {
  document: shape({
    event_questions: array,
  }).isRequired,
  errors: oneOfType([array, object]).isRequired,
  onUpdateAcceptance: func.isRequired,
};

export default connect(Questions);
