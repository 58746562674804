/* eslint-disable new-cap */
import React from 'react'
import { Route, IndexRoute, IndexRedirect } from 'react-router'

export default function superOrganizationRoutes() {
  const Super = path => (_, cb) =>
    require.ensure([], require => cb(null, require('chunks/super')[path]))

  return (
    <Route path="organizations">
      <IndexRoute getComponent={Super('Organizations')} />
      <Route path="archived" getComponent={Super('Organizations/archived')} />
      <Route path=":id" getComponent={Super('Organizations/show')}>
        <IndexRedirect to="members" />
        <Route path="members" getComponent={Super('Organizations/Members')} />
        <Route path="archived" getComponent={Super('Organizations/Archived')} />
        <Route path="archived/:page" getComponent={Super('Organizations/Archived')} />
        <Route path="collections" getComponent={Super('Organizations/Collections')} />
        <Route path="statistics" getComponent={Super('Organizations/Statistics')} />
        <Route path="accounts" getComponent={Super('Organizations/Accounts')} />
        <Route path="account-balances" getComponent={Super('Organizations/AccountBalances')} />
        <Route path="g-money" getComponent={Super('Organizations/G-Money')} />
        <Route path="transfers" getComponent={Super('Organizations/Transfers')} />
        <Route path="transfers/:page" getComponent={Super('Organizations/Transfers')} />
        <Route path="adjustments" getComponent={Super('Organizations/Adjustments')} />
        <Route path="adjustments/:page" getComponent={Super('Organizations/Adjustments')} />
        <Route path="bill-pay" getComponent={Super('Organizations/BillPay')} />
        <Route path="bill-pay/:account_id" getComponent={Super('Organizations/BillPay/show')} />
        <Route path="bills" getComponent={Super('Organizations/Bills')} />
        <Route path="bills/:page" getComponent={Super('Organizations/Bills')} />
        <Route path="settings" getComponent={Super('Organizations/Settings')} />
        <Route path="edit-site" getComponent={Super('Organizations/EditSite')} />
        <Route path="terms" getComponent={Super('Organizations/Terms')} />
        <Route path="taxes" getComponent={Super('Organizations/Taxes')} />
        <Route path="import" getComponent={Super('Organizations/Import')} />
        <Route path="remove" getComponent={Super('Organizations/Remove')} />
        <Route path="national-balance" getComponent={Super('Organizations/NationalBalance')} />
        <Route path="bank-account" getComponent={Super('Organizations/BankAccount')} />
        <Route
          path="archive-transactions"
          getComponent={Super('Organizations/ArchiveTransactions')}
        />
      </Route>
    </Route>
  )
}
