import { apiRequest } from 'actions/use';
import apiWrapper, { generatePayload } from './helpers';

export default class Transfer extends apiWrapper('/transfers') {

  static createGcmFee({ data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'POST',
      uri: '/api/transfers/create_gcm_fee',
      data: generatePayload({ data, query }),
      on: {
        201: onSuccess,
      },
      onFailure,
    });
  }

  static pending({ data, onSuccess, onFailure }) {
    apiRequest({
      method: 'GET',
      uri: '/api/transfers/pending',
      data,
      on: {
        200: onSuccess,
      },
      onFailure,
    });
  }
}
