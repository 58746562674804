import T from '../actions/types';

export default (state = null, action) => {
  if (action.type === T.SET_ACTIVE_ITEM) {
    return action.title;
  }

  return state;

};
