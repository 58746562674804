import { apiRequest } from 'actions/use';
import apiWrapper, { generatePayload, updateMethod, list } from './helpers';

export default class Member extends apiWrapper('/members') {

  static sendToCollections = updateMethod('/members', 'send_to_collections')
  static removeFromCollections = updateMethod('/members', 'remove_from_collections')
  static collectionsNotes = updateMethod('/members', 'collections_notes')

  static ledgers({ id, onSuccess }) {
    apiRequest({
      method: 'GET',
      uri: `/api/members/${id}/ledgers`,
      on: {
        200: onSuccess,
      },
    });
  }

  static quickNotes({ id, data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'PUT',
      uri: `/api/members/${id}/quick_notes`,
      data: generatePayload({ data, query }),
      onSuccess,
      onFailure,
    });
  }

  static budgets({ id, data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'PUT',
      uri: `/api/members/${id}/budgets`,
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    });
  }

  static groups({ id, data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'PUT',
      uri: `/api/members/${id}/groups`,
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    });
  }

  static sendBalanceEmails(id) {
    return new Promise((onSuccess, onFailure) => {
      apiRequest({
        method: 'PUT',
        uri: `/api/members/${id}/send_balance_emails`,
        onSuccess,
        onFailure,
      });
    });
  }

  static history({ id, onSuccess }) {
    apiRequest({
      method: 'GET',
      uri: `/api/members/${id}/history`,
      on: {
        200: onSuccess,
      },
    });
  }

  static listSearch(opts) {
    list('/members/list_search', opts);
  }

  static search(opts) {
    list('/members/search', opts);
  }

  static archived({ data, query, onSuccess }) {
    apiRequest({
      method: 'GET',
      uri: '/api/members/archived',
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
    });
  }
}

export const GRADES = {
  freshman1st: '1st semester Freshman',
  freshman2nd: '2nd semester Freshman',
  sophomore1st: '1st semester Sophomore',
  sophomore2nd: '2nd semester Sophomore',
  junior1st: '1st semester Junior',
  junior2nd: '2nd semester Junior',
  senior1st: '1st semester Senior',
  senior2nd: '2nd semester Senior',
  other: 'Other',
};
