import React from 'react';
import { object, bool } from 'prop-types';
import cx from 'classnames';

import { ExpandableImage } from 'components/utilities';

const Photos = ({ event, isPublic = false }) => {
  const documents = _.get(event, 'document_folder.documents', []);

  return (
    <div className={cx('clearfix photo-wrapper', { 'is-flex': isPublic })}>
      {_.map(documents, ({ url }, index) => (
        <ExpandableImage
          key={index}
          image={url}
          className={cx({ 'pull-left': !isPublic, 'p-r-1': isPublic && index !== documents.length - 1 })}
        >
          <img
            src={url}
            role="presentation"
            className={cx('event-photo d-inline m-b-1', { 'm-r-2': !isPublic })}
          />
        </ExpandableImage>
      ))}
    </div>
  );
};

Photos.propTypes = {
  event: object.isRequired,
  isPublic: bool,
};

export default Photos;
