import React from 'react'
import PropTypes from 'prop-types'
import { Parent } from 'resources'

import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const { func, object, arrayOf } = PropTypes

export default class ParentBillDelivery extends React.Component {
  static propTypes = {
    user: object.isRequired,
    parent: object.isRequired,
    member: object.isRequired,
    allParents: arrayOf(object).isRequired,
    onParentUpdate: func.isRequired,
  }

  static defaultProps = {
    onParentUpdate: () => {},
  }

  constructor(props) {
    super(props)

    this.state = {
      send_email_bills: props.parent.send_email_bills,
      send_physical_bills: props.parent.send_physical_bills,
      send_sms_reminders: props.parent.send_sms_reminders,
    }
  }

  onUpdateBillDelivery = ({ data }) => this.props.onParentUpdate({ data })

  doUpdateBillDelivery = type => e => {
    this.setState({ [type]: e.target.checked }, () => {
      const parent = {
        send_email_bills: this.state.send_email_bills,
        send_physical_bills: this.state.send_physical_bills,
        send_sms_reminders: this.state.send_sms_reminders,
      }

      Parent.updateDeliverySettings({
        id: this.props.parent.id,
        query: `
          parent {
            send_email_bills
            send_physical_bills
            send_sms_reminders

            data { member_count }

            user {
              first_name
              last_name
              phone_number
              email

              address {
                address
                address_2
                city
                state
                postal_code
                country
              }
            }
            member {
              user {
                first_name
                last_name
              }
            }
          }
        `,
        data: { parent },
        onSuccess: this.onUpdateBillDelivery,
      })
    })
  }

  render() {
    const { user, parent, allParents, member } = this.props

    const sendPhysicalBillsCheckboxIsVacant = !_.some(allParents, 'send_physical_bills')

    const physicalBillDeliverySelectionEnabled =
      sendPhysicalBillsCheckboxIsVacant || parent.send_physical_bills
    const greenDeliveryEnabled = member.no_physical_bills

    const checkboxDisabled =
      _.isNull(user.address) || !physicalBillDeliverySelectionEnabled || greenDeliveryEnabled

    const physicalBillDeliveryCheckbox = (
      <label htmlFor={`physicalBills-${parent.id}`}>
        <input
          name="parent.send_physical_bills"
          id={`physicalBills-${parent.id}`}
          type="checkbox"
          checked={this.state.send_physical_bills}
          disabled={checkboxDisabled}
          onChange={this.doUpdateBillDelivery('send_physical_bills')}
        />
        Send physical bills
      </label>
    )

    return (
      <div className="notification-settings">
        <div className="checkbox">
          <label htmlFor={`smsReminders-${parent.id}`}>
            <input
              name="parent.send_sms_reminders"
              id={`smsReminders-${parent.id}`}
              type="checkbox"
              disabled={!user.phone_number}
              checked={this.state.send_sms_reminders}
              onChange={this.doUpdateBillDelivery('send_sms_reminders')}
            />
            Send monthly SMS bill reminders
          </label>
        </div>

        <div className="checkbox">
          <label htmlFor={`emailBills-${parent.id}`}>
            <input
              name="parent.send_email_bills"
              id={`emailBills-${parent.id}`}
              type="checkbox"
              checked={this.state.send_email_bills}
              disabled={!user.email}
              onChange={this.doUpdateBillDelivery('send_email_bills')}
            />
            Send email bills
          </label>
        </div>

        <div className="checkbox">
          {!physicalBillDeliverySelectionEnabled &&
          !_.isNull(user.address) &&
          !greenDeliveryEnabled ? (
            <OverlayTrigger
              overlay={
                <Tooltip id={`bill-${parent.id}`}>
                  Physical bills can be sent to only one address
                </Tooltip>
              }
            >
              {physicalBillDeliveryCheckbox}
            </OverlayTrigger>
          ) : (
            physicalBillDeliveryCheckbox
          )}
          {greenDeliveryEnabled ? (
            <span className="help-block">
              No bills will be snail mailed because green delivery is active
            </span>
          ) : (
            ''
          )}
        </div>
      </div>
    )
  }
}
