import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'components/form';

import { Row, Col, FormControl, Checkbox } from 'react-bootstrap';
const { array, func } = PropTypes;

const AdminCustomEventQuestions = ({
  customFields,
  onChangeEventQuestions,
  onChangeFieldOptions,
}) => (
  <div>
    { _.map(customFields, (field, index) =>
      <div
        key={index}
      >
        <Field
          labelFor={`custom-field-${index + 1}`}
          label={`Custom field #${index + 1}`}
        >
          <FormControl
            type="text"
            id={`custom-field-${index + 1}`}
            defaultValue={field.question}
            onChange={e => onChangeEventQuestions(e, 'question', index)}
          />

          <Row>
            <Col md={6}>
              <Checkbox
                checked={field.mandatory || false}
                onChange={e => onChangeEventQuestions(e, 'mandatory', index)}
              >
                An answer is mandatory
              </Checkbox>
            </Col>
          </Row>

          <div>
            <Row>
              <Col md={8}>
                <Checkbox
                  checked={field.with_image || false}
                  onChange={e => onChangeEventQuestions(e, 'with_image', index)}
                >
                  Answer can be an Excel, Word, or PDF document
                </Checkbox>
              </Col>
            </Row>

            <Row>
              <Col md={10}>
                <Checkbox
                  checked={!!field.select}
                  onChange={e => onChangeEventQuestions(e, 'select', index)}
                >
                  The person registering must select one of a group of options
                </Checkbox>
              </Col>
            </Row>

            {field.select && (
              <div className="m-t-05">
                <FormControl
                  onChange={onChangeFieldOptions(index)}
                  defaultValue={_.join(field.options, ', ')}
                  className="fs-100 field-options"
                  type="text"
                />
                <span className="help-block fs-90">
                  Separate options with commas
                </span>
              </div>
            )}

            <Row>
              <Col md={6}>
                <Checkbox
                  checked={!!field.checkbox}
                  onChange={e => onChangeEventQuestions(e, 'checkbox', index)}
                >
                  Check all that apply
                </Checkbox>
              </Col>
            </Row>

            {field.checkbox && (
              <div className="m-t-05">
                <FormControl
                  onChange={onChangeFieldOptions(index)}
                  defaultValue={_.join(field.options, ', ')}
                  className="fs-100 field-options"
                  type="text"
                />
                <span className="help-block fs-90">
                  Separate options with commas
                </span>
              </div>
            )}

          </div>

        </Field>

      </div>
    )}
  </div>
);

AdminCustomEventQuestions.propTypes = {
  customFields: array.isRequired,
  onChangeEventQuestions: func.isRequired,
  onChangeFieldOptions: func.isRequired,
};

export default AdminCustomEventQuestions;
