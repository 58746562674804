import React from 'react'
import { connect } from 'helpers'

import Header from './header'
import Item from './item'

class DevMenu extends React.Component {
  render() {
    // const { user } = this.context;
    const { morph } = this.props.actions

    /*
     * This is to hide the quick morphs for demos
     */
    const hide = false // user.id === 5;

    return (
      <ul className="sidebar-menu c-pointer">
        <Header>Quick morph</Header>
        <Item onClick={() => morph(25)} icon="user-secret">
          Member
        </Item>
        <Item onClick={() => morph(47)} icon="user-secret">
          Admin
        </Item>
        <Item onClick={() => morph(36148)} icon="user-secret">
          National
        </Item>
        <Item onClick={() => morph(76884)} icon="user-secret">
          Alumni
        </Item>
        {hide || (
          <Item onClick={() => morph(77651)} icon="user-secret">
            Chi Psi
          </Item>
        )}
        {hide || (
          <Item onClick={() => morph(74718)} icon="user-secret">
            KBG
          </Item>
        )}
        {hide || (
          <Item onClick={() => morph(157717)} icon="user-secret">
            SAEPi
          </Item>
        )}
        {hide || (
          <Item onClick={() => morph(127828)} icon="user-secret">
            KΣ
          </Item>
        )}
        {hide || (
          <Item onClick={() => morph(77653)} icon="user-secret">
            SPD
          </Item>
        )}
        {hide || (
          <Item onClick={() => morph(97622)} icon="user-secret">
            GAO
          </Item>
        )}
        {hide || (
          <Item onClick={() => morph(159005)} icon="user-secret">
            ΔΕΨ
          </Item>
        )}
      </ul>
    )
  }
}

export default connect(DevMenu)
