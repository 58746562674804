import api from './api'
import { GCM_ENVIRONMENT } from 'helpers'

export function* morph(action) {
  const { id } = action

  const { data } = yield api({
    method: 'POST',
    uri: `/api/users/morph/${id}`,
  })

  const token = localStorage.getItem('token')
  localStorage.setItem('rootToken', token)
  localStorage.setItem('token', data.jwt)

  const role = localStorage.getItem('role')
  localStorage.setItem('rootRole', role)

  window.location = '/'
}

export function* unmorph({ uri }) {
  const token = localStorage.getItem('rootToken')

  const payload = btoa(JSON.stringify({ token }))
  const origin = GCM_ENVIRONMENT === 'production' ? 'next.greekcapitalmanagement.com' : 'gc.m:3000'
  const url = `https://${origin}${uri}?ls=${payload}`
  window.location = url
}
