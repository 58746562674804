import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { FaIcon, Dropzone } from 'components/utilities'
import { extForMimeType } from 'helpers'

import Preview from './preview'
import S3ProgressBar from './s3-progress-bar'

const { arrayOf, func, string } = PropTypes

export default class S3UploadBox extends React.Component {
  static propTypes = {
    mimeTypes: arrayOf(string).isRequired,
    noun: string.isRequired,
    getS3Info: func.isRequired,
    onStart: func,
    onSuccess: func.isRequired,
  }

  static defaultProps = {
    mimeTypes: [
      'application/pdf',
      'image/jpeg',
      'image/png',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/x-iwork-pages-sffpages',
      'application/x-iwork-numbers-sffnumbers',
    ],
  }

  constructor(props) {
    super(props)

    this.state = {
      state: 'pending',
      s3: null,
      file: null,
      rejected: false,
      percentage: 0,
    }

    props.getS3Info().then(({ data: s3 }) => this.setState({ s3 }))
  }

  onDrop = files => {
    if (files.length > 0) {
      this.setState({
        state: this.props.preprocessor ? 'preprocessing' : 'uploading',
        file: files[0],
        rejected: false,
      })
      if (this.props.onStart) {
        this.props.onStart()
      }
    }
  }

  allowedExtensions = () => this.props.mimeTypes.map(m => `.${extForMimeType(m)}`)

  onDropRejected = () => {
    this.setState({ rejected: true })
  }

  onDragEnter = () => {
    this.setState({ rejected: false })
  }

  onSuccess = () => {
    this.setState(
      {
        state: 'saving',
      },
      () =>
        this.props.onSuccess({
          preview: this.state.file.preview,
          s3_path: this.state.s3.path,
          mimetype: this.state.file.type,
          name: this.state.file.name,
        })
    )
  }

  onPreprocessorSuccess = file => {
    this.setState({
      file,
      state: 'uploading',
    })
  }

  doClearUpload = () => {
    this.setState({
      upload: {
        state: 'pending',
      },
    })
  }

  renderPending = () => {
    const { mimeTypes, noun } = this.props
    const { s3, rejected } = this.state
    const allowedExtensions = this.allowedExtensions()

    return (
      <Dropzone
        multiple={false}
        maxSize={10000000}
        activeClassName="accepted-file-hovering"
        rejectClassName="rejected-file-hovering"
        accept={mimeTypes.concat(allowedExtensions).join(',')}
        onDrop={this.onDrop}
        onDropRejected={this.onDropRejected}
        onDragEnter={this.onDragEnter}
        className={cx('file-drag-and-drop', {
          'ready-for-upload': !_.isNil(s3),
          'rejected-file-hovering': rejected,
        })}
      >
        <FaIcon cloud-upload />
        <h5>
          Drag &amp; drop <br />a {noun} here
        </h5>
        or <a className="text-bold">browse</a>
        {rejected && (
          <p className="text-danger m-b-0 m-t-1">
            Allowed extensions are: {allowedExtensions.join(', ')}
          </p>
        )}
      </Dropzone>
    )
  }

  renderProgress = () => {
    const { file, s3 } = this.state

    return (
      <div>
        <Preview file={file} />
        <S3ProgressBar s3={s3} file={file} sendDimensions showComplete onSuccess={this.onSuccess} />
      </div>
    )
  }

  render() {
    const { state, file } = this.state
    const { preprocessor: Preprocessor } = this.props

    if (state === 'pending') {
      return this.renderPending()
    } else if (state === 'preprocessing') {
      return <Preprocessor file={file} onSuccess={this.onPreprocessorSuccess} />
    } else if (state === 'uploading' || state === 'saving') {
      return this.renderProgress()
    }

    return <Preview file={file} />
  }
}
