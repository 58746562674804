import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Radio, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FaIcon } from 'components/utilities';
import Field from 'components/form/field';

export default class Payment extends React.Component {

  static contextTypes = {
    accounts: PropTypes.array.isRequired,
  }

  renderAccounts = () => {
    const { accounts } = this.context;
    if (_.size(accounts) === 1) {
      return (
        <input
          type="hidden"
          name="transaction.account_id"
          value={accounts[0].id}
        />
      );
    }

    // const defaultAccountId = _.last(accounts).id;
    // const selectedAccountId = _.defaultTo(accountId, defaultAccountId);

    const disabledAccountOverlay = (
      <Tooltip id="tooltip">
        <strong>Manually added payments can only go into {_.last(accounts).name}.</strong>
        <br />
        Contact GCM to record a payment on another balance.
      </Tooltip>
    );

    return (
      <Field name="transaction.account_id" label="Apply to">
        {_.map(accounts, ({ id, name }, i) => {
          const isDefaultAccount = i === _.size(accounts) - 1;
          return (
            <Radio
              key={id}
              id={id}
              name="transaction.account_id"
              value={id}
              checked={isDefaultAccount}
              readOnly
              disabled={!isDefaultAccount}
              className={classnames({ 'text-muted': !isDefaultAccount })}
            >
              {isDefaultAccount ? name : (
                <OverlayTrigger placement="right" overlay={disabledAccountOverlay}>
                  <span>
                    {name}
                    <FaIcon question-circle className="m-l-05" />
                  </span>
                </OverlayTrigger>
              )}
            </Radio>
          );
        })}
      </Field>
    );
  }

  render = () => (
    <div>

      <Field
        name="transaction.description"
        label="Description"
        autoFocus
      />

      <Field
        name="transaction.amount"
        label="Amount"
        inputSize={5}
        money
      />

      {this.renderAccounts()}

    </div>
  )

}
