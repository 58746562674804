import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router';
import { Name, Label, Thumbnail } from 'components/utilities';
import { Column } from 'components/table';

const { bool, object } = PropTypes;

class MemberThumbnail extends React.Component {

  static propTypes = {
    isAdminView: bool,
    member: object.isRequired,
    organization: object.isRequired,
  }

  renderAdminLabel = () => {
    const { member, organization } = this.props;

    const isPrimary = _.get(organization, 'primary_admin.id') === member.id;
    if (isPrimary) {
      return (
        <Label primary className="m-l-1">primary</Label>
      );
    }

    const isAdmin = member.is_admin;
    if (isAdmin) {
      return (
        <Label info className="m-l-1">admin</Label>
      );
    }

    return null;
  }

  render() {
    const { member, isAdminView } = this.props;

    return (
      <Thumbnail
        avatar={member}
        popoverId={`avatar-${member.id}`}
        popoverTitle={`${member.user.first_name} ${member.user.last_name}`}
      >
        {isAdminView ? (
          <Link to={`/admin/members/${member.id}`}>
            <Name full user={member} />
            {this.renderAdminLabel()}
          </Link>
        ) : (
          <Name full user={member} />
        )}
      </Thumbnail>
    )
  }
}

function formatNameWithThumbnail({ organization, admin }) {
  return (__, member) => (
    <MemberThumbnail organization={organization} isAdminView={admin} member={member} />
  );
}

function sortMembersBy(organization, isAdminView) {
  return (name, { id, is_admin: isAdmin }, asc) => {

    if (!isAdminView) {
      return _.lowerCase(name);
    }

    const isPrimary = _.get(organization, 'primary_admin.id') === id;
    const first = asc ? !isPrimary : isPrimary;
    const second = asc ? !isAdmin : isAdmin;
    return [first, second, _.lowerCase(name)];
  };
}

export default function ({ organization, admin, columnProps }) {
  if (!organization) { return null; }

  const validKeys = _.concat(
    ['email', 'phone-number', 'alternate-email', 'campus-address', 'parent-email', 'parent-phone-number', 'parent-address'],
    _.map(organization.custom_fields, 'slug'),
  );

  const type = admin ? 'admin_roster' : 'member_roster';
  const roster = _.filter(organization[type], f => (
    _.includes(validKeys, f)
  ));

  const defaultFields = admin ? ['name', 'balance'] : ['name'];
  const fields = _.sortBy(_.concat(defaultFields, roster), f => validKeys.indexOf(f));

  const width = (100 - (admin ? 36 : 26)) / roster.length;

  return _.map(fields, field => {

    if (field === 'name') {

      return (
        <Column
          key={field}
          width={22}
          value={({ user }) => `${user.last_name}, ${user.first_name}`}
          sortBy={sortMembersBy(organization, admin)}
          formatter={formatNameWithThumbnail({ organization, admin })}
          {..._.get(columnProps, field, {})}
        >
          Member
        </Column>
      );
    }

    if (field === 'balance') {
      return (
        <Column key={field} width={10} value={({ data }) => _.toNumber(data.balance)}
          balance>Balance</Column>
      );
    }

    if (field === 'email') {
      return (
        <Column key={field} width={width} sortBy={_.lowerCase}
          value={({ user }) => user.email}
          email>Email</Column>
      );
    }

    if (field === 'phone-number') {
      return (
        <Column key={field} width={15}
          value={({ user }) => user.phone_number}
          phone>Phone number</Column>
      );
    }

    if (field === 'alternate-email') {
      return (
        <Column key={field} width={width} sortBy={_.lowerCase}
          value="alternate_email" email>Alternate email</Column>
      );
    }

    if (field === 'campus-address') {
      return (
        <Column key={field} width={width}
          value={({ user }) => user.address}
          address>Campus address</Column>
      );
    }

    if (field === 'parent-email') {
      return (
        <Column
          key={field}
          width={width}
          value={({ first_parent }) => _.get(first_parent, 'user.email')}
          email
        >
          Parent email
        </Column>
      );
    }

    if (field === 'parent-phone-number') {
      return (
        <Column
          key={field}
          width={width}
          value={({ first_parent }) => _.get(first_parent, 'user.phone_number')}
          phone
        >
          Parent phone number
        </Column>
      );
    }

    if (field === 'parent-address') {
      return (
        <Column
          key={field}
          width={width}
          value={({ first_parent }) => _.get(first_parent, 'user.address')}
          address
        >
          Parent address
        </Column>
      );
    }

    const customField = _.find(organization.custom_fields, cf => cf.slug === field);
    if (!customField) {
      return (
        <Column
          key={field}
          width={width}
          value={() => 'error'}
        >
          Error
        </Column>
      );
    }

    return (
      <Column key={field} width={width} none
        value={({ custom_fields }) => custom_fields[customField.slug]}
        >{customField.name}</Column>
    );
  });
}
