/* global Rollbar */
import LogRocket from 'logrocket';
import { put } from 'redux-saga/effects';
import { bootstrap, logoutRequest, loginFailure } from '../actions/authentication';
import { setOrganization } from '../actions/organization';
import api from './api';

import { GLOBAL_QUERY } from '../resources/user';

export function* me(action) {
  try {

    const data = { query: GLOBAL_QUERY };
    if (localStorage.getItem('rootToken')) {
      data.morphed = 1;
    }

    const { data: user } = yield api({
      method: 'get',
      uri: '/api/users/me',
      data,
    });

    analytics.identify(user.id, {
      name: `${user.first_name} ${user.last_name}`,
      email: user.email,
    });

    Rollbar.configure({
      payload: {
        person: {
          id: user.id,
          username: `${user.first_name} ${user.last_name}`,
          email: user.email,
        },
      },
    });

    LogRocket.identify(user.id, {
      name: `${user.first_name} ${user.last_name}`,
      email: user.email,
    });

    if (user.member) {
      yield put(setOrganization(user.member.organization));
    }

    if (user.alumni) {
      yield put(setOrganization(user.alumni.organization));
    }

    yield put(bootstrap({
      ...action,
      user,
    }));

  } catch (err) {

    if (action.interactive) {
      yield put(loginFailure(err));
    } else {
      yield put(logoutRequest({
        returnTo: action.returnTo,
      }));
    }

  }
}
