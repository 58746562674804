import React from 'react';
import { number, oneOf, string } from 'prop-types';
import { Money } from 'components/utilities';
import { Col, Row } from 'react-bootstrap';

const RegistrationChargeInfo = ({ costType, totalCost, numberOfTickets, ticketsCost, addons, method }) => {
  const methodName = method === 'card' ? 'card' : 'account';
  const filteredAddons = _.filter(addons, (item) => item.quantity > 0);

  // eslint-disable-next-line no-param-reassign
  if (costType === 'fixed' && !_.isFinite(numberOfTickets)) numberOfTickets = 1;

  return (
    <React.Fragment>
      {(costType !== 'variable' && _.isFinite(numberOfTickets)) && (
        <Row>
          <Col md={3}>Ticket cost &nbsp;<em className="text-muted">х{numberOfTickets}</em>:</Col>
          <Col md={3} className="text-right"><Money amount={ticketsCost} /></Col>
        </Row>
      )}

      {_.map(filteredAddons, (item, index) => (
        <Row key={index}>
          <Col md={3}> {item.name} &nbsp;<em className="text-muted">х{item.quantity}</em>:</Col>
          <Col md={3} className="text-right"><Money amount={item.cost * item.quantity} /></Col>
        </Row>
      ))}

      {method ? (
        <span className="is-flex p-t-1">
          Your {methodName} will be
          charged a total of&nbsp;<Money amount={totalCost} />.
        </span>
      ) : (
        <span className="is-flex p-t-1 text-danger">First add your payment method</span>
      )}
    </React.Fragment>
  );
};

RegistrationChargeInfo.propTypes = {
  costType: oneOf(['free', 'fixed', 'variable']).isRequired,
  totalCost: number.isRequired,
  method: string.isRequired,
  numberOfTickets: number.isRequired,
};

export default RegistrationChargeInfo;
