import React from 'react';
import { Link } from 'react-router';
import { Grid } from 'react-bootstrap';
import Search from './search';

export function BreadcrumbItem({ link, onClick, children }) {
  const key = _.kebabCase(children);

  if (link) {
    return (
      <li key={key}>
        <Link to={link}>{children}</Link>
      </li>
    );
  }

  if (onClick) {
    return (
      <li key={key}>
        <span className="clickable" onClick={onClick}>{children}</span>
      </li>
    );
  }

  return <li key={key} className="active">{children}</li>;
}

export default function ({ search, actions, children }) {
  return (
    <Grid className="page-header page-header-breadcrumbs">

      <ol className="breadcrumb breadcrumb-header">
        {children}
      </ol>

      <div className="page-actions hidden-print">
        {!search || <Search className="m-r-1 btn-group" />}
        {actions}
      </div>

    </Grid>
  );

}
