

import cx from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TextInput extends Component {
  static propTypes = {
    /**
     * Specify the size of the input.
     */
    bsSize: PropTypes.oneOf(['large', 'lg', 'small', 'sm']),
  }

  render() {
    const { bsSize, className, hasAux, inputId, ...otherProps } = this.props;

    return (
      <input
        autoComplete="off"
        {...otherProps}
        id={inputId}
        className={cx('form-control', {
          'has-aux': hasAux,
          'input-lg': bsSize === 'large' || bsSize === 'lg',
          'input-sm': bsSize === 'small' || bsSize === 'sm',
        }, className)}
        ref={input => this._input = input}
        type="text"
      />
    );
  }

  getInstance() {
    return this._input;
  }
}

export default TextInput;
