import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import actionProps from 'actions'

import { DropdownButton, MenuItem } from 'react-bootstrap'
import DateRangeModal from 'components/modals/date-range'

import styles from './selector.styles'

const { arrayOf, bool, object, shape, string } = PropTypes

const isChildOf = (el, className) => {
  while (el.tagName !== 'BODY') {
    if (el.classList.contains(className)) {
      return true
    }
    // eslint-disable-next-line no-param-reassign
    el = el.parentNode
  }
  return false
}

class TermSelector extends React.Component {
  static propTypes = {
    start: object.isRequired,
    end: object.isRequired,
    terms: arrayOf(
      shape({
        name: string.isRequired,
        started_on: string.isRequired,
        ended_on: string.isRequired,
      }).isRequired
    ).isRequired,
    topAligned: bool,
    rightAligned: bool,
    halfYears: bool,
    showAll: bool,
    className: string,
  }

  state = {
    open: false,
  }

  componentDidMount() {
    document.body.addEventListener('click', this.closeTermSelector)
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.closeTermSelector)
  }

  // eslint-disable-next-line react/sort-comp
  closeTermSelector = e => {
    if (this.state.open && !isChildOf(e.target, 'popup-date-range')) {
      this.setState({ open: false })
    }
  }

  // eslint-disable-next-line react/sort-comp
  get readableDateRange() {
    const { start, end, halfYears } = this.props

    const todayOrFormat = (date, format) => {
      const formatted = date.format(format)
      const nowFormatted = moment().format(format)
      return formatted === nowFormatted ? 'Today' : formatted
    }

    const term = this.findTerm()
    if (term) {
      if (halfYears) {
        return term.name
      }
      return `${term.name} <span class="m-l-1" /> ${start.format('MMM D')} - ${todayOrFormat(
        end,
        'MMM D'
      )}`
    }

    const nowYear = moment().year()
    const format = start.year() === nowYear && end.year() === nowYear ? 'MMM D' : 'MMM D YYYY'

    return `${start.format(format)} - ${todayOrFormat(end, format)}`
  }

  get termRanges() {
    const { terms } = this.props

    return _.reduce(
      terms,
      (memo, term) =>
        _.merge(memo, {
          [term.name]: {
            name: term.name,
            startDate: moment(term.started_on),
            endDate: moment(term.ended_on).endOf('day'),
          },
        }),
      {}
    )
  }

  onDateRangeChange = (dateRange, __, termName) => {
    const { halfYears, actions } = this.props

    if (termName) {
      this.setState({ open: false, termName })
    }

    const func = halfYears ? 'setHalfYearDateRange' : 'setDateRange'
    actions[func]({
      start: dateRange.startDate.local(),
      end: dateRange.endDate.local(),
    })
  }

  findTerm = () => {
    const { terms, start, end } = this.props

    const theStart = start.format('YYYY-MM-DD')
    const theEnd = end.format('YYYY-MM-DD')

    return _.find(terms, term => theStart === term.started_on && theEnd === term.ended_on)
  }

  calendarContainerStyles = () => {
    const containerStyles = _.clone(styles.calendarContainer)

    if (this.props.topAligned) {
      _.assign(containerStyles, styles.calendarContainerTopAligned)
    }

    if (this.props.rightAligned) {
      _.assign(containerStyles, styles.calendarContainerRightAligned)
    }

    return containerStyles
  }

  handleSelector = key => {
    if (key === 0) {
      this.props.actions.openModal('DateRange')()
    } else if (key === 'all-transactions') {
      const keys = Object.keys(this.termRanges)
      const startDate = this.termRanges[keys[keys.length - 1]].startDate
      const endDate = this.termRanges[keys[0]].endDate
      this.onDateRangeChange({ startDate, endDate }, null, 'all-transactions')
    } else {
      this.onDateRangeChange(this.termRanges[key], null, key)
    }

    document.activeElement.blur()
  }

  render = () => (
    <span className={this.props.className}>
      <DropdownButton
        title={
          <span dangerouslySetInnerHTML={{ __html: this.readableDateRange }} className="m-r-05" />
        }
        dropup={!this.props.topAligned}
        pullRight
        id="term-selector"
        onSelect={this.handleSelector}
        className="drop-button-no-style"
      >
        {_.chain(this.termRanges)
          .keys()
          .map((termName, i) => (
            <MenuItem key={i} eventKey={termName}>
              {termName}
            </MenuItem>
          ))
          .value()}

        <MenuItem divider />
        {this.props.showAll && <MenuItem eventKey="all-transactions">All Transactions</MenuItem>}
        <MenuItem eventKey={0}>Custom date range</MenuItem>
      </DropdownButton>

      <DateRangeModal
        onRangeChange={this.onDateRangeChange}
        startDate={this.props.start}
        endDate={this.props.end}
        noRanges
      />
    </span>
  )
}

function mapStateToProps(state, ownProps) {
  let dateRange
  let terms

  if (ownProps.halfYears) {
    dateRange = state.get('halfYearDateRange')
    terms = state.get('halfYears')
  } else {
    dateRange = state.get('dateRange')
    terms = state.get('terms').terms
  }

  return _.assign({}, dateRange, { terms })
}

export default connect(mapStateToProps, actionProps)(TermSelector)
