import React from 'react';
import { ButtonToolbar } from 'react-bootstrap';
import { FaButton } from './font-awesome';

export function PrintAction(props) {
  return <TableAction icon="print" onClick={window.print} {...props} />;
}

export function TableAction(props) {
  return <FaButton {...props} />;
}

export default function ({ children }) {
  return (
    <ButtonToolbar className="pull-right hidden-print hidden-xs">
      {children}
    </ButtonToolbar>
  );
}
