import { connect, isGranted } from 'helpers'
import PropTypes from 'prop-types'
import React from 'react'

import HourTrackerTooltip from './alerts/hour-tracker-pending-tooltip'
import Contacts from './contacts'
import Header from './header'
import Item from './item'
import MemberAccount from './member-account'
import National from './national'
import PrimaryAdmin from './primary-admin'
import VotesAside from './votes-aside'

import {
  ATTENDANCE_FEATURE,
  DOCUMENTS_FEATURE,
  EVENTS_FEATURE,
  GMONEY_FEATURE,
  HOUR_TRACKER_FEATURE,
  MESSAGING_FEATURE,
  RUSH_FEATURE,
  VOTING_FEATURE,
  WEBSITE_FEATURE,
} from 'resources/feature'

const { bool, object, shape, number, array } = PropTypes

class MemberMenu extends React.Component {
  static contextTypes = {
    user: shape({
      data: shape({
        all_features: array.isRequired,
        has_gmoney_access: bool.isRequired,
      }).isRequired,
    }).isRequired,
    organization: shape({
      id: number.isRequired,
    }),
  }

  static propTypes = {
    requiredActions: object.isRequired,
  }

  isGranted(feature) {
    const { user } = this.context

    return isGranted(user, feature)
  }

  renderMemberFeatures = () => (
    <ul className="sidebar-menu">
      <Header>Admin</Header>
      {this.isGranted(ATTENDANCE_FEATURE) && (
        <Item url="/admin/attendance" icon="clock-o">
          Attendance
        </Item>
      )}
      {this.isGranted(DOCUMENTS_FEATURE) && (
        <Item url="/admin/document-folders" icon="folder">
          Documents
        </Item>
      )}
      {this.isGranted(HOUR_TRACKER_FEATURE) && (
        <Item url="/admin/hour-tracker" icon="clock-o" id="hour-tracker#admin">
          Hour tracker
          <HourTrackerTooltip hourTracker={this.props.requiredActions.hour_tracker} />
        </Item>
      )}
      {this.isGranted(MESSAGING_FEATURE) && (
        <Item url="/admin/messaging" icon="bullhorn">
          Messaging
        </Item>
      )}
      {this.isGranted(RUSH_FEATURE) && (
        <Item url="/admin/rush" icon="users">
          Recruitment
        </Item>
      )}
      {this.isGranted(EVENTS_FEATURE) && (
        <Item url="/admin/events" icon="calendar">
          Events
        </Item>
      )}
      {this.isGranted(VOTING_FEATURE) && (
        <Item url="/admin/voting" icon="gavel" id="voting#admin">
          Voting
        </Item>
      )}
      {this.isGranted(WEBSITE_FEATURE) && (
        <Item url="/admin/settings" icon="cog" id="settings#admin">
          Settings
        </Item>
      )}
    </ul>
  )

  renderMember() {
    const { requiredActions } = this.props

    return (
      <ul className="sidebar-menu">
        <Header>Chapter</Header>
        <Item url="/budgets" icon="book">
          Budgets
        </Item>
        {(this.isGranted(GMONEY_FEATURE) || this.context.user.data.has_gmoney_access) && (
          <Item url="/g-money" icon="usd" backgroundIcon="file">
            gMoney
          </Item>
        )}
        <Item url="/calendar" icon="calendar">
          Calendar
        </Item>
        {this.isGranted(DOCUMENTS_FEATURE) || (
          <Item url="/document-folders" icon="folder">
            Documents
          </Item>
        )}
        <Item url="/executive-board" icon="address-book">
          Executive Board
        </Item>
        <Item url="/committee-positions" icon="users">
          Chairs/Committees
        </Item>
        <Item url="/message-board" icon="comment">
          Message Board
        </Item>
        <Item url="/members" icon="users">
          Roster
        </Item>
        <Item
          url="/voting"
          icon="gavel"
          aside={<VotesAside votes={requiredActions.votes} />}
          asideType="primary"
        >
          Voting
        </Item>
      </ul>
    )
  }

  render = () => (
    <div>
      <MemberAccount alwaysExpanded />
      {this.renderMember()}
      {this.context.user.member.data.all_features.length > 0 && this.renderMemberFeatures()}
      <National />
      <PrimaryAdmin organization={this.context.organization} />
      <Contacts />
    </div>
  )
}

const mapStateToProps = state => ({
  requiredActions: state.get('requiredActions'),
})

export default connect(MemberMenu, mapStateToProps)
