import React from 'react'
import PropTypes, { object } from 'prop-types'
import { modal } from 'helpers'
import { Payment } from 'resources'

import PaymentContainerWithPayPal from './pay/payment-container-with-paypal'
import { Row, Col, Modal, ButtonToolbar, Button, Radio } from 'react-bootstrap'
import { PaymentMethod as PaymentMethodComponent } from 'components/utilities'
import { Form, Field, SubmitButton } from 'components/form'

const { arrayOf, number, string, shape } = PropTypes

class QuickPayModal extends PaymentContainerWithPayPal {
  static contextTypes = {
    activeMember: shape({
      id: number.isRequired,
    }),
    user: shape({
      role: string.isRequired,
      member: shape({
        id: number.isRequired,
      }),
      parent: shape({
        member: shape({
          id: number.isRequired,
        }),
      }),
    }).isRequired,
  }

  static propTypes = {
    paymentMethods: arrayOf(
      shape({
        id: number.isRequired,
        company: string.isRequired,
        method: string.isRequired,
        last_four: string.isRequired,
      }).isRequired
    ).isRequired,
  }

  render() {
    const { user } = this.context
    const member = this.getMember()
    const { paymentMethods } = this.props
    const { amount, selectedPaymentMethod } = this.state
    const rootError = this.getRootError()

    const data = {
      payment: {
        amount: this.getChargedAmount(),
        source: `web/${user.role}`,
        member_id: member.id,
      },
    }

    const formContent = (
      <React.Fragment>
        <Modal.Header closeButton>
          <Modal.Title>Quick pay</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <hr className="spacer-xs" />

          <Field
            name="payment.payment_method_id"
            label="Payment method"
            inputSize={9}
            noFeedbackControl
          >
            {_.isEmpty(paymentMethods) ? (
              <ButtonToolbar className="wrap-buttons">
                <Button onClick={this.openModal('AddCreditCard')} bsSize="sm">
                  Add credit card
                </Button>
                <Button onClick={this.openModal('AddBankAccount')} bsSize="sm">
                  Add bank account
                </Button>
                {this.renderPayPalPaymentMethodButton()}
              </ButtonToolbar>
            ) : (
              <React.Fragment>
                {_.map(paymentMethods, (paymentMethod, i) => (
                  <Radio
                    key={i}
                    name="payment.payment_method_id"
                    value={paymentMethod.id}
                    checked={paymentMethod.id === _.get(selectedPaymentMethod, 'id')}
                    onChange={this.doSelectPaymentMethod.bind(this, paymentMethod)}
                  >
                    <PaymentMethodComponent paymentMethod={paymentMethod} />
                  </Radio>
                ))}
              </React.Fragment>
            )}
          </Field>

          <hr className="spacer-xs" />

          <Field
            name="payment.input_amount"
            errorKey="payment.amount"
            label="Amount to pay"
            inputSize={4}
            defaultValue={Math.max(amount, 0)}
            money
            autoFocus
            onChange={this.onAmountChange}
          />

          {rootError && (
            <Row className="text-danger fw-semibold m-t-neg-1 m-b-1">
              <Col md={9} mdPush={3}>
                {rootError}
              </Col>
            </Row>
          )}

          {this.renderUpCharge()}

          <hr className="spacer-xs" />
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col md={5}>
              <Button onClick={this.openModal('AdvancedPay')} className="pull-left">
                More options
              </Button>
            </Col>
            {this.isPayPalForm() ? (
              this.renderPayPalSubmitButton()
            ) : member.organization && member.organization.id === 1 ? (
              <Button bsStyle="success" disabled onClick={() => {}} className="m-l-0 m-r-1">
                Disabled in Demo
              </Button>
            ) : (
              <SubmitButton offset={0} bsStyle="success" className="m-l-0">
                Make payment
              </SubmitButton>
            )}
          </Row>
        </Modal.Footer>
      </React.Fragment>
    )

    return (
      <div>
        <Modal show onHide={this.closeModal}>
          {this.isPayPalForm() ? (
            this.renderPayPalForm(formContent)
          ) : (
            <Form
              action={Payment.paymentMethod}
              data={data}
              onSuccess={this.onPaymentCreate}
              onValidationErrors={this.onError}
            >
              {formContent}
            </Form>
          )}
        </Modal>
      </div>
    )
  }
}

export default modal('QuickPay', QuickPayModal)
