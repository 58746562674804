import { apiRequest } from 'actions/use';
import apiWrapper, { generatePayload } from './helpers';

export default class Term extends apiWrapper('/terms') {

  static bulkCreate({ data, query, onSuccess, onFailure }) {

    apiRequest({
      uri: '/api/terms/bulk_create',
      method: 'POST',
      data: generatePayload({ data, query }),
      onSuccess,
      onFailure,
    });
  }

}
