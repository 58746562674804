import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import actionProps from 'actions';

const { number, object, shape, string } = PropTypes;

export default function (Component, mapStateToProps) {

  // eslint-disable-next-line no-param-reassign
  const contextTypes = Component.contextTypes || (Component.contextTypes = {});

  contextTypes.user = object.isRequired;

  contextTypes.owner = shape({
    owner_type: string,
    owner_id: number,
  });

  return connect(mapStateToProps, actionProps)(Component);
}
