import React from 'react';
import PropTypes from 'prop-types';
import { modal } from 'helpers';

import { Button, Modal } from 'react-bootstrap';
import { Field } from 'components/form';
import Container from 'container';

const { func, array } = PropTypes;

class HalfYearSelectModal extends Container {

  static propTypes = {
    onSelect: func.isRequired,
    halfYears: array,
  }

  state = { halfYearIndex: 1 }

  componentWillMount() {
    const { halfYears, actions } = this.props;

    if (!halfYears) actions.ensureHalfYears();
  }

  onSelect = () => {
    const { halfYears, onSelect } = this.props;
    const { halfYearIndex } = this.state;

    onSelect(halfYears[halfYearIndex]);
  }

  render() {
    const { halfYears } = this.props;
    const { closeModal } = this.props.actions;
    const { halfYearIndex } = this.state;

    if (!halfYears) return null;

    return (
      <Modal show onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Select which half-year</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <hr className="spacer-xs" />

          <Field label="Half-year" name="half_year.half">
            <select
              value={halfYearIndex}
              className="form-control"
              onChange={this.updateStateFromInput('halfYearIndex')}
              id="half_year.half"
            >
              {halfYears.map((halfYear, index) => (
                <option key={halfYear.name} value={index}>{halfYear.name}</option>
              ))}
            </select>
          </Field>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="secondary" onClick={this.onSelect} disabled={!halfYearIndex}>
            Select
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

}

export default modal('HalfYearSelect', HalfYearSelectModal, (state) => ({
  halfYears: state.get('halfYears'),
}));
