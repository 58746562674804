import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { connect, GCM_ENVIRONMENT } from 'helpers'

import HeaderNav from './header-nav'
import Sidebar from './sidebar'
import MenuToggle from 'components/utilities/menu-toggle'

import { Button } from 'react-bootstrap'

const logo = require('images/tmp/old-logo.png')
const { object, string, number, shape } = PropTypes

class Wrapper extends React.Component {
  static contextTypes = {
    router: object.isRequired,
    user: shape({
      role: string.isRequired,
      first_name: string.isRequired,
      last_name: string.isRequired,
      parent: shape({
        member: shape({
          id: number.isRequired,
        }),
      }),
      member: shape({
        id: number.isRequired,
      }),
      federation: shape({
        id: number.isRequired,
      }),
      avatar: shape({
        id: number.isRequired,
      }),
    }),
  }

  static propTypes = {
    isMorphed: PropTypes.bool.isRequired,
  }

  state = {
    sidebarVisible: false,
  }

  // eslint-disable-next-line react/sort-comp
  routerListener = null

  componentDidMount() {
    this.routerListener = this.context.router.listen(() => {
      this.setState({
        sidebarVisible: false,
      })
    })
  }

  componentWillUnmount() {
    if (this.routerListener) {
      this.routerListener()
      this.routerListener = null
    }
  }

  renderUnmorph() {
    const { user } = this.context

    const role = localStorage.getItem('rootRole')

    let uri = '/'
    if (role === 'federation' && user.member) {
      uri = `/federation/members/${user.member.id}`
    } else if (role === 'federation' && user.alumni) {
      uri = `/federation/alumni/${user.alumni.id}`
    } else if (_.includes(['member', 'admin'], user.role)) {
      uri = `/super/members/${user.member.id}/transactions`
    } else if (user.role === 'parent') {
      uri = `/super/members/${user.parents[0].member.id}/access`
    } else if (user.role === 'federation') {
      uri = `/super/federations/${user.federation.id}`
    }

    return (
      <div className="is-morphed">
        <div className="col-sm-7 user">
          {user.first_name} {user.last_name}
        </div>
        <div className="col-sm-5">
          <Button
            bsStyle="default"
            bsSize="sm"
            block
            onClick={() => this.props.actions.unmorph(uri)}
          >
            unmorph
          </Button>
        </div>
      </div>
    )
  }

  render = () => {
    const { user } = this.context
    const { isMorphed } = this.props

    const sidebarVisible = this.state.sidebarVisible ? 'sidebar__is-visible' : ''

    const production = GCM_ENVIRONMENT === 'production'

    return (
      <div id="wrapper" className={`${user.role} ${production ? 'live' : 'dev'}`}>
        {production ? null : <div className="is-not-production" />}

        <div className="navbar navbar-default hidden-print" role="navigation">
          <div className="container">
            <div className="navbar-header">
              <div
                className="navbar-toggle-sidebar"
                onClick={() => {
                  this.setState(state => ({
                    sidebarVisible: !state.sidebarVisible,
                  }))
                }}
              >
                <MenuToggle toggled={this.state.sidebarVisible} />
              </div>

              <div className="navbar-brand">
                <Link to="/" className="logo">
                  <img src={logo} height={50} role="presentation" />
                </Link>
              </div>
            </div>

            <HeaderNav key={_.get(user, 'avatar.id')} />
          </div>
        </div>

        <div className={`sidebar ${sidebarVisible} hidden-print`}>
          <div className="sidebar-bg"></div>

          {isMorphed && this.renderUnmorph()}

          <div className="sidebar-inner">
            <Sidebar />

            <div className="clearfix"></div>
          </div>
        </div>

        <div className="content-page">{this.props.children}</div>

        <div className="mobile-footer visible-xs hidden-print">
          <hr />
          Greek Capital Management
        </div>
      </div>
    )
  }
}

const mapStateToProps = () => ({
  isMorphed: localStorage.getItem('rootToken') !== null,
})

export default connect(Wrapper, mapStateToProps)
