import { apiRequest } from 'actions/use'
import queryString from 'query-string'
import apiWrapper, { updateMethod, generatePayload, list } from './helpers'

export const MEMBER_GLOBAL_QUERY = `
  organization {
    name
    secondary
    member_roster
    admin_roster
    pledge_roster
    cc_upcharge
    pledge_info
    ein_number
    tax_year_ending_on
    last_filed_at
    stripe_onboarded_at

    filing_deadline_on

    data {
      default_account_id
    }

    primary_logo {
      public_url
    }
    secondary_logo {
      public_url
    }
    federation {
      name
    }
    primary_admin {
      user {
        first_name
        last_name
        email
        phone_number
      }
    }
  }
`

export const ALUMNI_GLOBAL_QUERY = `
  organization {
    alumni_group_id
    federation_id
  }
`

export const FEDERATION_GLOBAL_QUERY = `
  organization {
    name
    secondary
    details

    ein_number
    tax_year_ending_on
    filing_deadline_on
    primary_admin {
      id
    }

    federation {
      id
    }

    archived_at
  }
`

export default class Organization extends apiWrapper('/organizations') {
  static updateTax = updateMethod('/organizations', 'update_tax')
  static updateFiledDate = updateMethod('/organizations', 'update_filed_date')
  static updateBankAccount = updateMethod('/organizations', 'update_bank_account')

  static updateAddresses = updateMethod('/organizations', 'update_addresses')

  static retrievePublic({ id, data, onSuccess }) {
    apiRequest({
      method: 'GET',
      uri: `/api/organizations/${id}/retrieve_public`,
      data,
      onSuccess,
    })
  }

  static archiveMembers({ id, data, query, onSuccess }) {
    apiRequest({
      method: 'PUT',
      uri: `/api/organizations/${id}/archive_members`,
      data: generatePayload({ data, query }),
      onSuccess,
    })
  }

  static unarchive({ id, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'PUT',
      uri: `/api/organizations/${id}/unarchive`,
      data: generatePayload({ query }),
      onSuccess,
      onFailure,
    })
  }

  static archived({ query, onSuccess }) {
    apiRequest({
      method: 'GET',
      uri: '/api/organizations/archived',
      data: generatePayload({ query }),
      on: {
        200: onSuccess,
      },
    })
  }

  static logo({ id, data, query, onSuccess }) {
    apiRequest({
      method: 'PUT',
      uri: `/api/organizations/${id}/logo`,
      data: generatePayload({ data, query }),
      onSuccess,
    })
  }

  static memberBalances({ id, data, onSuccess }) {
    apiRequest({
      method: 'GET',
      uri: `/api/organizations/${id}/member_balances`,
      data,
      onSuccess,
    })
  }

  static balancesToDate({ id, data, onSuccess }) {
    apiRequest({
      method: 'GET',
      uri: `/api/organizations/${id}/balances_to_date`,
      data,
      onSuccess,
    })
  }

  static lookup({ data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'GET',
      uri: '/api/organizations/lookup',
      data: generatePayload({ data, query }),
      onSuccess,
      onFailure,
    })
  }

  static lookupList({ data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'GET',
      uri: '/api/organizations/lookup_list',
      data: generatePayload({ data, query }),
      onSuccess,
      onFailure,
    })
  }

  static siteToken({ data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'GET',
      uri: `/api/organizations/${data.organization_id}/site_token`,
      data: generatePayload({ data, query }),
      onSuccess,
      onFailure,
    })
  }

  static domain({ data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'PUT',
      uri: `/api/organizations/${data.organization.organization_id}/domain`,
      data: generatePayload({ data, query }),
      onSuccess,
      onFailure,
    })
  }

  static updateWithMemberQuery(request) {
    this.update({ ...request, data: { ...request.data, query: MEMBER_GLOBAL_QUERY } })
  }

  static syncEosMasterListToDropbox({ onSuccess, data }) {
    apiRequest({
      method: 'GET',
      uri: '/api/organizations/sync_eos_master_list_to_dropbox',
      data: generatePayload({ data }),
      on: {
        200: onSuccess,
      },
    })
  }

  static syncEosReportsToDropbox({ onSuccess, data }) {
    apiRequest({
      method: 'GET',
      uri: '/api/organizations/sync_eos_reports_to_dropbox',
      data: generatePayload({ data }),
      on: {
        200: onSuccess,
      },
    })
  }

  static syncBudgetReportsToDropbox({ onSuccess, data }) {
    apiRequest({
      method: 'GET',
      uri: '/api/organizations/sync_budget_reports_to_dropbox',
      data: generatePayload({ data }),
      on: {
        200: onSuccess,
      },
    })
  }

  static federationTransactions({ id, data, onSuccess }) {
    const query = queryString.stringify(data, { arrayFormat: 'bracket' })

    apiRequest({
      method: 'GET',
      uri: `/api/organizations/${id}/federation_transactions?${query}`,
      onSuccess,
    })
  }

  static sizeStatistic({ data, onSuccess, onFailure }) {
    apiRequest({
      method: 'GET',
      uri: '/api/organizations/size_statistic',
      data: generatePayload({ data }),
      onSuccess,
      onFailure,
    })
  }

  static listByRequiredDocument(opts) {
    list('/organizations/list_by_required_document', opts)
  }

  static billPayBalance({ id, onSuccess }) {
    apiRequest({
      method: 'GET',
      uri: `/api/organizations/${id}/bill_pay_balance`,
      on: {
        200: onSuccess,
      },
    })
  }

  static gCapitalLimits({ id, onSuccess }) {
    apiRequest({
      method: 'GET',
      uri: `/api/organizations/${id}/g_capital_limits`,
      on: {
        200: onSuccess,
      },
    })
  }
}
