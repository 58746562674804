import React from 'react';

import { ButtonGroup } from 'react-bootstrap';

export default function ({ column, data }) {
  let children;

  if ('formatter' in column) {
    children = column.formatter(data);
  } else {
    children = React.Children.map(column.children, child => {
      if (!('data-map-on-click' in child.props)) {
        return child;
      }

      const onClick = () => child.props.onClick(data);
      const disabled = _.isFunction(child.props.disabled) ? child.props.disabled(data) : false;
      return React.cloneElement(child, { onClick, disabled });
    });
  }

  return (
    <td className="text-right hidden-print">
      <ButtonGroup bsSize="sm">
        {children}
      </ButtonGroup>
    </td>
  );
}
