/* eslint-disable new-cap */
import React from 'react'
import { IndexRedirect, Route } from 'react-router'

export default function federationMemberRoutes() {
  const Federation = path => (_, cb) =>
    require.ensure([], require => cb(null, require('chunks/federation')[path]))

  return (
    <Route path="entities/:id" getComponent={Federation('Entity')}>
      <IndexRedirect to="profile" />
      <Route path="profile" getComponent={Federation('Entity/Profile')} />
      <Route path="associations" getComponent={Federation('Entity/Associations')} />
      <Route path="donations" getComponent={Federation('Entity/Donations')} />
      <Route path="notes" getComponent={Federation('Entity/Notes')} />
    </Route>
  )
}
