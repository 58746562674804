import { apiRequest } from 'actions/use';
import apiWrapper from './helpers';

export default class Invoice extends apiWrapper('/invoices') {

  static syncAllToDropbox({ data, onSuccess }) {
    apiRequest({
      method: 'GET',
      uri: `/api/invoices/sync_all_to_dropbox?${_.toQueryString(data)}`,
      on: {
        200: onSuccess,
      },
    });
  }

}
