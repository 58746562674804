import React from 'react';
import formatMoney from 'utils/format_money.js';

export default function ({ amount, warning, praise, plus, className, strip }) {
  if (isNaN(amount)) {
    return <span className="text-muted">&ndash;</span>;
  }

  let formatted = formatMoney(amount);
  const isPositive = amount >= 0 || !formatted.match(/[1-9]/);

  if (strip) {
    formatted = formatted.replace(/\.00$/, '');
  }

  if (isPositive) {
    return <span className={className}>{formatted}</span>;
  } else if (warning) {
    return <span className="text-danger text-semibold">-{formatted}</span>;
  } else if (praise) {
    const modifier = plus ? '+' : '-';
    return <span className="text-success text-semibold">{modifier}{formatted}</span>;
  }
  return <span>+{formatted}</span>;
}
