import React from 'react';
import { arrayOf, func, number, string } from 'prop-types';
import { modal } from 'helpers';
import { Document } from 'resources';

import { Modal } from 'react-bootstrap';
import { Form, Field, SubmitButton } from 'components/form';
import { Typeahead } from 'components/typeahead';

class LabelsModal extends React.Component {

  static propTypes = {
    id: number.isRequired,
    labels: arrayOf(string).isRequired,
    onSuccess: func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      labels: _.map(props.labels, label => ({ id: label, label })),
    };
  }

  onLabelChange = labels => {
    this.setState({ labels });
  }

  onSuccess = ({ data }) => {
    const { onSuccess } = this.props;
    onSuccess({ data });
  }

  render() {
    const { closeModal } = this.props.actions;
    const { id } = this.props;
    const { labels } = this.state;

    const data = {
      document: {
        labels: _.map(labels, 'label'),
      },
    };

    return (
      <Modal show onHide={closeModal}>
        <Form
          action={Document.labels}
          id={id}
          data={data}
          query="document { document_labels { label } }"
          onSuccess={this.onSuccess}
        >
          <Modal.Header closeButton>
            <Modal.Title>Change labels</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Field label="Document labels">
              <Typeahead
                multiple
                allowNew
                emptyLabel="Enter a label"
                newSelectionPrefix=""
                options={[]}
                selected={labels}
                onChange={this.onLabelChange}
              />
              <em className="c-pointer m-t-05 d-block text-muted">Press enter after each label</em>
            </Field>

          </Modal.Body>
          <Modal.Footer>
            <SubmitButton offset={5}>Save</SubmitButton>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }

}

export default modal('Labels', LabelsModal);
