import T from '../actions/types';
const organizationIdStorageKey = 'organizationId';
export default (state = {}, action) => {
  switch (action.type) {

    case T.FETCH_ORGANIZATION:
      return {
        isFetching: true,
      };

    case T.SET_ORGANIZATION:
      sessionStorage.setItem(organizationIdStorageKey, action.organization.id);
      return action.organization;

    case T.CLEAR_ORGANIZATION:
      sessionStorage.removeItem(organizationIdStorageKey);
      return {};

    default:
      return state;
  }
};
