import _ from 'lodash';

_.mixin({

  toQueryString: obj => _
    .chain(obj)
    .toPairs()
    .map(p => `${p[0]}=${encodeURI(p[1])}`)
    .join('&')
    .value(),

  mapSomeValues: (arr, funcMap) => _
    .chain(arr)
    .map(obj => {
      const data = {};
      _.forEach(funcMap, (func, key) => {
        data[key] = func(obj[key], obj);
      });
      return _.assign(obj, data);
    })
    .value(),

  toAccumulator: (arr, reduceKey) => _
    .chain(arr)
    .map(obj => [_.get(obj, reduceKey), 0])
    .fromPairs()
    .value(),

  reduceSumByKey: (arr, reduceKey, sumKey, accumulator = {}) => _
    .reduce(arr, (memo, obj) => {
      let reduceVal = null;
      if (_.isFunction(reduceKey)) {
        reduceVal = reduceKey(obj);
      } else {
        reduceVal = _.get(obj, reduceKey);
      }
      const newValue = (memo[reduceVal] || 0) + _.get(obj, sumKey);
      return _.assign({}, memo, {
        [reduceVal]: Math.abs(newValue) < 1e-4 ? 0 : newValue,
      });
    }, accumulator),

  anyAreNil: (...args) => _.some(args, _.isNil),

  toMap: (arr, func) => (
    _.reduce(arr, (memo, el) => {
      const { key, val } = func(el);
      memo[key] = val; // eslint-disable-line no-param-reassign
      return memo;
    }, {})
  ),

  formatList: (arr) => {
    if (arr.length <= 1) {
      return arr.join();
    }
    const last = arr.pop();
    return `${arr.join(', ')} and ${last}`;
  },

  checkFormat: (obj, func, defaultt) => {
    if (func(obj)) {
      return obj;
    }
    return defaultt;
  },

  /* eslint-disable no-param-reassign */
  deepDiff: (base, obj) => {
    if (base === undefined) return obj;
    if (obj === undefined) return base;

    const keys = Object.keys({ ...base, ...obj });
    return _.reduce(keys, (memo, key) => {
      if (_.isPlainObject(base[key])) {
        const diff = _.deepDiff(base[key], obj[key]);
        if (!_.isEmpty(diff)) {
          memo[key] = diff;
        }
      } else if (_.isArray(base[key])) {
        const len = Math.max(_.get(base, `${key}.length`), _.get(obj, `${key}.length`));
        for (let i = 0; i < len && !memo[key]; i++) {
          if (_.isPlainObject(base[key][i]) || _.isPlainObject(obj[key][i])) {
            const diff = _.deepDiff(base[key][i], obj[key][i]);
            if (!_.isEmpty(diff)) {
              memo[key] = true;
            }
          } else if (!_.isEqual(base[key][i], obj[key][i])) {
            memo[key] = true;
          }
        }
      } else if (!_.isEqual(base[key], obj[key])) {
        memo[key] = true;
      }
      return memo;
    }, {});
  },
  /* eslint-enable no-param-reassign */

  sumByKey: (arr, key) => _.chain(arr).values().sumBy(key).value() || 0,

  applyIfTruly: (value, fun, defaultValue = null) => value ? fun(value) : defaultValue,

});

export default _;
