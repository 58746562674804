import React from 'react';
import PropTypes from 'prop-types';

import { iconForMimeType } from 'helpers';
import { Field, Input } from 'components/form';
import { UploadFileModal } from 'components/modals';
import { FaButton } from 'components/utilities';
import { Checkbox } from 'react-bootstrap';

const { array, func, object, shape, string, number } = PropTypes;

function PreviewImage({ mimetype, name }) {
  const src = iconForMimeType(mimetype, name);
  return <img className="sm-btn-image p-l-1" src={src} alt="Preview" />;
}

class CustomEventQuestions extends React.Component {

  static propTypes = {
    event: shape({
      event_questions: array.isRequired,
    }).isRequired,
    errors: object,
    labelClassName: string,
    inputSize: number,
    onChangeEventAnswer: func.isRequired,
    onChangeEventCheckboxAnswer: func.isRequired,
    openModal: func,
    closeModal: func,
  };

  constructor(props) {
    super(props);

    this.state = { documents: {} };
  }

  onUploadSuccess = index => document => {
    const { onChangeEventAnswer, closeModal } = this.props;
    const { documents } = this.state;

    documents[index] = document;

    this.setState({ documents });

    onChangeEventAnswer(document, index, 'document');
    closeModal();
  }

  render() {
    const { event, errors, onChangeEventAnswer, labelClassName, inputSize, openModal, onChangeEventCheckboxAnswer } = this.props;
    const { documents } = this.state;

    return (
      <div>
        {event.event_questions.map((obj, index) => {
          const error = _.get(errors, obj.question);

          return (
            <Field
              key={index}
              hint={error}
              validationState={error && 'error'}
              id={obj.question}
              labelFor={obj.question}
              label={
                <span>
                  {obj.mandatory && <span className="text-danger">* </span>}
                  {obj.question}
                </span>
              }
              {...{
                labelClassName,
                inputSize,
              }}
            >
              {obj.options && !obj.checkbox && (
                <select
                  id={obj.question}
                  className="form-control"
                  onChange={e => onChangeEventAnswer(e.target.value, index)}
                >
                  <option value="">&mdash; select answer &mdash;</option>
                  {_.map(obj.options, (value, i) => <option key={i} value={value}>{value}</option>)}
                </select>
              )}

              {obj.options && obj.checkbox && _.map(obj.options, (value, i) => (
                <Checkbox
                  key={i}
                  onChange={e => onChangeEventCheckboxAnswer(e.target, index)}
                  value={value}
                >
                  {value}
                </Checkbox>
              ))}

              {!obj.select && !obj.checkbox && (
                <Input name={obj.question} onChange={e => onChangeEventAnswer(e.target.value, index)} />
              )}

              {obj.with_image && (
                <div>
                  <FaButton
                    icon="cloud-upload"
                    bsSize="sm"
                    onClick={openModal('UploadFile', index)}
                    className="m-t-1"
                  >
                    Upload document
                  </FaButton>

                  {documents[index] && <PreviewImage {...documents[index]} />}

                  <UploadFileModal
                    id={index}
                    noun="document"
                    onSuccess={this.onUploadSuccess(index)}
                  />
                </div>
              )}
            </Field>
          );
        })}
      </div>
    );
  }
}

export default CustomEventQuestions;
