import React from 'react';
import { modal } from 'helpers';

import { Modal, Button } from 'react-bootstrap';

class NoticeModal extends React.Component {

  onCloseModal = () => {
    const { onClose, actions: { closeModal } } = this.props;
    if (typeof onClose !== 'undefined') onClose();
    closeModal();
  }

  render() {
    const { title, children } = this.props;

    return (
      <Modal show onHide={this.onCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <hr className="spacer-xs" />
          <h6 className="text-center m-b-1 lh-15">{children}</h6>

        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={this.onCloseModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}

export default modal('Notice', NoticeModal);
