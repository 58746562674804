import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'react-bootstrap';

const { node, number, bool, func, oneOfType } = PropTypes;

export default class extends React.Component {
  static propTypes = {
    activePage: number,
    items: number,
    maxButtons: number,

    /**
     * When `true`, will display the first and the last button page when
     * displaying ellipsis.
     */
    boundaryLinks: bool,

    /**
     * When `true`, will display the default node value ('&hellip;').
     * Otherwise, will display provided node (when specified).
     */
    ellipsis: oneOfType([
      bool, node,
    ]),

    /**
     * When `true`, will display the default node value ('&laquo;').
     * Otherwise, will display provided node (when specified).
     */
    first: oneOfType([
      bool, node,
    ]),

    /**
     * When `true`, will display the default node value ('&raquo;').
     * Otherwise, will display provided node (when specified).
     */
    last: oneOfType([
      bool, node,
    ]),

    /**
     * When `true`, will display the default node value ('&lsaquo;').
     * Otherwise, will display provided node (when specified).
     */
    prev: oneOfType([
      bool, node,
    ]),

    /**
     * When `true`, will display the default node value ('&rsaquo;').
     * Otherwise, will display provided node (when specified).
     */
    next: oneOfType([
      bool, node,
    ]),

    onSelect: func,
    className: node,
  }

  static defaultProps = {
    activePage: 1,
    items: 1,
    maxButtons: 0,
    first: false,
    last: false,
    prev: false,
    next: false,
    ellipsis: true,
    boundaryLinks: false,
  }

  renderPageButtons(activePage, items, maxButtons, boundaryLinks, ellipsis) {
    const pageButtons = [];

    let startPage;
    let endPage;

    if (maxButtons && maxButtons < items) {
      startPage = Math.max(
        Math.min(
          activePage - Math.floor(maxButtons / 2, 10),
          (items - maxButtons) + 1,
        ),
        1,
      );
      endPage = (startPage + maxButtons) - 1;
    } else {
      startPage = 1;
      endPage = items;
    }

    for (let page = startPage; page <= endPage; ++page) {
      pageButtons.push(
        <Pagination.Item
          key={page}
          onClick={this.onBtnClick(page)}
          active={page === activePage}
        >
          {page}
        </Pagination.Item>,
      );
    }

    if (ellipsis && boundaryLinks && startPage > 1) {
      if (startPage > 2) {
        pageButtons.unshift(
          <Pagination.Ellipsis
            key="ellipsisFirst"
            disabled
          />,
        );
      }

      pageButtons.unshift(
        <Pagination.Item
          key={1}
          onClick={this.onBtnClick(1)}
          active={false}
        >
          1
        </Pagination.Item>,
      );
    }

    if (ellipsis && endPage < items) {
      if (!boundaryLinks || endPage < items - 1) {
        pageButtons.push(
          <Pagination.Ellipsis
            key="ellipsis"
            disabled
          />,
        );
      }

      if (boundaryLinks) {
        pageButtons.push(
          <Pagination.Item
            key={items}
            onClick={this.onBtnClick(items)}
            active={false}
          >
            {items}
          </Pagination.Item>,
        );
      }
    }

    return pageButtons;
  }

  onBtnClick = eventKey => () => {
    const { items, onSelect } = this.props;

    if (eventKey > items || eventKey === 0) return;

    onSelect(eventKey);
  }

  render() {
    const {
      activePage,
      items,
      maxButtons,
      boundaryLinks,
      ellipsis,
      first,
      last,
      prev,
      next,
      className,
    } = this.props;

    return (
      <Pagination className={className}>
        {first && (
          <Pagination.First
            onClick={this.onBtnClick(1)}
            disabled={activePage === 1}
          />
        )}
        {prev && (
          <Pagination.Prev
            onClick={this.onBtnClick(activePage - 1)}
            disabled={activePage === 1}
          />
        )}

        {this.renderPageButtons(
          activePage, items, maxButtons, boundaryLinks, ellipsis,
        )}

        {next && (
          <Pagination.Next
            onClick={this.onBtnClick(activePage + 1)}
            disabled={activePage >= items}
          />
        )}
        {last && (
          <Pagination.Last
            onClick={this.onBtnClick(items)}
            disabled={activePage >= items}
          />
        )}
      </Pagination>
    );
  }
}
