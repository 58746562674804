import T from '../actions/types'

export default (state = null, action) => {
  switch (action.type) {
    case T.SET_ACTIVE_PARENT:
      localStorage.setItem('parentId', action.id)
      const activeParent = state.parents.find(p => p.id === action.id)
      return { ...state, activeParent }

    case T.SET_PARENTS:
      const { parents } = action

      const parentId = parseInt(localStorage.getItem('parentId'), 10)
      const index = parents.findIndex(p => p.id === parentId)
      const idx = index !== -1 ? index : 0

      return {
        ...state,
        parents,
        activeParent: parents[idx],
      }

    default:
      return state
  }
}
