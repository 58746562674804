import React from 'react'
import { DropdownButton, MenuItem } from 'react-bootstrap'

export function Item({ icon, onClick, disabled, children }) {
  return (
    <MenuItem onClick={onClick} disabled={disabled} eventKey={children}>
      <i className={`fa fa-${icon}`} />
      &nbsp;&nbsp; {children}
    </MenuItem>
  )
}

export function Divider() {
  return <MenuItem divider />
}

export default function ({ disabled, className, children }) {
  return (
    <DropdownButton
      id="settings"
      title={<i className="fa fa-cog" />}
      noCaret
      pullRight
      disabled={disabled}
      className={className}
    >
      {children}
    </DropdownButton>
  )
}
