// store callbacks until user is loaded
const callbacksStack = [];

export default function (store, callback) {
  const auth = store.getState().get('auth');
  const user = auth && auth.user;

  callbacksStack.push(callback);

  if (user) {
    let fun;
    // eslint-disable-next-line
    while (fun = callbacksStack.pop()) fun(user);
  }
}
