import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { InputGroup, FormControl, FormGroup } from 'react-bootstrap';

import { connect } from 'helpers';
import { FaIcon } from 'components/utilities';
import DatePickerModal from 'components/modals/date-picker';

const { func, string } = PropTypes;

class DatePickerInput extends React.Component {

  static propTypes = {
    value: string,
    onChange: func.isRequired,
    renderFormat: string,
    outFormat: string,
    label: string,
    id: string,
  }

  static defaultProps = {
    renderFormat: 'MMM D, YYYY',
    outFormat: 'YYYY-MM-DD',
    label: '',
  }

  onDateSelected = date => {
    const { onChange, outFormat } = this.props;
    onChange(date && moment(date).format(outFormat));
  }

  openDatePickerModal = () => {
    const { id } = this.props;
    this.props.actions.openModal('DatePicker', id);
  }

  renderInputGroup() {
    const { value, id, renderFormat } = this.props;

    return (
      <InputGroup
        onClick={this.openDatePickerModal()}
        style={{ cursor: 'pointer' }}
      >
        <FormControl
          value={value ? moment(value).format(renderFormat) : ''}
          type="text"
          readOnly
          style={{ cursor: 'pointer' }}
        />
        <InputGroup.Addon className="bg-white">
          <FaIcon calendar />
        </InputGroup.Addon>

        <DatePickerModal
          id={id}
          date={(value && moment(value)) || moment().add(1, 'day')}
          onDateSelected={this.onDateSelected}
        />
      </InputGroup>
    );
  }

  render() {
    const { label } = this.props;

    if (label.length > 0) {
      return (
        <FormGroup>
          {
            // eslint-disable-next-line
          }<label onClick={this.openDatePickerModal()} className="control-label">
            {label}
          </label>
          {this.renderInputGroup()}
        </FormGroup>
      );
    }

    return this.renderInputGroup();
  }
}

export default connect(DatePickerInput);
