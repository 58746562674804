import React from 'react'
import PropTypes from 'prop-types'
import { modal } from 'helpers'
import { Payment } from 'resources'

import PaymentContainerWithPayPal from './pay/payment-container-with-paypal'
import { Row, Col, Modal, Button, Radio, ButtonToolbar } from 'react-bootstrap'
import { Form, Field, SubmitButton } from 'components/form'
import { FaButton, PaymentMethod as PaymentMethodComponent } from 'components/utilities'

const { number, string, shape } = PropTypes

class AdvancedPayModal extends PaymentContainerWithPayPal {
  static contextTypes = {
    user: shape({
      role: string.isRequired,
      member: shape({
        id: number.isRequired,
        unique_id: string.isRequired,
      }),
      parent: shape({
        member: shape({
          id: number.isRequired,
          unique_id: string.isRequired,
        }),
      }),
    }).isRequired,
  }

  render() {
    const { user } = this.context
    const member = this.getMember()
    const { downloadPDFBill } = this.props.actions
    const { paymentMethods } = this.props
    const { amount, selectedPaymentMethod } = this.state
    const rootError = this.getRootError()

    const data = {
      payment: {
        amount: this.getChargedAmount(),
        source: `web/${user.role}`,
        member_id: member.id,
      },
    }

    const formContent = (
      <React.Fragment>
        <Modal.Header closeButton>
          <Modal.Title>Payment options</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <hr className="spacer-xs" />

          <Field name="payment.method" label="Payment method" inputSize={9}>
            {_.map(paymentMethods, (paymentMethod, i) => (
              <Radio
                key={i}
                name="payment.payment_method_id"
                value={paymentMethod.id}
                checked={paymentMethod.id === _.get(selectedPaymentMethod, 'id')}
                onChange={this.doSelectPaymentMethod.bind(this, paymentMethod)}
              >
                <PaymentMethodComponent paymentMethod={paymentMethod} />
              </Radio>
            ))}

            <ButtonToolbar className="m-t-1">
              <Button onClick={this.openModal('AddCreditCard')} bsSize="sm">
                Add credit card
              </Button>
              <Button onClick={this.openModal('AddBankAccount')} bsSize="sm">
                Add bank account
              </Button>
              {this.renderPayPalPaymentMethodButton()}
            </ButtonToolbar>
          </Field>

          <Field
            name="payment.input_amount"
            label="Amount to pay"
            inputSize={4}
            defaultValue={Math.max(amount, 0)}
            money
            autoFocus
            onChange={this.onAmountChange}
          />

          {rootError && (
            <Row className="text-danger fw-semibold m-t-neg-1 m-b-1">
              <Col md={9} mdPush={3}>
                {rootError}
              </Col>
            </Row>
          )}

          {this.renderUpCharge()}

          <hr className="spacer-sm" />

          <Field className="m-b-0" name="payment.destination" label="Send checks to:" inputSize={9}>
            Greek Capital Management
            <br />
            PO Box 41027
            <br />
            Bethesda, MD 20824
            <div className="fs-125 m-t-05 m-b-05">
              <strong>Memo line:</strong> {member.unique_id}
            </div>
            <FaButton icon="download" bsSize="sm" onClick={() => downloadPDFBill(member.id)}>
              Download bill PDF
            </FaButton>
          </Field>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col md={5}>
              <Button onClick={this.closeModal} className="pull-left">
                &laquo; Quick pay
              </Button>
            </Col>
            {this.isPayPalForm() ? (
              this.renderPayPalSubmitButton()
            ) : member.organization && member.organization.id === 1 ? (
              <Button bsStyle="success" disabled onClick={() => {}} className="m-l-0 m-r-1">
                Disabled in Demo
              </Button>
            ) : (
              <SubmitButton offset={0} bsStyle="success" className="m-l-0">
                Make payment
              </SubmitButton>
            )}
          </Row>
        </Modal.Footer>
      </React.Fragment>
    )

    return (
      <Modal show onHide={this.closeModal}>
        {this.isPayPalForm() ? (
          this.renderPayPalForm(formContent)
        ) : (
          <Form
            action={Payment.paymentMethod}
            data={data}
            onSuccess={this.onPaymentCreate}
            onValidationErrors={this.onError}
          >
            {formContent}
          </Form>
        )}
      </Modal>
    )
  }
}

export default modal('AdvancedPay', AdvancedPayModal)
