import React, { useState } from 'react';
import { oneOfType, object, array, bool, func, shape } from 'prop-types';
import { Document } from 'resources';

import SignatureCanvas from 'react-signature-canvas';
import { Button } from 'react-bootstrap';
import S3ProgressBar from 'components/uploader/s3-progress-bar';
import { Portlet, Body } from 'components/utilities';

const height = window.document.body.clientWidth < 600 ? 250 : 150;

const Signature = ({ acceptance, isReady, isSaving, onUpdateAcceptance, doSubmit, errors }) => {
  const [s3Props, setS3Props] = useState({});

  const canvasRef = React.createRef();

  const uploadSignature = () => (
    canvasRef.current.getCanvas().toBlob(file => (
      Document.uploadUrl({}).then(({ data: s3 }) => {
        onUpdateAcceptance('s3_path', s3.path);
        setS3Props({ s3, file });
      })
    ), 'image/png')
  );

  const onSignatureChange = () => {
    const isEmpty = canvasRef.current.isEmpty();
    if (acceptance.has_signature === isEmpty) {
      onUpdateAcceptance('has_signature', !isEmpty);
    }
  };

  const prevErrorsRef = React.useRef();
  React.useEffect(() => { prevErrorsRef.current = errors; });
  const prevErrors = prevErrorsRef.current;
  if (prevErrors && prevErrors.length !== 0 && !_.isEmpty(s3Props)) setS3Props({});

  return (
    <Portlet boxed>
      <Body>

        <h6 className="m-b-1">Please sign in the box below.</h6>

        <SignatureCanvas
          ref={canvasRef}
          onEnd={onSignatureChange}
          canvasProps={{
            height,
            className: 'signature-canvas',
          }}
        />

        {errors.length === 0 && !_.isEmpty(s3Props) && (
          <S3ProgressBar
            {...s3Props}
            sendDimensions
            onSuccess={doSubmit}
          />
        )}

        <div className="m-t-1">
          <Button
            onClick={() => {
              canvasRef.current.clear();
              onSignatureChange();
            }}
            disabled={isSaving}
            className="pull-left"
          >
            Clear
          </Button>

          <Button
            onClick={uploadSignature}
            disabled={!acceptance.has_signature || !isReady || isSaving}
            bsStyle="secondary"
            className="pull-right"
          >
            {isSaving ? 'Please wait...' : 'Sign'}
          </Button>
        </div>
      </Body>
    </Portlet>
  );
};

Signature.propTypes = {
  acceptance: shape({
    has_signature: bool.isRequired,
  }).isRequired,
  errors: oneOfType([array, object]).isRequired,
  isReady: bool.isRequired,
  isSaving: bool.isRequired,
  onUpdateAcceptance: func.isRequired,
  doSubmit: func.isRequired,
};

export default Signature;
