import React from 'react';
import moment from 'moment';

const monthsList = [...Array(12).keys()].map(m => moment().month(m).format('MMMM'));

export default function ({ id, name, defaultValue, onChange }) {
  return (
    <select
      id={id}
      name={name}
      defaultValue={defaultValue}
      className="form-control"
      onChange={e => onChange(e.target.value)}
    >
      {monthsList.map((m, index) => (
        <option key={index} value={index + 1}>{m}</option>
      ))}
    </select>
  );
}
