import React from 'react';

import { Row, Col, Well, Button } from 'react-bootstrap';

const frownyFace = require('images/etc/frowny-face.svg');

const renderActions = actions => {
  if (_.isEmpty(actions)) { return null; }

  return (
    <p>
      {_.map(actions, ({ action, onClick }, i) => (
        <Button
          key={i}
          bsStyle="primary"
          bsSize="large"
          onClick={onClick}
          className="m-l-05 m-r-05"
        >
          {action}
        </Button>
      ))}
    </p>
  );
};

export default function (props) {
  const { message, icon } = props;

  const actions = _.defaultTo(props.actions, []);
  if (props.action) {
    actions.push(_.pick(props, 'action', 'onClick'));
  }

  const size = _.defaultTo(props.size, 8);
  const padding = (12 - size) / 2;

  return (
    <Row>
      <Col md={size} mdPush={padding}>

        <hr className="spacer-sm" />

        <Well bsSize="large" className="text-center empty-state">
          <h3 className="m-b-1">{message}</h3>
          {icon !== false && (
            <span className="text-muted">
              <img src={frownyFace} height={80} role="presentation" />
            </span>
          )}
          <hr className="spacer-sm" />
          {renderActions(actions)}
        </Well>

      </Col>
    </Row>
  );
}
