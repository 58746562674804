import React from 'react'
import PropTypes from 'prop-types'
import { modal } from 'helpers'

import { Modal } from 'react-bootstrap'
import { Form, Field, SubmitButton } from 'components/form'

const { string, func } = PropTypes

class MoneyModal extends React.Component {
  static propTypes = {
    title: string.isRequired,
    button: string.isRequired,
    name: string.isRequired, // eslint-disable-line
    label: string.isRequired, // eslint-disable-line
    action: func.isRequired, // eslint-disable-line
    onSuccess: func.isRequired, // eslint-disable-line
  }

  render() {
    const { title, button } = this.props
    const { closeModal } = this.props.actions

    const formProps = _.pick(this.props, ['method', 'action', 'data', 'query', 'id', 'onSuccess'])
    const fieldProps = _.pick(this.props, [
      'hint',
      'name',
      'label',
      'textarea',
      'placeholder',
      'defaultValue',
      'filename',
      'onChange',
    ])

    return (
      <Modal show onHide={closeModal}>
        <Form {...formProps}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <hr className="spacer-xs" />

            <Field money {...fieldProps} autoFocus />
          </Modal.Body>
          <Modal.Footer>
            <SubmitButton offset={5}>{button}</SubmitButton>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

export default modal('Money', MoneyModal)
