/* eslint-disable new-cap */
import React from 'react'
import { Route, IndexRedirect } from 'react-router'
import { ensureRole } from '../helpers.js'

export default function parentRoutes(store) {
  const Parent = path => (_, cb) =>
    require.ensure([], require => cb(null, require('chunks/parent')[path]))

  return (
    <Route path="parent" onEnter={ensureRole(store, 'parent')}>
      <IndexRedirect to="balance" />

      <Route path="balance" getComponent={Parent('Balance')} />
      <Route path="required-documents" getComponent={Parent('RequiredDocuments')} />
      <Route path="required-documents/:id" getComponent={Parent('RequiredDocuments/show')} />

      <Route path="settings" getComponent={Parent('Settings')}>
        <IndexRedirect to="profile" />
        <Route path="profile" getComponent={Parent('Settings/Profile')} />
        <Route path="password" getComponent={Parent('Settings/Password')} />
        <Route path="notifications" getComponent={Parent('Settings/Notifications')} />
        <Route path="payment-methods" getComponent={Parent('Settings/PaymentMethods')} />
      </Route>
    </Route>
  )
}
