import React from 'react'
import formatDate from 'utils/format_date'

const DEFAULT_FORMAT = 'long'

export default function (props) {
  const dateFormat =
    _.reject(Object.keys(props), propKey => ['className', 'date'].includes(propKey))[0] ||
    DEFAULT_FORMAT
  const formatted = formatDate(props.date, dateFormat)

  if (props.onClick) {
    return (
      <a
        href
        onClick={e => {
          e.preventDefault()
          props.onClick()
          e.target.blur()
        }}
        className={props.className}
      >
        {formatted}
      </a>
    )
  }

  return <span className={props.className}>{formatted}</span>
}
