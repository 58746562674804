import React from 'react';

export default function ({ toggled }) {
  return (<div className="menu-toggle-btn" data-toggled={toggled}>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>);
}
