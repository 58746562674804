import { put, select } from 'redux-saga/effects';
import { push } from 'react-router-redux';

export function* open({ id, props }) {
  const location = yield select((state) => (
    state.get('route').get('locationBeforeTransitions')
  ));

  const newModal = props.id ? `${id}:${props.id}` : id;
  const previousModelsHash = location.get('hash');
  const hash = previousModelsHash ? `${previousModelsHash};${newModal}` : `#${newModal}`;

  yield put(push({
    pathname: location.get('pathname'),
    search: location.get('search'),
    hash,
  }));
}

export function* close(opts) {
  const location = yield select((state) => (
    state.get('route').get('locationBeforeTransitions')
  ));

  if (!location.get('hash')) {
    return;
  }

  let hash;
  if (opts.closeAll) {
    hash = '';
  } else {
    hash = location.get('hash').split(';').slice(0, -1).join(';');
  }

  yield put(push({
    pathname: location.get('pathname'),
    search: location.get('search'),
    hash,
  }));
}
