import { apiRequest } from 'actions/use';
import apiWrapper, { generatePayload } from './helpers';

export default class Account extends apiWrapper('/accounts') {

  static overview({ data, onSuccess, onFailure }) {
    apiRequest({
      method: 'GET',
      uri: '/api/accounts/overview',
      data,
      on: {
        200: onSuccess,
      },
      onFailure,
    });
  }

  static weights = {
    update({ data, query, onSuccess }) {
      apiRequest({
        method: 'PUT',
        uri: '/api/accounts/weights',
        data: generatePayload({ data, query }),
        on: {
          200: onSuccess,
        },
      });
    },
  }

  static ledger({ id, data, onSuccess }) {
    const theId = id || data.id;

    apiRequest({
      method: 'GET',
      uri: `/api/accounts/${theId}/ledger?${_.toQueryString(data)}`,
      on: {
        200: onSuccess,
      },
    });
  }

  static federationLedger({ id, data, onSuccess }) {
    const theId = id || data.id;

    apiRequest({
      method: 'GET',
      uri: `/api/accounts/${theId}/federation_ledger?${_.toQueryString(data)}`,
      on: {
        200: onSuccess,
      },
    });
  }

}
