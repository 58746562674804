import T from '../actions/types';

export default function (state = {}, action) {
  switch (action.type) {

    case T.BOOTSTRAP:
      return {
        user: action.user,
      };

    case T.LOGIN_REQUEST:
      return {
        authenticating: true,
      };

    case T.LOGIN_SUCCESS:
      return {
        authenticating: true,
      };

    case T.LOGIN_FAILURE:
      return _.omit(action, 'type');

    case T.LOGOUT_REQUEST:
      return null;

    case T.LOGOUT_SUCCESS:
      return null;

    default:
      return state;
  }
}
