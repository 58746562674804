import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Humanize from 'humanize-plus'
import { modal } from 'helpers'
import Container from 'container'
import { Transaction } from 'resources'

import { Typeahead } from 'components/typeahead'
import { Modal, Button, ButtonGroup } from 'react-bootstrap'
import { Form, Field } from 'components/form'
import { DatePickerModal } from 'components/modals'

import Charge from './charge'
import Credit from './credit'
import Payment from './payment'

const { array, arrayOf, bool, func, number, object } = PropTypes

class AddTransactionModal extends Container {
  static propTypes = {
    isRoot: bool,
    accountId: number,
    balances: object,
    onTransactionCreate: func.isRequired,

    // Only one of memberId or memberIds should be passed.
    //  If memberIds is passed, there will be a select to edit the list
    memberId: number,

    memberIds: arrayOf(number),
    members: arrayOf(object),
  }

  static contextTypes = {
    accounts: array.isRequired,
  }

  static childContextTypes = {
    accounts: array.isRequired,
    moreOptions: bool.isRequired,
  }

  constructor(props) {
    super(props, {
      type: 'charge',
      moreOptions: false,
      formData: {},
      dueDate: null,
      memberIds: props.memberIds,
      amount: 0,
    })
  }

  componentWillReceiveProps(nextProps) {
    const { memberIds } = nextProps
    this.setState({ memberIds })

    super.componentWillReceiveProps(nextProps)
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.show && this.props.show) {
      const { formData } = this.state
      _.each(formData, (value, name) => {
        const el = document.getElementsByName(name)[0]
        if (el && el.type === 'text') {
          el.value = value
        }
      })
    }
  }

  getChildContext = () => ({
    accounts: this.getAccounts(),
    moreOptions: this.state.moreOptions,
  })

  onTransactionCreate = ({ data }) => {
    this.props.actions.closeModal()
    this.props.onTransactionCreate({ data })
    this.setState({
      isLoading: false,
      formData: {},
    })
  }

  onMemberChange = members => {
    const memberIds = _.map(members, 'id')
    this.setState({ memberIds })
  }

  getAccounts = () => {
    const accounts = _.sortBy(this.context.accounts, 'weight')
    return _.filter(
      accounts,
      (account, index) => account.scope === 'user' || index === _.size(accounts) - 1
    )
  }

  doStoreInput = e => {
    const el = e.target
    this.setState(state => ({
      formData: _.assign({}, state.formData, {
        [el.name]: el.value,
      }),
    }))
  }

  doCreateTransaction = request => {
    const { type, isLoading } = this.state

    if (isLoading) {
      return
    }
    this.setState({ isLoading: true })

    Transaction.create(type, request)
  }

  getAddButtonText = type => {
    const { creditAmount } = this.state
    if (type === 'credit' && creditAmount > 0) {
      return `Add $${Humanize.formatNumber(creditAmount, 2)} credit`
    }

    return `Add ${type}`
  }

  isDemo() {
    const isMorphed = localStorage.getItem('rootToken') !== null
    if (isMorphed) return false
    return this.props.organizationId === 1 && location.pathname === '/admin/members'
  }

  renderTransactionType = () => {
    switch (this.state.type) {
      case 'charge':
        return (
          <Charge
            accountId={this.props.accountId}
            dueDate={this.state.dueDate}
            openModal={this.openModal}
            formData={this.state.formData}
            inputChange={this.doStoreInput}
          />
        )
      case 'credit':
        return (
          <Credit
            balances={this.props.balances}
            organizationId={this.props.organizationId}
            onCreditAmountChange={creditAmount => this.setState({ creditAmount })}
          />
        )
      case 'payment':
        return <Payment />
      default:
        return null
    }
  }

  renderToggleButton = type => (
    <Button
      onClick={this.setStateTo('type', type)}
      active={this.state.type === type}
      bsStyle={this.state.type === type ? 'secondary' : 'default'}
    >
      {`${type.charAt(0).toUpperCase()}${type.slice(1)}`}
    </Button>
  )

  render() {
    const { memberId, members, isRoot } = this.props
    const { type, moreOptions, memberIds, isLoading } = this.state

    const data = {
      transaction: {
        member_ids: memberId ? [memberId] : memberIds,
      },
    }

    return (
      <div>
        <Modal show onHide={this.closeModal}>
          <Form
            action={this.doCreateTransaction}
            data={data}
            onSuccess={this.onTransactionCreate}
            onValidationErrors={() => this.setState({ isLoading: false })}
            onInput={this.doStoreInput}
            ref={f => (this.form = f)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add a transaction</Modal.Title>
            </Modal.Header>
            <Modal.Body className="form-horizontal">
              <hr className="spacer-xs" />

              {memberIds ? (
                <Field name="transaction.member_ids" label="Members" inputSize={9}>
                  <Typeahead
                    name="transaction.member_ids"
                    multiple
                    labelKey="name"
                    placeholder="Select members..."
                    multiplePlaceholder="Select members..."
                    options={members || []}
                    selected={_.filter(members, m => _.includes(memberIds, m.id))}
                    onChange={this.onMemberChange}
                  />
                </Field>
              ) : null}

              <Field name="txn_type" label="Type">
                <ButtonGroup id="txn_type">
                  {this.renderToggleButton('charge')}
                  {this.renderToggleButton('credit')}

                  {isRoot ? (
                    <Button
                      onClick={this.openModal('ChargeCreditCard', memberId)}
                      bsStyle="default"
                    >
                      Credit card
                    </Button>
                  ) : null}

                  {isRoot ? (
                    <Button onClick={this.openModal('RecordCheck', memberId)} bsStyle="default">
                      Check
                    </Button>
                  ) : null}

                  {!isRoot ? this.renderToggleButton('payment') : null}
                </ButtonGroup>
              </Field>

              {this.renderTransactionType()}
            </Modal.Body>
            <Modal.Footer>
              {type === 'charge' ? (
                <Button onClick={this.toggleState('moreOptions')} className="pull-left">
                  {moreOptions ? 'Fewer' : 'More'} options
                </Button>
              ) : null}

              {this.isDemo() ? (
                <Button type="submit" disabled bsStyle="primary">
                  Disabled in Demo
                </Button>
              ) : (
                <Button
                  type="submit"
                  disabled={isLoading}
                  bsStyle="primary"
                  className={cx({
                    'btn-loading': isLoading,
                  })}
                >
                  {this.getAddButtonText(type)}
                </Button>
              )}
            </Modal.Footer>
          </Form>
        </Modal>

        <DatePickerModal onDateSelected={dueDate => this.setState({ dueDate })} />
      </div>
    )
  }
}

export default modal('AddTransaction', AddTransactionModal)
