import React from 'react';
import { Link } from 'react-router';

export default function () {
  return (
    <div className="content">
      <div className="container">
        <br className="sm-50 md-100" />
        <div className="error-container">
          <div className="error-code">404</div>
          <div className="error-details">
            <h4>Oops, <span className="text-primary">You're lost</span>.</h4>
            <p>
              We can not find the page you're looking for.
            </p>
            <Link to="/">Get back to safety.</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
