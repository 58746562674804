import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TimePicker from 'rc-time-picker';

import Calendar from 'components/date-range/Calendar';

const { func, object, string } = PropTypes;

export default class DateTimeInput extends React.Component {

  static propTypes = {
    dateTime: object,
    onChange: func.isRequired,
    className: string,
  }

  constructor(props) {
    super();

    this.state = {
      dateTime: props.dateTime || moment().add(1, 'days'),
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      dateTime: nextProps.dateTime || moment().add(1, 'days'),
    });
  }

  onDateChange = (newDate) => {
    const { onChange } = this.props;
    const { dateTime } = this.state;

    const newDateTime = dateTime.clone();
    newDateTime.set({
      date: newDate.date(),
      month: newDate.month(),
      year: newDate.year(),
    });

    onChange(newDateTime);
  }

  onTimeChange = dt => this.props.onChange(dt)
  onClear = () => this.props.onChange(null)

  render() {
    const { className } = this.props;
    const { dateTime } = this.state;

    return (
      <div className={`date-time-picker ${className}`}>
        <Calendar
          minDate={moment().add(1, 'days')}
          date={dateTime.clone()}
          onChange={this.onDateChange}
        />
        <hr className="spacer-xs" />
        <TimePicker
          allowEmpty={false}
          value={dateTime}
          showSecond={false}
          use12Hours
          minuteStep={5}
          format="h:mma"
          onChange={this.onTimeChange}
          className="col-md-5 p-l-0"
        />
      </div>
    );
  }
}
