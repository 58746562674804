/* eslint-disable new-cap */
import React from 'react';
import { Route, IndexRoute, IndexRedirect } from 'react-router';

export default function adminMemberRoutes() {

  const Admin = path => (_, cb) =>
    require.ensure([], (require) => cb(null, require('../../chunks/admin')[path]));

  return (
    <Route path="members/:id" getComponent={Admin('Member')}>
      <IndexRedirect to="transactions" />
      <Route path="transactions">
        <IndexRoute getComponent={Admin('Member/Transactions')} />
        <Route path=":account_id" getComponent={Admin('Member/Transactions')} />
      </Route>
      <Route path="payment-plan" getComponent={Admin('Member/PaymentPlan')} />
      <Route path="profile" getComponent={Admin('Member/Profile')} />
      <Route path="access" getComponent={Admin('Member/Access')} />
      <Route path="password" getComponent={Admin('Member/ResetPassword')} />
      <Route path="groups" getComponent={Admin('Member/Groups')} />
      <Route path="budget-committees" getComponent={Admin('Member/BudgetCommittees')} />
      <Route path="grade" getComponent={Admin('Member/Grade')} />
      <Route path="archive" getComponent={Admin('Member/Archive')} />
    </Route>
  );
}
