import { apiRequest } from 'actions/use';
import apiWrapper, { generatePayload, list, updateMethod } from './helpers';

export default class Event extends apiWrapper('/events') {

  static retrievePublic({ id, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'GET',
      uri: `/api/events/${id}/retrieve_public`,
      data: generatePayload({ query }),
      onSuccess,
      onFailure,
    });
  }

  static listByUser(opts) {
    list('/events/list_by_user', opts);
  }

  static endRegistration = updateMethod('/events', 'end_registration')

}
