import T from '../actions/types';

export default (state = {}, action) => {
  if (action.type === T.NOTIFICATION) {
    if (action.payload.type === 'PollVoteCreate') {
      return {
        [action.payload.poll_id]: {
          data: { vote_count: action.payload.vote_count },
        },
      };
    }

    if (action.payload.type === 'PollEndVoting') {
      return {
        [action.payload.poll_id]: {
          ended_at: action.payload.ended_at,
          data: { poll_votes: action.payload.poll_votes },
        },
      };
    }
  }

  return state;
};
