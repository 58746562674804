import React from 'react'
import PropTypes from 'prop-types'
import AppWrapper from 'components/layout/wrapper'
import { authorized } from 'helpers'

const { object, number } = PropTypes

export class App extends React.Component {
  static propTypes = {
    auth: object,
    organization: object,
  }

  static childContextTypes = {
    member_id: number,
    organization: object.isRequired,
    user: object.isRequired,
    owner: object,
  }

  getChildContext() {
    const {
      auth: { user },
      organization,
    } = this.props
    const context = {}

    if (user && user.member) {
      context.member_id = user.member.id
    }

    context.user = user || {}
    context.organization = organization

    if (_.includes(['member', 'admin', 'alumni'], user.role)) {
      context.owner = {
        owner_type: 'Organization',
        owner_id: organization.id,
      }
    } else if (user.role === 'federation') {
      context.owner = {
        owner_type: 'Federation',
        owner_id: user.federation.id,
      }
    }

    return context
  }

  isLoaded = () => {
    const {
      auth: { user },
      organization,
    } = this.props
    if (!user) return false

    return !organization.id || organization.id === this.organizationId()
  }

  organizationId = () => {
    const {
      auth: { user },
    } = this.props

    if (user.alumni) return user.alumni.organization_id

    const member = user.parents ? user.parents[0].member : user.member

    return member ? member.organization_id : null
  }

  render = () => {
    if (!this.isLoaded()) {
      return null
    }
    return <AppWrapper {...this.props} />
  }
}

export default authorized(App)
