import { apiRequest } from 'actions/use';
import querystring from 'querystring';
import apiWrapper, { generatePayload } from './helpers';

export default class Bill extends apiWrapper('/bills') {

  static paid({ id, data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'PUT',
      uri: `/api/bills/${id}/paid`,
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    });
  }

  static approve({ id, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'PUT',
      uri: `/api/bills/${id}/approve`,
      data: generatePayload({ query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    });
  }

  static cancel({ id, data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'PUT',
      uri: `/api/bills/${id}/cancel`,
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    });
  }

  static refuse({ id, data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'PUT',
      uri: `/api/bills/${id}/refuse`,
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    });
  }

  static transfer({ data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'POST',
      uri: '/api/bills/transfer',
      data: generatePayload({ data, query }),
      on: {
        201: onSuccess,
      },
      onFailure,
    });
  }

  static reporting({ data, onSuccess }) {
    apiRequest({
      method: 'GET',
      uri: `/api/bills/reporting?${querystring.stringify(data)}`,
      on: {
        200: onSuccess,
      },
    });
  }

  static summary({ onSuccess }) {
    apiRequest({
      method: 'GET',
      uri: '/api/bills/summary',
      on: {
        200: onSuccess,
      },
    });
  }

}
