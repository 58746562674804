import { apiRequest } from 'actions/use';
import apiWrapper from './helpers';

export default class MeetingDate extends apiWrapper('/meeting_dates') {

  static archive({ id, onSuccess, onFailure }) {

    apiRequest({
      uri: `/api/meeting_dates/${id}`,
      method: 'DELETE',
      on: {
        204: onSuccess,
      },
      onFailure,
    });
  }

}
