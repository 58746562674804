import React from 'react';

const badge = require('images/etc/braintree-badge-wide-light.png');

export default function Badge({ height, className }) {

  return (
    <a
      href="https://www.braintreepayments.com/features/data-security"
      target="_blank"
      tabIndex="-1"
      className={`braintree-badge ${className}`}
      style={{ marginTop: 3 }}
    >
      <img
        src={badge}
        width={height * (280.0 / 44.0)}
        height={height}
        role="presentation"
      />
    </a>
  );
}
