import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FaIcon } from 'components/utilities';

export default function ({ profile }) {
  return (
    profile && (!profile.complete) ? (
      <OverlayTrigger placement="right" overlay={(
        <Tooltip id="">Required information is missing</Tooltip>
      )}>
        <FaIcon exclamation-circle warning className="pull-right" />
      </OverlayTrigger>
    ) : null
  );
}
