import T from '../actions/types';

export default (state = null, action) => {
  switch (action.type) {

    case T.SET_DATE_RANGE:
      return action.dateRange;

    default:
      return state;
  }
};
