import React from 'react'
import PropTypes from 'prop-types'
import scriptLoader from 'react-async-script-loader'
import { modal } from 'helpers'
import { Parent } from 'resources'

import { Modal, Col, FormGroup } from 'react-bootstrap'
import { AddressInput } from 'components/utilities'
import { Form, Field, SubmitButton, hasErrors } from 'components/form'

class AddParentModal extends React.Component {
  static propTypes = {
    memberId: PropTypes.number.isRequired,
    onParentCreate: PropTypes.func.isRequired,
    isScriptLoadSucceed: PropTypes.bool.isRequired,
  }

  constructor() {
    super()

    this.state = {
      errors: {},
      data: {},
    }
  }

  componentWillReceiveProps = () => this.setState({ errors: {} })

  onParentCreate = ({ data }) => {
    this.emptyAddressData()
    this.props.actions.closeModal()
    this.props.onParentCreate({ data })
  }

  onValidationErrors = errors => this.setState({ errors })

  onAddressSelect = address => {
    this.setState({
      data: {
        parent: {
          user: { address },
        },
      },
    })
  }

  onHide = () => {
    this.emptyAddressData()
    this.props.actions.closeModal()
  }

  emptyAddressData = () => {
    this.setState({
      data: {
        parent: {
          user: { address: null },
        },
      },
    })
  }

  render() {
    const { memberId } = this.props
    const { data, errors } = this.state

    if (!this.props.isScriptLoadSucceed) {
      return null
    }

    return (
      <Modal show onHide={this.onHide}>
        <Form
          action={Parent.create}
          data={data}
          query={`
            parent {
              send_email_bills
              send_physical_bills
              send_sms_reminders
              data { member_count }
              user {
                first_name
                last_name
                phone_number
                email

                address {
                  address
                  address_2
                  city
                  state
                  postal_code
                  country
                }
              }
              member {
                user {
                  first_name
                  last_name
                }
              }
            }
          `}
          onValidationErrors={this.onValidationErrors}
          onSuccess={this.onParentCreate.bind(this)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add a parent</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input type="hidden" name="parent.member_id" value={memberId} />

            <hr className="spacer-xs" />

            <FormGroup
              validationState={
                hasErrors(errors, 'parent.user', 'first_name', 'last_name') ? 'error' : null
              }
            >
              <label htmlFor="parent.user.first_name" className="col-md-3 control-label">
                Name
              </label>
              <Col md={4}>
                <input
                  name="parent.user.first_name"
                  id="parent.user.first_name"
                  type="text"
                  className="form-control"
                  placeholder="First"
                  autoFocus
                />
              </Col>
              <Col md={5}>
                <input
                  name="parent.user.last_name"
                  type="text"
                  className="form-control"
                  placeholder="Last"
                />
              </Col>
            </FormGroup>

            <Field name="parent.user.email" label="Email" inputSize={9} />

            <Field
              name="parent.user.phone_number"
              label="Phone number"
              mask="(999) 999 - 9999"
              inputSize={9}
            />

            <Field
              label="Address"
              validationState={hasErrors(errors, 'parent.user', 'address') ? 'error' : null}
              inputSize={9}
            >
              <AddressInput namePrefix="parent.user" onAddressSelect={this.onAddressSelect} />
            </Field>
          </Modal.Body>
          <Modal.Footer>
            <SubmitButton offset={5}>Add parent</SubmitButton>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

export default scriptLoader(
  `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_PLACES_API_KEY}&libraries=places`
)(modal('AddParent', AddParentModal))
