import { modal } from 'helpers'
import PropTypes from 'prop-types'
import React from 'react'

import { Field, Form, SubmitButton } from 'components/form'
import { FormGroup, Modal, Row, Col, Button } from 'react-bootstrap'

const { string } = PropTypes

class InputModal extends React.Component {
  static propTypes = {
    title: string.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    label: string.isRequired,
    button: string.isRequired,
    rootErrorKey: string,
  }

  state = {}

  onValidationErrors = errors => this.setState({ errors })

  render() {
    const { errors } = this.state
    const { title, button, children, rootErrorKey } = this.props
    const { closeModal } = this.props.actions

    const formProps = _.pick(this.props, ['method', 'action', 'data', 'query', 'id', 'onSuccess'])
    const fieldProps = _.pick(this.props, [
      'hint',
      'name',
      'label',
      'textarea',
      'placeholder',
      'disabled',
      'defaultValue',
      'filename',
      'money',
      'onChange',
    ])

    return (
      <Modal show onHide={closeModal}>
        <Form {...formProps} onValidationErrors={this.onValidationErrors}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <hr className="spacer-xs" />

            <Field {...fieldProps} autoFocus />

            {rootErrorKey && errors && (
              <FormGroup validationState="error">
                <span className="help-block text-danger m-l-1">{_.get(errors, rootErrorKey)}</span>
              </FormGroup>
            )}
            {children}
          </Modal.Body>
          <Modal.Footer>
            <Row>
              {this.props.disabled ? (
                <Col md={7} mdPush={5}>
                  <Button bsStyle="primary" disabled>
                    {button}
                  </Button>
                </Col>
              ) : (
                <SubmitButton offset={5}>{button}</SubmitButton>
              )}
            </Row>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

export default modal('Input', InputModal)
