import _ from 'helpers/lodash'
import { apiRequest } from 'actions/use'
import apiWrapper, { generatePayload } from './helpers'

export default class Transaction extends apiWrapper('/transactions') {
  static create(type, { data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'POST',
      uri: `/api/transactions/${type}s`,
      data: generatePayload({ data, query }),
      on: {
        201: onSuccess,
      },
      onFailure,
    })
  }

  static search({ data, onSuccess }) {
    apiRequest({
      method: 'GET',
      uri: `/api/transactions/search?${_.toQueryString(data)}`,
      on: {
        200: onSuccess,
      },
    })
  }

  static searchRoot({ data, query, onSuccess }) {
    apiRequest({
      method: 'GET',
      uri: `/api/transactions/search_root?${_.toQueryString({ ...data, query })}`,
      on: {
        200: onSuccess,
      },
    })
  }

  static summary({ data, onSuccess }) {
    apiRequest({
      method: 'GET',
      uri: `/api/transactions/summary?${_.toQueryString(data)}`,
      on: {
        200: onSuccess,
      },
    })
  }

  static listUnpaid({ data, onSuccess }) {
    apiRequest({
      method: 'GET',
      uri: `/api/transactions/list_unpaid?${_.toQueryString(data)}`,
      on: {
        200: onSuccess,
      },
    })
  }

  static archiveAll({ data, onSuccess, onFailure }) {
    apiRequest({
      method: 'DELETE',
      uri: '/api/transactions/0/archive_all',
      data,
      on: {
        204: onSuccess,
      },
      onFailure,
    })
  }
}
