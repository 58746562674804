import 'babel-polyfill' // MUST BE FIRST

import LogRocket from 'logrocket'
import { GCM_ENVIRONMENT } from 'helpers'

import 'utils/console'

// eslint-disable-next-line no-console
console.log(`=== Welcome to ${GCM_ENVIRONMENT} ===`)

const sendDataToRemoteServices = GCM_ENVIRONMENT !== 'development' && GCM_ENVIRONMENT !== 'test'

/* eslint-disable */
/************************/
/**  ROLLBAR TRACKING  **/
/************************/
const rollbarConfig = {
  accessToken: process.env.ROLLBAR_JS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: sendDataToRemoteServices,
  verbose: !sendDataToRemoteServices,
  payload: {
    environment: GCM_ENVIRONMENT,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: process.env.COMMIT_HASH,
      },
    },
  },
}

const rollbar = require('./utils/rollbar.umd.min.js')
window.Rollbar = new rollbar(rollbarConfig)
/************************/

if (sendDataToRemoteServices) {
  // LogRocket.init('fxa4pp/greek-capital-management')

  // prettier-ignore
  ; (function (i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r
      ; (i[r] =
        i[r] ||
        function () {
          ; (i[r].q = i[r].q || []).push(arguments)
        }),
        (i[r].l = 1 * new Date())
      ; (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0])
    a.async = 1
    a.src = g
    m.parentNode.insertBefore(a, m)
  })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga')

  ga('create', 'UA-91033303-1', 'auto')
  ga('send', 'pageview')

  /* Facebook Pixel Code */
  // prettier-ignore
  !function (f, b, e, v, n, t, s) {
    if (f.fbq) return; n = f.fbq = function () {
      n.callMethod ?
        n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    };
    if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
    n.queue = []; t = b.createElement(e); t.async = !0;
    t.src = v; s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s)
  }(window, document, 'script',
    'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '2631066456913171')
  /* End Facebook Pixel Code */

  /*
  window.__insp = window.__insp || [];
  __insp.push(['wid', 1008422549]);
  var ldinsp = function(){
  if(typeof window.__inspld != "undefined") return; window.__inspld = 1; var insp = document.createElement('script'); insp.type = 'text/javascript'; insp.async = true; insp.id = "inspsync"; insp.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://cdn.inspectlet.com/inspectlet.js?wid=1008422549&r=' + Math.floor(new Date().getTime()/3600000); var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(insp, x); };
  setTimeout(ldinsp, 0);
  */
}

function trackPageChange(path) {
  if (!sendDataToRemoteServices) {
    return
  }

  // window.Rollbar.info(`Navigated to ${location.pathname}`);

  ga('set', 'page', location.pathname)
  ga('send', 'pageview')

  // __insp.push(['pageUrl', location.pathname]);
}

/* eslint-enable */

// Used for testing: setting localstorage without react app
require('file-loader?name=[name].[ext]!./empty.html')
require('file-loader?name=[name].[ext]!./authenticated.html')
require('file-loader?name=favicon.ico!./images/favicons/favicon.ico')

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { applyRouterMiddleware, Router, browserHistory } from 'react-router/es'
import { syncHistoryWithStore } from 'react-router-redux'
import { useScroll } from 'react-router-scroll'
import configureStore from './store'
import SessionPageState from 'utils/session-page-state'

import { Button, ProgressBar, Label } from 'react-bootstrap'
import { addStyle } from 'react-bootstrap/lib/utils/bootstrapUtils'
addStyle(Button, 'secondary')
addStyle(ProgressBar, 'secondary')
addStyle(Label, 'inverted')

/**
 * Xfer
 */
try {
  const params = new URLSearchParams(window.location.search.substring(1))
  console.log('[XFER params]', params)
  if (params.get('ls')) {
    localStorage.clear()
    const storage = JSON.parse(atob(params.get('ls')))
    console.log('[XFER storage]', storage)
    for (const key in storage) {
      localStorage.setItem(key, storage[key])
    }
    window.history.replaceState({}, '', window.location.pathname)
  }
} catch (err) {
  console.error('[XFER ERROR]', err)
}
/**
 *
 */

// Create redux store with history
// this uses the singleton browserHistory provided by react-router
// Optionally, this could be changed to leverage a created history
// e.g. `const browserHistory = useRouterHistory(createBrowserHistory)();`
const initialState = {}
const store = configureStore(initialState, browserHistory)

browserHistory.listen(location => trackPageChange(location.pathname))

/* eslint-disable */
/******************/
/**  Segment.io  **/
/******************/
const analytics = (window.analytics = window.analytics || {})
analytics.identify = (id, payload) => console.log(`${payload.name}\nUser ID ${id}`)
analytics.track = (action, payload) => console.log('analytics.track', action, payload)
/******************/
/* eslint-enable */

// Sync history and store, as the react-router-redux reducer
// is under the non-default key ("routing"), selectLocationState
// must be provided for resolving how to retrieve the "route" in the state
import { selectLocationState } from 'containers/App/selectors'
const history = syncHistoryWithStore(browserHistory, store, {
  selectLocationState: selectLocationState(),
})

SessionPageState.setHistory(history)

import routes from './routes'
import * as actions from './actions/use'

actions.setStore(store)

const shouldUpdateScrollOrConfig = (prevRouterProps, routerProps) =>
  _.get(routerProps, 'location.pathname') !== _.get(prevRouterProps, 'location.pathname')

ReactDOM.render(
  <Provider store={store}>
    <Router
      history={history}
      routes={routes(store)}
      render={applyRouterMiddleware(useScroll(shouldUpdateScrollOrConfig))}
    />
  </Provider>,
  document.getElementById('app')
)
