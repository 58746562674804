import queryString from 'query-string';
import { apiRequest } from 'actions/use';
import apiWrapper, { generatePayload } from './helpers';

export default class Federation extends apiWrapper('/federations') {

  static lookup({ data, onSuccess, onFailure }) {
    apiRequest({
      method: 'GET',
      uri: '/api/federations/lookup',
      data,
      onSuccess,
      onFailure,
    });
  }

  static lookupList({ data, onSuccess, onFailure }) {
    apiRequest({
      method: 'GET',
      uri: '/api/federations/lookup_list',
      data,
      onSuccess,
      onFailure,
    });
  }

  static allDonationsList({ data, onSuccess, onFailure }) {
    apiRequest({
      method: 'GET',
      uri: '/api/federations/all_donations_list',
      data,
      onSuccess,
      onFailure,
    });
  }

  static transactions({ id, data, onSuccess }) {
    const query = queryString.stringify(data, { arrayFormat: 'bracket' });

    apiRequest({
      method: 'GET',
      uri: `/api/organizations/${id}/transactions?${query}`,
      onSuccess,
    });
  }

  static logo({ id, data, query, onSuccess }) {
    apiRequest({
      method: 'PUT',
      uri: `/api/federations/${id}/logo`,
      data: generatePayload({ data, query }),
      onSuccess,
    });
  }

  static billPayBalances({ data, onSuccess, onFailure }) {
    apiRequest({
      method: 'GET',
      uri: '/api/federations/bill_pay_balances',
      data,
      onSuccess,
      onFailure,
    });
  }

}
