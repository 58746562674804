import cx from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'
import { connect, orgHelpers } from 'helpers'

import VotesAside from './votes-aside'
import HourTrackerTooltip from './alerts/hour-tracker-pending-tooltip'
import BillPayTooltip from './alerts/bill-pay-tooltip'
import Contacts from './contacts'
import Divider from './divider'
import Header from './header'
import Item from './item'
import MemberAccount from './member-account'
import National from './national'
import PaymentPlansTooltip from './alerts/payment-plans-awaiting-tooltip'
import PrimaryAdmin from './primary-admin'
import ReimbursementsTooltip from './alerts/reimbursements-tooltip'
import OrganizationPaymentPlanTooltip from './alerts/organization-payment-plan-tooltip'
import { FaIcon } from 'components/utilities'

const { object, shape, number } = PropTypes

class AdminMenu extends React.Component {
  static contextTypes = {
    organization: shape({
      id: number.isRequired,
      federation: shape({
        id: number.isRequired,
      }),
    }),
  }

  static propTypes = {
    requiredActions: object.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      reportingVisible: sessionStorage.getItem('reportingVisible') === 'true',
    }
  }

  toggleReporting = () => {
    const reportingVisible = !this.state.reportingVisible
    sessionStorage.setItem('reportingVisible', reportingVisible)
    this.setState({ reportingVisible })
  }

  renderAdmin() {
    const { organization } = this.context
    const {
      admin_hour_tracker: hourTracker,
      billpay,
      payment_plans: paymentPlans,
      reimbursements,
      votes,
      organization_payment_plans: organizationPaymentPlans,
    } = this.props.requiredActions
    const { reportingVisible } = this.state

    const nationalOrg = orgHelpers.isNationalOrganization(organization)

    return (
      <ul className="sidebar-menu">
        <Header>Chapter finances</Header>
        <Item url="/admin/members" icon="users">
          Roster
        </Item>
        <Item url="/admin/bill-pay" id="BillPay" icon="usd" backgroundIcon="file">
          gCapital
          <BillPayTooltip billpay={billpay} />
        </Item>
        <Item url="/admin/payment-plans" id="payment-plans#admin" icon="calculator">
          Payment plans
          <PaymentPlansTooltip paymentPlans={paymentPlans} />
        </Item>
        <Item url="/admin/reimbursements" id="reimbursements#admin" icon="exchange">
          Reimbursements
          <ReimbursementsTooltip reimbursements={reimbursements} />
        </Item>
        <Item url="/admin/budgets" icon="book">
          Budgets
        </Item>
        {nationalOrg && (
          <Item id="finances" url="/admin/national-overview" icon="bar-chart">
            {organization.federation.name} Finances
            {_.get(organizationPaymentPlans, 'count', 0) > 0 && (
              <OrganizationPaymentPlanTooltip
                count={organizationPaymentPlans.count}
                className="absolute"
              />
            )}
          </Item>
        )}
        <Item url="/admin/groups" icon="users">
          Groups
        </Item>

        <Divider />
        <Header>Chapter operations</Header>
        <Item url="/admin/attendance" icon="clock-o">
          Attendance
        </Item>
        <Item url="/calendar" icon="calendar">
          Calendar
        </Item>
        <Item url="/admin/document-folders" icon="folder">
          Documents
        </Item>
        <Item url="/admin/hour-tracker" icon="clock-o" id="hour-tracker#admin">
          Hour tracker
          <HourTrackerTooltip hourTracker={hourTracker} />
        </Item>
        <Item url="/message-board" icon="comment">
          Message Board
        </Item>
        <Item url="/admin/messaging" icon="bullhorn">
          Messaging
        </Item>
        <Item url="/admin/events" icon="calendar">
          Events
        </Item>
        <Item url="/admin/executive-board" icon="address-book">
          Executive Board
        </Item>
        <Item url="/admin/committee-positions" icon="users">
          Chairs/Committees
        </Item>
        <Item url="/admin/rush" icon="users">
          Recruitment
        </Item>
        <Item
          url="/admin/voting"
          icon="gavel"
          aside={<VotesAside votes={votes} />}
          asideType="primary"
        >
          Voting
        </Item>
        <Item url="/admin/required-documents" icon="file-text">
          Required documents
        </Item>
        <Item url="/admin/settings" id="settings#admin" icon="cog">
          Settings
        </Item>
        {/* <Item url="/games" icon="gamepad">Games</Item> */}

        <Divider />
        <Header key="sidebar-header">
          <div className="c-pointer" onClick={this.toggleReporting}>
            <FaIcon chevron-right className={cx({ expanded: reportingVisible })} />
            Reporting
          </div>
        </Header>

        {reportingVisible && [
          <Item key={0} url="/admin/reporting" icon="dashboard">
            Overview
          </Item>,
          <Item key={1} url="/admin/transfers" icon="exchange">
            Transfers
          </Item>,
          <Item key={2} url="/admin/transactions" icon="money">
            Transactions
          </Item>,
          <Item key={3} url="/admin/donations" icon="money">
            Donations
          </Item>,
          <Item key={4} url="/admin/reporting/transaction-summaries" icon="table">
            Transaction summaries
          </Item>,
          <Item key={5} url="/admin/reporting/member-balances" icon="table">
            Member balances
          </Item>,
          <Item key={6} url="/admin/reporting/aging-detail" icon="table">
            Aging detail
          </Item>,
          <Item key={7} url="/admin/collections" icon="gavel">
            Collections
          </Item>,
          <Item key={8} url="/admin/taxes" icon="university">
            Taxes
          </Item>,
        ]}
      </ul>
    )
  }

  render = () => (
    <div>
      <MemberAccount />
      {this.renderAdmin()}
      <National />
      <PrimaryAdmin organization={this.context.organization} />
      <Contacts />
    </div>
  )
}

const mapStateToProps = state => ({
  requiredActions: state.get('requiredActions'),
})

export default connect(AdminMenu, mapStateToProps)
