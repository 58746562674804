import React from 'react';
import PropTypes from 'prop-types';
import { modal } from 'helpers';
import moment from 'moment';

import { Modal, Button } from 'react-bootstrap';
import Calendar from 'components/date-range/Calendar';

const { func, object, string, bool, oneOfType } = PropTypes;

class DatePickerModal extends React.Component {

  static propTypes = {
    date: oneOfType([object, func, string]),
    minDate: oneOfType([object, func, string]),
    maxDate: oneOfType([object, func, string]),
    hideClearDateButton: bool,
    onDateSelected: func.isRequired,
    title: string,
  }

  constructor(props) {
    super(props);

    this.state = {
      date: props.date,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if (!this.props.show && nextProps.show) {
      this.setState({ date: nextProps.date });
    }
  }

  onChange = date => this.setState({ date })

  doSetDate = () => {
    const { onDateSelected, actions } = this.props;
    onDateSelected(this.state.date);
    actions.closeModal();
  }

  doClearDate = () => {
    this.setState({
      date: null,
    }, this.doSetDate);
  }

  render() {
    const { closeModal } = this.props.actions;
    const { title, minDate, maxDate, hideClearDateButton } = this.props;
    const { date } = this.state;

    return (
      <Modal show onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{title || 'Select date'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <center>
            <Calendar
              date={date || moment().utcOffset(-4).add(1, 'day')}
              minDate={minDate}
              maxDate={maxDate}
              onChange={this.onChange}
            />
          </center>

        </Modal.Body>
        <Modal.Footer>
          {hideClearDateButton ? null : (
            <Button className="pull-left" onClick={this.doClearDate}>Clear date</Button>
          )}
          <Button bsStyle="primary" onClick={this.doSetDate}>Set date</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}

export default modal('DatePicker', DatePickerModal);
