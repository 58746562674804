import T from '../actions/types';

export default (state = null, action) => {
  switch (action.type) {

    case T.SET_CALENDAR_EVENT:
      return action.event;

    case T.CLEAR_CALENDAR_EVENT:
      return null;

    default:
      return state;
  }
};
