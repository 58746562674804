/* eslint-disable new-cap */
import React from 'react'
import { Route, IndexRoute, IndexRedirect } from 'react-router'

import {
  HOUR_TRACKER_FEATURE,
  ATTENDANCE_FEATURE,
  RUSH_FEATURE,
  MESSAGING_FEATURE,
  DOCUMENTS_FEATURE,
  EVENTS_FEATURE,
  VOTING_FEATURE,
  WEBSITE_FEATURE,
} from 'resources/feature'

import { ensureRole, ensureFeature } from '../helpers.js'
import AdminMemberRoutes from './member.js'

export default function adminRoutes(store) {
  const Admin = path => (_, cb) =>
    require.ensure([], require => cb(null, require('../../chunks/admin')[path]))

  return (
    <Route path="admin">
      <IndexRedirect to="members" />

      <Route onEnter={ensureRole(store, 'admin')}>
        <Route path="bill-pay" getComponent={Admin('BillPay')} />
        <Route path="bill-pay/reporting" getComponent={Admin('BillPay/reporting')} />
        <Route path="bill-pay/:id" getComponent={Admin('BillPay/show')} />
        <Route path="bills" getComponent={Admin('Bills')} />
        <Route path="bills/:page" getComponent={Admin('Bills')} />
        <Route path="budgets" getComponent={Admin('Budgets')} />
        <Route path="budgets/:id" getComponent={Admin('Budgets/show')} />
        <Route path="cards/:id" getComponent={Admin('Cards/show')} />
        <Route path="committee-positions" getComponent={Admin('CommitteePositions')} />
        <Route path="donations" getComponent={Admin('Donations')} />
        <Route path="donations/:page" getComponent={Admin('Donations')} />
        <Route path="executive-board" getComponent={Admin('ExecutiveBoard')} />
        <Route path="federation-transactions" getComponent={Admin('FederationTransactions')} />
        <Route path="g-money" getComponent={Admin('G-Money')} />
        <Route path="g-money/setup" getComponent={Admin('G-Money/Setup')} />
        <Route path="groups" getComponent={Admin('Groups')} />
        <Route path="members" getComponent={Admin('Members')} />
        <Route path="national-overview" getComponent={Admin('NationalOverview')} />
        <Route
          path="organization-payment-plans/new"
          getComponent={Admin('OrganizationPaymentPlans/new')}
        />
        <Route
          path="organization-payment-plans/:id"
          getComponent={Admin('OrganizationPaymentPlans/show')}
        />
        <Route path="payment-plans" getComponent={Admin('PaymentPlans')} />
        <Route path="payment-plan-schedules" getComponent={Admin('PaymentPlanSchedules')} />
        <Route
          path="payment-plan-schedules/create"
          getComponent={Admin('PaymentPlanSchedules/create')}
        />
        <Route path="reimbursements" getComponent={Admin('Reimbursements')} />
        <Route path="reimbursements/:page" getComponent={Admin('Reimbursements')} />
        <Route path="reports" getComponent={Admin('Reports')} />
        <Route path="reports/:id" getComponent={Admin('Reports/show')} />
        <Route path="reports/:id/:date" getComponent={Admin('Reports/show')} />
        <Route path="report-submissions" getComponent={Admin('ReportSubmissions')} />
        <Route path="report-submissions/:id" getComponent={Admin('ReportSubmissions/show')} />
        <Route path="required-documents" getComponent={Admin('RequiredDocuments')} />
        <Route
          path="required-documents/reporting"
          getComponent={Admin('RequiredDocuments/reporting')}
        />
        <Route
          path="required-documents/archived"
          getComponent={Admin('RequiredDocuments/archived')}
        />
        <Route path="required-documents/:id" getComponent={Admin('RequiredDocuments/show')} />
        <Route path="transactions" getComponent={Admin('Transactions')} />
        <Route path="transfers" getComponent={Admin('Transfers')} />
        <Route path="transfers/:page" getComponent={Admin('Transfers')} />
        <Route path="reporting" getComponent={Admin('Reporting/Dashboard')} />
        <Route
          path="reporting/transaction-summaries"
          getComponent={Admin('Reporting/TransactionSummaries')}
        />

        <Route path="reporting/member-balances">
          <IndexRedirect to="balances" />
          <Route path=":type" getComponent={Admin('Reporting/MemberBalances')} />
        </Route>

        <Route path="reporting/aging-detail">
          <IndexRedirect to="members" />
          <Route path=":tab" getComponent={Admin('Reporting/AgingDetail')} />
        </Route>

        <Route path="collections" getComponent={Admin('Collections')} />
        <Route path="taxes" getComponent={Admin('Taxes')} />

        {AdminMemberRoutes()}
      </Route>

      <Route path="settings" getComponent={Admin('Settings')}>
        <IndexRoute
          onEnter={(nextState, replace) => {
            const user = store.getState().get('auth').user
            replace(
              user.role === 'admin' ? '/admin/settings/member-info' : '/admin/settings/edit-site'
            )
          }}
        />
        <Route onEnter={ensureRole(store, 'admin')}>
          <Route path="permissions" getComponent={Admin('Settings/Permissions')} />
          <Route path="member-info" getComponent={Admin('Settings/MemberInfo')} />
          <Route path="pledge-info" getComponent={Admin('Settings/PledgeInfo')} />
          <Route path="member-roster" getComponent={Admin('Settings/MemberRoster')} />
          <Route path="admin-roster" getComponent={Admin('Settings/AdminRoster')} />
          <Route path="pledge-roster" getComponent={Admin('Settings/PledgeRoster')} />
          <Route path="addresses" getComponent={Admin('Settings/Addresses')} />
          <Route path="members-permissions" getComponent={Admin('Settings/MembersPermissions')} />
          <Route path="groups-permissions" getComponent={Admin('Settings/GroupsPermissions')} />
          <Route path="bank-account" getComponent={Admin('Settings/BankAccount')} />
        </Route>
        <Route
          onEnter={ensureFeature(store, WEBSITE_FEATURE)}
          path="edit-site"
          getComponent={Admin('Settings/EditSite')}
        />
      </Route>

      <Route onEnter={ensureFeature(store, DOCUMENTS_FEATURE)}>
        <Route path="document-folders" getComponent={Admin('DocumentFolders')} />
        <Route path="document-folders/:id" getComponent={Admin('DocumentFolders/show')} />
      </Route>

      <Route onEnter={ensureFeature(store, HOUR_TRACKER_FEATURE)}>
        <Route path="hour-tracker" getComponent={Admin('HourTracker')} />
        <Route path="hour-tracker/archived" getComponent={Admin('HourTracker/archived')} />
        <Route path="hour-tracker/:id" getComponent={Admin('HourTracker/show')} />
      </Route>

      <Route onEnter={ensureFeature(store, ATTENDANCE_FEATURE)}>
        <Route path="attendance" getComponent={Admin('Attendance')} />
        <Route path="attendance/:id" getComponent={Admin('Attendance/show')} />
      </Route>

      <Route onEnter={ensureFeature(store, MESSAGING_FEATURE)}>
        <Route path="messaging" getComponent={Admin('Messaging')} />
      </Route>

      <Route onEnter={ensureFeature(store, EVENTS_FEATURE)}>
        <Route path="events" getComponent={Admin('Events')} />
        <Route path="events/new" getComponent={Admin('Events/new')} />
        <Route path="events/new/:step" getComponent={Admin('Events/new')} />
        <Route path="events/:id/edit" getComponent={Admin('Events/edit')} />
        <Route path="events/:id/edit/:step" getComponent={Admin('Events/edit')} />
        <Route path="events/:id" getComponent={Admin('Events/show')} />
        <Route path="event-registrations/:id" getComponent={Admin('EventRegistrations/show')} />
      </Route>
      <Route path="events/:id" getComponent={Admin('Events/show')} />

      <Route path="voting" onEnter={ensureFeature(store, VOTING_FEATURE)}>
        <IndexRoute getComponent={Admin('Voting')} />
        <Route path="new" getComponent={Admin('Voting/new')} />
        <Route path=":id" getComponent={Admin('Voting/show')} />
      </Route>

      <Route path="kappa-sigma">
        <IndexRoute getComponent={Admin('KappaSigma')} />
        <Route path="add-pledges" getComponent={Admin('KappaSigma/add-pledges')} />
        <Route path="initiate" getComponent={Admin('KappaSigma/initiate')} />
        <Route path="alumni" getComponent={Admin('KappaSigma/alumni')} />
      </Route>

      <Route
        path="rush"
        getComponent={Admin('Pledges')}
        onEnter={ensureFeature(store, RUSH_FEATURE)}
      />
    </Route>
  )
}
