import React from 'react';
import { iconForMimeType } from 'helpers';

export default function ({ file }) {
  const { preview, type } = file;

  if (type.match(/^image\//)) {
    return <img src={preview} role="presentation" className="upload-preview" />;
  }

  const icon = iconForMimeType(type, file.name);

  return <img src={icon} role="presentation" className="upload-preview-icon m-b-1" />;
}
