import { apiRequest } from 'actions/use';
import apiWrapper, { generatePayload } from './helpers';

export default class Payment extends apiWrapper('/payments') {

  static check({ data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'POST',
      uri: '/api/payments/check',
      data: generatePayload({ data, query }),
      on: {
        201: onSuccess,
      },
      onFailure,
    });
  }

  static creditCard({ data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'POST',
      uri: '/api/payments/credit_card',
      data: generatePayload({ data, query }),
      on: {
        201: onSuccess,
      },
      onFailure,
    });
  }

  static paymentMethod({ data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'POST',
      uri: '/api/payments/payment_method',
      data: generatePayload({ data, query }),
      on: {
        201: onSuccess,
      },
      onFailure,
    });
  }

  static refund({ id, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'PUT',
      uri: `/api/payments/${id}/refund`,
      data: generatePayload({ query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    });
  }

}
