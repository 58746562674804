import React from 'react';
import moment from 'moment';

export default function ({ id, name, month, onChange }) {
  const date = month ? moment(month, 'MM') : moment();
  const daysCount = date.daysInMonth();
  const daysList = [...Array(daysCount).keys()];

  return (
    <select id={id} name={name} className="form-control" onChange={e => onChange(e.target.value)}>
      {daysList.map(d => <option key={d} value={d + 1}>{date.date(d + 1).format('Do')}</option>)}
    </select>
  );
}
