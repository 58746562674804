import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';

import actionProps from 'actions';

class TutorialModal extends React.Component {

  render() {
    if (!this.props.tutorial) { return null; }

    const { closeModal } = this.props.actions;
    const { title, video, action } = this.props.tutorial;

    return (
      <Modal show onHide={closeModal} bsClass="lights-out modal">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <hr className="spacer-xs" />

          <center>
            <iframe src={video} className="vzaar-video-player" type="text/html" width="448" height="300" frameBorder="0" allowFullScreen></iframe>
          </center>

        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={this.close}>{action}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}

const mapStateToProps = (state) => ({
  show: state.get('modal') === 'Tutorial',
  tutorial: state.get('tutorial'),
});

export default connect(mapStateToProps, actionProps)(TutorialModal);
