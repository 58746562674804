/* eslint-disable new-cap */
import React from 'react'
import { Route, IndexRedirect, IndexRoute } from 'react-router'

export default function superMemberRoutes() {
  const Super = path => (_, cb) =>
    require.ensure([], require => cb(null, require('chunks/super')[path]))

  return (
    <Route path="members/:id" getComponent={Super('Members/Show')}>
      <IndexRedirect to="transactions" />
      <Route path="transactions" getComponent={Super('Members/Show/Transactions')}>
        <Route path=":account_id" getComponent={Super('Members/Show/Transactions')} />
      </Route>
      <Route path="profile" getComponent={Super('Members/Show/Profile')} />
      <Route path="history" getComponent={Super('Members/Show/History')} />
      <Route path="access" getComponent={Super('Members/Show/Access')} />
      <Route path="notes" getComponent={Super('Members/Show/Notes')} />
      <Route path="password" getComponent={Super('Members/Show/Password')} />
      <Route path="reset-password" getComponent={Super('Members/Show/ResetPassword')} />
      <Route path="archive" getComponent={Super('Members/Show/Archive')} />
      <Route path="collections" getComponent={Super('Members/Show/Collections')} />
      <Route path="subscription" getComponent={Super('Members/Show/Subscription')} />
      <Route path="payment-plan" getComponent={Super('Members/Show/PaymentPlan')} />
      <Route path="contact-events">
        <IndexRoute getComponent={Super('Members/Show/ContactEvents')} />
        <Route path="page/:page" getComponent={Super('Members/Show/ContactEvents')} />
        <Route path="v2" getComponent={Super('Members/Show/ContactEvents/v2')} />
      </Route>
    </Route>
  )
}
