import { apiRequest } from 'actions/use'

export const generatePayload = ({ data, query }) => {
  const payload = _.clone(data) || {}
  if (query) {
    payload.query = query.replace(/\s+/g, ' ')
  }
  return payload
}

export const list = (uri, { data, query, onSuccess, onFailure }) => {
  apiRequest({
    method: 'GET',
    uri: `/api${uri}`,
    data: generatePayload({ data, query }),
    on: {
      200: onSuccess,
    },
    onFailure,
  })
}

export const create = (uri, { data, query, onSuccess, onFailure }) => {
  apiRequest({
    method: 'POST',
    uri: `/api${uri}`,
    data: generatePayload({ data, query }),
    on: {
      201: onSuccess,
      204: onSuccess,
    },
    onFailure,
  })
}

export const retrieve = (uri, { id, data, query, onSuccess, onFailure }) => {
  apiRequest({
    method: 'GET',
    uri: `/api${uri}/${id}`,
    data: generatePayload({ data, query }),
    on: {
      200: onSuccess,
    },
    onFailure,
  })
}

export const update = (uri, { id, data, query, onSuccess, onFailure }) => {
  apiRequest({
    method: 'PUT',
    uri: `/api${uri}/${id}`,
    data: generatePayload({ data, query }),
    on: {
      200: onSuccess,
    },
    onFailure,
  })
}

export const archive = (uri, { id, data, query, onSuccess, onFailure }) => {
  apiRequest({
    method: 'DELETE',
    uri: `/api${uri}/${id}`,
    data: generatePayload({ data, query }),
    on: {
      204: onSuccess,
    },
    onFailure,
  })
}

export const unarchive = (uri, { id, query, onSuccess, onFailure }) => {
  apiRequest({
    method: 'PUT',
    uri: `/api${uri}/${id}/unarchive`,
    data: generatePayload({ query }),
    on: {
      200: onSuccess,
    },
    onFailure,
  })
}

export default resource =>
  class ApiWrapper {
    static list = opts => list(resource, opts)
    static create = opts => create(resource, opts)
    static retrieve = opts => retrieve(resource, opts)
    static update = opts => update(resource, opts)
    static archive = opts => archive(resource, opts)
    static unarchive = opts => unarchive(resource, opts)
  }

export function updateMethod(uri, method) {
  return ({ id, data, query, onSuccess, onFailure }) =>
    apiRequest({
      method: 'PUT',
      uri: `/api${uri}/${id}/${method}`,
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    })
}

export function getMethod(uri) {
  return ({ data, query, onSuccess, onFailure }) =>
    apiRequest({
      method: 'GET',
      uri: `/api${uri}`,
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    })
}
