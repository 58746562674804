import T from './types';

export function fetchRequiredActions() {
  return {
    type: T.FETCH_REQUIRED_ACTIONS,
  };
}

export function setRequiredActions(requiredActions) {
  return {
    type: T.SET_REQUIRED_ACTIONS,
    requiredActions,
  };
}

export function clearRequiredActions() {
  return {
    type: T.CLEAR_REQUIRED_ACTIONS,
  };
}
