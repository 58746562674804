import React, { Fragment } from 'react'
import { number, shape } from 'prop-types'
import { isGranted, connect } from 'helpers'
import Item from './item'

import Header from './header'
import Divider from './divider'
import ReimbursementsTooltip from './alerts/reimbursements-tooltip'
import OrganizationPaymentPlanTooltip from './alerts/organization-payment-plan-tooltip'
import ContributionTooltip from './alerts/contribution-tooltip'

import {
  FINANCES_FEATURE,
  BUDGET_APPROVAL,
  FEDERATION_BILLPAY,
  CALENDAR_FEATURE,
  DOCUMENTS_FEATURE,
  MESSAGE_BOARD_FEATURE,
  MESSAGING_FEATURE,
  ALUMNI_GROUPS_FEATURE,
  VOTING_FEATURE,
  EVENTS_FEATURE,
} from 'resources/feature'

class FederationMenu extends React.Component {
  static contextTypes = {
    user: shape({
      federation: shape({
        id: number.isRequired,
      }),
    }),
  }

  static propTypes = {
    requiredActions: shape({
      reimbursement_count: number,
      payment_plan_count: number,
    }),
  }

  isGranted(feature) {
    const { user } = this.context

    return isGranted(user, feature)
  }

  isDemo() {
    const { user } = this.context

    return user.federation.id === 1
  }

  render = () => {
    const { requiredActions } = this.props

    return (
      <ul className="sidebar-menu">
        {this.isGranted(FINANCES_FEATURE) && (
          <Fragment>
            <Header>Finances</Header>
            <Item id="Billing categories" url="/federation/billing-categories/overview" icon="book">
              Billing categories
              <OrganizationPaymentPlanTooltip count={requiredActions.payment_plan_count} />
            </Item>
            <Item url="/federation/billing-categories" icon="usd">
              Financial summary
            </Item>
            <Item url="/federation/budgets" icon="book">
              Budgets
              {this.isGranted(BUDGET_APPROVAL) && (
                <ReimbursementsTooltip
                  reimbursements={{
                    pending: requiredActions.reimbursement_count,
                  }}
                />
              )}
            </Item>
            {this.isDemo() && (
              <Item url="/federation/grants" icon="money">
                Grants
              </Item>
            )}
            {this.isGranted(FEDERATION_BILLPAY) && (
              <Item url="/federation/bill-pay" icon="usd">
                BillPay
              </Item>
            )}
            <Item url="/federation/donations" icon="money">
              Donations
            </Item>
            <Item url="/federation/taxes" icon="institution">
              Tax information
            </Item>
            <Item url="/federation/transfers" icon="book">
              Transfers
            </Item>
            <Divider />
          </Fragment>
        )}

        <Header>Membership</Header>
        <Item url="/federation/organizations" icon="list">
          Chapters
        </Item>
        <Item url="/federation/executive-boards" icon="address-book">
          Executive Boards
        </Item>
        <Item url="/federation/committees" icon="address-book">
          Chairs/Committees
        </Item>
        {this.isGranted(EVENTS_FEATURE) && (
          <Item url="/federation/events" icon="calendar">
            Events
          </Item>
        )}
        <Item url="/federation/forms" icon="file-text-o">
          Forms
        </Item>
        <Item url="/federation/members" icon="users">
          Members
        </Item>
        {this.isDemo() && (
          <Item url="/federation/entities" icon="address-card-o">
            Entities
          </Item>
        )}
        <Item
          url="/federation/contributions"
          icon="thermometer-three-quarters"
          id="contribution-tracker"
        >
          Contribution Tracker
          <ContributionTooltip count={requiredActions.contribution_count} />
        </Item>
        <Item url="/federation/attendance" icon="clock-o">
          Attendance
        </Item>
        <Item url="/federation/volunteers-and-staff" icon="users">
          Volunteers &amp; staff
        </Item>
        <Item url="/federation/statistics" icon="bar-chart">
          Statistics
        </Item>
        <Item url="/federation/required-documents" icon="file-text">
          Required documents
        </Item>
        {this.isDemo() && (
          <Item url="/federation/incident-reports" icon="exclamation-triangle">
            Incident reports
          </Item>
        )}

        {(this.isGranted(CALENDAR_FEATURE) ||
          this.isGranted(DOCUMENTS_FEATURE) ||
          this.isGranted(MESSAGE_BOARD_FEATURE) ||
          this.isGranted(MESSAGING_FEATURE) ||
          this.isGranted(ALUMNI_GROUPS_FEATURE) ||
          this.isGranted(VOTING_FEATURE)) && (
          <Fragment>
            <Divider />
            <Header>Communication</Header>
            {this.isGranted(CALENDAR_FEATURE) && (
              <Item url="/federation/calendar" icon="calendar">
                Calendar
              </Item>
            )}
            {this.isGranted(DOCUMENTS_FEATURE) && (
              <Item url="/federation/document-folders" icon="folder">
                Documents
              </Item>
            )}
            {this.isGranted(MESSAGE_BOARD_FEATURE) && (
              <Item url="/federation/message-board" icon="comment">
                Message Board
              </Item>
            )}
            {this.isGranted(MESSAGING_FEATURE) && (
              <Item url="/federation/messaging" icon="envelope-o">
                Messaging
              </Item>
            )}
            {this.isGranted(ALUMNI_GROUPS_FEATURE) && (
              <Item url="/federation/groups" icon="users">
                Alumni groups
              </Item>
            )}
            {this.isGranted(VOTING_FEATURE) && (
              <Item url="/federation/voting" icon="gavel">
                Voting
              </Item>
            )}
          </Fragment>
        )}

        <Divider />
        <Item url="/federation/settings" icon="cog">
          Settings
        </Item>
      </ul>
    )
  }
}

const mapStateToProps = state => ({
  requiredActions: state.get('requiredActions'),
})

export default connect(FederationMenu, mapStateToProps)
