import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FaIcon, Units } from 'components/utilities';

export default function ({ hourTracker }) {
  return (
    hourTracker && (hourTracker.required > 0) ? (
      <OverlayTrigger placement="right" overlay={(
        <Tooltip id="hour-tracker-required-tooltip">
          <Units count={hourTracker.required} noun="hour" /> required
        </Tooltip>
      )}>
        <FaIcon exclamation-circle warning className="pull-right" />
      </OverlayTrigger>
    ) : null
  );
}
