import T from './types';

import { storeDateRange } from 'helpers/date-range-persistance';

export function setHalfYearDateRange(dateRange) {

  storeDateRange(dateRange);

  return {
    type: T.SET_HALF_YEAR_DATE_RANGE,
    dateRange,
  };
}
