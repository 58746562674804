import T from '../actions/types';

export default (state = {}, action) => {
  switch (action.type) {

    case T.FETCH_REQUIRED_ACTIONS:
      return {};

    case T.SET_REQUIRED_ACTIONS:
      return action.requiredActions;

    case T.CLEAR_ORGANIZATION:
      return {};

    case T.NOTIFICATION:
      if (action.payload.type === 'PollCreate') {
        return { votes: { needed: _.get(state, 'votes.needed') + 1 } };
      }
      return state;

    default:
      return state;
  }
};
