import apiWrapper, { updateMethod, list } from './helpers'

export default class Contribution extends apiWrapper('/contributions') {
  static approve = updateMethod('/contributions', 'approve')
  static deny = updateMethod('/contributions', 'deny')

  static transfer(opts) {
    list('/contributions/transfer', opts)
  }
}
