import React, { Fragment, useEffect, useState } from 'react';
import { bool, func, number, shape } from 'prop-types';
import cx from 'classnames';
import { connect, payments } from 'helpers';
import { PaymentMethod } from 'resources';

import { Button, ButtonToolbar } from 'react-bootstrap';
import { AddBankAccountModal, AddCreditCardModal } from 'components/modals';
import { Money, Portlet, Body, PaymentMethod as PaymentMethodComponent, Percentage } from 'components/utilities';

const fetchPaymentMethods = (userId) => new Promise((resolve, reject) => {
  PaymentMethod.list({
    data: {
      user_id: userId,
    },
    query: `
      payment_method {
        method
        company
        last_four
      }
    `,
    onSuccess: resolve,
    onFailure: reject,
  });
});

const PaymentMethodSelector = ({ acceptance, hasUpcharge, userId, onUpdateAcceptance, actions, errors }) => {
  const [paymentMethods, setPaymentMethods] = useState([]);

  useEffect(() => {
    fetchPaymentMethods(userId)
      .then(({ data }) => {
        setPaymentMethods(data);

        const paymentMethodId = _.get(data, '0.id');
        onUpdateAcceptance('payment_method_id', paymentMethodId);
      });
  }, [userId]);

  const onPaymentMethodCreate = paymentMethod => {
    const newPaymentMethods = _.concat(paymentMethods, paymentMethod);
    setPaymentMethods(newPaymentMethods);

    onUpdateAcceptance('payment_method_id', paymentMethod.id);
  };

  return (
    <Fragment>
      <Portlet boxed>
        <Body>

          <h5>Please select a payment method:</h5>

          {_.map(paymentMethods, (paymentMethod) => (
            <h6
              key={paymentMethod.id}
              className="c-pointer payment-method"
              onClick={() => onUpdateAcceptance('payment_method_id', paymentMethod.id)}
                >
              <input
                type="radio"
                name="payment_plan.payment_method_id"
                className="m-r-1"
                value={paymentMethod.id}
                checked={paymentMethod.id === acceptance.payment_method_id}
                onChange={() => {}}
              />
              <PaymentMethodComponent paymentMethod={paymentMethod} />
            </h6>
          ))}

          <ButtonToolbar className={cx('m-b-05', { 'm-t-2': !_.isEmpty(paymentMethods) })}>
            <Button onClick={actions.openModal('AddCreditCard')} bsSize="xs">Add credit card</Button>
            <Button onClick={actions.openModal('AddBankAccount')} bsSize="xs">Add bank account</Button>
          </ButtonToolbar>

          {hasUpcharge ? (
            <div className="p-t-05 lh-18">
              There is a <Percentage value={payments.CC_UPCHARGE / 100.0} decimalPlaces={0} /> charge for credit cards and a <Money amount={payments.ACH_UPCHARGE} /> charge for e-checks (per installment).
              <br />
              Paying by cash or check will settle your balance without additional cost.
            </div>
          ) : (
            <div className="p-t-05 lh-18">
              There is a <Money amount={payments.ACH_UPCHARGE} /> charge for e-checks (per installment).
              <br />
              Paying by credit card, cash, or check will settle your balance without additional cost.
            </div>
          )}

          {_.get(errors, 'payment_method_id') && (
            <p>
              <strong className="text-danger">Please select a payment method</strong>
            </p>
          )}

        </Body>
      </Portlet>

      <AddCreditCardModal onCreditCardCreate={onPaymentMethodCreate} />
      <AddBankAccountModal onBankAccountCreate={onPaymentMethodCreate} />

    </Fragment>
  );
};

PaymentMethodSelector.propTypes = {
  acceptance: shape({
    payment_plan_option: number,
  }).isRequired,
  hasUpcharge: bool.isRequired,
  userId: number.isRequired,
  onUpdateAcceptance: func.isRequired,
};

export default connect(PaymentMethodSelector);
