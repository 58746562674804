export const formats = {
  short: ({ first_name: first, last_name: last }) => `${last}, ${first[0]}`,
  full: ({ first_name: first, last_name: last }) => `${last}, ${first}`,
  natural: ({ first_name: first, last_name: last }) => `${first} ${last}`,
  firstInitial: ({ first_name: first, last_name: last }) => `${first[0]} ${last}`,
};

export default function formatName(user, format = 'full') {
  return formats[format](user);
}
