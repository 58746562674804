import React from 'react';
import PropTypes from 'prop-types';

import { Alert } from 'react-bootstrap';
import { Date } from 'components/utilities';

const { shape, string } = PropTypes;

const PendingApprovalAlert = ({ event, eventRegistration }) => (
  <Alert bsStyle="warning">
    <strong>
      Your {event.purpose === 'event' ? 'registration' : 'submission'} is pending. It was submitted on <Date date={eventRegistration.created_at} />.
    </strong>
  </Alert>
);


PendingApprovalAlert.propTypes = {
  event: shape({
    purpose: string.isRequired,
  }).isRequired,
  eventRegistration: shape({
    created_at: string.isRequired,
  }).isRequired,
};

export default PendingApprovalAlert;
