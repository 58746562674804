import { apiRequest } from 'actions/use';
import apiWrapper, { updateMethod, list } from './helpers';

export default class RequiredDocument extends apiWrapper('/required_documents') {

  static paymentSchedule = updateMethod('/required_documents', 'payment_schedule')

  static missingSignatures({ id, onSuccess }) {
    apiRequest({
      method: 'GET',
      uri: `/api/required_documents/${id}/missing_signatures`,
      on: {
        200: onSuccess,
      },
    });
  }

  static listByOrganization(opts) {
    list('/required_documents/list_by_organization', opts);
  }

  static listForParent(opts) {
    list('/required_documents/list_for_parent', opts);
  }

  static listByFederation(opts) {
    list('/required_documents/list_by_federation', opts);
  }

  static reportingByMember(opts) {
    list('/required_documents/reporting_by_member', opts);
  }

}
