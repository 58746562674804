import React from 'react';
import PropTypes from 'prop-types';
import { modal } from 'helpers';
import moment from 'moment';

import { Modal, Button } from 'react-bootstrap';
import DateRange from 'components/date-range/DateRange';

const { bool, func, object } = PropTypes;

class DateRangeModal extends React.Component {

  static propTypes = {
    onRangeChange: func,
    onClose: func,
    maxDate: object,
    minDate: object,
    startDate: object,
    endDate: object,
    noRanges: bool,
  }

  state = {
    startDate: null,
    endDate: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      startDate: props.startDate,
      endDate: props.endDate,
    };
  }

  onRangeChange = data => this.setState(data)

  doCloseModal = (data) => {
    const { onRangeChange, onClose, actions: { closeModal } } = this.props;
    const { startDate, endDate } = this.state;

    onRangeChange({ startDate, endDate });

    if (onClose) {
      onClose(data);
    }

    closeModal();
  }

  ranges = () => ({
    'This month': {
      startDate: now => now.startOf('month'),
      endDate: now => now,
    },
    'Last month': {
      startDate: now => now.subtract(1, 'month').startOf('month'),
      endDate: now => now.subtract(1, 'month').endOf('month'),
    },
    'Last 30 days': {
      startDate: now => now.subtract(30, 'days').startOf('day'),
      endDate: now => now,
    },
    'Last 90 days': {
      startDate: now => now.subtract(90, 'days').startOf('day'),
      endDate: now => now,
    },
  })

  render() {
    const { minDate, maxDate, startDate, endDate } = this.props;

    const resultStartDate = startDate || moment().subtract(30, 'days');
    const resultEndDate = endDate || moment();

    return (
      <Modal show onHide={this.doCloseModal} className="has-rdr">
        <Modal.Header closeButton>
          <Modal.Title>Select date range</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <hr className="spacer-xs" />

          <DateRange
            calendars={1}
            startDate={resultStartDate}
            endDate={resultEndDate}
            maxDate={maxDate}
            minDate={minDate}
            onInit={this.onRangeChange}
            onChange={this.onRangeChange}
            ranges={this.props.noRanges ? null : this.ranges()}
          />

        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={this.doCloseModal}>Set date range</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}

export default modal('DateRange', DateRangeModal);
