// todo add permission parameter (e.g read, write...)
export default (user, feature) => {
  if (user.role === 'admin') {
    return true
  } else if (user.role === 'alumni') {
    return false
  }

  let features
  if (user.federation) {
    features = user.all_features
  } else if (user.role === 'member') {
    features = user.member.data.all_features
  }

  return _.includes(features, feature)
}
