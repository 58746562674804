/* global forte, Rollbar */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import scriptLoader from 'react-async-script-loader';
import { modal, authorized } from 'helpers';
import Container from 'container';
import { PaymentMethod } from 'resources';
import { Modal, Radio, Button, ProgressBar } from 'react-bootstrap';

import { ParentNameForm } from 'components/parents';
import { Field } from 'components/form';

const { number, shape } = PropTypes;

class AddBankAccountModal extends Container {

  static contextTypes = {
    user: shape({
      id: number.isRequired,
    }),
  }

  static propTypes = {
    onBankAccountCreate: PropTypes.func.isRequired,
    paymentMethodQuery: PropTypes.string,
  }

  static defaultProps = {
    paymentMethodQuery: `
        payment_method {
          method
          company
          last_four
          updated_at
          created_at

          last_payment {
            amount
            updated_at
            created_at
          }

          payment_plans {
            status
            updated_at
            created_at
          }
        }
      `,
  }

  state = {
    routingNumber: '',
    accountNumber: '',
    accountType: 'c',
    bank: null,
    error: null,
    progress: 0,
  }

  componentWillUnmount = () => clearTimeout(this.interval)

  onPaymentMethodCreate = ({ data }) => {
    const { onBankAccountCreate } = this.props;

    this.notifyAndClose(`Your bank account ending in ${data.last_four} has been added to your account.`);
    onBankAccountCreate(data);
  }

  onChangeRoutingNumber = e => {
    if (!e.target) { return; }

    const routingNumber = e.target.value.replace(/[^0-9]+/g, '');

    this.setState({
      routingNumber,
      bank: null,
      error: null,
      progress: 0,
    });

    if (routingNumber.length === 9) {
      PaymentMethod.bankLookup({
        routingNumber,
        onSuccess: this.onBankLookupSuccess,
      });
    }

  }

  onBankLookupSuccess = ({ data }) => {
    const { bank } = data;
    if (_.isNull(bank)) { return; }
    this.setState({ bank });
  }

  onChangeAccountNumber = e => {
    const accountNumber = e.target.value.replace(/[^0-9]+/g, '');

    this.setState({
      accountNumber,
      error: null,
    });
  }

  onChangeAccountType = e => {
    const accountType = e.target.value;
    this.setState({
      accountType,
      error: null,
    });
  }

  onTokenCreated = response => {
    const { paymentMethodQuery } = this.props;

    if (response.event !== 'success') {
      this.setState({ error: true });
      return;
    }

    PaymentMethod.createBankAccount({
      data: {
        payment_method: {
          forte_token: response.onetime_token,
          routing_number: this.state.routingNumber,
          user_id: this.context.user.id,
        },
      },
      query: paymentMethodQuery,
      onSuccess: ({ data }) => {
        this.closeModal();
        this.onPaymentMethodCreate({ data });
      },
      onFailure: ({ data }) => {
        const error = _.get(data, 'payment_method._[0]', 'Unable to confirm your routing & account numbers.');
        if (this.interval) {
          clearTimeout(this.interval);
        }
        this.setState({
          error,
          isLoading: false,
          progress: 0,
        });
      },
    });
  }

  onTokenFailed = (response) => {
    this.setState({
      error: 'Unable to confirm your routing & account numbers.',
    });
    Rollbar.critical('Unable to persist Forte bank account', response);
    this.stopLoading();
  }

  doPaymentMethodCreate = e => {
    e.preventDefault();

    this.startLoading();

    const data = {
      api_login_id: process.env.FORTE_JS_API_ID,
      account_number: this.state.accountNumber,
      routing_number: this.state.routingNumber,
      account_type: this.state.accountType,
    };

    forte
      .createToken(data)
      .success(this.onTokenCreated)
      .error(this.onTokenFailed);
  }

  startLoading = () => {
    const initialValue = 10.0; // percent of progress
    const totalTime = 6.0; // time to reach 100%
    const updateInterval = 25.0; // milliseconds

    const intervals = (totalTime / updateInterval) * 1000;
    const increment = (100 - initialValue) / intervals;

    this.setState({
      error: null,
      isLoading: true,
      progress: initialValue,
    });

    this.interval = setInterval((() => {
      const progress = this.state.progress + increment;
      this.setState({ progress });
      if (progress >= 100 || !this.state.isLoading) {
        clearTimeout(this.interval);
      }
    }), updateInterval);
  }

  stopLoading = () => this.setState({ isLoading: false });

  bankModal = () => {
    const { routingNumber, accountNumber, accountType, bank, error, isLoading, progress } = this.state;
    return (
      <Modal show onHide={this.props.actions.closeModal}>
        <form
          onSubmit={this.doPaymentMethodCreate}
          autoComplete="off"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add a bank account</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <hr className="spacer-sm" />

            <Field
              label="Routing number"
              id="routing_number"
              name="routing_number"
              mask="999 999 999"
              maskChar=""
              hint={bank}
              value={routingNumber}
              onChange={this.onChangeRoutingNumber}
            />

            <Field
              label="Account number"
              id="account_number"
              name="account_number"
              mask="9999 9999 9999 9999 99"
              maskChar=""
              value={accountNumber}
              onChange={this.onChangeAccountNumber}
            />

            <Field label="Account type">

              <Radio
                value="c"
                name="account_type"
                checked={accountType === 'c'}
                onChange={this.onChangeAccountType}
              >
                Checking
              </Radio>

              <Radio
                value="s"
                name="account_type"
                checked={accountType === 's'}
                onChange={this.onChangeAccountType}
              >
                Saving
              </Radio>

            </Field>

            {error ? (
              <div className="text-danger text-center fs-125">
                {error}
              </div>
            ) : null}

            {isLoading && progress > 0 ? (
              <ProgressBar now={progress} />
            ) : null}

          </Modal.Body>
          <Modal.Footer>

            {false ? ( // eslint-disable-line
              <span
                onClick={this.props.actions.openModal('Security')}
                className="safe-data text-muted"
              >
                Read how we keep your data safe
              </span>
            ) : null}

            <Button
              type="submit"
              bsStyle="primary"
              disabled={isLoading}
              className={cx({
                'btn-loading': isLoading,
              })}
            >
              Add bank account
            </Button>

          </Modal.Footer>
        </form>
      </Modal>
    );
  }

  userNameUpdateModal = () => (
    <Modal show onHide={this.props.actions.closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>To add a bank account, we need your name on file</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ParentNameForm objUser={this.context.user} />
      </Modal.Body>
    </Modal>
  );

  render() {
    const { isScriptLoadSucceed } = this.props;
    const { user: { first_name: firstName, last_name: lastName, role } } = this.context;

    if (!isScriptLoadSucceed) {
      return null;
    }

    if (role === 'parent' && _.some([firstName, lastName], _.isEmpty)) {
      return this.userNameUpdateModal();
    }

    return this.bankModal();
  }

}

export default scriptLoader(process.env.FORTE_JS_API_ENDPOINT)(
  authorized(modal('AddBankAccount', AddBankAccountModal))
);
