import T from '../actions/types';

export default (state = null, action) => {
  if (action.type === T.NOTIFICATION) {
    if (action.payload.type === 'PollCreate') {
      return action.payload;
    }
  }

  return null;
};
