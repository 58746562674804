import React from 'react';
import { connect } from 'helpers';
import Container from 'container';

import Header from './header';
import Item from './item';

class OrganizationAdvisors extends Container {

  render() {
    const { user } = this.context;

    const orgs = _.get(user, 'alumni.advised_organizations', []);
    if (orgs.length === 0) {
      return null;
    }
    const firstOrganizationId = _.get(user, 'alumni.advised_organizations[0].id');

    return (
      <ul className="sidebar-menu">
        <Header>Chapter Advising</Header>
        <Item url={`/alumni/advising/${firstOrganizationId}/members`} icon="users">Chapter rosters</Item>
        <Item url={`/alumni/advising/${firstOrganizationId}/executive-board`} icon="address-book">Executive Boards</Item>
        <Item url={`/alumni/advising/${firstOrganizationId}/committee-positions`} icon="users">Chairs/Committees</Item>
        <Item url={`/alumni/advising/${firstOrganizationId}/volunteers-and-staff`} icon="users">Volunteers & staff</Item>
        <Item url={`/alumni/advising/${firstOrganizationId}/visitation-reports`} icon="book">Visitation reports</Item>
        <Item url={`/alumni/advising/${firstOrganizationId}/taxes`} icon="university">Taxes</Item>
        <Item url={`/alumni/advising/${firstOrganizationId}/financial-overview`} icon="usd">Financial overview</Item>
        <Item url={`/alumni/advising/${firstOrganizationId}/budgets`} icon="book">Budgets</Item>
        <Item url={`/alumni/advising/${firstOrganizationId}/bill-pay`} icon="usd" backgroundIcon="file">BillPay</Item>
        <Item url={`/alumni/advising/${firstOrganizationId}/national-balance`} icon="table">National balances</Item>
        <Item url={`/alumni/advising/${firstOrganizationId}/forms`} icon="file-text-o">Form submissions</Item>
      </ul>
    );
  }

}

export default connect(OrganizationAdvisors);
