import React from 'react';
import classnames from 'classnames';

import { FaIcon } from 'components/utilities';

const renderSort = sorted => {

  let icon = 'sort';
  if (_.isBoolean(sorted)) {
    icon = sorted ? 'sort-asc' : 'sort-desc';
  }

  return (
    <FaIcon
      icon={icon}
      className={classnames('m-r-05', {
        'o-25': _.isNull(sorted),
      })}
    />
  );
};

export default function (props) {
  const { onChangeSort } = props;
  const { noSort, sorted, width, children, align, verticalAlign } = props;

  if (noSort) {
    return <th className="no-sort" style={{ width: `${width}%`, textAlign: align }}>{children}</th>;
  }

  return (
    <th
      className={sorted === true ? 'dropup' : ''} onClick={onChangeSort}
      style={{
        width: `${width}%`,
        textAlign: align,
        verticalAlign,
      }}>
      {renderSort(sorted)}
      {children}
    </th>
  );
}
