import React from 'react';

export default function ({ fallback, children }) {

  if (children) {
    return <span>{children}</span>;
  }

  if (fallback === 'n/a') {
    return <span className="text-muted not-applicable"></span>;
  }

  return fallback;
}
