import apiWrapper from './helpers';
import queryString from 'query-string';
import { apiRequest } from 'actions/use';

export default class StaffMember extends apiWrapper('/staff_members') {

  static delete({ data, onSuccess, onFailure }) {
    apiRequest({
      method: 'DELETE',
      uri: `/api/staff_members?${queryString.stringify(data)}`,
      on: {
        200: onSuccess,
      },
      onFailure,
    });
  }

}
