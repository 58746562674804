import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import scriptLoader from 'react-async-script-loader'
import PlacesAutocomplete, { geocodeByPlaceId } from 'components/places-autocomplete'

const { bool, func, object, string } = PropTypes

class AddressInput extends React.Component {
  static propTypes = {
    address: object,
    namePrefix: string,
    placeholder: string,
    address2Name: string,
    onAddressSelect: func.isRequired,
    isScriptLoadSucceed: bool.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = this.initialAddressState(props.address)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.address !== this.props.address) {
      this.setState(this.initialAddressState(nextProps.address))
    }
  }

  onAddressChange = addressStr => {
    this.setState({
      populated: false,
      addressError: false,
    })

    if (addressStr.length === 0) {
      this.props.onAddressSelect(null)
    }

    this.setState({
      populated: false,
      addressStr,
    })
  }

  onAddressSelect = (addressStr, placeId) => {
    this.setState({
      addressStr,
      addressLoading: true,
    })

    const pGeocode = geocodeByPlaceId(placeId)
    pGeocode
      .then(results => {
        const parts = this.parseGooglePayload(results[0])

        const subAddress = parts.street_number ? `${parts.street_number} ${parts.route}` : null
        const address = {
          address: subAddress,
          city: parts.locality || parts.sublocality,
          state: parts.administrative_area_level_1,
          postal_code: parts.postal_code,
          country: parts.country,
        }

        const addressKeys = Object.keys(address)

        const addressHasRequiredKeys = addressKeys.every(key => !_.isEmpty(address[key]))
        const addressError = addressHasRequiredKeys ? false : 'notExactStreetAddress'

        this.setState({
          addressError,
          populated: true,
          addressStr: results[0].formatted_address,
          addressLoading: false,
        })

        if (!addressError) this.props.onAddressSelect(address)
      })
      .catch(reason => {
        this.setState({
          addressError: reason,
          populated: true,
          addressLoading: false,
        })
      })
  }

  onBlur = e => {
    const classnames = e.currentTarget.className
    if (classnames.match(/address-populated/)) {
      return
    }

    if (e.target.value === '') {
      this.setState({
        addressError: null,
      })
      return
    }

    const { addressLoading } = this.state
    if (!addressLoading) {
      this.setState({
        addressStr: '',
      })
    }
  }

  initialAddressState(address) {
    let addressStr = ''

    if (address) {
      addressStr = _.trim(
        `${address.address}, ${address.city || ''} ${address.state || ''} ${
          address.postal_code || ''
        }`
      )
    }

    return {
      addressStr,
      addressError: false,
      addressLoading: false,
    }
  }

  parseGooglePayload = ({ address_components }) =>
    _.reduce(
      address_components,
      (memo, c) => {
        const type = _.find(c.types, t => t !== 'political')
        // eslint-disable-next-line no-param-reassign
        memo[type] = c.short_name
        return memo
      },
      {}
    )

  renderError = () => {
    const { addressError } = this.state

    if (!addressError) return null

    switch (addressError) {
      case 'mustSelectAddress':
        return (
          <div>
            <p className="text-danger fw-semibold m-t-05 m-b-0">
              You must select an address from the dropdown list.
            </p>
            <p>This enables us to ensure you will receive bills as requested.</p>
          </div>
        )
      case 'notExactStreetAddress':
        return (
          <p className="text-danger fw-semibold m-t-05">
            The address you selected appears not to be an exact street address.
          </p>
        )
      case 'ZERO_RESULTS':
        return <p className="text-danger fw-semibold m-t-05">Unable to find a valid address.</p>
      default:
        return (
          <p className="text-danger fw-semibold m-t-05">
            The address you selected appears not to be an exact street address.
          </p>
        )
    }
  }

  render() {
    const { address, namePrefix, address2Name, isScriptLoadSucceed, placeholder } = this.props
    const { addressStr, populated } = this.state

    if (!isScriptLoadSucceed) {
      return null
    }

    return (
      <div
        onKeyDown={this.onKeyDown}
        onBlur={this.onBlur}
        className={cx({ 'address-populated': populated })}
      >
        <PlacesAutocomplete
          inputProps={{
            value: addressStr,
            onChange: this.onAddressChange,
            placeholder: placeholder || '',
          }}
          value={addressStr}
          options={{
            types: ['address'],
            componentRestrictions: {
              country: ['us', 'ca'],
            },
          }}
          onSelect={this.onAddressSelect}
          classNames={{ input: 'form-control' }}
        />

        {this.renderError()}

        <input
          name={address2Name || `${namePrefix}.address.address_2`}
          defaultValue={(address || {}).address_2}
          type="text"
          placeholder="Apt or suite number"
          className="form-control m-t-1"
        />
      </div>
    )
  }
}

export default scriptLoader(
  `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_PLACES_API_KEY}&libraries=places`
)(AddressInput)
