import React from 'react';
import PropTypes from 'prop-types';
import { modal } from 'helpers';
import { Document } from 'resources';

import { Modal } from 'react-bootstrap';
import Form from 'components/form/form';
import Uploader from 'components/uploader';

const { func, string, number } = PropTypes;

class UploadFileModal extends React.Component {

  static propTypes = {
    noun: string,
    organizationId: number,
    onSuccess: func.isRequired,
  }

  state = {
    s3Path: null,
  }

  onSuccess = (args) => {
    const { onSuccess } = this.props;
    window.uploadDocumentName = _.get(args, 'name');
    onSuccess(args);
  }

  render = () => {
    const { noun, organizationId } = this.props;
    const { s3Path } = this.state;

    const data = {
      document: {
        s3_path: s3Path,
      },
    };

    return (
      <Modal show onHide={this.props.actions.closeModal}>
        <Form action={Document.create} data={data} onSuccess={this.onSuccess}>
          <Modal.Header closeButton>
            <Modal.Title>Upload a {noun || 'file'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="upload-wrapper">

              <Uploader
                noun={noun || 'file'}
                getS3Info={() => Document.uploadUrl({
                  organization_id: organizationId,
                })}
                onSuccess={this.onSuccess}
              />

            </div>

          </Modal.Body>
        </Form>
      </Modal>
    );
  }

}

export default modal('UploadFile', UploadFileModal);
