import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'helpers';
import { FaIcon } from 'components/utilities';
import Header from './header';
import Item from './item';
import PaymentPlansTooltip from './alerts/payment-plans-proposed-tooltip';
import SettingsTooltip from './alerts/settings-tooltip';
import HourTrackerTooltip from './alerts/hour-tracker-required-tooltip';

const { object, bool } = PropTypes;

class MemberAccount extends React.Component {

  static propTypes = {
    requiredActions: object.isRequired,
    alwaysExpanded: bool,
  }

  static defaultProps = {
    alwaysExpanded: false,
  }


  constructor(props) {
    super(props);

    const myAccountVisible = sessionStorage.getItem('myAccountVisible') === 'true' || props.alwaysExpanded;

    this.state = { myAccountVisible };
  }

  toggleMyAccount = () => {
    const myAccountVisible = !this.state.myAccountVisible;
    sessionStorage.setItem('myAccountVisible', myAccountVisible);
    this.setState({ myAccountVisible });
  }

  render() {
    const { alwaysExpanded, requiredActions } = this.props;
    const { profile, hour_tracker: hourTracker, payment_plans: paymentPlans } = requiredActions;
    const { myAccountVisible } = this.state;
    const expanded = myAccountVisible ? 'expanded' : '';

    let key = 0;

    return (
      <ul className="sidebar-menu">
        {alwaysExpanded ? (
          <Header>My Account</Header>
        ) : (
          <Header key="sidebar-header">
            <div className="c-pointer" onClick={this.toggleMyAccount}>
              <FaIcon chevron-right className={expanded} />
              My Account
            </div>
          </Header>
        )}
        {myAccountVisible && [
          <Item key={key++} url="/balance" icon="bank">Balance</Item>,
          <Item key={key++} url="/payment-plan" id="Payment plan" icon="calculator">
            Payment plan
            <PaymentPlansTooltip paymentPlans={paymentPlans} />
          </Item>,
          <Item key={key++} url="/reimbursements" id="reimbursements#member" icon="exchange">Reimbursements</Item>,
          <Item key={key++} url="/hour-tracker" icon="clock-o" id="hour-tracker#member">
            Hour tracker
            <HourTrackerTooltip hourTracker={hourTracker} />
          </Item>,
          <Item key={key++} url="/event-registrations" icon="calendar" id="eventRegistration">Events</Item>,
          <Item key={key} url="/settings" id="settings#user" icon="cog">
            Settings
            <SettingsTooltip profile={profile} />
          </Item>,
        ]}
      </ul>
    );
  }
}

const mapStateToProps = (state) => ({
  requiredActions: state.get('requiredActions'),
});

export default connect(MemberAccount, mapStateToProps);
