import apiWrapper, { generatePayload } from './helpers';
import { apiRequest } from 'actions/use';

export default class PaymentMethod extends apiWrapper('/payment_methods') {

  static createBankAccount({ data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'POST',
      uri: '/api/payment_methods/create_bank_account',
      data: generatePayload({ data, query }),
      on: {
        201: onSuccess,
      },
      onFailure,
    });
  }

  static authorization({ onSuccess }) {
    apiRequest({
      method: 'POST',
      uri: '/api/braintree/authorization',
      on: {
        201: onSuccess,
      },
    });
  }

  static bankLookup({ routingNumber, onSuccess }) {
    apiRequest({
      method: 'GET',
      uri: '/api/payment_methods/bank_lookup',
      data: {
        routing_number: routingNumber,
      },
      on: {
        200: onSuccess,
      },
    });
  }

}
