import moment from 'moment';

export const storeDateRange = (dateRange) => {

  const formattedDateRange = {
    start: dateRange.start.format(),
    end: dateRange.end.format(),
  };

  const serializedDateRange = JSON.stringify(formattedDateRange);

  localStorage.setItem('dateRange', serializedDateRange);
};

export const restoreDateRange = () => {
  const dateRange = localStorage.getItem('dateRange');

  if (!dateRange) {
    return null;
  }

  const deserializedDateRange = JSON.parse(dateRange);

  const parsedDateRange = {
    start: moment(deserializedDateRange.start),
    end: moment(deserializedDateRange.end),
  };

  return parsedDateRange;
};
