import React from 'react';
import PropTypes from 'prop-types';

import { FormControl, InputGroup } from 'react-bootstrap';

const { string, func } = PropTypes;

export default class FilenameInput extends React.Component {

  static propTypes = {
    name: string.isRequired,
    defaultValue: string,
    groupClassName: string,
    onChange: func,
  }


  constructor(...args) {
    super(...args);

    const { defaultValue: value } = this.props;
    const { 1: cutName, 2: extension } = /(.*?)(\.[^.]+)?$/.exec(value);

    this.state = { cutName, extension, value };
  }

  onChange = (e) => {
    const { onChange } = this.props;
    const { extension } = this.state;
    const value = e.target.value + extension;

    this.setState({
      cutName: e.target.value,
      value,
    });

    if (onChange) {
      onChange(value);
    }
  }

  render() {
    const controlProps = _.omit(this.props, ['defaultValue', 'onChange', 'groupClassName']);
    const { extension, value, cutName } = this.state;
    const { name } = this.props;

    return (
      <InputGroup className={this.props.groupClassName}>
        <FormControl
          id={name}
          type="text"
          {...controlProps}
          onChange={this.onChange}
          defaultValue={cutName} />
        <InputGroup.Addon>{extension}</InputGroup.Addon>
        <input type="hidden" name={name} value={value} />
      </InputGroup>
    );
  }

}
