import T from './types';

/*
const isBraintreeAuthorization = (payload) => (
  payload.uri === '/api/braintree/authorization' &&
  payload.method === 'POST'
);
*/

export function apiRequest(payload) {
  /*
  if (payload.method !== 'GET' && !isBraintreeAuthorization(payload)) {
    window.Rollbar.info(`${payload.method} ${payload.uri}`);
  }
  */

  return {
    type: T.API_REQUEST,
    ...payload,
  };
}

export function apiSuccess(payload) {
  /*
  if (payload.method !== 'GET' && !isBraintreeAuthorization(payload)) {
    window.Rollbar.info(`${payload.status} ${payload.method} ${payload.uri}`);
  }
  */

  return {
    type: T.API_SUCCESS,
    payload,
  };
}

export function apiFailure(payload) {
  window.Rollbar.info(`${payload.status} ${payload.method} ${payload.uri}`);

  return {
    type: T.API_FAILURE,
    payload,
  };
}
