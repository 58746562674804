import T from './types';

export function setCalendarEvent(event) {
  return {
    type: T.SET_CALENDAR_EVENT,
    event,
  };
}

export function clearCalendarEvent() {
  return {
    type: T.CLEAR_CALENDAR_EVENT,
  };
}
