import apiWrapper, { getMethod, list, generatePayload } from './helpers'
import { apiRequest } from 'actions/use'

export default class DocumentFolder extends apiWrapper('/document_folders') {
  static retrieveOrCreateByPurpose = getMethod('/document_folders/retrieve_or_create_by_purpose')

  static listByUser(opts) {
    list('/document_folders/list_by_user', opts)
  }

  static listByEmail(opts) {
    list('/document_folders/list_by_email', opts)
  }

  static listByPermitted(opts) {
    list('/document_folders/list_by_permitted', opts)
  }

  static createWithUsers({ data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'POST',
      uri: '/api/document_folders/create_with_users',
      data: generatePayload({ data, query }),
      on: {
        201: onSuccess,
      },
      onFailure,
    })
  }

  static updateAccess({ id, data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'PUT',
      uri: `/api/document_folders/${id}/access`,
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    })
  }
}
