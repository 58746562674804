import api from './api'
import moment from 'moment'

const openDownloadLink = url => {
  try {
    window.openRef(url, '_blank')
  } catch (e) {
    window.location.href = url
  }
}

const getDownloadToken = () =>
  api({
    method: 'GET',
    uri: '/api/user_download_token',
  })

export function* PDFBill(action) {
  const {
    data: { download_token },
  } = yield getDownloadToken()

  // eslint-disable-next-line camelcase
  const url = `/api/download/${action.memberId}/bill.pdf?token=${download_token}`
  openDownloadLink(url)
}

export function* organizationInvoicesPDF(action) {
  const { defaultDueOn } = action
  const {
    data: { download_token },
  } = yield getDownloadToken()

  // eslint-disable-next-line camelcase
  const url = `/api/download/${action.organizationId}/invoices.pdf?token=${download_token}&due_date=${defaultDueOn}`
  openDownloadLink(url)
}

export function* transactionsCSV(action) {
  const {
    data: { download_token },
  } = yield getDownloadToken()

  // eslint-disable-next-line camelcase
  const url = `/api/download/transactions?${action.serializedQuery}&token=${download_token}`
  openDownloadLink(url)
}

export function* membersCSV(action) {
  const {
    data: { download_token },
  } = yield getDownloadToken()

  // eslint-disable-next-line camelcase
  const url = `/api/download/members?${action.serializedQuery}&token=${download_token}`
  openDownloadLink(url)
}

export function* donationsCSV(action) {
  const {
    data: { download_token },
  } = yield getDownloadToken()

  // eslint-disable-next-line camelcase
  const url = `/api/download/donations?${action.serializedQuery}&token=${download_token}`
  openDownloadLink(url)
}

export function* organizationMembersCSV(action) {
  const {
    data: { download_token },
  } = yield getDownloadToken()

  // eslint-disable-next-line camelcase
  const url = `/api/download/organization_members?${action.serializedQuery}&token=${download_token}`
  openDownloadLink(url)
}

export function* downloadBudgetPDF(action) {
  const {
    data: { download_token },
  } = yield getDownloadToken()

  // eslint-disable-next-line camelcase
  const url = `/api/download/${action.organizationId}/budget.pdf?term_id=${action.termId}&token=${download_token}`
  openDownloadLink(url)
}

export function* downloadRequiredDocumentAcceptance(action) {
  const {
    data: { download_token },
  } = yield getDownloadToken()

  // eslint-disable-next-line camelcase
  const url = `/api/download/required_document_acceptance/${action.id}?token=${download_token}`
  openDownloadLink(url)
}

export function* downloadOrganizationReport(action) {
  const {
    data: { download_token },
  } = yield getDownloadToken()

  let endDate = action.endDate || moment().format('YYYY-MM-DD')
  const parts = endDate.split(/-/)
  const day = parseInt(parts[2], 10)
  if (_.includes(['01', '06'], parts[1]) && day <= 7) {
    endDate = moment(endDate).subtract(day, 'day').format('YYYY-MM-DD')
  }

  // eslint-disable-next-line camelcase
  const url = `/api/download/${action.organizationId}/report.pdf?start_date=${action.startDate}&end_date=${endDate}&token=${download_token}`
  openDownloadLink(url)
}

export function* downloadAllAdjustments(action) {
  const {
    data: { download_token: downloadToken },
  } = yield getDownloadToken()
  const { startDate, endDate } = action

  const url = `/api/download/all_adjustments?token=${downloadToken}&start_date=${startDate}&end_date=${endDate}`
  openDownloadLink(url)
}

export function* downloadAllBillPays(action) {
  const {
    data: { download_token: downloadToken },
  } = yield getDownloadToken()
  const { startDate, endDate } = action

  const url = `/api/download/all_bill_pays?token=${downloadToken}&start_date=${startDate}&end_date=${endDate}`
  openDownloadLink(url)
}

export function* downloadAllTransfers(action) {
  const {
    data: { download_token },
  } = yield getDownloadToken()

  // eslint-disable-next-line camelcase
  const url = `/api/download/all_transfer?federation_id=${action.federationId}&token=${download_token}`
  openDownloadLink(url)
}

export function* downloadSingleTransfer(action) {
  const {
    data: { download_token },
  } = yield getDownloadToken()

  // eslint-disable-next-line camelcase
  const url = `/api/download/single_transfer?federation_transfer_id=${action.federationTransferId}&token=${download_token}`
  openDownloadLink(url)
}

export function* downloadFederationMembershipReport(action) {
  const {
    data: { download_token },
  } = yield getDownloadToken()

  // eslint-disable-next-line camelcase
  const url = `/api/download/federation_membership_report?report_submission_id=${action.reportSubmissionId}&token=${download_token}`
  openDownloadLink(url)
}

export function* downloadSalesforceRoster(action) {
  const {
    data: { download_token },
  } = yield getDownloadToken()

  // eslint-disable-next-line camelcase
  const url = `/api/download/salesforce_roster?id=${action.sfId}&token=${download_token}`
  openDownloadLink(url)
}

export function* registrationCSV(action) {
  const {
    data: { download_token: downloadToken },
  } = yield getDownloadToken()

  let url = `/api/download/${action.id}/events?token=${downloadToken}`
  if (action.organizationId) {
    url += `&organization_id=${action.organizationId}`
  }

  openDownloadLink(url)
}

export function* requiredDocumentCSV(action) {
  const {
    data: { download_token: downloadToken },
  } = yield getDownloadToken()

  const url = `/api/download/required_document?required_document_id=${action.requiredDocumentId}&token=${downloadToken}`
  openDownloadLink(url)
}

export function* reconcileOrganizationCSV(action) {
  const {
    data: { download_token: downloadToken },
  } = yield getDownloadToken()

  const url = `/api/download/reconcile_organization?organization_id=${action.organizationId}&token=${downloadToken}`
  openDownloadLink(url)
}

export function* openNewBrowserTab(action) {
  openDownloadLink(action.url)
}
