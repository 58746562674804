import React from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';

import { connect } from 'helpers';

const { func, string } = PropTypes;

class ColorInput extends React.Component {

  static propTypes = {
    color: string.isRequired,
    name: string,
    className: string,
    onChange: func,
  }

  static defaultProps = {
    color: '#fff',
  }

  constructor(props) {
    super(props);

    this.state = { displayColorPicker: false, color: props.color };
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(color.hex);
    }
    this.setState({ color: color.hex });
  };

  render() {
    const { name, className } = this.props;
    const { displayColorPicker, color } = this.state;

    return (
      <div className={className}>
        <input type="hidden" name={name} value={color} />
        <div className="color-input-swatch m-l-1" onClick={this.handleClick}>
          <div className="color-input-color" style={{ background: color }} />
        </div>
        { displayColorPicker ? (
          <div className="color-input-popover">
            <div className="color-input-cover" onClick={this.handleClose} />
            <ChromePicker color={color} onChange={this.handleChange} />
          </div>) : null }
      </div>
    );
  }
}

export default connect(ColorInput);
