import { apiRequest } from 'actions/use';
import apiWrapper, { generatePayload } from './helpers';

export default class CommitteePosition extends apiWrapper('/committee_positions') {

  static updateOrdering = ({ data, onSuccess, onFailure }) => {
    apiRequest({
      method: 'PUT',
      uri: '/api/committee_positions/update_ordering',
      data: generatePayload({ data }),
      on: {
        200: onSuccess,
      },
      onFailure,
    });
  };

}
