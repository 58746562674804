
const mimeTypes = {
  'text/csv': 'csv',
  'image/jpeg': 'jpg',
  'image/jpg': 'jpg',
  'image/png': 'png',
  'application/pdf': 'pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'doc',
  'application/msword': 'doc',
  'binary/octet-stream': 'doc',
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xls',
  'application/x-iwork-pages-sffpages': 'pages',
  'application/x-iwork-numbers-sffnumbers': 'numbers',
};

const extensions = {
  numbers: 'xls',
  pages: 'doc',
};

export function extFromName(filename) {
  return filename.split('.').pop();
}

export function extForMimeType(type, filename) {
  if (/^\.\w+$/.test(type)) return type.substring(1)

  return mimeTypes[type] || (filename && extensions[extFromName(filename)]) || 'unknown';
}

export function iconForMimeType(type, filename) {
  const ext = extForMimeType(type, filename);
  return require(`images/file-types/${ext}.svg`); // eslint-disable-line global-require
}
