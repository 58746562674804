import React from 'react';
import { array, func } from 'prop-types';
import { Button, Col, FormGroup, Row } from 'react-bootstrap';
import { MoneyInput, FaIcon } from 'components/utilities';
import { Input } from 'components/form';

export class EventOptionalAddons extends React.Component {

  static propTypes = {
    optionalAddons: array,
    onChangeOptionalAddons: func.isRequired,
  }

  static defaultProps = {
    optionalAddons: [{ name: '', cost: 0 }],
  }

  constructor(props) {
    super(props);

    const optionalAddons = props.optionalAddons ? props.optionalAddons : EventOptionalAddons.defaultProps.optionalAddons;
    this.state = { optionalAddons: optionalAddons.map((item, index) => ({ id: index + 1, ...item })) };
  }

  deleteRow = (index) => {
    const { optionalAddons } = this.state;
    optionalAddons.splice(index, 1);
    this.setState({ optionalAddons }, this.pushToForm);
  }

  onAddField = () => {
    const { optionalAddons } = this.state;
    optionalAddons.push({ id: optionalAddons.length + 1, name: '', cost: 0 });

    this.setState({ optionalAddons });
  }

  onChangeField = index => (e) => {
    const { optionalAddons } = this.state;

    if (typeof e === 'object') {
      optionalAddons[index].name = e.target.value;
    } else {
      optionalAddons[index].cost = parseFloat(e.replace(/[^0-9.]+/g, '')).toFixed(2);
    }

    this.setState({ optionalAddons }, this.pushToForm);
  }

  pushToForm = () => {
    const { onChangeOptionalAddons } = this.props;
    const { optionalAddons } = this.state;

    const formattedOptionalAddons = _.map(optionalAddons, (item) => _.omit(item, 'id'));

    onChangeOptionalAddons(formattedOptionalAddons);
  }

  render() {
    const { optionalAddons } = this.state;

    return (
      <React.Fragment>
        <b>If you'd like to have additional add-ons, enter them below:</b>
        {_.map(optionalAddons, (item, index) => {
          const itemName = `name[${index}]`;
          const itemCost = `cost[${index}]`;
          const itemCostValue = _.toNumber(optionalAddons[index].cost) === 0 ? null : _.toNumber(optionalAddons[index].cost);
          return (
            <FormGroup
              key={optionalAddons[index].id}
              className="m-l-1 m-b-0"
            >
              <Row>
                <Col md={4}>
                  <FormGroup className="m-b-1">
                    <label htmlFor={itemName} className="control-label text-muted">Item name</label>
                    <Input name={itemName} onChange={this.onChangeField(index)} defaultValue={optionalAddons[index].name} />
                  </FormGroup>
                </Col>
                <Col md={4} className="m-l-1">
                  <FormGroup className="m-b-1">
                    <label htmlFor={itemCost} className="control-label text-muted">Cost</label>
                    <MoneyInput
                      placeholder="free"
                      name={itemCost}
                      onChange={this.onChangeField(index)}
                      defaultValue={itemCostValue}
                    />
                  </FormGroup>
                </Col>
                <Col md={1} className="m-t-2 p-t-1">
                  <FaIcon
                    size="lg"
                    icon="trash"
                    className="c-pointer"
                    onClick={() => this.deleteRow(index)}
                  />
                </Col>
              </Row>
            </FormGroup>
          );
        })}

        <Button
          bsSize="sm"
          onClick={this.onAddField}
        >
          Add a option
        </Button>

      </React.Fragment>
    );
  }

}

export default EventOptionalAddons;
