import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import actionProps from 'actions'

const { object, shape } = PropTypes

export default function (WrappedComponent, mapStateToPropsFunc) {
  // eslint-disable-next-line no-param-reassign
  const contextTypes = WrappedComponent.contextTypes || (WrappedComponent.contextTypes = {})
  contextTypes.user = PropTypes.object.isRequired
  contextTypes.member_id = PropTypes.number
  contextTypes.organization = PropTypes.object.isRequired

  const extendedComponent = class extends React.Component {
    static contextTypes = {
      member: object,
    }

    static propTypes = {
      dateRange: shape({
        start: object.isRequired,
        end: object.isRequired,
      }),
    }

    componentWillMount() {
      const organizationId = _.get(this.context, 'member.organization_id')
      this.props.actions.ensureTerms(organizationId)
    }

    render() {
      const { dateRange } = this.props

      if (!dateRange) {
        return null
      }

      return <WrappedComponent dateRange={dateRange} {...this.props} />
    }
  }

  const mapStateToProps = state => {
    let data = {}

    if (mapStateToPropsFunc) {
      data = mapStateToPropsFunc(state)
    }

    const auth = state.get('auth')
    const dateRange = state.get('dateRange')
    return _.assign(data, { dateRange, auth })
  }

  return connect(mapStateToProps, actionProps)(extendedComponent)
}
