import React from 'react';
import { Label } from 'react-bootstrap';

const styles = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'inverted'];

export default function (props) {
  const style = _.reduce(props, (result, value, key) => (
    styles.indexOf(key) !== -1 ? key : result
  ), 'default');

  return (
    <Label
      bsStyle={style}
      className={props.className}
      onClick={props.onClick}
      title={props.title}
    >
      {props.children}
    </Label>
  );
}
