import React from 'react';
import { arrayOf, func, number, shape, string } from 'prop-types';

import { Money, Date, Portlet, Body } from 'components/utilities';

const PaymentOption = ({ document, acceptance, onUpdateAcceptance }) => (
  <Portlet boxed>
    <Body>
      <h5>Please pick a payment plan:</h5>
      {_.map(document.payment_schedule.options, (option, idx) => (
        <div
          key={idx}
          className="m-b-1 c-pointer payment-plan-option"
          onClick={() => onUpdateAcceptance('payment_plan_option', idx)}
        >
          <h6>
            <input
              type="radio"
              name="payment_plan.option_id"
              className="m-r-1"
              value={idx}
              checked={idx === acceptance.payment_plan_option}
              onChange={() => {}}
            />
            Option {idx + 1}:
          </h6>
          <ul className="list-unstyled m-l-2">
            {_.map(option, (payment) => (
              <li key={`${payment.due_on}-${payment.amount}`} className="m-b-025">
                - <strong><Money amount={payment.amount} /></strong> on <Date date={payment.due_on} short />
              </li>
              ))}
          </ul>
        </div>
        ))}
    </Body>
  </Portlet>
);

PaymentOption.propTypes = {
  document: shape({
    payment_schedule: shape({
      options: arrayOf(arrayOf(shape({
        due_on: string.isRequired,
        amount: number.isRequired,
      }))).isRequired,
    }).isRequired,
  }).isRequired,
  acceptance: shape({
    paymnet_plan_option: number,
  }).isRequired,
  onUpdateAcceptance: func.isRequired,
};

export default PaymentOption;
