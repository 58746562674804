import apiWrapper from './helpers';

export default class StatusUpdateReason extends apiWrapper('/status_update_reasons') {

}

export const REASON_TYPES = {
  nothing: 'Doesn\'t effect roster',
  remove: 'Remove from roster',
  convert: 'Convert to alumni',
};
