import { downloadCSV } from 'components/table'
import TermSelector from 'components/terms/selector'
import {
  Action,
  ActionGroup,
  Body,
  BreadcrumbHeader,
  BreadcrumbItem,
  ButtonBar,
  Content,
  EmptyState,
  Header,
  Pagination,
  Portlet,
  PrintAction,
  TableAction,
} from 'components/utilities'
import React from 'react'
import { Button, Col, Grid, Row } from 'react-bootstrap'
import parseMetadata from 'utils/parse-metadata'

export default class Container extends React.Component {
  constructor(props, state = {}, context = {}) {
    super(props, context)

    this.resources = {}
    this.loadings = []
    this.expectedLoadingTime = 'short'

    const metadata = props.location ? parseMetadata(props.location) : {}

    const processedState = {}
    _.each(state, (v, k) => {
      processedState[k] = _.isFunction(v) ? v(metadata) : v
    })

    this.modalDataId = this.getModalDataId(props)

    this.state = _.assign(
      {},
      {
        data: {
          list: null,
          object: null,
          isLoading: true,
          page: +_.get(props, 'params.page') || 1,
          metadata: {},
        },
        metadata,
        showLoading: false,
        listValidationErrors: null,
      },
      processedState
    )

    this.doShowLoading = _.debounce(this.doShowLoading, 400)
  }

  componentDidMount() {
    this.mounted = true

    const { list, object } = this.resources

    if (list) {
      this.doResourceList()
    }

    if (object) {
      this.doObjectRetrieve()
    }

    if (this.sidebarItem) {
      const item = _.isFunction(this.sidebarItem) ? this.sidebarItem() : this.sidebarItem
      this.props.actions.setActiveSidebarItem(item)
    }
  }

  componentWillReceiveProps(nextProps) {
    const oldHash = _.get(this.props, 'location.hash')
    const newHash = _.get(nextProps, 'location.hash')
    if (oldHash !== newHash) {
      const modalDataId = this.getModalDataId(nextProps)
      if (modalDataId) {
        this.modalDataId = modalDataId
      }
    }

    const oldPage = _.get(this.props, 'params.page')
    const newPage = _.get(nextProps, 'params.page')
    if (oldPage !== newPage && this.resources.list) {
      this.setState(
        state => ({
          data: _.assign(state.data, {
            page: nextProps.params.page || 1,
          }),
        }),
        this.doResourceList
      )
    }
  }

  componentDidUpdate() {
    // Fetch if listScope has changed
    const { list } = this.resources
    if (list && !this.isDataLoading()) {
      const nextListScope = JSON.stringify(this.processListScope())
      if (!_.isEqual(nextListScope, this.currentListScope)) {
        this.doResourceList()
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }

  setState(...args) {
    if (this.mounted) {
      super.setState(...args)
    }
  }

  /* eslint-disable react/sort-comp */

  afterHook(func, ...args) {
    const functionName = `after${func}`
    if (_.isFunction(this[functionName])) {
      this[functionName](...args)
    }
    this.afterHookCheckAllResources()
  }

  afterHookCheckAllResources = () => {
    if (!this.isDataLoading()) {
      if (_.isFunction(this.afterAllResources)) {
        this.afterAllResources()
      }
    }
  }

  /* List */
  processListScope = () => {
    let listScope = this.listScope

    if (listScope === false) {
      return null
    }

    if (_.isFunction(listScope)) {
      return listScope()
    }

    if (_.isString(listScope)) {
      switch (listScope) {
        case 'user':
          listScope = {
            user_id: this.context.user.id,
          }
          break
        case 'alumni':
          listScope = {
            alumni_id: this.context.user.alumni.id,
          }
          break
        case 'organization':
          listScope = {
            organization_id: this.context.organization.id,
          }
          break
        case 'member':
          listScope = {
            member_id: this.context.member_id,
          }
          break
        case 'federation':
          listScope = {
            federation_id: this.context.user.federation.id,
          }
          break
        case 'parent':
          listScope = {
            parent_id: this.props.activeParent.id,
          }
          break
        case 'owned_by_organization':
          listScope = {
            owner_type: 'Organization',
            owner_id: this.context.organization.id,
          }
          break
        case 'owned_by_federation':
          listScope = {
            owner_type: 'Federation',
            owner_id: this.context.user.federation.id,
          }
          break
        case 'has_owner':
          listScope = this.owner
          break
        default:
          break
      }
    }

    const doesntHaveDates = _.isEmpty(_.pick(listScope, 'start_date', 'end_date'))
    if ('dateRange' in this.props && doesntHaveDates) {
      listScope = _.assign(this.dateRangeForRequest(), listScope)
    }

    return listScope || {}
  }

  dateRangeForRequest = () => {
    // eslint-disable-next-line react/prop-types
    const { dateRange } = this.props
    return {
      start_date: dateRange.start.format('YYYY-MM-DD'),
      end_date: dateRange.end.format('YYYY-MM-DD'),
    }
  }

  isDataLoading = () => {
    const { isLoading } = this.state.data
    return isLoading
  }

  shouldShowLoading = () => this.state.showLoading

  shouldDisableHeaderButtons = () => {
    const { list, object } = this.resources
    if (object && _.isEmpty(this.getObject())) {
      return true
    } else if (list && _.isEmpty(this.getList())) {
      return true
    } else if (this.isDataLoading()) {
      return true
    }

    return false
  }

  doShowLoading = () => {
    this.setState({ showLoading: true })
  }

  getWrapperClassName = () => {
    const classes = []
    if (this.isDataLoading()) {
      classes.push('wrapper-loading')
    }
    if (this.shouldShowLoading()) {
      classes.push('wrapper-loading-show')
    }

    return classes.join(' ')
  }

  doResourceList = () => {
    const resource = this.resources.list

    if (!resource) {
      throw new Error('Resource for the `list` is not provided')
    }

    this.setLoading('list', true)

    const listScope = this.processListScope()
    this.currentListScope = JSON.stringify(listScope)

    const func = this.listResourceAction || 'list'

    const opts = {
      data: listScope,
      onSuccess: this.onResourceList.bind(this),
      onFailure: this.onResourceListFailure,
    }

    if (this.listQuery) {
      opts.query = this.listQuery
    }

    resource[func](opts)
  }

  onResourceListFailure = ({ status, data }) => {
    this.setLoading('list', false)

    this.setState({
      listValidationErrors: status === 422 ? data : null,
    })
  }

  onResourceList({ data, headers }) {
    if (!this.mounted) return

    this.setState({
      listValidationErrors: null,
    })

    this.listSet(data, headers).then(() => {
      this.setLoading('list', false)
      this.afterHook('ResourceList', this.getList(), headers)
    })
  }

  clearList() {
    this.setState(state => ({
      data: _.assign(state.data, {
        list: null,
        metadata: null,
        page: 1,
      }),
    }))
  }

  listSet(data, metadata) {
    return new Promise(resolve =>
      this.setState(state => {
        let list = data

        if (typeof list === 'function') {
          list = list(state.data.list || [])
        }

        const optionFuncs = {
          filter: 'filter',
          map: 'mapSomeValues',
          sort: 'sortBy',
        }

        _.forEach(optionFuncs, (lodashFunc, type) => {
          const func = `${type}ListBy`
          if (!_.isUndefined(this[func])) {
            list = _[lodashFunc](list, this[func])
          }
        })

        const updatedMetadata = _.assign(state.data.metadata, metadata || {})

        return {
          data: _.assign(state.data, {
            list,
            metadata: updatedMetadata,
            page: +updatedMetadata.paginationPage,
          }),
        }
      }, resolve)
    )
  }

  listAdd({ data }) {
    this.listSet(list => list.concat(data))
  }

  listPrepend({ data }) {
    this.listSet(list => _.concat(data, list))
  }

  listAppend({ data }) {
    const list = this.getList()
    const concatenated = _.concat(list, data)
    this.listSet(concatenated)
  }

  listReplace({ data }) {
    this.listSet(list => list.map(item => (item.id === data.id ? data : item)))
  }

  listRemove({ id }) {
    this.listSet(list => list.filter(item => item.id !== id))
  }

  listMerge(data) {
    const list = this.getList()
    const mergedList = _.map(list, item => _.extend(item, _.find(data, { id: item.id })))
    this.listSet(mergedList)
  }

  listAddOrUpdate({ data }) {
    const object = this.getListObjectById(data.id)
    const func = object ? 'Replace' : 'Add'
    this[`list${func}`]({ data })
  }

  listUpdateObject(id, funcMap) {
    const list = this.getList()
    const object = _.find(list, obj => obj.id === id)

    const data = {}
    _.forEach(funcMap, (func, key) => {
      data[key] = func(object[key], object)
    })

    this.listReplace({
      data: _.assign(object, data),
    })
  }

  listSetAndNotify({ data, message, type }) {
    this.listSet(data)
    this.props.actions.closeModal()
    this.props.actions.notify({ message, type })
  }

  listAddAndNotify({ data, message, type }) {
    this.listAdd({ data })
    this.props.actions.closeModal()
    this.props.actions.notify({ message, type })
  }

  listReplaceAndNotify({ data, message, type }) {
    this.listReplace({ data })
    this.props.actions.closeModal()
    this.props.actions.notify({ message, type })
  }

  listRemoveAndNotify({ id, message, type }) {
    this.listRemove({ id })
    this.props.actions.closeModal()
    this.props.actions.notify({ message, type })
  }

  getList = key => {
    const { list } = this.state.data
    return key ? _.map(list, key) : list
  }

  getDataHeader = key => _.get(this.state.data.metadata, key)

  getListObjectById = id => {
    const list = this.getList()
    return _.find(list, obj => obj.id === id)
  }

  updateObjectInList = obj =>
    this.listSet(list => list.map(item => (item.id === obj.id ? _.merge(item, obj) : item)))

  /* Object */

  doObjectRetrieve(forceId) {
    const resource = this.resources.object

    if (!resource) {
      throw new Error('Resource for the `retrieve` is not provided')
    }

    this.setLoading('object', true)

    const id = forceId || this.retrieveId || this.props.params.id
    const func = this.objectResourceAction || 'retrieve'

    const opts = {
      id,
      onSuccess: this.onObjectRetrieve.bind(this),
    }

    if (this.objectQuery) {
      opts.query = this.objectQuery
    }

    if (this.objectScope) {
      const data = _.isFunction(this.objectScope) ? this.objectScope() : this.objectScope
      _.assign(opts, { data })
    }

    resource[func](opts)
  }

  doObjectArchive() {
    const resource = this.resources.object

    if (!resource) {
      throw new Error('Resource for the `archive` is not provided')
    }

    const id = this.retrieveId || this.props.params.id

    resource.archive({
      id,
      onSuccess: this.onObjectArchive.bind(this),
    })
  }

  doObjectUpdate(data) {
    const resource = this.resources.object

    if (!resource) {
      throw new Error('Resource for the `update` is not provided')
    }

    const id = this.retrieveId || this.props.params.id

    resource.update({
      id,
      data,
      onSuccess: this.onObjectUpdate.bind(this),
    })
  }

  onObjectRetrieve({ data, headers }) {
    if (!this.mounted) {
      return
    }

    this.objectSet(data, headers).then(() => {
      this.setLoading('object', false)
      this.afterHook('ObjectRetrieve', data)
    })
  }

  onObjectArchive() {
    const { name } = this.state.data.object
    let { pathname } = this.props.location

    const index = pathname.search(/\/\d(?!([\s\S]+\/\d))/)

    if (index !== -1) {
      pathname = pathname.slice(0, index)
    }

    this.props.actions.notifyAndRedirect({
      message: `${name} has been deleted`,
      type: 'warning',
      location: pathname,
    })
  }

  onObjectUpdate({ data }) {
    this.objectSet(data)
  }

  objectSet(data, metadata) {
    const { mapValues } = this

    return new Promise(resolve =>
      this.setState(state => {
        let object = data

        if (typeof object === 'function') {
          object = object(state.data.object || {})
        } else {
          object = _.clone(object)
        }

        if (mapValues) {
          object = _.mapSomeValues([object], mapValues)[0]
        }

        const headers = _.assign(state.data.metadata, metadata || {})
        return {
          data: _.assign(state.data, { object, headers }),
        }
      }, resolve)
    )
  }

  objectMerge(data) {
    return this.objectSet(object => _.merge(object, data))
  }

  getObject = (key, fallback) => {
    const { object } = this.state.data
    if (key && object) {
      return _.get(object, key, fallback)
    }
    return object
  }

  setObject = (key, val) => {
    const { object } = this.state.data
    _.set(object, key, val)
    this.objectSet(object)
  }

  /* State */

  updateState = (key, callback) => ({ data }) => {
    this.setState(
      {
        [key]: data,
      },
      callback
    )
  }

  updateStateSimple = key => val => {
    this.setState({
      [key]: val,
    })
  }

  updateStateFromInput = key => e => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value

    this.setState(state => {
      _.set(state, key, value)
      return state
    })
  }

  toggleState = key => e => {
    this.setState({
      [key]: !this.state[key],
    })
    if (e.target && e.target.blur) {
      e.target.blur()
    }
  }

  setStateTo = (key, val) => () => {
    this.setState(state => _.set(state, key, val))
  }

  setStateByDot = (key, val) => {
    this.setState(_.set(this.state, key, val))
  }

  setLoading(name, flag) {
    if (typeof flag !== 'boolean') return

    const index = this.loadings.indexOf(name)

    if (flag && index !== -1) return
    if (!flag && index === -1) return

    if (flag) {
      if (!this.loadings.length) {
        const isLongLoading = this.expectedLoadingTime === 'long'
        this.setState(state => ({
          data: _.assign(state.data, { isLoading: true }),
          showLoading: isLongLoading,
        }))
        if (!isLongLoading) {
          this.doShowLoading()
        }
      }

      this.loadings.push(name)
    } else {
      this.loadings.splice(index, 1)

      if (!this.loadings.length) {
        this.setState(state => ({
          data: _.assign(state.data, { isLoading: false }),
          showLoading: false,
        }))
        this.doShowLoading.cancel()
      }
    }
  }

  wrapWithLoading(key, func) {
    return new Promise((resolve, reject) =>
      this.setState({ [key]: true }, () => {
        const promise = func()
        promise.then((...args) => this.setState({ [key]: false }, () => resolve(...args)))
        promise.catch(reject)
      })
    )
  }

  /* Util */

  openModal = (...args) => this.props.actions.openModal(...args)
  closeModal = opts => this.props.actions.closeModal(opts)
  routePush = (...args) => this.props.actions.routePush(...args)

  notify = n => {
    this.props.actions.notify(n)
  }

  getModalDataId(props) {
    const hash = _.get(props, 'location.hash')
    if (!hash) {
      return null
    }

    const modalId = hash.split('/', 1)[0].substring(1)
    let modalDataId = _.last(modalId.split(':'))
    if (modalDataId) {
      modalDataId = _.last(modalDataId.split('-')) // some modals have `type-${id}` format
    }
    modalDataId = parseInt(modalDataId, 10)

    return _.isNaN(modalDataId) ? null : modalDataId
  }

  routePrefix(route = '') {
    let prefix

    switch (this.context.user.role) {
      case 'root':
        prefix = '/super'
        break
      case 'federation':
        prefix = '/federation'
        break
      default:
        prefix = ''
    }

    return `${prefix}/${_.trim(route, '/')}`
  }

  /* Pagination */

  getPagination = () => {
    if (_.isFunction(this.pagination)) {
      return this.pagination()
    }
    return this.pagination
  }

  goToPage = page => {
    const { routePush } = this.props.actions
    const pagination = this.getPagination()
    routePush(`${pagination.uri}/${page}`)
  }

  /* Modal */
  notifyAndClose = (n, opts = {}) => {
    this.props.actions.notify(n)
    this.props.actions.closeModal(opts)
  }

  /* Counter */
  increaseCounter = func => {
    const counter = (this.state.counter || 0) + 1
    this.setState({ counter }, func && func())
    return counter
  }

  getCounter = () => this.state.counter || 0

  /* Rendering */

  wrapperComponents() {
    return [<Content />, <Grid />]
  }

  renderAlerts = () => null

  renderHeader() {
    if (this.tabHeader) {
      return this.renderTabHeader()
    }
    if (this.getBreadcrumbHeader) {
      return this.renderBreadCrumbHeader()
    }

    const actions = this.renderHeaderActions()
    return (
      <Header
        preActions={this.preActions}
        actions={actions}
        search={this.search}
        disabled={this.shouldDisableHeaderButtons()}
      >
        {typeof this.header === 'function' ? this.header() : this.header}
      </Header>
    )
  }

  renderTabHeader = () => {
    if (this.getBreadcrumbHeader) {
      return <div className="heading-block">{this.renderBreadCrumbHeader()}</div>
    }

    return (
      <div className="heading-block">
        <div className="pull-right">{this.renderHeaderActions()}</div>
        <h3>{this.header}</h3>
      </div>
    )
  }

  renderBreadCrumbHeader = () => {
    const breadcrumbs = this.getBreadcrumbHeader()
    if (_.isNull(breadcrumbs)) {
      return null
    }

    const items = []
    _.each(breadcrumbs, (item, index) => {
      const key = `breadcrumb-item-${index}!`
      if (item.link) {
        items.push(
          <BreadcrumbItem key={key} link={item.link}>
            {item.title}
          </BreadcrumbItem>
        )
      } else {
        items.push(<BreadcrumbItem key={key}>{item.title}</BreadcrumbItem>)
      }
    })
    return <BreadcrumbHeader actions={this.renderHeaderActions()}>{items}</BreadcrumbHeader>
  }

  renderListModal = () => {
    const { list } = this.state.data
    const itemData = _.find(list, { id: this.modalDataId })
    if (!itemData || !this.mapListToModals) {
      return null
    }
    return this.mapListToModals(itemData)
  }

  renderModals = () => {
    if (this.isDataLoading()) {
      return null
    }

    return (
      <div>
        {this.renderObjectModals && this.renderObjectModals()}
        {this.renderListModal()}
      </div>
    )
  }

  headerActions = () => []

  renderHeaderActions = () => {
    const { openModal } = this.props.actions
    const actions = this.headerActions()

    if (_.size(actions) === 0) {
      return null
    }

    return (
      <ActionGroup>
        {_.map(actions, (props, i) => {
          const propsSubset = _.assign(
            {},
            {
              onClick: openModal(props.modal),
              children: props.message,
              disabled: this.shouldDisableHeaderButtons() || props.disabled,
            },
            _.omit(props, 'modal', 'message')
          )
          return <Action key={i} {...propsSubset} />
        })}
      </ActionGroup>
    )
  }

  renderDownloadAction = tableName => (
    <TableAction
      icon="download"
      disabled={this.shouldShowLoading()}
      onClick={downloadCSV(tableName)}
    />
  )

  renderButtonBar({ tableName }) {
    const shouldShowLoading = this.shouldShowLoading()

    return (
      <Row>
        <Col sm={9} lg={9}>
          {'dateRange' in this.props && !this.hideTermSelector && (
            <TermSelector halfYears={this.halfYears} className="pull-left m-r-2" />
          )}
          {this.renderPagination()}
        </Col>
        <Col sm={3} lg={3}>
          <ButtonBar className="footer-toolbar">
            <PrintAction disabled={shouldShowLoading} />
            {tableName && this.renderDownloadAction(tableName)}
          </ButtonBar>
        </Col>
      </Row>
    )
  }

  renderWrapper = (body, components) => {
    if (!components) {
      // eslint-disable-next-line no-param-reassign
      components = this.wrapperComponents()
    }

    components.push(body)
    return _.reduceRight(components, (children, parent) => React.cloneElement(parent, { children }))
  }

  renderEmptyState = () => {
    const { openModal } = this.props.actions

    if (!this.emptyState) {
      return null
    }

    let props = this.emptyState()
    if (_.isFunction(props)) {
      const { object } = this.state.data
      if (_.isNull(object)) {
        return null
      }
      props = props(object)
    }

    props.onClick = props.onClick || openModal(props.modal)

    return this.renderWrapper(
      <div>
        <EmptyState {...props} />
      </div>
    )
  }

  renderPagination = () => {
    const pagination = this.getPagination()

    if (!pagination) {
      return null
    }

    const { viewAll } = pagination
    const { page, metadata } = this.state.data

    if (_.anyAreNil(metadata, page)) {
      return null
    }

    if (+metadata.paginationTotalCount <= +metadata.paginationPerPage) {
      return null
    }

    const pageCount = Math.ceil(+metadata.paginationTotalCount / +metadata.paginationPerPage)

    return (
      <div className="pagination-bar pull-left">
        <Pagination
          prev
          next
          boundaryLinks
          maxButtons={5}
          items={pageCount}
          activePage={+page}
          onSelect={this.goToPage}
          className="pagination-footer"
        />
        {viewAll && <Button>View all</Button>}
      </div>
    )
  }

  tableWrapperComponents() {
    return [<Portlet boxed />, <Body table />]
  }

  getTableName({ table }) {
    return _.get(table, 'props.csvName')
  }

  renderTableWrapper = () => {
    const table = this.renderTable()
    const tableName = this.getTableName({ table })

    return this.renderWrapper(
      <div className={this.getWrapperClassName()}>
        {this.renderAlerts()}
        {this.renderHeader()}
        {this.renderWrapper(<div>{table}</div>, this.tableWrapperComponents())}
        {this.renderButtonBar({ tableName })}
      </div>
    )
  }

  renderSettingsTableWrapper = () => {
    const table = this.renderTable()
    const tableName = _.get(table, 'props.csvName')

    return (
      <div className={this.getWrapperClassName()}>
        {this.renderAlerts()}
        {this.renderTabHeader()}
        {table}
        {this.renderButtonBar({ tableName })}
      </div>
    )
  }

  renderGeneric = () =>
    this.renderWrapper(
      <div>
        {this.renderAlerts()}
        {this.renderHeader()}
        {this.renderContent()}
      </div>
    )

  render() {
    const { list: listResource } = this.resources
    const { list, isLoading } = this.state.data

    let content = null

    // empty state is usually rendered inside table
    // but not if renderEmptyStateOutsideTable is set
    if (
      listResource &&
      !isLoading &&
      _.isEmpty(list) &&
      (!this.renderTable || this.renderEmptyStateOutsideTable)
    ) {
      const emptyState = this.renderEmptyState()
      if (_.isObject(emptyState)) {
        content = emptyState
      }
    }

    if (content == null) {
      if (this.renderTable) {
        content = this.renderTableWrapper()
      } else {
        content = this.renderGeneric()
      }
    }

    return (
      <div>
        {content}
        {this.renderModals()}
      </div>
    )
  }
}
