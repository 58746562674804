/* eslint-disable react/no-multi-comp, no-class-assign, new-cap, no-shadow, react/no-find-dom-node */
import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import classnames from 'classnames'
import { connect } from 'helpers'

import { Link } from 'react-router'
import { Avatar, Name, QuickNavigation } from 'components/utilities'
import Next from './next'

const { object, bool, func, string, shape } = PropTypes

class HeaderNav extends React.Component {
  state = {
    headerNavVisible: false,
  }

  render = () => (
    <div className="navbar-header-right">
      <NavbarCollapse visible={this.state.headerNavVisible} />

      <NavbarSmall
        onToggleCollapse={() => {
          this.setState(state => ({
            headerNavVisible: !state.headerNavVisible,
          }))
        }}
      />
    </div>
  )
}

class NavbarCollapse extends React.Component {
  static contextTypes = {
    organization: shape({
      secondary_logo: shape({
        public_url: string.isRequired,
      }),
      primary_logo: shape({
        public_url: string.isRequired,
      }),
    }),
    user: shape({
      role: string.isRequired,
      parent: shape({
        user: shape({
          first_name: string.isRequired,
          last_name: string.isRequired,
        }),
      }),
      member: shape({
        unique_id: string.isRequired,
      }),
    }),
    federation: shape({
      secondary_logo: shape({
        public_url: string.isRequired,
      }),
      primary_logo: shape({
        public_url: string.isRequired,
      }),
    }),
  }

  static propTypes = {
    visible: bool.isRequired,
    dropdown: object,
  }

  static defaultProps = {
    dropdown: {},
  }

  renderName = () => {
    const { user } = this.context

    if (['member', 'admin', 'alumni'].includes(user.role)) {
      return <Avatar user={user} className="navbar-profile-avatar m-r-1" />
    }

    if (user.role === 'parent') {
      const { member } = user.parents[0]
      return (
        <span className="m-r-1 no-avatar">
          Parent of {member.user.first_name} {member.user.last_name}
        </span>
      )
    }

    return (
      <span className="m-r-1 m-l-1 no-avatar">
        <Name user={user} firstInitial />
      </span>
    )
  }

  settingLink = () => {
    const {
      user: { role },
    } = this.context
    let url = '/settings'

    if (role === 'parent') {
      url = '/parent/settings'
    } else if (role === 'alumni') {
      url = '/alumni/settings'
    }

    return url
  }

  render = () => {
    const {
      organization,
      user: { federation, member, alumni, role, id },
    } = this.context
    const { dropdown } = this.props
    const classes = {
      'navbar-collapse': true,
      'navbar-collapse__is-visible': this.props.visible,
    }

    const logoFederation = federation || _.get(alumni, 'organization.federation')
    const primaryLogo = organization.primary_logo || (logoFederation && logoFederation.primary_logo)
    const secondaryLogo =
      organization.secondary_logo || (logoFederation && logoFederation.secondary_logo)

    return (
      <div className={classnames(classes)}>
        {['root', 'admin', 'federation'].includes(role) ? <QuickNavigation /> : null}

        <ul className="nav navbar-nav navbar-right navbar-collapse__user">
          <li className="divider"></li>

          {member ? (
            <li>
              <a className="member-id no-style">Member ID: {member.unique_id}</a>
            </li>
          ) : null}

          {(id === 2 || id === 3) && (
            <li>
              <Next />
            </li>
          )}

          <li className="dropdown navbar-profile" data-open={dropdown.isOpen}>
            <div className="dropdown-toggle" role="button" {...dropdown.toggle}>
              {this.renderName()}
              <i className="fa fa-caret-down"></i>
            </div>

            <ul className="dropdown-menu" role="menu">
              {role === 'root' ? null : (
                <li>
                  <Link to={this.settingLink()}>
                    <i className="fa fa-user"></i>
                    &nbsp; My account
                  </Link>
                </li>
              )}

              {role === 'root' ? null : <li className="divider"></li>}

              <li>
                <Link to="/logout">
                  <i className="fa fa-sign-out"></i>
                  &nbsp; Logout
                </Link>
              </li>
            </ul>
          </li>
        </ul>

        {/*
        <ul className="nav navbar-nav navbar-right navbar-collapse__notifications">

          <li className="dropdown navbar-notification">
            <Link to="./page-notifications.html" className="dropdown-toggle">
              <i className="fa fa-bell navbar-notification-icon"></i>
              <span className="visible-xs-inline">&nbsp;Notifications</span>
              <b className="badge badge-primary">2</b>
            </Link>

            <div className="dropdown-menu">

              <div className="dropdown-header">&nbsp;Notifications</div>

              <div className="notification-list">

                <Link to="./page-notifications.html" className="notification">
                  <span className="notification-icon"><i className="fa fa-cloud-upload text-primary"></i></span>
                  <span className="notification-title">Notification Title</span>
                  <span className="notification-description">Praesent dictum nisl non est sagittis luctus.</span>
                  <span className="notification-time">20 minutes ago</span>
                </Link>

                <Link to="./page-notifications.html" className="notification">
                  <span className="notification-icon"><i className="fa fa-ban text-secondary"></i></span>
                  <span className="notification-title">Notification Title</span>
                  <span className="notification-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit...</span>
                  <span className="notification-time">20 minutes ago</span>
                </Link>

                <Link to="./page-notifications.html" className="notification">
                  <span className="notification-icon"><i className="fa fa-warning text-tertiary"></i></span>
                  <span className="notification-title">Storage Space Almost Full!</span>
                  <span className="notification-description">Praesent dictum nisl non est sagittis luctus.</span>
                  <span className="notification-time">20 minutes ago</span>
                </Link>

                <Link to="./page-notifications.html" className="notification">
                  <span className="notification-icon"><i className="fa fa-ban text-danger"></i></span>
                  <span className="notification-title">Sync Error</span>
                  <span className="notification-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit...</span>
                  <span className="notification-time">20 minutes ago</span>
                </Link>

              </div>

              <Link to="./page-notifications.html" className="notification-link">View All Notifications</Link>

            </div>

          </li>
          <li className="dropdown navbar-notification">

            <Link to="./page-notifications.html" className="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown">
              <i className="fa fa-envelope navbar-notification-icon"></i>
              <span className="visible-xs-inline">&nbsp;Messages</span>
              <b className="badge badge-warning">5</b>
            </Link>

            <div className="dropdown-menu">

              <div className="dropdown-header">Messages</div>

              <div className="notification-list">

                <Link to="./page-notifications.html" className="notification">
                  <div className="notification-icon"><img alt="" /></div>
                  <div className="notification-title">New Message</div>
                  <div className="notification-description">Praesent dictum nisl non est sagittis luctus.</div>
                  <div className="notification-time">20 minutes ago</div>
                </Link>

                <Link to="./page-notifications.html" className="notification">
                  <div className="notification-icon"><img alt="" /></div>
                  <div className="notification-title">New Message</div>
                  <div className="notification-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit...</div>
                  <div className="notification-time">20 minutes ago</div>
                </Link>

                <Link to="./page-notifications.html" className="notification">
                  <div className="notification-icon"><img alt="" /></div>
                  <div className="notification-title">New Message</div>
                  <div className="notification-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit...</div>
                  <div className="notification-time">20 minutes ago</div>
                </Link>

                <Link to="./page-notifications.html" className="notification">
                  <div className="notification-icon"><img alt="" /></div>
                  <div className="notification-title">New Message</div>
                  <div className="notification-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit...</div>
                  <div className="notification-time">20 minutes ago</div>
                </Link>

              </div>

              <Link to="./page-notifications.html" className="notification-link">View All Messages</Link>

            </div>

          </li>
          <li className="navbar-divider"></li>
        </ul>
         */}

        {(secondaryLogo || primaryLogo) && (
          <ul key={1} className="nav navbar-nav navbar-right navbar-collapse__logos">
            {secondaryLogo && (
              <li className="navbar-logo">
                <img role="presentation" src={secondaryLogo.public_url} width={48} height={48} />
              </li>
            )}
            {primaryLogo && (
              <li className="navbar-logo" style={{ marginRight: alumni ? 12 : 0 }}>
                <img role="presentation" src={primaryLogo.public_url} width={48} height={48} />
              </li>
            )}
          </ul>
        )}

        {member && organization && (
          <ul key={2} className="nav navbar-nav navbar-right navbar-collapse__user-id">
            <li className="divider" />
            <li>
              <a className="member-id p-r-0 no-style">Member ID: {member.unique_id}</a>
            </li>
          </ul>
        )}
      </div>
    )
  }
}

class NavbarSmall extends React.Component {
  static contextTypes = {
    user: shape({
      role: string.isRequired,
    }),
  }

  static propTypes = {
    dropdown: object,
    onToggleCollapse: func,
  }

  static defaultProps = {
    dropdown: {},
  }

  settingLink = () => {
    const {
      user: { role },
    } = this.context
    let url = '/settings'

    if (role === 'parent') {
      url = '/parent/settings'
    } else if (role === 'alumni') {
      url = '/alumni/settings'
    }

    return url
  }

  render() {
    const { dropdown } = this.props
    const { user } = this.context

    return (
      <div className="navbar-header-small">
        <div className="dropdown header-nav__user-dropdown" data-open={dropdown.isOpen}>
          <div className="dropdown-toggle" role="button" {...this.props.dropdown.toggle}>
            <Avatar user={user} className="navbar-profile-avatar m-r-1" />
            <i className="fa fa-caret-down"></i>
          </div>

          <ul className="dropdown-menu dropdown-menu-right" role="menu">
            <li>
              <Link to={this.settingLink()}>
                <i className="fa fa-user"></i>
                &nbsp;&nbsp;My account
              </Link>
            </li>

            <li className="divider"></li>

            <li>
              <Link to="/logout">
                <i className="fa fa-sign-out"></i>
                &nbsp;&nbsp;Logout
              </Link>
            </li>
          </ul>
        </div>

        <div className="navbar-toggle" role="button" onClick={this.props.onToggleCollapse}>
          <span className="sr-only">Toggle navigation</span>
          <i className="fa fa-cog"></i>
        </div>
      </div>
    )
  }
}

NavbarCollapse = DropdownWrapper(NavbarCollapse)
NavbarSmall = DropdownWrapper(NavbarSmall)

function DropdownWrapper(Component) {
  return class DropdownWrapper extends React.Component {
    static contextTypes = {
      router: object.isRequired,
    }

    state = {
      isOpen: false,
    }

    listenOutside = () => {
      document.addEventListener('click', this.outsideListener, true)
    }

    unlistenOutside = () => {
      document.removeEventListener('click', this.outsideListener, true)
    }

    outsideListener = e => {
      if (!this.state.isOpen) {
        return
      }

      const node = this.toggleRef && ReactDOM.findDOMNode(this.toggleRef)

      if (!node) {
        return
      }

      if (node !== e.target && !node.contains(e.target)) {
        this.closeDropdown()
      }
    }

    closeDropdown = () => {
      this.unlistenOutside()

      this.setState({
        isOpen: false,
      })
    }

    toggleRef = null

    onRef = ref => {
      this.toggleRef = ref
    }

    onClick = () => {
      this.setState(state => {
        const shown = !state.isOpen

        if (shown) {
          this.listenOutside()
        } else {
          this.unlistenOutside()
        }

        return {
          isOpen: shown,
        }
      })
    }

    routerListener = null

    componentDidMount() {
      this.routerListener = this.context.router.listen(() => {
        this.closeDropdown()
      })
    }

    componentWillUnmount() {
      this.unlistenOutside()

      if (this.routerListener) {
        this.routerListener()
        this.routerListener = null
      }
    }

    render = () => (
      <Component
        {...this.props}
        dropdown={{
          toggle: {
            ref: this.onRef,
            onClick: this.onClick,
          },
          isOpen: this.state.isOpen,
        }}
      >
        {this.props.children}
      </Component>
    )
  }
}

export default connect(HeaderNav)
