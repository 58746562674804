import React from 'react';
import PropTypes from 'prop-types';
import { modal } from 'helpers';

import { Modal } from 'react-bootstrap';
import { Typeahead } from 'components/typeahead';
import { Form, Field, SubmitButton } from 'components/form';

const { object, string } = PropTypes;

class SelectMembersModal extends React.Component {

  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    data: object.isRequired,
    valueProperty: string.isRequired,
  }

  static defaultProps = {
    valueProperty: 'id',
  }

  constructor(props) {
    super(props);

    let selected = props.selected;
    if (_.isEmpty(selected) && props.metadata.ids) {
      selected = _.map(props.metadata.ids.split(/-/), _.toNumber);
    }

    this.state = { selected };
  }

  onMemberChange = members => {
    const { valueProperty } = this.props;
    const selected = _.map(members, valueProperty);
    this.setState({ selected });
  }

  render() {
    const { closeModal } = this.props.actions;
    const { title, button, members, membersName } = this.props;
    const { selected } = this.state;
    const { valueProperty } = this.props;

    const formProps = _.pick(this.props, ['action', 'data', 'onSuccess']);
    const fieldProps = _.pick(this.props, ['name', 'label']);

    _.set(formProps.data, membersName, this.state.selected);

    return (
      <Modal show onHide={closeModal}>
        <Form {...formProps}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Field {...fieldProps} autoFocus />

            <Field label="Members">
              <Typeahead
                name="transaction.member_ids"
                multiple
                labelKey="name"
                placeholder="Select members..."
                multiplePlaceholder="Select members..."
                options={members || []}
                selected={_.filter(members, m => _.includes(selected, _.get(m, valueProperty)))}
                onChange={this.onMemberChange}
              />
            </Field>

          </Modal.Body>
          <Modal.Footer>
            <SubmitButton offset={5}>{button}</SubmitButton>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }

}

export default modal('SelectMembers', SelectMembersModal);
