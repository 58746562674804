import React from 'react'
import PropTypes, { object, oneOfType } from 'prop-types'
import cx from 'classnames'
import { modal } from 'helpers'

import { Modal, Button } from 'react-bootstrap'

const { bool, func, string } = PropTypes

class ConfirmModal extends React.Component {
  static propTypes = {
    prompt: oneOfType([string, object]).isRequired,
    yes: string.isRequired,
    showLoading: bool,
    onConfirm: func.isRequired,
  }

  state = {
    loading: false,
  }

  onConfirm = () => {
    const { id, showLoading } = this.props
    this.props.onConfirm({ id })
    if (showLoading) {
      this.setState({ loading: true })
    } else {
      this.props.actions.closeModal()
    }
  }

  render() {
    const { prompt, yes } = this.props

    return (
      <Modal show onHide={this.props.actions.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Double checking&hellip;</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <hr className="spacer-xs" />
          <h6 className="text-center m-b-1">{prompt}</h6>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="primary"
            onClick={this.onConfirm}
            className={cx({
              'btn-loading': this.state.loading,
            })}
            disabled={this.state.loading}
          >
            Yes, {yes}
          </Button>

          <Button onClick={this.props.actions.closeModal} autoFocus>
            No, cancel
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default modal('Confirm', ConfirmModal)
