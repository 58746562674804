import moment from 'moment';
import { put, select } from 'redux-saga/effects';
import { setDateRange } from 'actions/date-range';

export function* setFromTerms() {
  const { terms } = yield select(state => state.get('terms'));

  const term = _.find(terms, (t) => {
    const startedOn = moment(_.get(t, 'started_on'));
    return startedOn.isBefore();
  });

  const range = {
    start: moment(term.started_on),
    end: moment(term.ended_on).endOf('day'),
  };

  yield put(setDateRange(range));
}
