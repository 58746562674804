import T from './types';

const tutorials = {
  budgets: {
    prompt: 'Watch budgets video',
    title: 'Keep track of your money',
    video: 'https://view.vzaar.com/617971/player?autoplay=false',
    action: 'Create a budget',
    time: 200,
  },
  documents: {
    prompt: 'Watch documents video',
    title: 'Keep your chapter organized',
    video: 'https://view.vzaar.com/617971/player?autoplay=false',
    action: 'Create a documents folder',
    time: 234,
  },
  voting: {
    prompt: 'How to hold votes',
    title: 'Voting on GCM',
    video: 'https://view.vzaar.com/617971/player?autoplay=false',
    action: 'Create a vote',
    time: 381,
  },
};

export function updateTutorial(tutorial) {
  return {
    type: T.TUTORIAL,
    tutorial: tutorials[tutorial],
  };
}
