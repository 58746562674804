import React from 'react';

import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';

export default class Notifications extends React.Component {

  render() {
    return <NotificationContainer />;
  }
}
