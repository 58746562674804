import { subscribe, unsubscribe } from 'actions/subscriptions';
import { detectRouteChange, waitForUserAndFire } from 'middlewares/helpers';

const getSubscriptions = user => ([{
  channel: 'Poll::Channel::EndVoting',
  user_id: user.id,
}, {
  channel: 'Poll::Channel::Create',
  user_id: user.id,
}, {
  channel: 'PollVote::Channel::Create',
  user_id: user.id,
}]);

const votingRouteMatcher = /^\/((admin|alumni|federation)\/)*voting/;
const isMatchVoting = route => route && route.match(votingRouteMatcher);

export default function () {
  return (store) => next => action => {
    const { dispatch } = store;
    const routeChanges = detectRouteChange(store, action, votingRouteMatcher);
    const result = next(action);

    waitForUserAndFire(store, (loadedUser) => {
      if (routeChanges) {
        if (['admin', 'member', 'federation', 'alumni'].includes(loadedUser.role)) {
          if (isMatchVoting(routeChanges.prev.pathname)) {
            dispatch(unsubscribe(getSubscriptions(loadedUser)));
          } else if (isMatchVoting(routeChanges.next.pathname)) {
            dispatch(subscribe(getSubscriptions(loadedUser)));
          }
        }
      }
    });

    return result;
  };
}
