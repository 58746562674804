import T from './types';

export function subscribe(subscriptions) {
  return {
    type: T.SUBSCRIBE_REQUEST,
    subscriptions,
  };
}

export function unsubscribe(subscriptions) {
  return {
    type: T.UNSUBSCRIBE_REQUEST,
    subscriptions,
  };
}

export function subscriptionNotice(payload) {
  return {
    type: T.NOTIFICATION,
    payload,
  };
}
