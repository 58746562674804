import { call, put } from 'redux-saga/effects'
import { push } from 'react-router-redux'

import { apiSuccess, apiFailure } from 'actions/api'
import { ajax } from 'helpers'

export default function* api(params) {
  const method = params.method.toUpperCase()

  if (['GET', 'POST', 'PUT', 'DELETE'].indexOf(method) === -1) {
    const error = {
      status: 0,
      data: null,
      error: new Error(`Not supported API method: ${method}`),
    }

    throw error
  }

  try {
    const { response, data, headers } = yield call(ajax[method], {
      uri: params.uri,
      data: params.data,
    })

    return { status: response.status, data, headers }
  } catch (e) {
    const { response } = e

    if (response) {
      // Site went into maintenance mode
      if (response.status === 503) {
        window.location.reload()
        return {}
      }

      // JWT token has expired or is invalid
      if (response.status === 417) {
        window.location.reload()
        return {}
      }
    }

    let error

    if (response) {
      error = {
        status: response.status,
        data: e.data,
        error: e.error,
        headers: e.headers,
      }
    } else {
      error = {
        status: 0,
        data: null,
        error: e,
      }
    }

    throw error
  }
}

export function* request(action) {
  const identifier = action.identifier || _.uniqueId()
  let result

  try {
    const params = _.pick(action, ['uri', 'method', 'data'])
    result = yield api(params)
  } catch (error) {
    yield put(
      apiFailure({
        ..._.pick(action, ['uri', 'method']),
        ...error,
        identifier,
      })
    )

    const statusHandler = action.on && action.on[error.status]

    if (typeof statusHandler === 'function') {
      yield call(statusHandler, error)
      return
    }

    if (typeof action.onFailure === 'function') {
      yield call(action.onFailure, error)
    }

    if (typeof action.onFinally === 'function') {
      yield call(action.onFinally)
    }

    return
  }

  yield put(
    apiSuccess({
      ..._.pick(action, ['uri', 'method']),
      ...result,
      identifier,
    })
  )

  const statusHandler = action.on && action.on[result.status]

  if (typeof statusHandler === 'function') {
    yield call(statusHandler, result)
    return
  }

  if (typeof action.onSuccess === 'string') {
    yield put(push(action.onSuccess))
  } else if (typeof action.onSuccess === 'function') {
    yield call(action.onSuccess, result)
  }
}
