import apiWrapper from './helpers';

export default class Pledge extends apiWrapper('/pledges') {
  static statuses = [
    { code: 'newly', title: 'New' },
    { code: 'interested', title: 'Showed interest' },
    { code: 'extended', title: 'Voted to extend' },
    { code: 'declined', title: 'Declined' },
    { code: 'accepted', title: 'Accepted' },
  ]
}
