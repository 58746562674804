import T from '../actions/types'

export default (state = null, action) => {
  switch (action.type) {
    case T.FETCH_TERMS:
      return null

    case T.SET_TERMS:
      return _.pick(action, 'terms', 'organizationId')

    case T.CLEAR_TERMS:
      return null

    default:
      return state
  }
}
