import React from 'react';
import PropTypes from 'prop-types';
import Container from 'container';
import { modal } from 'helpers';
import { Payment } from 'resources';

import { Modal, Row, Col } from 'react-bootstrap';
import Form from 'components/form/form';
import Field from 'components/form/field';
import SubmitButton from 'components/form/submit-button';

const { string, shape, object, func } = PropTypes;

class RecordCheckModal extends Container {

  static contextTypes = {
    user: shape({
      role: string.isRequired,
    }).isRequired,
  }

  static propTypes = {
    member: object.isRequired,
    onSuccess: func.isRequired,
  }

  onSuccess = payment => {
    this.notify('The check has been recorded.');
    this.closeModal({ closeAll: true });
    this.props.onSuccess(payment);
  }

  render() {
    const { user } = this.context;
    const { member } = this.props;

    const data = {
      payment: {
        description: 'Check sent to GCM - Thank you.',
        source: `web/${user.role}`,
        member_id: member.id,
      },
    };

    return (
      <Modal show onHide={this.props.actions.closeModal}>
        <Form
          action={Payment.check}
          data={data}
          query={`
            payment {
              amount
              description
              external_ref
              created_at

              member {
                user {
                  first_name
                  last_name
                }
              }
            }
          `}
          onSuccess={this.onSuccess}
        >
          <Modal.Header closeButton>
            <Modal.Title>Record check payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <hr className="spacer-xs" />

            <Row>
              <Col md={7} mdPush={3}>
                Check payment for {member.user.first_name} {member.user.last_name}
                &nbsp; <span className="text-muted">{member.unique_id}</span>
                <hr className="spacer-xs" />
              </Col>
            </Row>

            <Field name="payment.amount" label="Amount" inputSize={4} money autoFocus />

            <Field name="payment.external_ref" label="Reference" placeholder="Check number for example (optional)" />

          </Modal.Body>
          <Modal.Footer>
            <SubmitButton offset={5}>Record payment</SubmitButton>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }

}

export default modal('RecordCheck', RecordCheckModal);
