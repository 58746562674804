import React from 'react';

import { Link } from 'react-router';
import { formatName } from 'helpers';

function WithLink({ to, onClick, children, className }) {
  if (to) {
    return <Link to={to} className={className}>{children}</Link>;
  }

  return (
    <span
      onClick={onClick}
      className={className}
    >
      {children}
    </span>);
}

export default function ({ user, to, onClick, className, format, short, full, natural, firstInitial }) {

  // eslint-disable-next-line no-param-reassign
  if (user.user) { user = user.user; }

  if (!user.last_name) {
    return <WithLink to={to} className={className}>{user.first_name}</WithLink>;
  }

  /* eslint-disable no-param-reassign */
  format = format || 'short';
  if (short) { format = 'short'; }
  if (full) { format = 'full'; }
  if (natural) { format = 'natural'; }
  if (firstInitial) { format = 'firstInitial'; }
  /* eslint-enable no-param-reassign */

  const formatted = formatName(user, format);
  return <WithLink to={to} onClick={onClick} className={className}>{formatted}</WithLink>;
}
