import React, { Component, Fragment } from 'react';
import { string } from 'prop-types';

import { ColorInput } from 'components/utilities';
import { Col, FormGroup, Radio } from 'react-bootstrap';

class CustomizePublicEvent extends Component {

  static propTypes = {
    backgroundColor: string,
    headerColor: string,
  };

  constructor(props) {
    super(props);

    const { backgroundColor, headerColor } = props;

    if (backgroundColor || headerColor) {
      this.state = { isCustomize: true };
    } else {
      this.state = { isCustomize: false };
    }
  }

  setCustomize = e => {
    const isCustomize = e.target.value === 'true';
    this.setState({ isCustomize });
  }

  renderSelectColor = () => {
    const { backgroundColor, headerColor } = this.props;

    return (
      <Fragment>
        <FormGroup>
          <label htmlFor="type" className="col-md-3 control-label">
            Header color
          </label>
          <ColorInput color={headerColor || '#2b3d4c'} name="event.header_color" />
        </FormGroup>
        <FormGroup>
          <label htmlFor="type" className="col-md-3 control-label">
            Background color
          </label>
          <ColorInput color={backgroundColor || '#f4f4f4'} name="event.background_color" />
        </FormGroup>
      </Fragment>
    );
  }

  render() {
    const { isCustomize } = this.state;

    return (
      <Fragment>
        <FormGroup>
          <label htmlFor="type" className="col-md-3 control-label">
           Customize the color scheme?
          </label>
          <Col md={7}>
            <Radio
              defaultChecked={isCustomize}
              name="event.is_customize"
              value="true"
              onChange={this.setCustomize}
           >
             Yes, customize the colors on the public registration page
           </Radio>

            <Radio
              defaultChecked={!isCustomize}
              name="event.is_customize"
              value="false"
              onChange={this.setCustomize}
           >
             No, keep the default color scheme
           </Radio>
          </Col>
        </FormGroup>

        {isCustomize && this.renderSelectColor()}

      </Fragment>
    );
  }
}

export default CustomizePublicEvent;
