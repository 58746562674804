import qs from 'qs';

function decorateWithJSON(response) {
  const headers = Array.from(response.headers)
    .filter(h => h[0].match(/^x-/))
    .reduce((result, header) => {
      const camelCasedHeader = _.camelCase(header[0].substring(2));
      // eslint-disable-next-line no-param-reassign
      result[camelCasedHeader] = header[1];
      return result;
    }, {});

  return response
    .json()
    .then(data => ({ response, data, headers }))
    .catch(error => Promise.reject({ response, error, headers }));
}

function authenticate(opts) {
  const token = localStorage.getItem('token');
  if (!token) { return opts; }
  const dup = Object.assign({ headers: {} }, opts);
  dup.headers.Authorization = `Bearer ${token}`;
  return dup;
}

export function POST({ uri, data }) {
  const opts = authenticate({
    method: 'post',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  return fetch(uri, opts)
    .then(handleResponse);
}

export function GET({ uri, data }) {

  const opts = authenticate({
    method: 'get',
    headers: {
      Accept: 'application/json',
    },
  });

  let url = uri;
  if (!_.isEmpty(data)) {
    const query = qs.stringify(data, { arrayFormat: 'brackets' });
    url = `${uri}?${query}`;
  }

  return doAjax(url, opts)
    .catch(() => doAjax(url, opts))
    .catch(() => sleep(500))
    .catch(() => doAjax(url, opts))
    .catch(() => sleep(1000))
    .catch(() => doAjax(url, opts))
    .then(handleResponse);
}

export function PUT({ uri, data }) {
  const opts = authenticate({
    method: 'put',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  return fetch(uri, opts)
    .then(handleResponse);
}

export function DELETE({ uri, data }) {
  const opts = authenticate({
    method: 'delete',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  return fetch(uri, opts)
    .then(handleResponse);
}

function sleep(ms) {
  return new Promise(r => setTimeout(r, ms));
}

function doAjax(url, opts) {
  return new Promise((resolve, reject) => fetch(url, opts).then(resolve, reject));
}

function handleResponse(response) {
  const { status } = response;

  if (status === 204) {
    return Promise.resolve({ response });
  }

  return decorateWithJSON(response).then((payload) => {
    if (!response.ok) {
      throw payload;
    }

    return payload;
  });
}
