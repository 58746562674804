import { apiRequest } from 'actions/use';
import apiWrapper, { generatePayload } from './helpers';

export default class BudgetStartingBalance extends apiWrapper('/budget_starting_balances') {

  static set({ data, query, onSuccess, onFailure }) {
    apiRequest({
      uri: '/api/budget_starting_balances/set',
      method: 'PUT',
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    });
  }

}
