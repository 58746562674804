import React from 'react';
import ReactDOM from 'react-dom';

export default class Lightbox extends React.Component {
  state = {
    loading: true,
  };

  onImageLoaded = () => {
    this.setState({
      loading: false,
    });
  }

  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  onContainerClick = (e) => {
    /* eslint-disable react/no-find-dom-node */
    /* eslint-disable react/no-string-refs */
    if (e.target === ReactDOM.findDOMNode(this.refs.container)) {
      this.onClose();
    }
  }

  render() {
    const image = (<img
      role="presentation"
      hidden={this.state.loading ? true : null}
      src={this.props.image}
      onLoad={this.onImageLoaded}
      className="expbtn-image"
      ref={(img) => {
        if (!img) {
          return;
        }

        if (img.complete && this.state.loading) {
          this.setState({
            loading: false,
          });
        }
      }}
    />);

    /* eslint-disable react/no-string-refs */
    return (<div className="expbtn-container" ref="container" onClick={this.onContainerClick}>
      <div
        className="expbtn-loading"
        hidden={this.state.loading ? null : true}
      >
        Loading ...
      </div>
      {image}
      <div className="expbtn-header">
        <div className="expbtn-header-title">
          {this.props.title || ''}
        </div>
        <div className="expbtn-header-close" role="button" onClick={this.onClose}>
          <i className="fa fa-times" aria-hidden="true"></i>
        </div>
      </div>
    </div>);
  }
}
