import T from './types';

export function openModal(id, props = {}) {
  return {
    type: T.MODAL_OPEN,
    id,
    props,
  };
}

export function closeModal(opts = {}) {
  return {
    type: T.MODAL_CLOSE,
    closeAll: opts.closeAll,
  };
}
