import apiWrapper, { generatePayload, list } from './helpers';
import { apiRequest } from 'actions/use';

export default class EventRegistration extends apiWrapper('/event_registrations') {

  static retrievePublic({ id, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'GET',
      uri: `/api/event_registrations/${id}/retrieve_public`,
      data: generatePayload({ query }),
      onSuccess,
      onFailure,
    });
  }

  static createPublic({ data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'POST',
      uri: '/api/event_registrations/create_public',
      data: generatePayload({ data, query }),
      onSuccess,
      onFailure,
    });
  }

  static listByOwner(opts) {
    list('/event_registrations/list_by_owner', opts);
  }

  static approve({ id, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'PUT',
      uri: `/api/event_registrations/${id}/approve`,
      data: generatePayload({ query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    });
  }

  static deny({ id, data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'DELETE',
      uri: `/api/event_registrations/${id}/deny`,
      data: generatePayload({ data, query }),
      on: {
        204: onSuccess,
      },
      onFailure,
    });
  }

}
