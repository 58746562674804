import { loginCheck } from '../actions/authentication'
import { GCM_ENVIRONMENT, isGranted } from 'helpers'
import { fetchOrganization, clearOrganization } from '../actions/organization'

export function onAuthRequired(store, nextState, replace, callback) {
  const { dispatch } = store
  const auth = store.getState().get('auth')
  if (auth && auth.user) {
    callback()
    return
  }

  const { pathname, search, hash } = nextState.location
  dispatch(
    loginCheck({
      location: pathname + search + hash,
    })
  )
}

export function onAuthAndOrganizationRequired(store, nextState, replace, callback) {
  const { dispatch } = store

  const organizationIdStorageKey = 'organizationId'
  const organizationIdValue = sessionStorage.getItem(organizationIdStorageKey)
  const storedOrganizationId = _.isNull(organizationIdValue)
    ? null
    : parseInt(organizationIdValue, 10)

  const user = store.getState().get('auth').user
  const organization = store.getState().get('organization')

  const { pathname, search, hash } = nextState.location
  const location = pathname + search + hash

  // No auth
  if (_.isEmpty(user)) {
    dispatch(loginCheck({ location }))

    if (storedOrganizationId && _.isEmpty(organization)) {
      fetchOrganization(storedOrganizationId, location)
    }

    return
  }

  const member = user.role === 'parent' ? user.parents[0].member : user.member
  const organizationId =
    user.role === 'alumni' ? user.alumni.organization_id : _.get(member, 'organization_id')

  // User doesn't have a member so shouldn't have an organization
  if (_.isNil(member) && _.isNil(user.alumni)) {
    dispatch(clearOrganization())
    callback()
    return
  }

  // Auth exists already. Need to find organization only
  if (_.isEmpty(organization)) {
    dispatch(fetchOrganization(organizationId, location))
    return
  }

  // We have a correct organization, things are going well!
  if (storedOrganizationId === organizationId) {
    callback()
    return
  }

  // If organization in the store is not a member organization; edge case
  dispatch(fetchOrganization(organizationId, location))
}

export function onHomePage(store, nextState, replace) {
  const user = store.getState().get('auth').user

  if (user.role === 'root') {
    return replace('/super/organizations')
  }

  if (user.role === 'federation') {
    return replace('/federation/organizations')
  }

  if (user.member && user.member.is_admin) {
    return replace('/admin/members')
  }

  if (user.role === 'parent') {
    return replace('/parent/balance')
  }

  if (user.role === 'alumni') {
    return replace('/alumni')
  }

  return replace('/balance')
}

export function ensureRole(store, role) {
  return (nextState, replace) => {
    const user = store.getState().get('auth').user
    if (!user) return null

    if (user.role !== 'member' && user.role !== role) {
      return replace('/')
    }

    /**
     *
     */
    const dontTransfer = localStorage.getItem('dontTransfer')
    if (dontTransfer) return null

    const xfer = shouldTransfer(user)
    if (!xfer) return null

    if (window.xfering) return null
    window.xfering = true

    const payload = btoa(JSON.stringify(localStorage))
    const origin =
      GCM_ENVIRONMENT === 'production' ? 'next.greekcapitalmanagement.com' : 'gc.m:3000'
    const url = `https://${origin}${nextState.location.pathname}?ls=${payload}`
    window.location.href = url
    /**
     *
     */

    return null
  }
}

function shouldTransfer(user) {
  if (user.role === 'root') return true
  if (user.role === 'admin') return true
  if (user.role === 'member') return true
  if (user.role === 'parent') return true

  // Check if root user is whitelisted
  try {
    const rootToken = localStorage.getItem('rootToken')
    if (!rootToken) return false
    // const rootUserId = JSON.parse(atob(rootToken.split('.')[1])).sub
    // if (rootUserId === 2) return true
    if (user.member.organization.id === 1) return true

    // whitelisted admin orgs
    return [].includes(user.member.organization.id)
  } catch (e) { }

  return false
}

export function ensureFeature(store, feature) {
  return (nextState, replace) => {
    const user = store.getState().get('auth').user
    if (user && !isGranted(user, feature)) {
      return replace('/')
    }
    return null
  }
}
