const extensionsImages = {
  xlsx: 'xls',
  numbers: 'xls',
  docx: 'doc',
  pages: 'doc',
  jpeg: 'jpg',
  csv: 'xls',
};

export default function (extension) {
  const imageName = extensionsImages[extension] || extension;

  try {
    return require(`images/file-types/${imageName}.svg`); // eslint-disable-line
  } catch (e) {
    return false;
  }
}
