/* eslint-disable no-nested-ternary */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import actionProps from 'actions'
import Container from 'container'
import { Parent, User } from 'resources'
import {
  Portlet,
  Body,
  SettingsDropdown,
  Item,
  Divider,
  Email,
  Address,
  Phone,
  FaButton,
} from 'components/utilities'
import { ConfirmModal, InputModal } from 'components/modals'
import { EditParentModal } from 'components/parents'

import ParentBillDelivery from 'components/parents/bill-delivery'

const { bool, func, object, shape, arrayOf } = PropTypes

class ParentDetail extends Container {
  static contextTypes = {
    user: shape({
      is_root: bool,
    }),
  }

  static propTypes = {
    admin: bool.isRequired,
    onParentUpdate: func,
    onParentArchive: func,
    parent: object.isRequired,
    allParents: arrayOf(object).isRequired,
    member: object.isRequired,
    editPassword: bool,
  }

  static defaultProps = {
    admin: false,
    editPassword: false,
  }

  onUpdateBillDelivery = ({ data }) => this.props.onParentUpdate({ data })

  onPasswordUpdated = () => this.notifyAndClose({ message: 'Password has been updated!' })

  doUpdateBillDelivery = type => e => {
    const parent = _.pick(
      this.props.parent,
      'id',
      'send_email_bills',
      'send_physical_bills',
      'send_sms_reminders'
    )
    parent[type] = e.target.checked

    Parent.updateDeliverySettings({
      id: parent.id,
      query: `
        parent {
          send_email_bills
          send_physical_bills
          send_sms_reminders

          data { member_count }

          user {
            first_name
            last_name
            phone_number
            email

            address {
              address
              address_2
              city
              state
              postal_code
              country
            }
          }

          member {
            user {
              first_name
              last_name
            }
          }
        }
      `,
      data: { parent },
      onSuccess: this.onUpdateBillDelivery,
    })
  }

  doParentArchive = () => {
    const { parent, onParentArchive } = this.props

    Parent.archive({
      id: parent.id,
      onSuccess: () => onParentArchive(parent),
    })
  }

  render() {
    const { openModal } = this.props.actions
    const { parent, admin, allParents, member, preview, editPassword } = this.props

    const isRoot = this.context.user.is_root

    const forPrompt = parent.user.first_name
      ? `${parent.user.first_name} ${parent.user.last_name}`
      : 'them'
    const confirmProps = {
      prompt: `Are you sure you want to remove ${forPrompt} from ${
        admin ? 'their' : 'your'
      } account?`,
      yes: `remove ${parent.user.first_name || 'them'}`,
    }

    const morphButton = (
      <FaButton icon="user-secret" onClick={() => this.props.actions.morph(parent.user.id)}>
        Morph
      </FaButton>
    )

    const notSpecified = 'Not specified'

    const fullName = `${_.toString(parent.user.first_name)} ${_.toString(parent.user.last_name)}`
    const displayName = _.chain(fullName).trim().isEmpty().value() ? '[no name]' : fullName

    return (
      <Portlet className="portlet-posts portlet-with-table">
        <div className="portlet-header clearfix thin ">
          <h5 className="portlet-title">{displayName}</h5>
          {preview || (
            <div className="pull-right">
              {isRoot ? morphButton : null}
              &nbsp;
              <SettingsDropdown>
                <Item
                  disabled={parent.data.member_count > 1}
                  icon="pencil"
                  onClick={openModal('EditParent', parent.id)}
                >
                  Edit parent
                </Item>
                {editPassword && (
                  <Item
                    disabled={parent.data.member_count > 1}
                    icon="key"
                    onClick={openModal('Input', `UpdatePlainPassword${parent.user.id}`)}
                  >
                    Edit password
                  </Item>
                )}
                <Divider />
                <Item icon="ban" onClick={openModal('Confirm', parent.id)}>
                  Remove parent
                </Item>
              </SettingsDropdown>
            </div>
          )}
        </div>

        <Body>
          <dl className="dl-horizontal">
            <dt>Email</dt>
            <dd>
              {parent.user.email ? (
                <Email className="no-style">{parent.user.email}</Email>
              ) : preview ? (
                notSpecified
              ) : parent.data.member_count === 1 ? (
                <span
                  onClick={openModal('EditParent', parent.id)}
                  className="text-danger c-pointer"
                >
                  add email address
                </span>
              ) : (
                <span className="text-muted">no email address on file</span>
              )}
            </dd>
            <hr className="spacer-xs" />
            <dt>Phone number</dt>
            <dd>
              {parent.user.phone_number ? (
                <Phone className="no-style">{parent.user.phone_number}</Phone>
              ) : preview ? (
                notSpecified
              ) : parent.data.member_count === 1 ? (
                <span
                  onClick={openModal('EditParent', parent.id)}
                  className="text-danger c-pointer"
                >
                  add phone number
                </span>
              ) : (
                <span className="text-muted">no phone number on file</span>
              )}
            </dd>
            <hr className="spacer-xs" />
            <dt>Address</dt>
            <dd>
              {parent.user.address ? (
                <Address className="no-style" address={parent.user.address} />
              ) : preview ? (
                notSpecified
              ) : parent.data.member_count === 1 ? (
                <span
                  onClick={openModal('EditParent', parent.id)}
                  className="text-danger c-pointer"
                >
                  add address
                </span>
              ) : (
                <span className="text-muted">no address on file</span>
              )}
            </dd>
            <hr className="spacer-xs" />
            {preview || (
              <div>
                <dt>Bill delivery</dt>
                <dd>
                  <ParentBillDelivery
                    key={parent.id}
                    user={parent.user}
                    parent={parent}
                    allParents={allParents}
                    member={member}
                    onParentUpdate={this.props.onParentUpdate}
                  />
                </dd>
              </div>
            )}
          </dl>
        </Body>

        <EditParentModal
          id={parent.id}
          parent={parent}
          onParentUpdate={this.props.onParentUpdate}
        />

        <InputModal
          id={parent.user.id}
          hashPrefix="UpdatePlainPassword"
          title="Update password"
          button="Update password"
          label="New password"
          name="user.password"
          action={User.changePasswordPlain}
          onSuccess={this.onPasswordUpdated}
        />

        <ConfirmModal
          id={parent.id}
          {...confirmProps}
          onConfirm={this.doParentArchive.bind(this)}
        />
      </Portlet>
    )
  }
}

export default connect(null, actionProps)(ParentDetail)
