import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cx from 'classnames';
import { browserHistory } from 'react-router';
import Humanize from 'humanize-plus';

import { GlobalSearch } from 'resources';
import AsyncTypeahead from 'components/typeahead/AsyncTypeahead';

const RETURN_KEY_CODE = 13;
const { string, shape, number } = PropTypes;

export default class QuickNavigation extends PureComponent {
  static contextTypes = {
    user: shape({
      role: string.isRequired,
      member: shape({
        organization_id: number.isRequired,
      }),
      federation: shape({
        id: number.isRequired,
      }),
    }).isRequired,
  }

  constructor() {
    super();

    this.state = {
      loading: false,
      options: [],
      memberUniqueIdSearch: false,
    };

    this.searchTerm = null;
    this.requestNumber = 0;
  }

  getAction = () => {
    const { user } = this.context;

    return (user.role);
  }

  onSearch = (term) => {
    const { user } = this.context;

    const params = {};
    params.term = term;

    this.searchTerm = term;

    if (_.isObject(user.member)) {
      params.organization_id = user.member.organization_id;
    }

    if (user.role === 'federation') {
      params.federation_id = user.federation.id;
    }
    const requestNumber = ++this.requestNumber;
    this.setState({ loading: true }, () => {
      GlobalSearch[this.getAction()]({
        data: params,
        onSuccess: ({ data, headers }) => {
          if (requestNumber !== this.requestNumber) return;
          if (data.length >= 5) {
            data.splice(1, 0, { type: 'view_all' });
          }
          this.setState({
            loading: false,
            options: data,
            memberUniqueIdSearch: (headers.memberUniqueIdSearch === 'true'),
          });
        },
      });
    });
  }

  onChange = (items) => {
    this.navigateTo(items[0]);
  }

  onInputChange = () => {
    this.setState({ options: [], loading: true });
  }

  onKeyDown = (event) => {
    if (event.keyCode === RETURN_KEY_CODE) {
      // Hackish but it works
      // eslint-disable-next-line no-underscore-dangle
      const typeahead = this.typeahead._instance.refs.instance;
      const activeIndex = typeahead.activeIndex();

      if (activeIndex === 0) {
        const { options } = this.state;
        this.navigateTo(options[activeIndex]);
        typeahead.clear();
      }
    }
  }

  navigateTo = (item) => {
    if (_.isNil(item)) { return; }

    let link;
    switch (item.type) {
      case 'user': {
        const { user } = this.context;
        if (user.role === 'root') {
          if (['admin', 'member'].includes(item.role)) {
            if (item.member_parents_emails.indexOf(this.searchTerm) !== -1) {
              link = `/super/members/${item.member_id}/access`;
            } else {
              link = `/super/members/${item.member_id}`;
            }
          } else {
            link = `/super/alumni/${item.alumni_id}`;
          }
        } else if (user.role === 'federation') {
          if (item.role === 'alumni') {
            link = `/federation/alumni/${item.alumni_id}`;
          } else {
            link = `/federation/members/${item.member_id}`;
          }
        } else {
          link = `/admin/members/${item.member_id}`;
        }

        break;
      }
      case 'parent': {
        const { user } = this.context;
        if (user.role === 'root') {
          link = `/super/members/${item.member_id}/access`;
        } else {
          link = `/admin/members/${item.member_id}/access`;
        }

        break;
      }
      case 'transaction':
        link = `/super/members/${item.member_id}/transactions/${item.account_id}?highlighted_transaction_id=${item.id}`;
        break;
      case 'payment':
        link = `/super/members/${item.member_id}/transactions?highlighted_payment_id=${item.id}#PaymentDetails:${item.id}`;
        break;
      case 'view_all':
        link = `/super/search/${this.searchTerm}`;
        break;
      case 'organization':
        link = `/federation/organizations/${item.id}`;
        break;
      default:
        throw new TypeError(`Undefined item type: ${item.type}`);
    }

    browserHistory.push(link);
  }

  extractItemOptions = (item) => {
    switch (item.type) {
      case 'user': {
        const { memberUniqueIdSearch } = this.state;
        const title = memberUniqueIdSearch ? `${item.name} [${item.member_unique_id}]` : item.name;

        return {
          icon: 'fa-user',
          title,
          secondary: `${item.organization_name} at ${item.organization_secondary}`,
        };
      }
      case 'parent': {
        const title = <span>{item.first_name} {item.last_name} <em className="text-muted m-l-05 fs-90">parent</em></span>;

        return {
          icon: 'fa-user-o',
          title,
          secondary: `${item.organization_name} at ${item.organization_secondary}`,
        };
      }
      case 'transaction':
      case 'payment': {
        const sign = (item.amount > 0) ? '' : '+';
        const amount = `${sign}$${Humanize.formatNumber(Math.abs(item.amount), 2)}`;

        const createdAt = moment(item.created_at).format('MMM D');

        return {
          icon: 'fa-money',
          title: `${amount}, ${item.member_name}`,
          secondary: `${createdAt}: ${item.description}`,
        };
      }
      case 'organization':

        return {
          icon: 'fa-institution',
          title: `${item.name}`,
          secondary: `${item.secondary}`,
        };
      default:
        throw new TypeError(`Undefined item type: ${item.type}`);
    }
  }

  renderMenuItemChildren = (item) => {
    if (item.type === 'view_all') {
      return (
        <div
          className="navbar-search-result"
          key={'view_all'}
        >
          <div className="navbar-search-result-content text-danger">
            View All Results
          </div>
        </div>
      );
    }

    const { icon, title, subtitle, secondary } = this.extractItemOptions(item);

    return (
      <div
        className="navbar-search-result"
        key={item.id}
      >
        <i className={cx('fa', icon, 'navbar-search-result-icon')}></i>
        <div className="navbar-search-result-content">
          <div className="clearfix">
            <span className="strong pull-left">{title}</span>
            <span className="pull-left">{subtitle}</span>
          </div>
          <div>{secondary}</div>
        </div>
      </div>
    );
  }

  filterBy = () => true;
  labelKey = () => '';

  render() {
    const { options, loading } = this.state;

    return (
      <form className="navbar-form navbar-search-form navbar-left">
        <div
          className="form-group"
          onKeyDown={this.onKeyDown.bind(this)}
        >
          <AsyncTypeahead
            ref={(typeahead) => { this.typeahead = typeahead; }}
            options={options}
            loading={loading}
            onSearch={this.onSearch}
            onChange={this.onChange}
            onInputChange={this.onInputChange}
            filterBy={this.filterBy}
            labelKey={this.labelKey}
            defaultActiveIndex={0}
            useCache={false}
            renderMenuItemChildren={this.renderMenuItemChildren}
          />
        </div>
      </form>
    );
  }
}
