import { apiRequest } from 'actions/use'
import apiWrapper from './helpers'

export default class KappaSigma extends apiWrapper('/kappa_sigmas') {
  static addPledge({ data, onSuccess, onFailure }) {
    apiRequest({
      method: 'POST',
      uri: '/api/kappa_sigmas/add_pledge',
      data,
      onSuccess,
      onFailure,
    })
  }

  static initiateMember({ id, data, onSuccess, onFailure }) {
    apiRequest({
      method: 'PUT',
      uri: `/api/kappa_sigmas/${id}/initiate_member`,
      data,
      onSuccess,
      onFailure,
    })
  }

  static convertToAlumni({ id, data, onSuccess, onFailure }) {
    apiRequest({
      method: 'PUT',
      uri: `/api/kappa_sigmas/${id}/convert_to_alumni`,
      data,
      onSuccess,
      onFailure,
    })
  }
}
