import React from 'react';
import { func, number, object, oneOf, array } from 'prop-types';
import { connect } from 'helpers';
import Container from 'container';
import { PaymentMethod } from 'resources';

import { Row, Col, FormGroup, Radio, ButtonToolbar, Button } from 'react-bootstrap';
import { PaymentMethod as PayMethod } from 'components/utilities';
import { Field } from 'components/form';
import { AddCreditCardModal, AddBankAccountModal } from 'components/modals';
import RegistrationChargeInfo from './registration-charge-info';

class PaymentMethods extends Container {

  static propTypes = {
    costType: oneOf(['free', 'fixed', 'variable']).isRequired,
    totalCost: number,
    ticketsCost: number,
    addons: array,
    numberOfTickets: number.isRequired,
    selectedPaymentMethod: object,
    onPaymentMethodSelect: func.isRequired,
  }

  resources = {
    list: PaymentMethod,
  }

  listScope = 'user'

  listQuery = `
    payment_method {
      method
      company
      last_four
    }
  `

  afterResourceList = (paymentMethods) => (
    paymentMethods.length > 0 && this.props.onPaymentMethodSelect(paymentMethods[0])
  )

  onPaymentMethodCreate = (paymentMethod) => {
    this.listPrepend({ data: paymentMethod });
    this.props.onPaymentMethodSelect(paymentMethod);
  }

  renderPaymentMethods = () => {
    const paymentMethods = this.getList();
    const { selectedPaymentMethod, onPaymentMethodSelect } = this.props;

    return (
      <FormGroup className="m-l-05" >
        {_.map(paymentMethods, (method, i) => (
          <Radio
            key={i}
            name="payment_method_id"
            value={method.id}
            checked={method.id === _.get(selectedPaymentMethod, 'id')}
            onChange={() => onPaymentMethodSelect(method)}
          >
            <PayMethod
              paymentMethod={method}
            />
          </Radio>
        ))}
      </FormGroup>
    );
  }

  renderModals = () => (
    <div>
      <AddCreditCardModal
        onCreditCardCreate={this.onPaymentMethodCreate}
      />
      <AddBankAccountModal
        onBankAccountCreate={this.onPaymentMethodCreate}
      />
    </div>
  )

  render() {
    const { selectedPaymentMethod, costType, totalCost, numberOfTickets, addons, ticketsCost } = this.props;
    const paymentMethods = this.getList();

    if (_.anyAreNil(paymentMethods)) {
      return <hr />;
    }

    return (
      <div>
        <Field
          label="Payment method"
        >

          {this.renderPaymentMethods()}

          <ButtonToolbar>

            <Button
              onClick={this.openModal('AddCreditCard')}
              bsSize="sm"
            >
              Add credit card
            </Button>

            <Button
              onClick={this.openModal('AddBankAccount')}
              bsSize="sm"
            >
              Add bank account
            </Button>

          </ButtonToolbar>
        </Field>

        <Row>
          <Col
            md={9}
            mdPush={3}
            className="m-b-1 m-l-05"
          >
            <span>
              <RegistrationChargeInfo
                costType={costType}
                totalCost={totalCost}
                ticketsCost={ticketsCost}
                addons={addons}
                numberOfTickets={numberOfTickets}
                method={_.get(selectedPaymentMethod, 'method', '')}
              />
            </span>
          </Col>

          <Col
            md={9}
            mdPush={3}
            className="m-l-05 has-error"
          >
            <span className="help-block">
              {_.get({}, ['payment_method_id', 0])}
            </span>
          </Col>

        </Row>

        {this.renderModals()}

      </div>
    );
  }

}

export default connect(PaymentMethods);
