import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'helpers'

import Header from './header'
import Item from './item'
import SettingsTooltip from './alerts/settings-tooltip'
import Divider from './divider'
import OrganizationAdvisors from './organization-advisors'
import VotesAside from './votes-aside'

const { object, shape, number } = PropTypes

class AlumniMenu extends React.Component {
  static contextTypes = {
    organization: shape({
      alumni_group_id: number.isRequired,
    }),
  }

  static propTypes = {
    requiredActions: object.isRequired,
  }

  render = () => {
    const { organization } = this.context
    const { requiredActions } = this.props
    const { profile } = requiredActions

    return (
      <ul className="sidebar-menu">
        <Header>My Account</Header>
        <Item url="/alumni/donate" icon="bank">
          Donate
        </Item>
        <Item url="/alumni/settings" id="settings#alumni" icon="cog">
          Settings
          <SettingsTooltip profile={profile} />
        </Item>
        <Divider />
        <Header>{organization.federation.name}</Header>
        <Item url="/alumni/calendar" icon="calendar">
          Calendar
        </Item>
        <Item url="/alumni/document-folders" icon="folder">
          Documents
        </Item>
        <Item url="/alumni/event-registrations" icon="file-text-o">
          Events
        </Item>
        <Item url="/alumni/forms" icon="file-text-o">
          Forms
        </Item>
        <Item url="/alumni/message-board" icon="comment">
          Message Board
        </Item>
        <Item
          url="/alumni/voting"
          icon="gavel"
          aside={<VotesAside votes={requiredActions.votes} />}
          asideType="primary"
        >
          Voting
        </Item>
        <Item url={`/alumni/group-members/${organization.alumni_group_id}`} icon="users">
          Group members
        </Item>
        <Divider />
        <Header>Alumni activities</Header>
        <Item url="/alumni/groups" icon="users">
          Groups
        </Item>
        <Divider />
        <OrganizationAdvisors />
      </ul>
    )
  }
}

const mapStateToProps = state => ({
  requiredActions: state.get('requiredActions'),
})

export default connect(AlumniMenu, mapStateToProps)
