import _ from 'lodash'
import { takeEvery, takeLatest } from 'redux-saga'
import T from './actions/types'
import * as API from './sagas/api'
import * as Authentication from './sagas/authentication'
import * as DateRangeActions from './sagas/date-range'
import * as Download from './sagas/download'
import * as HalfYearsActions from './sagas/half-years'
import * as Modals from './sagas/modals'
import * as Morphing from './sagas/morphing'
import * as Notifications from './sagas/notifications'
import * as Organization from './sagas/organization'
import * as RequiredActions from './sagas/required-actions'
import * as TermsActions from './sagas/terms'
import * as Users from './sagas/users'

const sagas = {
  LOGIN_REQUEST: Authentication.login,
  LOGOUT_REQUEST: Authentication.logout,
  LOGIN_CHECK: Authentication.checkLogin,
  LOGIN_REQUIRED: Authentication.required,
  LOGOUT_SUCCESS: Authentication.loggedOut,
  LOGIN_SUCCESS: Users.me,

  FETCH_ORGANIZATION: Organization.fetchOrganization,

  BOOTSTRAP: Authentication.bootstrap,
  REFRESH_USER: Users.me,
  LOGIN_WITH_TOKEN: Authentication.loginWithToken,

  MODAL_OPEN: Modals.open,
  MODAL_CLOSE: Modals.close,

  MORPH: Morphing.morph,
  UNMORPH: Morphing.unmorph,

  DOWNLOAD_PDF_BILL: Download.PDFBill,
  DOWNLOAD_BUDGET_PDF: Download.downloadBudgetPDF,
  DOWNLOAD_REQUIRED_DOCUMENT_ACCEPTANCE_PDF: Download.downloadRequiredDocumentAcceptance,
  DOWNLOAD_ORGANIZATION_REPORT: Download.downloadOrganizationReport,
  DOWNLOAD_ALL_ADJUSTMENTS: Download.downloadAllAdjustments,
  DOWNLOAD_ALL_BILL_PAYS: Download.downloadAllBillPays,
  DOWNLOAD_ORGANIZATION_INVOICES_PDF: Download.organizationInvoicesPDF,
  DOWNLOAD_TRANSACTIONS_CSV: Download.transactionsCSV,
  DOWNLOAD_MEMBERS_CSV: Download.membersCSV,
  DOWNLOAD_DONATIONS_CSV: Download.donationsCSV,
  DOWNLOAD_ORGANIZATION_MEMBERS_CSV: Download.organizationMembersCSV,
  DOWNLOAD_REGISTRATION_CSV: Download.registrationCSV,
  DOWNLOAD_OPEN_NEW_BROWSER_TAB: Download.openNewBrowserTab,
  DOWNLOAD_ALL_TRANSFERS: Download.downloadAllTransfers,
  DOWNLOAD_SINGLE_TRANSFER: Download.downloadSingleTransfer,
  DOWNLOAD_FEDERATION_MEMBERSHIP_REPORT: Download.downloadFederationMembershipReport,
  DOWNLOAD_REQUIRED_DOCUMENT_CSV: Download.requiredDocumentCSV,
  DOWNLOAD_RECONCILE_ORGANIZATION_CSV: Download.reconcileOrganizationCSV,
  DOWNLOAD_SALESFORCE_ROSTER: Download.downloadSalesforceRoster,

  FETCH_REQUIRED_ACTIONS: RequiredActions.fetchRequiredActions,

  ENSURE_TERMS: TermsActions.ensureTerms,
  FETCH_TERMS: TermsActions.fetchTerms,
  ADD_TERM: TermsActions.addTerm,
  UPDATE_TERM: TermsActions.updateTerm,
  REMOVE_TERM: TermsActions.removeTerm,

  SET_TERMS: DateRangeActions.setFromTerms,

  ENSURE_HALF_YEARS: HalfYearsActions.ensureHalfYears,
}

const sagasAll = {
  API_REQUEST: API.request,
  NOTIFY_REQUEST: Notifications.notify,
  NOTIFY_AND_REDIRECT: Notifications.notifyAndRedirect,
}

export default _.flatten([
  _.map(
    sagas,
    (saga, action) =>
      function* () {
        // eslint-disable-line func-names
        yield* takeLatest(T[action], saga)
      }
  ),
  _.map(
    sagasAll,
    (saga, action) =>
      function* () {
        // eslint-disable-line func-names
        yield* takeEvery(T[action], saga)
      }
  ),
])
