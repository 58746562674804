/* eslint-disable new-cap */
import React from 'react';
import { Route } from 'react-router';

export default function AlumniAdvisingRoutes() {

  const Alumni = path => (_, cb) =>
    require.ensure([], (require) => cb(null, require('../../chunks/alumni')[path]));

  return (
    <Route path="advising" getComponent={Alumni('Advising')}>

      <Route path=":id/">
        <Route path="financial-overview" getComponent={Alumni('Advising/FinancialOverview')} />
        <Route path="members" getComponent={Alumni('Advising/Members')} />
        <Route path="national-balance" getComponent={Alumni('Advising/NationalBalance')} />
        <Route path="budgets" getComponent={Alumni('Advising/Budgets')} />
        <Route path="budgets/:budget_id" getComponent={Alumni('Advising/Budgets/show')} />
        <Route path="forms" getComponent={Alumni('Advising/Forms')} />
        <Route path="forms/:form_id" getComponent={Alumni('Advising/Forms/show')} />
        <Route path="visitation-reports" getComponent={Alumni('Advising/VisitationReports')} />
        <Route path="executive-board" getComponent={Alumni('Advising/ExecutiveBoard')} />
        <Route path="volunteers-and-staff" getComponent={Alumni('Advising/VolunteersAndStaff')} />
        <Route path="bill-pay" getComponent={Alumni('Advising/BillPay')} />
        <Route path="bill-pay/:account_id" getComponent={Alumni('Advising/BillPay/show')} />
        <Route path="committee-positions" getComponent={Alumni('Advising/CommitteePositions')} />
        <Route path="taxes" getComponent={Alumni('Advising/Taxes')} />
      </Route>

      <Route path=":page" getComponent={Alumni('Redirect')} />
    </Route>
  );
}
