import React from 'react';
import MaskedInput from 'react-input-mask';

const onChange = props => event => {
  // Fix preventDefault in react-input-mask
  if (event.nativeEvent.isTrusted) {
    const inputEvent = document.createEvent('Event');
    inputEvent.initEvent('input', true, true);

    event.target.dispatchEvent(inputEvent);
  }

  if (props.onChange) props.onChange(event);
};

export default function (props) {
  return (
    <MaskedInput
      onChange={onChange(props)}
      {..._.omit(props, ['onChange'])}
    />
  );
}
