import { apiRequest } from 'actions/use'
import apiWrapper, { generatePayload } from './helpers'

export default class MeetingMember extends apiWrapper('/meeting_members') {
  static setStatus({ data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'POST',
      uri: '/api/meeting_members/set_status',
      data: generatePayload({ data, query }),
      on: {
        201: onSuccess,
      },
      onFailure,
    })
  }
}
