import React from 'react';
import { object, func, string, oneOfType } from 'prop-types';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import { Row, Col, FormControl, InputGroup } from 'react-bootstrap';
import { connect } from 'helpers';

import { FaIcon } from 'components/utilities';
import DatePickerModal from 'components/modals/date-picker';

class CompactDateTimeInput extends React.Component {

  static propTypes = {
    modalId: string.isRequired,
    onChange: func.isRequired,
    dateTime: oneOfType([object, string]),
    minDate: oneOfType([object, string]),
    maxDate: oneOfType([object, string]),
  }

  constructor(props) {
    super();

    this.state = { dateTime: props.dateTime ? moment(props.dateTime) : null };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      dateTime: nextProps.dateTime ? moment(nextProps.dateTime) : null,
    });
  }

  onDateChange = (newDate) => {
    const { onChange } = this.props;
    const { dateTime } = this.state;


    if (newDate && dateTime) {
      const newDateTime = dateTime.clone();

      newDateTime.set({
        date: newDate.date(),
        month: newDate.month(),
        year: newDate.year(),
      });

      onChange(newDateTime);
    } else {
      if (newDate) newDate.set({ h: 12 });
      onChange(newDate);
    }
  }

  onTimeChange = (newTime) => {
    const { onChange } = this.props;
    const { dateTime } = this.state;

    if (newTime) {
      const newDateTime = dateTime ? dateTime.clone() : moment();
      const minutes = newTime.minute() % 5 === 0 ? newTime.minute() : 0;
      newDateTime.set({ h: newTime.hour(), m: minutes });
      onChange(newDateTime);
    }
  }

  render() {
    const { actions, modalId, minDate, maxDate } = this.props;
    const { dateTime, isOpenTimePicker } = this.state;
    const dateTimeValue = dateTime ? dateTime.clone() : null;

    return (
      <Row>
        <Col md={7}>
          <InputGroup
            onClick={actions.openModal('DatePicker', modalId)}
            className="c-pointer"
          >
            <FormControl
              value={dateTime ? dateTime.format('MMMM D, YYYY') : ''}
              type="text"
              readOnly
              className="c-pointer bg-white"
            />
            <InputGroup.Addon className="bg-white">
              <FaIcon calendar />
            </InputGroup.Addon>
          </InputGroup>
        </Col>
        <Col md={5}>
          <InputGroup
            onClick={() => this.setState({ isOpenTimePicker: true })}
            className="c-pointer"
          >
            <TimePicker
              className="c-pointer"
              allowEmpty
              open={isOpenTimePicker}
              value={dateTime}
              showSecond={false}
              use12Hours
              minuteStep={5}
              format="h:mma"
              onClose={() => this.setState({ isOpenTimePicker: false })}
              onChange={this.onTimeChange}
            />
            <InputGroup.Addon className="bg-white">
              <FaIcon clock-o />
            </InputGroup.Addon>
          </InputGroup>
        </Col>

        <DatePickerModal
          id={modalId}
          date={dateTimeValue || moment().add(1, 'day')}
          minDate={minDate ? moment(minDate) : moment()}
          maxDate={maxDate ? moment(maxDate) : null}
          onDateSelected={this.onDateChange}
        />
      </Row>
    );
  }
}

export default connect(CompactDateTimeInput);
