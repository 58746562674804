import React, { Fragment } from 'react';
import { connect } from 'helpers';
import Container from 'container';
import { ContributionTracker } from 'resources';

import Item from './item';

class ContributionTrackersMenu extends Container {

  resources = {
    list: ContributionTracker,
  }

  listResourceAction = 'listByUser'

  get listScope() {
    const { user } = this.context;
    return { user_id: user.id };
  }

  listQuery = `
    contribution_tracker {
      title
      purpose
    }
  `

  render() {
    const trackers = this.getList();

    if (!trackers) {
      return null;
    }

    return (
      <Fragment>
        {_.map(trackers, (tracker, idx) => (
          <Item
            url={`/contributions/${tracker.id}`}
            icon="thermometer-three-quarters"
            key={idx}
          >
            {tracker.title}
          </Item>
        ))}
      </Fragment>
    );
  }

}

export default connect(ContributionTrackersMenu);
