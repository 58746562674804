export default {
  MODAL_OPEN: 'MODAL_OPEN',
  MODAL_CLOSE: 'MODAL_CLOSE',

  SET_ACTIVE_ITEM: 'SET_ACTIVE_ITEM',

  TUTORIAL: 'TUTORIAL',

  SET_CALENDAR_EVENT: 'SET_CALENDAR_EVENT',
  CLEAR_CALENDAR_EVENT: 'CLEAR_CALENDAR_EVENT',

  FETCH_REQUIRED_ACTIONS: 'FETCH_REQUIRED_ACTIONS',
  SET_REQUIRED_ACTIONS: 'SET_REQUIRED_ACTIONS',
  CLEAR_REQUIRED_ACTIONS: 'CLEAR_REQUIRED_ACTIONS',

  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGIN_CHECK: 'LOGIN_CHECK',
  LOGIN_REQUIRED: 'LOGIN_REQUIRED',

  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',

  FETCH_ORGANIZATION: 'FETCH_ORGANIZATION',
  SET_ORGANIZATION: 'SET_ORGANIZATION',
  CLEAR_ORGANIZATION: 'CLEAR_ORGANIZATION',

  BOOTSTRAP: 'BOOTSTRAP',
  REFRESH_USER: 'REFRESH_USER',
  LOGIN_WITH_TOKEN: 'LOGIN_WITH_TOKEN',

  API_REQUEST: 'API_REQUEST',
  API_SUCCESS: 'API_SUCCESS',
  API_FAILURE: 'API_FAILURE',

  NOTIFY_REQUEST: 'NOTIFY_REQUEST',
  NOTIFY_AND_REDIRECT: 'NOTIFY_AND_REDIRECT',

  MORPH: 'MORPH',
  UNMORPH: 'UNMORPH',

  DOWNLOAD_PDF_BILL: 'DOWNLOAD_PDF_BILL',
  DOWNLOAD_REQUIRED_DOCUMENT_ACCEPTANCE_PDF: 'DOWNLOAD_REQUIRED_DOCUMENT_ACCEPTANCE_PDF',
  DOWNLOAD_ORGANIZATION_INVOICES_PDF: 'DOWNLOAD_ORGANIZATION_INVOICES_PDF',
  DOWNLOAD_TRANSACTIONS_CSV: 'DOWNLOAD_TRANSACTIONS_CSV',
  DOWNLOAD_MEMBERS_CSV: 'DOWNLOAD_MEMBERS_CSV',
  DOWNLOAD_DONATIONS_CSV: 'DOWNLOAD_DONATIONS_CSV',
  DOWNLOAD_ORGANIZATION_MEMBERS_CSV: 'DOWNLOAD_ORGANIZATION_MEMBERS_CSV',
  DOWNLOAD_REGISTRATION_CSV: 'DOWNLOAD_REGISTRATION_CSV',
  DOWNLOAD_BUDGET_PDF: 'DOWNLOAD_BUDGET_PDF',
  DOWNLOAD_ORGANIZATION_REPORT: 'DOWNLOAD_ORGANIZATION_REPORT',
  DOWNLOAD_ALL_ADJUSTMENTS: 'DOWNLOAD_ALL_ADJUSTMENTS',
  DOWNLOAD_ALL_BILL_PAYS: 'DOWNLOAD_ALL_BILL_PAYS',
  DOWNLOAD_OPEN_NEW_BROWSER_TAB: 'DOWNLOAD_OPEN_NEW_BROWSER_TAB',
  DOWNLOAD_ALL_TRANSFERS: 'DOWNLOAD_ALL_TRANSFERS',
  DOWNLOAD_SINGLE_TRANSFER: 'DOWNLOAD_SINGLE_TRANSFER',
  DOWNLOAD_REQUIRED_DOCUMENT_CSV: 'DOWNLOAD_REQUIRED_DOCUMENT_CSV',
  DOWNLOAD_RECONCILE_ORGANIZATION_CSV: 'DOWNLOAD_RECONCILE_ORGANIZATION_CSV',
  DOWNLOAD_FEDERATION_MEMBERSHIP_REPORT: 'DOWNLOAD_FEDERATION_MEMBERSHIP_REPORT',
  DOWNLOAD_SALESFORCE_ROSTER: 'DOWNLOAD_SALESFORCE_ROSTER',

  ENSURE_TERMS: 'ENSURE_TERMS',
  FETCH_TERMS: 'FETCH_TERMS',
  SET_TERMS: 'SET_TERMS',
  ADD_TERM: 'ADD_TERM',
  UPDATE_TERM: 'UPDATE_TERM',
  REMOVE_TERM: 'REMOVE_TERM',
  CLEAR_TERMS: 'CLEAR_TERMS',

  ENSURE_HALF_YEARS: 'ENSURE_HALF_YEARS',
  SET_HALF_YEARS: 'SET_HALF_YEARS',
  SET_HALF_YEAR_DATE_RANGE: 'SET_HALF_YEAR_DATE_RANGE',

  SET_DATE_RANGE: 'SET_DATE_RANGE',

  SUBSCRIBE_REQUEST: 'SUBSCRIBE_REQUEST',
  UNSUBSCRIBE_REQUEST: 'UNSUBSCRIBE_REQUEST',

  NOTIFICATION: 'NOTIFICATION',

  SET_CUSTOM_WRAPPER_STYLES: 'SET_CUSTOM_WRAPPER_STYLES',

  SET_ACTIVE_PARENT: 'SET_ACTIVE_PARENT',
  SET_PARENTS: 'SET_PARENTS',
}

export function isActionOf(action, type) {
  if (typeof action !== 'object' || !('type' in action)) {
    return false
  }
  return action.type === type
}
