import React from 'react';
import PropTypes from 'prop-types';
import { ExpandableImage } from 'components/utilities';

const { array, func } = PropTypes;

const AdminEventPhotos = ({ photos, doRemovePhoto }) => (
  <div>
    {
      _.map(photos, ({ url, id }, index) => (
        <div
          key={index}
          className="removable-image"
        >
          <i
            className="fa fa-close"
            onClick={() => doRemovePhoto(index, id)}
          />

          <ExpandableImage
            key={index}
            image={url}
            className="pull-left"
          >
            <img
              src={url}
              height={80}
              role="presentation"
              className="m-b-1 m-r-1"
            />
          </ExpandableImage>
        </div>
      ))
    }
  </div>
);

AdminEventPhotos.propTypes = {
  photos: array.isRequired,
  doRemovePhoto: func.isRequired,
};

export default AdminEventPhotos;
