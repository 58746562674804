import apiWrapper, { retrieve, updateMethod } from './helpers';

export default class BillingCategory extends apiWrapper('/billing_categories') {

  static weights(opts) {
    const update = updateMethod('', 'weights');
    _.assign(opts, { id: 'billing_categories' });
    return update(opts);
  }

  static transactions = (opts) => retrieve('/billing_categories', {
    ...opts,
    id: `${opts.data.id}/transactions`,
  })

}
