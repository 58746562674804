import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FaIcon, Units } from 'components/utilities';

export default function ({ hourTracker }) {
  return (
    hourTracker && hourTracker.pending > 0 ? (
      <OverlayTrigger placement="right" overlay={(
        <Tooltip id="hour-records-tooltip">
          <Units
            count={hourTracker.pending}
            noun="submission"
          /> are pending
        </Tooltip>
      )}>
        <FaIcon exclamation-circle warning className="pull-right" />
      </OverlayTrigger>
    ) : null
  );
}
