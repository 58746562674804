import { number, object, shape, string, arrayOf } from 'prop-types'
import { connect } from 'react-redux'
import actionProps from 'actions'

export default function (Component, mapStateToProps) {
  // eslint-disable-next-line no-param-reassign
  const contextTypes = Component.contextTypes || (Component.contextTypes = {})

  contextTypes.user = object.isRequired
  contextTypes.member_id = number
  contextTypes.organization = object.isRequired

  contextTypes.owner = shape({
    owner_type: string,
    owner_id: number,
  })

  contextTypes.activeParent = object
  contextTypes.parents = arrayOf(object)

  return connect(mapStateToProps, actionProps, null, { pure: false })(Component)
}
