import T from 'actions/types';

// Creates middleware which handles notification
export default function (handler) {
  return () => ({ dispatch }) => next => action => {
    const result = next(action);
    const payload = action.payload;

    if (action.type === T.NOTIFICATION && payload.type === handler.type) {
      handler.handle(dispatch, result);
    }

    return result;
  };
}
