import apiWrapper from './helpers'
import { apiRequest } from 'actions/use'

export default class Salesforce extends apiWrapper('/salesforce') {
  static search({ data, onSuccess }) {
    apiRequest({
      method: 'GET',
      uri: `/api/salesforces/search?${_.toQueryString(data)}`,
      on: {
        200: onSuccess,
      },
    })
  }
}
