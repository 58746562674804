import React from 'react';

export default function (props) {
  const { children } = props;

  return (
    <tr>
      {children}
    </tr>
  );
}
