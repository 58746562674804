/**
 * Create the store with asynchronously loaded reducers
 */

import { createStore, applyMiddleware, compose } from 'redux'
import { fromJS } from 'immutable'
import { routerMiddleware } from 'react-router-redux'
import createSagaMiddleware from 'redux-saga'
import createNotificationsMiddleware from './middlewares/notifications'
// To be able to register subscriptions dynamically during the app running
import * as subscriptionsMiddlewares from './middlewares/subscriptions'
import * as notificationsHandlersMiddlewares from './middlewares/notifications_handlers'
import requiredDocumentMiddleware from './middlewares/required_documents'
import createReducer from './reducers'
import { GCM_ENVIRONMENT } from './helpers'

const sagaMiddleware = createSagaMiddleware()

const notificationsMiddleware = createNotificationsMiddleware()

export default function configureStore(initialState = {}, history) {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [
    sagaMiddleware,
    routerMiddleware(history),
    ..._.map(subscriptionsMiddlewares, m => m()),
    ..._.map(notificationsHandlersMiddlewares, m => m()),
    notificationsMiddleware,
    requiredDocumentMiddleware(),
  ]

  if (_.includes(['development', 'test'], GCM_ENVIRONMENT)) {
    window.xhrLogs = []
    const watchActions = ['API_REQUEST', 'API_SUCCESS', 'API_FAILURE']

    middlewares.push(() => next => action => {
      if (_.includes(watchActions, action.type)) {
        const withoutOnEvent = _.omit(action, 'on')
        withoutOnEvent.datetime = `${new Date()}`
        window.xhrLogs.push(withoutOnEvent)

        if (window.xhrLogs.length > 10) {
          window.xhrLogs.shift()
        }
      }
      return next(action)
    })
  }

  const enhancers = [applyMiddleware(...middlewares)]

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose // eslint-disable-line no-underscore-dangle

  const store = createStore(createReducer(), fromJS(initialState), composeEnhancers(...enhancers))

  // Create hook for async sagas
  store.runSaga = sagaMiddleware.run

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    System.import('./reducers').then(reducerModule => {
      const createReducers = reducerModule.default
      const nextReducers = createReducers(store.asyncReducers)

      store.replaceReducer(nextReducers)
    })
  }

  // Initialize it with no other reducers
  store.asyncReducers = {}
  return store
}
