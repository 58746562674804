import React from 'react';
import PropTypes from 'prop-types';

import { ExpandableImage } from 'components/utilities';

import { iconForMimeType, extFromName } from 'helpers';

const { shape, string } = PropTypes;

const Preview = ({ document, empty }) => {

  if (_.isNull(document)) {
    return <em className="d-block m-t-2 text-center">{empty}</em>;
  }

  if (document.mimetype.match(/^image\//)) {
    return (
      <ExpandableImage image={document.url} className="preview-holder">
        <img
          src={document.url}
          alt="preview"
          className="preview"
        />
        <em className="d-block text-center m-t-1 text-muted">view larger</em>
      </ExpandableImage>
    );
  }

  const icon = iconForMimeType(document.mimetype, document.filename);
  const ext = document.filename && _.upperCase(extFromName(document.filename));

  return (
    <a target="_blank" href={document.url} className="preview-holder">
      <img
        src={icon}
        role="presentation"
        className="m-auto d-block m-t-2"
        style={{ width: '30%' }}
      />
      <span className="d-block text-center m-t-1">download {ext}</span>
    </a>
  );
};

Preview.propTypes = {
  document: shape({
    mimetype: string.isRequired,
    url: string.isRequired,
  }),
  empty: string,
};

Preview.defaultProps = {
  empty: 'no documents uploaded',
};

export default Preview;
