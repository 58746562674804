/* eslint-disable no-param-reassign */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'helpers'

import {
  AdminMenu,
  AlumniMenu,
  FederationMenu,
  MemberMenu,
  ParentMenu,
  RootMenu,
  DevMenu,
} from './sidebar-menu'
import Item from './sidebar-menu/item'

const { shape, string } = PropTypes

class Sidebar extends React.Component {
  static contextTypes = {
    user: shape({
      role: string.isRequired,
    }),
  }

  componentDidMount() {
    const { role } = this.context.user

    const roles = ['member', 'admin', 'alumni', 'federation']
    if (_.includes(roles, role)) {
      this.props.actions.fetchRequiredActions()
      this.requiredActionsInterval = setInterval(() => {
        this.props.actions.fetchRequiredActions()
      }, this.SIDEBAR_STATE_UPDATE_PERIOD)
    }
  }

  componentWillUnmount() {
    if (_.isNumber(this.requiredActionsInterval)) {
      clearTimeout(this.requiredActionsInterval)
    }
  }

  SIDEBAR_STATE_UPDATE_PERIOD = 10 * 60 * 1000

  render() {
    const { user } = this.context
    const { morph } = this.props.actions

    if (!user) {
      throw new Error('Sidebar: User is not authorized')
    }

    let Menu

    switch (user.role) {
      case 'admin':
        Menu = AdminMenu
        break
      case 'member':
        Menu = MemberMenu
        break
      case 'parent':
        Menu = ParentMenu
        break
      case 'federation':
        Menu = FederationMenu
        break
      case 'alumni':
        Menu = AlumniMenu
        break
      case 'root':
        Menu = RootMenu
        break
      default:
        Menu = null
    }
    const userId = _.get(user, 'morph_alumni.user.id')

    return (
      <div id="sidebar-menu" className="sidebar-grouped">
        {user.role === 'federation' && userId && (
          <ul className="sidebar-menu c-pointer">
            <Item onClick={() => morph(userId)} icon="arrow-circle-right">
              Go to my alumni account
            </Item>
          </ul>
        )}
        {Menu && <Menu />}
        {user.role === 'root' && <DevMenu />}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  tutorial: state.get('tutorial'),
  requiredActions: state.get('requiredActions'),
})

export default connect(Sidebar, mapStateToProps)
