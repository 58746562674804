import React from 'react';
import PropTypes from 'prop-types';

import Sortable from 'react-sortablejs';

const { func, string } = PropTypes;

export default class Tbody extends React.Component {

  static propTypes = {
    onDrop: func.isRequired,
    onChangeOrder: func,
    onCheckDrop: func,
    className: string,
  };

  render() {
    const { onChangeOrder, onCheckDrop, onDrop, children, className } = this.props;

    if (!onChangeOrder) {
      return <tbody className={className}>{children}</tbody>;
    }

    const sortableProps = {
      tag: 'tbody',
      onChange: onDrop,
      options: {
        handle: '.drag-handle',
        dataIdAttr: 'data-id',
        onMove: onCheckDrop ? (event) => (
          onCheckDrop(event.dragged, event.related)
        ) : null,

        onEnd: onCheckDrop ? () => {
          onCheckDrop();
        } : null,
      },
    };

    return (
      <Sortable {...sortableProps}>{children}</Sortable>
    );
  }

}
