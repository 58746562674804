import moment from 'moment'
import { put } from 'redux-saga/effects'

import { setHalfYears as doSetHalfYears } from 'actions/half-years'
import { setHalfYearDateRange as doSetHalfYearDateRange } from 'actions/half-year-date-range'
import { restoreDateRange } from 'helpers/date-range-persistance'

const getNextStart = ([_month, _year]) => {
  const month = _month === 1 ? 6 : 1
  const year = _month === 1 ? _year : _year + 1
  return [month, year]
}

export function* ensureHalfYears() {
  const currentDate = moment()

  const startDates = [[6, 2017]]

  let last = _.last(startDates)
  do {
    startDates.push(getNextStart(last))

    last = _.last(startDates)
  } while (moment(`${last[1]}-0${last[0]}-01`).isBefore(currentDate))

  const halfYears = _.map(startDates, ([month, year]) => {
    const startedOn = moment(`${year}-0${month}-01`)
    const endedOn = moment(`${year}-${month === 1 ? '05' : 12}-01`).endOf('month')

    return {
      name: `${month === 1 ? 'Spring' : 'Fall'} • ${year}`,
      started_on: startedOn.format('YYYY-MM-DD'),
      ended_on: endedOn.format('YYYY-MM-DD'),
    }
  })

  _.reverse(halfYears)

  let dateRange = restoreDateRange()
  if (!dateRange) {
    dateRange = {
      start: moment(_.get(halfYears[1], 'started_on')),
      end: moment(halfYears[1].ended_on).endOf('day'),
    }
  }

  yield [put(doSetHalfYears(halfYears)), put(doSetHalfYearDateRange(dateRange))]
}
