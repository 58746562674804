/* global braintree, Rollbar */

import React from 'react';
import PropTypes from 'prop-types';
import scriptLoader from 'react-async-script-loader';
import { PaymentMethod } from 'resources';

const { func, string, bool } = PropTypes;

class PayPalPaymentWrapper extends React.Component {
  static propTypes = {
    onProcessPayment: func.isRequired,
    onError: func.isRequired,
    onCheckoutInstanceReady: func,
    onSubmit: func,
    authorization: string,
    loadAuthorization: bool,
  }

  constructor(props) {
    super(props);

    this.state = {
      authorization: props.authorization,
    };
  }

  componentDidMount() {
    const { loadAuthorization } = this.props;

    if (loadAuthorization) {
      PaymentMethod.authorization({
        onSuccess: ({ data: { authorization } }) => this.setState({ authorization }),
      });
    }
  }

  componentDidUpdate = (prevProps) => {
    const { authorization } = this.props;

    if (prevProps.authorization !== authorization) {
      this.setState({ authorization });
    }

    this.loadCheckoutInstance();
  }

  loadCheckoutInstance = () => {
    const { initialized, authorization } = this.state;
    const { onCheckoutInstanceReady, isScriptLoadSucceed } = this.props;

    if (!isScriptLoadSucceed || !authorization || initialized) return;

    braintree
      .client
      .create({ authorization }, (clientErr, clientInstance) => {
        if (clientErr) {
          Rollbar.critical(clientErr);
          return;
        }

        braintree.paypal.create({ client: clientInstance }, (paypalClientErr, paypalCheckoutInstance) => {
          if (paypalClientErr) {
            Rollbar.critical(paypalClientErr);
            return;
          }

          this.setState({ paypalCheckoutInstance });

          onCheckoutInstanceReady({
            paypalCheckoutInstance,
            submitPayment: this.submitPayment,
          });
        });
      });

    this.setState({ initialized: true });
  }

  submitPayment = ({ payment: { amount } }) => {
    const { onSubmit } = this.props;
    const { paypalCheckoutInstance } = this.state;

    if (onSubmit) onSubmit();

    paypalCheckoutInstance.tokenize({
      flow: 'checkout',
      amount,
      currency: 'USD',
      intent: 'sale',
    }, this.onTokenized);
  }

  onTokenized = (tokenizeErr, payload) => {
    const { onError, onProcessPayment } = this.props;
    if (tokenizeErr) {
      onError(tokenizeErr);
      return;
    }
    const { nonce } = payload;

    onProcessPayment({ nonce });
  }

  render() {
    const { children } = this.props;

    return children;
  }
}

export default scriptLoader(
  'https://www.paypalobjects.com/api/checkout.js',
  'https://js.braintreegateway.com/web/3.6.2/js/client.min.js',
  'https://js.braintreegateway.com/web/3.6.2/js/paypal.min.js',
)(PayPalPaymentWrapper);
