import React from 'react'
import { connectParent } from 'helpers'
import Contacts from './contacts'
import Item from './item'

class ParentMenu extends React.Component {
  renderMembers = () => {
    const { activeParent, parents, actions } = this.props
    if (parents.length === 1) {
      return null
    }

    return (
      <ul className="sidebar-menu">
        {parents.map(parent => (
          <Item
            key={parent.id}
            onClick={() => actions.setActiveParent(parent.id)}
            isActive={parent.id === activeParent.id}
          >
            {parent.member.user.first_name} {parent.member.user.last_name}
          </Item>
        ))}
      </ul>
    )
  }

  render = () => (
    <div>
      {this.renderMembers()}
      <ul className="sidebar-menu">
        <Item url="/parent/balance" icon="bank">
          Balance
        </Item>
        <Item url="/parent/required-documents" icon="file-text-o">
          Required signatures
        </Item>
        <Item url="/parent/settings" icon="cog">
          Settings
        </Item>
      </ul>
      <Contacts />
    </div>
  )
}

export default connectParent(ParentMenu)
