/* eslint-disable new-cap */
import React from 'react'
import { Route, IndexRoute, IndexRedirect } from 'react-router'
import { ensureRole } from '../helpers'

export default function memberRoutes(store) {
  const Member = path => (_, cb) =>
    require.ensure([], require => cb(null, require('chunks/member')[path]))

  return (
    <Route onEnter={ensureRole(store, 'member')}>
      <Route path="balance" getComponent={Member('Balance')} />
      <Route path="balance/:account_id" getComponent={Member('Balance')} />
      <Route path="members" getComponent={Member('Members')} />
      <Route path="budgets" getComponent={Member('Budgets')} />
      <Route path="budgets/:id" getComponent={Member('Budgets/show')} />
      <Route path="calendar" getComponent={Member('Calendar')} />
      <Route path="committee-positions" getComponent={Member('CommitteePositions')} />
      <Route path="contributions/:id" getComponent={Member('ContributionTrackers/show')} />
      <Route path="document-folders" getComponent={Member('DocumentFolders')} />
      <Route path="document-folders/:id" getComponent={Member('DocumentFolders/show')} />
      <Route path="form-submissions" getComponent={Member('FormSubmissions')} />
      <Route path="event-registrations" getComponent={Member('EventRegistrations')} />
      <Route
        path="event-registrations/federation"
        isFederation
        getComponent={Member('EventRegistrations')}
      />
      <Route path="event-registrations/:id/new" getComponent={Member('EventRegistrations/new')} />
      <Route path="executive-board" getComponent={Member('ExecutiveBoard')} />
      <Route path="games" getComponent={Member('Games')} />
      <Route path="g-money" getComponent={Member('GMoney')} />
      <Route path="hour-tracker" getComponent={Member('HourTracker')} />
      <Route path="hour-tracker/:id" getComponent={Member('HourTracker/show')} />
      <Route path="issued-cards/:id" getComponent={Member('IssuedCards')} />
      <Route path="payment-plan" getComponent={Member('PaymentPlans')} />
      <Route path="reimbursements" getComponent={Member('Reimbursements')} />
      <Route path="important-contacts" getComponent={Member('ImportantContacts')} />

      <Route path="message-board">
        <IndexRoute getComponent={Member('MessageCategories')} />
        <Route path=":id">
          <IndexRoute getComponent={Member('MessageCategories/show')} />
          <Route path="folders/:folder_id" getComponent={Member('MessageCategories/folder')} />
          <Route path="threads/:thread_id" getComponent={Member('MessageThreads/show')} />
        </Route>
      </Route>

      <Route path="voting">
        <IndexRoute getComponent={Member('Voting')} />
        <Route path=":id" getComponent={Member('Voting/show')} />
      </Route>

      <Route path="settings" getComponent={Member('Settings')}>
        <IndexRedirect to="profile" />
        <Route path="profile" getComponent={Member('Settings/Profile')} />
        <Route path="password" getComponent={Member('Settings/Password')} />
        <Route path="access" getComponent={Member('Settings/Access')} />
        <Route path="payment-methods" getComponent={Member('Settings/PaymentMethods')} />
      </Route>
    </Route>
  )
}
