import React from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-images';

const { number, arrayOf, object, bool, func } = PropTypes;

export default class LightboxSlider extends React.Component {

  static propTypes = {
    currentImage: number,
    images: arrayOf(object).isRequired,
    onCloseLightbox: func.isRequired,
    isOpen: bool,
  }

  static defaultProps = {
    currentImage: 0,
    isOpen: false,
  }

  constructor(props) {
    super(props);

    this.state = { currentImage: props.currentImage };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentImage !== this.state.currentImage) {
      this.setState({ currentImage: nextProps.currentImage });
    }
  }

  gotoPrevious = () => {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }

  gotoNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }

  handleClickImage = () => {
    const { currentImage } = this.state;
    const { images } = this.props;

    if (currentImage === images.length - 1) {
      return;
    }

    this.gotoNext();
  }

  render = () => {
    const { currentImage } = this.state;
    const { images, isOpen, onCloseLightbox } = this.props;

    return (
      <Lightbox
        currentImage={currentImage}
        images={images}
        isOpen={isOpen}
        onClickImage={this.handleClickImage}
        onClickNext={this.gotoNext}
        onClickPrev={this.gotoPrevious}
        onClose={onCloseLightbox}
      />
    );
  }

}
