import React from 'react';
import PropTypes from 'prop-types';
import { modal } from 'helpers';

import { Modal, Button } from 'react-bootstrap';

const { string } = PropTypes;

class PublicSiteModal extends React.Component {

  static propTypes = {
    url: string.isRequired,
  }

  render = () => (
    <Modal show onHide={this.props.actions.closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Public URL</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <hr className="spacer-xs" />

        <input
          value={this.props.url}
          readOnly
          autoFocus
          className="form-control text-center"
          onFocus={e => e.target.select()}
          onClick={e => e.target.select()}
        />

        <hr className="spacer-xs" />

      </Modal.Body>
      <Modal.Footer>

        <Button
          bsStyle="primary"
          onClick={() => this.props.actions.openNewBrowserTab(this.props.url)}
        >
          Visit registration page
        </Button>

      </Modal.Footer>
    </Modal>
  )

}

export default modal('PublicSite', PublicSiteModal);
