import React from 'react';
import { Quill } from 'react-quill';
import Delta from 'quill-delta';
import { UploadFileModal } from 'components/modals';
import './s3-image-blot';

const STAGE_WAITING = 0;
const STAGE_SELECTING = 1;

export default class QuillImageHandler extends React.Component {
  state = {
    stage: STAGE_WAITING,
  };

  componentDidMount() {
    const { onReady } = this.props;
    onReady({ handle: this.startSelecting });
  }

  onImageUploaded = ({ preview, s3_path: s3Path }) => {
    const { editor } = this.props;
    const range = editor.getSelection(true);
    editor.updateContents(new Delta()
        .retain(range.index)
        .delete(range.length)
        .insert({ s3Image: { src: preview, s3Path } })
      , Quill.sources.USER);
    editor.setSelection(range.index + 1, Quill.sources.SILENT);
    this.setState({ stage: STAGE_WAITING });
  };

  startSelecting = editor => {
    this.setState({ stage: STAGE_SELECTING, editor });
  };

  render() {
    const { stage } = this.state;
    if (stage === STAGE_WAITING) return null;

    return (
      <UploadFileModal
        showAlways
        onSuccess={this.onImageUploaded}
      />
    );
  }
}
