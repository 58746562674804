import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import actionProps from 'actions'

const { object, shape } = PropTypes

export default function (WrappedComponent, mapStateToPropsFunc) {
  // eslint-disable-next-line no-param-reassign
  const contextTypes = WrappedComponent.contextTypes || (WrappedComponent.contextTypes = {})
  contextTypes.user = PropTypes.object.isRequired
  contextTypes.member_id = PropTypes.number
  contextTypes.organization = PropTypes.object.isRequired

  const extendedComponent = class extends React.Component {
    static propTypes = {
      halfYearDateRange: shape({
        start: object.isRequired,
        end: object.isRequired,
      }),
    }

    componentWillMount() {
      const { halfYearDateRange, actions } = this.props

      if (!halfYearDateRange) {
        actions.ensureHalfYears()
      }
    }

    render() {
      const { halfYearDateRange } = this.props

      if (!halfYearDateRange) {
        return null
      }

      return <WrappedComponent dateRange={halfYearDateRange} {...this.props} />
    }
  }

  const mapStateToProps = state => {
    let data = {}

    if (mapStateToPropsFunc) {
      data = mapStateToPropsFunc(state)
    }

    const halfYearDateRange = state.get('halfYearDateRange')
    return _.assign(data, { halfYearDateRange })
  }

  return connect(mapStateToProps, actionProps)(extendedComponent)
}
