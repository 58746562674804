import React from 'react';
import { replace } from 'react-router-redux';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import cx from 'classnames';

const logo = require('images/tmp/old-logo.png');

class UnauthenticatedWrapper extends React.Component { // eslint-disable-line react/prefer-stateless-function

  componentDidMount() {
    const { isLoggedIn } = this.props;

    if (isLoggedIn) {
      replace('/');
    }
  }

  render() {
    const { location, children, customizeWrapper } = this.props;
    const backgroundColor = _.get(customizeWrapper, 'backgroundColor', null);
    const headerColor = _.get(customizeWrapper, 'headerColor', null);
    const primaryLogo = _.get(customizeWrapper, 'primaryLogo', null);
    const secondaryLogo = _.get(customizeWrapper, 'secondaryLogo', null);

    const show = _.get(children, 'type.WrappedComponent.name') !== 'UnauthenticatedRegisterIndex' ||
                (_.has(customizeWrapper, 'backgroundColor') && _.has(customizeWrapper, 'headerColor') &&
                _.has(customizeWrapper, 'primaryLogo') && _.has(customizeWrapper, 'secondaryLogo'));

    return (
      <div className="unauthenticated" style={show ? {} : { display: 'none' }}>

        {backgroundColor && <style>{`body { background: ${backgroundColor}; }`}</style>}

        <header
          className="navbar"
          role="banner"
          style={headerColor ? { backgroundColor: headerColor, borderColor: headerColor } : {}}
        >

          <div className="container">

            <div className="navbar-header">
              <Link to="/" className={cx('logo', { 'secondary-logo': secondaryLogo || primaryLogo })}>
                {(!secondaryLogo || !primaryLogo) && <img src={logo} height={40} role="presentation" />}
                {primaryLogo && (
                  <img
                    src={primaryLogo}
                    className="secondary-logo-img"
                    role="presentation"
                    style={{ width: '48px !important', height: '48px !important' }}
                  />
                )}
                {secondaryLogo && (
                  <img
                    src={secondaryLogo}
                    className="secondary-logo-img"
                    role="presentation"
                    style={{ width: '48px !important', height: '48px !important' }}
                  />
                )}
              </Link>
            </div>

            {location.pathname === '/login' ? null : (
              <nav className="collapse navbar-collapse" role="navigation">
                <ul className="nav navbar-nav navbar-right">
                  <li>
                    <a href="/"><i className="fa fa-angle-double-left"></i> &nbsp;back</a>
                  </li>
                </ul>
              </nav>
            )}

          </div>

        </header>

        <div style={{ height: 100 }} />

        {children}

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customizeWrapper: state.get('customizeWrapper'),
    isLoggedIn: state.get('auth') && state.get('auth').user,
  };
}

export default connect(mapStateToProps, null, null, { pure: false })(UnauthenticatedWrapper);
