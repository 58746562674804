import { apiRequest } from 'actions/use'
import { MEMBER_GLOBAL_QUERY, ALUMNI_GLOBAL_QUERY } from './organization'
import apiWrapper, { generatePayload, getMethod, updateMethod } from './helpers'

export const GLOBAL_QUERY = `
  user {
    first_name
    last_name
    email
    phone_number
    role
    is_root
    all_features

    morph_alumni {
      user {
        id
      }
    }

    data {
      has_required_documents
      has_budgets
      has_gmoney_access
    }

    avatar {
      public_url
    }

    member {
      is_admin
      unique_id
      custom_fields
      graduation_year
      no_physical_bills
      organization_id

      ${MEMBER_GLOBAL_QUERY}

      data {
        all_features
      }
    }

    alumni {
      organization_id

      ${ALUMNI_GLOBAL_QUERY}
    }

    parents {
      send_email_bills
      send_physical_bills
      send_sms_reminders

      member {
        is_admin
        unique_id
        custom_fields
        no_physical_bills
        organization_id

        user {
          first_name
          last_name
          phone_number
          email
          role
        }

        data {
          all_features
        }
      }
    }

    alumni {
      organization_id
      graduation_year
      custom_fields

      organization {
        name
        secondary

        federation {
          name

          primary_logo {
            public_url
          }
          secondary_logo {
            public_url
          }
        }
      }

      lifetime_donation_tier {
        level
        threshold
      }
    }

    federation {
      name
      organization_roster
      alumni_group_id
      primary_logo {
        public_url
      }
      secondary_logo {
        public_url
      }
      custom_fields {
        name
        slug
      }
    }
  }
`

export default class User extends apiWrapper('/users') {
  static avatar({ id, data, query, onSuccess }) {
    apiRequest({
      method: 'PUT',
      uri: `/api/users/${id}/avatar`,
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
    })
  }

  static changePassword({ id, data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'PUT',
      uri: `/api/users/${id}/change_password`,
      data: generatePayload({ data, query }),
      onSuccess,
      onFailure,
    })
  }

  static changePasswordPlain({ id, data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'PUT',
      uri: `/api/users/${id}/plain_password`,
      data: generatePayload({ data, query }),
      onSuccess,
      onFailure,
    })
  }

  static passwordReset({ email, onSuccess, onFailure }) {
    apiRequest({
      method: 'POST',
      uri: '/api/password_resets',
      data: {
        password_reset: { email },
      },
      on: {
        201: onSuccess,
      },
      onFailure,
    })
  }

  static groups({ id, data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'PUT',
      uri: `/api/users/${id}/groups`,
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    })
  }

  static groupUsers({ data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'GET',
      uri: '/api/users/group_users',
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    })
  }

  static budgets({ id, data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'PUT',
      uri: `/api/users/${id}/budgets`,
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    })
  }

  static unsubscribe({ data, onSuccess, onFailure }) {
    apiRequest({
      method: 'PUT',
      uri: '/api/users/unsubscribe',
      data,
      on: {
        200: onSuccess,
      },
      onFailure,
    })
  }

  static unsubscribeById = updateMethod('/users', 'unsubscribe_by_id')

  static subscribeById = updateMethod('/users', 'subscribe_by_id')

  static listByEmail = getMethod('/users/list_by_email')

  static unarchive({ id, onSuccess, onFailure }) {
    apiRequest({
      method: 'PUT',
      uri: `/api/users/${id}/unarchive`,
      data: {
        query: 'user { archived_at }',
      },
      onSuccess,
      onFailure,
    })
  }
}
