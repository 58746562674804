import * as API from './api'
import * as AuthenticationActions from './authentication'
import * as CalendarActions from './calendar'
import * as DateRangeActions from './date-range'
import * as DownloadActions from './download'
import * as HalfYearsActions from './half-years'
import * as HalfYearDateRangeActions from './half-year-date-range'
import * as ModalActions from './modals'
import * as Morphing from './morphing'
import * as Notifications from './notifications'
import * as OrganizationActions from './organization'
import * as RequiredActions from './required-actions'
import * as SidebarActions from './sidebar'
import * as TermsActions from './terms'
import * as TutorialActions from './tutorial'
import * as CustomizeWrapperActions from './customize-wrapper'
import * as ParentActions from './parent'

import { push, replace } from 'react-router-redux'

export default function (dispatch) {
  return {
    actions: {
      login: data => dispatch(AuthenticationActions.loginRequest(data)),
      logout: () => dispatch(AuthenticationActions.logoutRequest()),
      refreshUser: (...args) => dispatch(AuthenticationActions.refreshUser(...args)),
      loginWithToken: data => dispatch(AuthenticationActions.loginWithToken(data)),

      fetchOrganization: organizationId =>
        dispatch(OrganizationActions.fetchOrganization(organizationId)),
      setOrganization: organization => dispatch(OrganizationActions.setOrganization(organization)),
      clearOrganization: () => dispatch(OrganizationActions.clearOrganization()),

      setActiveSidebarItem: title => dispatch(SidebarActions.setActiveItem(title)),

      openModal: (modal, id = null) => () => dispatch(ModalActions.openModal(modal, { id })),
      closeModal: opts => dispatch(ModalActions.closeModal(opts)),

      updateTutorial: tutorial => dispatch(TutorialActions.updateTutorial(tutorial)),

      setCalendarEvent: event => dispatch(CalendarActions.setCalendarEvent(event)),
      clearCalendarEvent: () => dispatch(CalendarActions.clearCalendarEvent()),

      apiRequest: payload => dispatch(API.apiRequest(payload)),

      notify: payload => dispatch(Notifications.notify(payload)),
      notifyAndRedirect: payload => dispatch(Notifications.notifyAndRedirect(payload)),

      morph: id => dispatch(Morphing.morph(id)),
      unmorph: uri => dispatch(Morphing.unmorph(uri)),

      routePush: (...args) => dispatch(push(...args)),
      routeReplace: (...args) => dispatch(replace(...args)),

      downloadFederationMembershipReport: reportSubmissionId =>
        dispatch(DownloadActions.downloadFederationMembershipReport(reportSubmissionId)),
      requiredDocumentCSV: requiredDocumentId =>
        dispatch(DownloadActions.requiredDocumentCSV(requiredDocumentId)),
      reconcileOrganizationCSV: orgId => dispatch(DownloadActions.reconcileOrganizationCSV(orgId)),
      downloadSingleTransfer: federationTransferId =>
        dispatch(DownloadActions.downloadSingleTransfer(federationTransferId)),
      downloadAllTransfers: federationId =>
        dispatch(DownloadActions.downloadAllTransfers(federationId)),
      downloadPDFBill: memberId => dispatch(DownloadActions.pdfBill(memberId)),
      downloadRequiredDocumentAcceptance: id =>
        dispatch(DownloadActions.downloadRequiredDocumentAcceptance(id)),
      downloadBudgetPDF: (organizationId, termId) =>
        dispatch(DownloadActions.downloadBudgetPDF(organizationId, termId)),
      downloadOrganizationReport: (organizationId, startDate, endDate) =>
        dispatch(DownloadActions.downloadOrganizationReport(organizationId, startDate, endDate)),
      downloadAllAdjustments: (startDate, endDate) =>
        dispatch(DownloadActions.downloadAllAdjustments(startDate, endDate)),
      downloadAllBillPays: (startDate, endDate) =>
        dispatch(DownloadActions.downloadAllBillPays(startDate, endDate)),
      downloadOrganizationInvoicesPDF: (organizationId, defaultDueOn) =>
        dispatch(DownloadActions.organizationInvoicesPDF(organizationId, defaultDueOn)),
      downloadRegistrationCSV: (organizationId, id) =>
        dispatch(DownloadActions.registrationCSV(organizationId, id)),
      downloadTransactionsCSV: queryString =>
        dispatch(DownloadActions.transactionsCSV(queryString)),
      downloadMembersCSV: queryString => dispatch(DownloadActions.membersCSV(queryString)),
      downloadDonationsCSV: () => dispatch(DownloadActions.donationsCSV()),
      downloadOrganizationMembersCSV: queryString =>
        dispatch(DownloadActions.organizationMembersCSV(queryString)),
      downloadSalesforceRoster: sfId => dispatch(DownloadActions.downloadSalesforceRoster(sfId)),
      openNewBrowserTab: url => dispatch(DownloadActions.openNewBrowserTab(url)),

      fetchRequiredActions: () => dispatch(RequiredActions.fetchRequiredActions()),

      ensureTerms: organizationId => dispatch(TermsActions.ensureTerms(organizationId)),
      fetchTerms: organizationId => dispatch(TermsActions.fetchTerms(organizationId)),
      setTerms: (organizationId, terms) => dispatch(TermsActions.setTerms(organizationId, terms)),
      addTerm: term => dispatch(TermsActions.addTerm(term)),
      updateTerm: term => dispatch(TermsActions.updateTerm(term)),
      removeTerm: id => dispatch(TermsActions.removeTerm(id)),
      clearTerms: () => dispatch(TermsActions.setTerms()),

      setDateRange: ({ start, end }) => dispatch(DateRangeActions.setDateRange({ start, end })),

      ensureHalfYears: () => dispatch(HalfYearsActions.ensureHalfYears()),
      setHalfYears: halfYears => dispatch(HalfYearsActions.setHalfYears(halfYears)),

      setHalfYearDateRange: ({ start, end }) =>
        dispatch(HalfYearDateRangeActions.setHalfYearDateRange({ start, end })),

      setCustomWrapperStyles: styles =>
        dispatch(CustomizeWrapperActions.setCustomWrapperStyles(styles)),

      setActiveParent: id => dispatch(ParentActions.setActiveParent(id)),
    },
  }
}
