import T from './types';

export function bootstrap(data) {
  return {
    ...data,
    type: T.BOOTSTRAP,
  };
}

export function loginRequest(data) {
  return {
    ...data,
    type: T.LOGIN_REQUEST,
  };
}

export function loginSuccess(action) {
  return {
    ...action,
    type: T.LOGIN_SUCCESS,
  };
}

export function loginFailure({ status }) {
  return {
    type: T.LOGIN_FAILURE,
    status,
  };
}

export function loginCheck({ location }) {
  return {
    type: T.LOGIN_CHECK,
    location,
  };
}

export function loginRequired({ location }) {
  return {
    type: T.LOGIN_REQUIRED,
    location,
  };
}

export function logoutRequest(data = {}) {
  return {
    type: T.LOGOUT_REQUEST,
    returnTo: data.returnTo,
  };
}

export function logoutSuccess(data = {}) {
  return {
    type: T.LOGOUT_SUCCESS,
    returnTo: data.returnTo,
  };
}

export function refreshUser(data = {}) {
  return {
    type: T.REFRESH_USER,
    redirect: data.redirect || false,
  };
}

export function loginWithToken(data) {
  return {
    type: T.LOGIN_WITH_TOKEN,
    ...data,
  };
}
