import apiWrapper, { generatePayload } from './helpers';
import { apiRequest } from 'actions/use';

export default class Report extends apiWrapper('/reports') {

  static expectedMembers = ({ id, data, query, onSuccess, onFailure }) => {
    apiRequest({
      method: 'GET',
      uri: `/api/reports/${id}/expected_members`,
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    });
  }

}
