import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FaIcon, Units } from 'components/utilities';

export default function ({ reimbursements }) {
  return (
    reimbursements && (reimbursements.pending > 0) ? (
      <OverlayTrigger placement="right" overlay={(
        <Tooltip id="reimbursement-tooltip">
          <Units
            count={reimbursements.pending}
            noun="reimbursement is"
            plural="reimbursements are"
          /> awaiting your approval
        </Tooltip>
      )}>
        <FaIcon exclamation-circle warning className="pull-right" />
      </OverlayTrigger>
    ) : null
  );
}
