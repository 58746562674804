import React from 'react';
import PropTypes from 'prop-types';
import Container from 'container';
import { connect } from 'helpers';

const { arrayOf, number, shape, string } = PropTypes;
import Table, { Column } from 'components/table';
import { Name } from 'components/utilities';

class RegistrationList extends Container {

  static propsTypes = {
    event: shape({
      cost: number,
      event_questions: arrayOf({
        question: string.isRequired,
      }),
    }),
    eventRegistrations: arrayOf({
      amount: number.isRequired,
      tickets: number.isRequired,
      event_answers: arrayOf({
        question: string.isRequired,
        answer: string,
      }),
    }).isRequired,
  }

  emptyState = () => ({
    message: 'No registrations yet',
  })

  render() {
    const { event } = this.props;

    return (
      <Table
        csvName="EventRegistrations"
        data={this.props.eventRegistrations}
        noResults={this.renderEmptyState()}
      >

        <Column
          width={12}
          value={({ name, user }) => (user ? <Name user={user} natural /> : name)}
        >
          Name
        </Column>

        <Column
          width={12}
          value={({ email, user }) => _.get(user, 'email', email)}
        >
          Email
        </Column>

        <Column
          width={12}
          value={({ user }) => (_.get(user, 'member') ? <strong className="text-success">yes</strong> : 'no')}
        >
          Member?
        </Column>


        <Column
          width={12}
          value={({ tickets }) => (Number(tickets) === 0 ? 1 : tickets)}
        >
          Tickets
        </Column>

        { Number(event.cost) > 0 &&
          <Column
            width={12}
            value="amount"
            none={<span className="text-muted">none</span>}
            money
          >
            Amount charged
          </Column>
        }

        <Column
          width={12}
          value="created_at"
          date="MMM D, YYYY"
        >
          Registration
        </Column>

        { _.map(event.event_questions, ({ question }, index) =>
          <Column
            key={`Q${index}`}
            width={10}
            value={({ event_answers: answers }) => {
              const objAnswer = _.find(answers, a => a.question === question);
              return objAnswer ? objAnswer.answer : null;
            }}
            noSort
            none={<span className="text-muted">none</span>}
          >
            { question }
          </Column>
        )}

      </Table>
    );
  }
}

export default connect(RegistrationList);
