import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

import Label from 'components/utilities/label'

const { bool, func, string } = PropTypes

export default class Access extends React.Component {
  static propTypes = {
    granted: bool.isRequired,
    grantedByGroup: bool,
    onChange: func.isRequired,
    enabledLabel: string.isRequired,
    disabledLabel: string.isRequired,
  }

  render() {
    const {
      enabledLabel,
      disabledLabel,
      onChange,
      granted,
      grantedByGroup,
    } = this.props

    if (grantedByGroup) {
      return (
        <Link to="/admin/groups">
          <Label className="o-75" success>by group</Label>
        </Link>
      )
    }

    if (granted) {
      return (
        <Label success onClick={onChange}>
          {enabledLabel} &nbsp;
          <i className="fa fa-toggle-on" />
        </Label>
      )
    }

    return (
      <Label danger onClick={onChange}>
        {disabledLabel} &nbsp;
        <i className="fa fa-toggle-off" />
      </Label>
    )
  }
}
