import { apiRequest } from 'actions/use';
import apiWrapper, { generatePayload } from './helpers';

export default class BudgetExpense extends apiWrapper('/budget_expenses') {

  static archive({ id, query, onSuccess, onFailure }) {
    apiRequest({
      uri: `/api/budget_expenses/${id}`,
      method: 'DELETE',
      data: generatePayload({ query }),
      on: {
        204: onSuccess,
      },
      onFailure,
    });
  }

}
