import React from 'react';
import PropTypes from 'prop-types';
import Container from 'container';
import NumericInput from 'react-numeric-input';
import { connect } from 'helpers';
import { EventRegistration } from 'resources';
import { FormGroup } from 'react-bootstrap';

import formatMoney from 'utils/format_money';
import { Form, Field, SubmitButton } from 'components/form';
import { Money, Toggle } from 'components/utilities';
import parseFloatMoney from 'utils/parse-float';

import { CustomEventQuestions, PaymentMethods } from 'components/event';

const { func, object } = PropTypes;

class EventRegistrationForm extends Container {

  static propTypes = {
    event: object.isRequired,
    onEventRegistrationCreate: func.isRequired,
  }

  constructor(props) {

    const eventRegistration = {
      event_id: props.event.id,
      event_answers: props.event.event_questions,
      event_optional_addons: props.event.optional_addons,
    };

    super(props, {
      eventRegistration,
      variableAmount: '',
    });
  }

  getTotalAmount = () => {
    const { event } = this.props;
    const { eventRegistration } = this.state;

    if (!event.cost) {
      return null;
    }

    return event.cost * (eventRegistration.tickets || 1);
  }

  getTotalTicketsAmount = () => {
    const { event } = this.props;
    const { eventRegistration } = this.state;

    if (!event.cost) {
      return null;
    }

    return event.cost * (eventRegistration.tickets || 1);
  }

  onChangeEventAnswer = (value, index, field = 'answer') => {
    const { eventRegistration } = this.state;
    _.set(eventRegistration, `event_answers.[${index}].${field}`, value);
    this.setState({ eventRegistration });
  }

  onChangeEventCheckboxAnswer = (target, index) => {
    const { eventRegistration } = this.state;
    const newValue = target.checked ?
      [..._.get(eventRegistration, `event_answers.[${index}].answer`, []), target.value] :
      _.remove(_.get(eventRegistration, `event_answers.[${index}].answer`, []), ans => ans !== target.value);
    _.set(eventRegistration, `event_answers.[${index}].answer`, newValue);
    this.setState({ eventRegistration });
  }

  onNumberOfTicketsChange = (value) => {
    const { eventRegistration } = this.state;
    eventRegistration.tickets = value;
    this.setState({ eventRegistration });
  }

  onPaymentMethodCreate = (selectedPaymentMethod) => {
    this.listPrepend(selectedPaymentMethod);
    this.setState({ selectedPaymentMethod });
  }

  onChangeEventAddon = (index) => (e) => {
    const { eventRegistration } = this.state;
    const value = e > 0 ? e : 0;

    _.set(eventRegistration, `event_optional_addons.[${index}].quantity`, value);
    this.setState({ eventRegistration });
  }

  getOptionalQuantity = (index) => {
    const { eventRegistration: { event_optional_addons: eventOptionalAddons } } = this.state;
    return _.get(eventOptionalAddons[index], 'quantity', 0);
  }

  getOptionalTotalCost = (index) => {
    const { eventRegistration: { event_optional_addons: eventOptionalAddons } } = this.state;
    return eventOptionalAddons[index].cost * this.getOptionalQuantity(index);
  }

  getAllOptionalCost = () => {
    const { eventRegistration: { event_optional_addons: eventOptionalAddons } } = this.state;

    let total = 0;
    _.map(eventOptionalAddons, (opt, index) => {
      total += this.getOptionalTotalCost(index);
    });

    return total;
  }

  getTotalVariableAmount = () => {
    const { variableAmount, eventRegistration } = this.state;

    return parseFloatMoney(variableAmount) * (eventRegistration.tickets || 1);
  }

  totalCost = () => {
    const { event } = this.props;

    const costTickets = event.cost_type === 'variable' ? this.getTotalVariableAmount() : this.getTotalTicketsAmount();

    return this.getAllOptionalCost() + costTickets;
  }

  render = () => {
    const { event, onEventRegistrationCreate } = this.props;
    const { eventRegistration, selectedPaymentMethod, errors } = this.state;
    const oneTicketCost = event.cost_type === 'variable' ? 'free' : formatMoney(event.cost) || 'free';

    eventRegistration.payment_method_id = _.get(selectedPaymentMethod, 'id');

    return (
      <Form
        action={EventRegistration.create}
        query={`
          event_registration {
            member_id
            email
            name
            tickets
            amount
            event_answers
            event_optional_addons
            created_at
          }
        `}
        data={{ event_registration: eventRegistration }}
        onSuccess={onEventRegistrationCreate}
        onValidationErrors={this.updateStateSimple('errors')}
      >

        <hr />

        <CustomEventQuestions
          event={event}
          errors={_.get(errors, 'event_registration.event_answers.[0]')}
          onChangeEventAnswer={this.onChangeEventAnswer}
          onChangeEventCheckboxAnswer={this.onChangeEventCheckboxAnswer}
          openModal={this.props.actions.openModal}
          closeModal={this.props.actions.closeModal}
        />

        {!_.isNil(event.maximum_tickets) && (
          <div>
            <Field
              label={`Number of tickets (${oneTicketCost})`}
              labelFor="event_registration.tickets"
              errorKey="event_registration.tickets"
              id="event_registration.tickets"
              inputWrapClassName="is-flex p-l-0"
              value={eventRegistration.tickets || 1}
              inputSize={4}
            >
              <NumericInput
                className="form-control w-90"
                name="event_registration.tickets"
                mobile
                min={1}
                value={eventRegistration.tickets || 1}
                onChange={this.onNumberOfTicketsChange}
                onValid={this.onNumberOfTicketsChange}
              />
            </Field>
          </div>
        )}

        {event.cost_type === 'variable' && (
          <div className="clearfix false form-group has-feedback">
            <label
              htmlFor="event_registration.variable_amount"
              className="col-md-3 p-t-1 control-label"
            >
              Amount to give per ticket
            </label>

            <div className="col-md-7 p-t-1">
              <Field
                name="event_registration.variable_amount"
                onChange={this.updateStateSimple('variableAmount')}
                money
              />
            </div>
          </div>
        )}

        {_.map(event.optional_addons, (item, index) => (
          <div className="clearfix false form-group has-feedback is-flex" key={index}>
            <label
              htmlFor={`event_registration.addon[${index}]`}
              className="col-md-3 control-label"
            >
              {item.name} (<Money amount={item.cost} />)
            </label>
            <NumericInput
              name={`event_registration.addon[${index}]`}
              className="form-control w-90"
              min={0}
              value={this.getOptionalQuantity(index)}
              onChange={this.onChangeEventAddon(index)}
              mobile
              />
          </div>
        ))}

        {(event.cost || event.cost_type === 'variable' || this.getAllOptionalCost() > 0) && (
        <React.Fragment>
          <PaymentMethods
            costType={event.cost_type}
            totalCost={this.totalCost()}
            numberOfTickets={+eventRegistration.tickets || 1}
            ticketsCost={this.getTotalTicketsAmount()}
            addons={eventRegistration.event_optional_addons}
            selectedPaymentMethod={selectedPaymentMethod}
            onPaymentMethodSelect={this.updateStateSimple('selectedPaymentMethod')}
          />

          <FormGroup validationState={_.get(errors, 'event_registration._.0') ? 'error' : null}>
            <Toggle show={!!_.get(errors, 'event_registration._.0')}>
              <span className="help-block text-danger m-l-1">{_.get(errors, 'event_registration._.0')}</span>
            </Toggle>
            <Toggle show={!!_.get(errors, 'event_registration.payment_method_id.0')}>
              <span className="help-block text-danger m-l-1">{_.get(errors, 'event_registration.payment_method_id.0')}</span>
            </Toggle>
          </FormGroup>
        </React.Fragment>
        )}

        <hr />

        <SubmitButton>
          {event.purpose === 'event' ? 'Register' : 'Submit'}
        </SubmitButton>

      </Form>
    );
  }

}

export default connect(EventRegistrationForm);
