import React from 'react';
import connect from './connect';
import { unserializeMetadata } from 'utils/parse-metadata';

const withResetBeforeShow = ModalComponent => props => {
  if (props.show === false && !props.showAlways) return null;

  return <ModalComponent {...props} />;
};

export default function (id, modal, mapStateToPropsFunc) {
  const mapStateToProps = (state, ownProps) => {
    const hash = state.get('route').get('locationBeforeTransitions').get('hash');
    const modalIds = hash.substring(1).split(';');
    const modals = modalIds.map(modalId => {
      const parts = modalId.split('/', 2);

      return {
        id: parts[0],
        metadata: parts[1],
      };
    });

    const currentModal = modals.find(m => {
      const idToMatch = ownProps.id ? `${id}:${ownProps.hashPrefix || ''}${ownProps.id}` : id;

      return m.id === idToMatch;
    });

    const metadata = unserializeMetadata(_.get(currentModal, 'metadata', ''));
    const user = _.get(state.get('auth'), 'user');

    const data = { show: currentModal !== undefined, metadata, user };

    if (mapStateToPropsFunc) {
      _.assign(data, mapStateToPropsFunc(state, ownProps));
    }

    return data;
  };

  return connect(withResetBeforeShow(connect(modal)), mapStateToProps);
}
