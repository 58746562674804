import { apiRequest } from 'actions/use';
import apiWrapper, { generatePayload } from './helpers';

export default class Parent extends apiWrapper('/parents') {

  static updateDeliverySettings({ id, data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'PUT',
      uri: `/api/parents/${id}/delivery`,
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    });
  }

}
