import { apiRequest } from 'actions/use';
import apiWrapper, { generatePayload } from './helpers';

export default class CalendarEvent extends apiWrapper('/calendar_events') {

  static asyncList({ data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'GET',
      uri: '/api/calendar_events/async_list',
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    });
  }

  static asyncCreate({ data, onSuccess, onFailure }) {
    apiRequest({
      method: 'POST',
      uri: '/api/calendar_events/async_create',
      data,
      on: {
        201: onSuccess,
      },
      onFailure,
    });
  }

  static asyncUpdate({ id, data, onSuccess, onFailure }) {
    apiRequest({
      method: 'PUT',
      uri: `/api/calendar_events/${id}/async_update`,
      data,
      on: {
        200: onSuccess,
      },
      onFailure,
    });
  }

  static asyncArchive({ id, data, onSuccess, onFailure }) {
    apiRequest({
      method: 'DELETE',
      uri: `/api/calendar_events/${id}/async_archive`,
      data,
      on: {
        204: onSuccess,
      },
      onFailure,
    });
  }

  static batchCreate({ data, onSuccess, onFailure }) {
    apiRequest({
      method: 'POST',
      uri: '/api/calendar_events/batch_create',
      data,
      on: {
        201: onSuccess,
      },
      onFailure,
    });
  }
}
