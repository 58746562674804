import Humanize from 'humanize-plus';

export default function (number, opts = {}) {
  const absolute = Math.abs(number);
  const formatted = Humanize.formatNumber(absolute, 2);

  if (formatted === '0.00') {
    return '$0.00';
  }

  const prefixPositive = opts.showPositive && number > 0 ? '+' : '';
  const prefixNegative = opts.showNegative && number < 0 ? '-' : '';

  const complete = `${prefixPositive}${prefixNegative}$${formatted}`;

  if (opts.strip) {
    return complete.replace(/\.00$/, '');
  }

  return complete;
}
