import React from 'react';
import PropTypes from 'prop-types';
import Humanize from 'humanize-plus';
import classnames from 'classnames';

import Field from 'components/form/field';

export default class Credit extends React.Component {

  static propTypes = {
    balances: PropTypes.object,
    onCreditAmountChange: PropTypes.func,
  }

  static contextTypes = {
    accounts: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      credits: _.mapValues(props.balances, () => 0),
    };
  }

  onCreditChange = (value, name) => {
    const amount = value.replace(/[^0-9.]+/, '');
    const accountId = name.replace(/^.+\.([0-9]+)$/, '$1');
    this.setState(state => _.assign(state.credits, {
      [accountId]: parseFloat(amount) || 0,
    }), this.updateButtonText);
  }

  getBalanceHint = accountId => {
    const { balances } = this.props;
    if (!balances) {
      return null;
    }

    const balance = balances[accountId];
    if (balance === 0) {
      return '$0 balance';
    }

    const formatted = Humanize.formatNumber(Math.abs(balance), 2);
    return balance > 0 ? `$${formatted} balance` : `+$${formatted} credit`;
  }

  updateButtonText = () => {
    const sum = _.chain(this.state.credits).values().sum().value();
    this.props.onCreditAmountChange(sum);
  }

  renderAccounts = () => (
    <div className={classnames({ 'thin-form-groups': this.state.memberIds })}>
      {_.map(this.context.accounts, ({ id, name }) => (
        <Field
          key={id}
          name={`transaction.amounts.${id}`}
          money
          label={name}
          hint={this.getBalanceHint(id)}
          onChange={this.onCreditChange}
          className="input-sm"
        />
      ))}
    </div>
  )

  render = () => (
    <div>

      <input
        type="hidden"
        name="transaction.organization_id"
        value={this.props.organizationId}
      />

      <Field
        name="transaction.description"
        label="Description"
        autoFocus
      />

      <hr />

      {this.renderAccounts()}

    </div>
  )

}
