import { call, put } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { GCM_ENVIRONMENT } from 'helpers';

import { NotificationManager } from 'react-notifications';

export function* notify(action) {
  const { type, title, message } = action.payload;
  let { duration } = action.payload;

  if (GCM_ENVIRONMENT === 'test') {
    duration = 15000;
  }

  yield call(NotificationManager[type || 'success'].bind(NotificationManager), message, title, duration);
}


export function* notifyAndRedirect(action) {
  const { location } = action.payload;

  yield call(notify, action);
  yield put(push(location));
}
