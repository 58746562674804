import { put } from 'redux-saga/effects'
import { push, replace } from 'react-router-redux'

import {
  loginSuccess,
  loginFailure,
  loginRequired,
  logoutSuccess,
  logoutRequest,
} from '../actions/authentication'

import { clearOrganization } from '../actions/organization'

import api from './api'
import { setParents } from '../actions/parent'

export function* login({ credentials, returnTo }) {
  try {
    const { data } = yield api({
      method: 'post',
      uri: '/api/user_token',
      data: {
        auth: credentials,
      },
    })

    /* global fbq */
    if ('fbq' in window) {
      fbq('trackCustom', 'Login')
    }

    localStorage.setItem('token', data.jwt)

    yield put(loginSuccess({ returnTo, interactive: true }))
  } catch (error) {
    if ('resetPassword' in error.headers) {
      yield put(push(`/forgot-password?security=true&email=${credentials.identifier}`))
    } else {
      yield put(loginFailure(error))
    }
  }
}

export function* logout({ returnTo }) {
  localStorage.clear()
  yield put(logoutSuccess({ returnTo }))
  yield put(clearOrganization())
}

export function* checkLogin({ location }) {
  try {
    if (localStorage.getItem('token')) {
      yield put(
        loginSuccess({
          returnTo: location,
        })
      )
    } else {
      yield put(loginRequired({ location }))
    }
  } catch (error) {
    yield put(
      logoutRequest({
        returnTo: location,
      })
    )
  }
}

export function* required({ location }) {
  const query = {}
  if (location !== '/') {
    query.return = location
  }
  yield put(
    replace({
      pathname: '/login',
      query,
    })
  )
}

export function* bootstrap({ redirect, returnTo, user }) {
  if (redirect !== false) {
    let location = returnTo || '/'

    const index = location.indexOf('/super')
    if ((user.is_root && index !== 0) || (!user.is_root && index === 0)) {
      location = '/'
    }

    localStorage.setItem('role', user.role)

    if (user.role === 'parent') {
      yield put(setParents(user.parents))
    }

    yield put(push(location))
  }
}

export function* loggedOut({ returnTo }) {
  yield put(
    replace({
      pathname: '/login',
      query: {
        return: returnTo,
      },
    })
  )
}

export function* loginWithToken(data) {
  try {
    localStorage.setItem('token', data.jwt)

    yield put(loginSuccess())
  } catch (e) {
    yield put(logoutRequest())
  }
}
