import apiWrapper, { list } from './helpers'

export default class MessageCategory extends apiWrapper('/message_categories') {
  static listByUser(opts) {
    list('/message_categories/list_by_user', opts)
  }

  static listByAlumni(opts) {
    list('/message_categories/list_by_alumni', opts)
  }
}
