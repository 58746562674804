import React from 'react'
import _ from 'lodash'

import { LinkContainer } from 'react-router-bootstrap'
import { Button } from 'react-bootstrap'

export function FaStack({ size, children }) {
  const classes = `fa-stack ${size || ''}`
  return <span className={classes}>{children}</span>
}

export function FaIcon(props) {
  const passThruProps = {}
  const classes = _.map(props, (val, prop) => {
    if (prop === 'icon') {
      return `fa-${val}`
    } else if (prop === 'size') {
      return `fa-${val}`
    } else if (prop === 'className') {
      return val
    } else if (['success', 'warning', 'danger'].indexOf(prop) > -1) {
      return `text-${prop}`
    } else if (prop.match(/^on/)) {
      passThruProps[prop] = val
    } else if (prop === 'style') {
      passThruProps.style = val
    }
    return `fa-${prop}`
  })
  return <i className={`fa ${classes.join(' ')}`} {...passThruProps} />
}

export function FaButton(props) {
  const { children, icon } = props

  return (
    <Button {...props}>
      <FaIcon icon={icon} />
      {!children || `${String.fromCharCode(160)} ${children}`}
    </Button>
  )
}

export function FaLink(props) {
  return (
    <LinkContainer to={props.to}>
      <FaButton {...props} />
    </LinkContainer>
  )
}

export default function (props) {
  if ('stack' in props) {
    const { front, back } = props
    return (
      <FaStack size={props.size}>
        <FaIcon {...{ front }} stack-2a />
        <FaIcon {...{ back }} stack-1x />
      </FaStack>
    )
  }

  return <FaIcon {...props} />
}
