import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FaIcon, Units } from 'components/utilities';

export default function ({ count, className }) {
  if (!count) {
    return null;
  }

  return (
    <OverlayTrigger
      placement="right"
      overlay={(
        <Tooltip id="payment-plan-tooltip">
          <Units
            count={count}
            noun="payment plan is"
            plural="payment plans are"
          /> pending
        </Tooltip>
      )}
    >
      <FaIcon
        exclamation-circle
        warning
        className={className || 'pull-right'}
      />
    </OverlayTrigger>
  );
}
