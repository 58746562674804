import T from './types';

export function notify(input) {
  const payload = _.isString(input) ? { message: input } : input;
  return {
    type: T.NOTIFY_REQUEST,
    payload,
  };
}


export function notifyAndRedirect(payload) {
  return {
    type: T.NOTIFY_AND_REDIRECT,
    payload,
  };
}
