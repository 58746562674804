import { repeat } from 'lodash'

window.debug = true

console.pretty = (...args) =>
  _.each(args, arg => console.log(JSON.stringify(arg, null, 2))) && false

console.styled = (msg, style, ...payload) => {
  if (!window.debug) {
    return
  }
  console.log(`%c${msg}`, style, ...payload)
}

console.wrap = (msg, style, ...payload) => {
  if (!window.debug) {
    return
  }
  let formatted = '\n'
  formatted += repeat('*', msg.length + 10)
  formatted += `\n**   ${msg}   **\n`
  formatted += `${repeat('*', msg.length + 10)}\n`
  if (payload.length > 0) {
    payload.forEach(data => {
      formatted += `${JSON.stringify(data, null, 2)}\n`
    })
    formatted += `${repeat('*', msg.length + 10)}\n`
  }
  console.log(`%c${formatted}`, style)
}

const colors = ['green', 'blue', 'orange', 'red']
colors.forEach(
  color =>
    (console[color] = (msg, ...payload) => {
      console.wrap(msg, `color: ${color}`, ...payload)
    })
)
