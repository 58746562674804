import { apiRequest } from 'actions/use';
import apiWrapper, { generatePayload } from './helpers';

export default class Budget extends apiWrapper('/budgets') {

  static users({ id, data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'PUT',
      uri: `/api/budgets/${id}/users`,
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    });
  }

}
