import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const placeholder = require('images/etc/avatar.svg');

export default class Avatar extends React.Component {

  static propTypes = {
    user: PropTypes.object.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    className: PropTypes.string,
    onClick: PropTypes.func,
  }

  render = () => {
    const { user, width, height, className, onClick } = this.props;

    let url = null;
    if (user.avatar) {
      url = user.avatar.public_url;
    } else if (user.user && user.user.avatar) {
      url = user.user.avatar.public_url;
    }

    return (
      <img
        src={url || placeholder}
        width={width}
        height={height}
        role="presentation"
        className={classnames(className, {
          'avatar-placeholder': _.isNull(url),
          'c-pointer': onClick,
        })}
        onClick={onClick}
      />
    );
  }

}
