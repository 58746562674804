import T from './types';

export function fetchOrganization(organizationId, location) {
  return {
    type: T.FETCH_ORGANIZATION,
    organizationId,
    location,
  };
}

export function setOrganization(organization) {
  return {
    type: T.SET_ORGANIZATION,
    organization,
  };
}

export function clearOrganization() {
  return {
    type: T.CLEAR_ORGANIZATION,
  };
}
