import { connectWithDateRange } from 'helpers'

export default function (Component) {
  function mapStateToProps(state) {
    return {
      ...state.get('parent'),
    }
  }

  return connectWithDateRange(Component, mapStateToProps)
}
