/* eslint-disable new-cap */
import React from 'react'
import { Route, IndexRedirect, IndexRoute } from 'react-router'
import { ensureRole } from '../helpers'

import AlumniAdvisingRoutes from './advising.js'

export default function alumniRoutes(store) {
  const Alumni = path => (_, cb) =>
    require.ensure([], require => cb(null, require('../../chunks/alumni')[path]))

  return (
    <Route path="alumni" onEnter={ensureRole(store, 'alumni')}>
      <IndexRedirect to="donate" />

      {AlumniAdvisingRoutes()}

      <Route path="donate" getComponent={Alumni('Donate')} />
      <Route path="document-folders" getComponent={Alumni('DocumentFolders')} />
      <Route path="document-folders/:id" getComponent={Alumni('DocumentFolders/show')} />
      <Route path="calendar" getComponent={Alumni('Calendar')} />
      <Route path="event-registrations" getComponent={Alumni('EventRegistrations')} />
      <Route path="event-registrations/:id/new" getComponent={Alumni('EventRegistrations/new')} />
      <Route path="groups" getComponent={Alumni('Groups')} />

      <Route path="settings" getComponent={Alumni('Settings')}>
        <IndexRedirect to="profile" />
        <Route path="profile" getComponent={Alumni('Settings/Profile')} />
        <Route path="password" getComponent={Alumni('Settings/Password')} />
        <Route path="payment-methods" getComponent={Alumni('Settings/PaymentMethods')} />
        <Route path="recurring-payments" getComponent={Alumni('Settings/RecurringPayments')} />
      </Route>
      <Route path="group-members/:id" getComponent={Alumni('GroupMembers')} />
      <Route path="group-members/:id/page/:page" getComponent={Alumni('GroupMembers')} />
      <Route path="forms" getComponent={Alumni('Forms')} />

      <Route path="message-board">
        <IndexRoute getComponent={Alumni('MessageCategories')} />
        <Route path=":id">
          <IndexRoute getComponent={Alumni('MessageCategories/show')} />
          <Route path="folders/:folder_id" getComponent={Alumni('MessageCategories/folder')} />
          <Route path="threads/:thread_id" getComponent={Alumni('MessageThreads/show')} />
        </Route>
      </Route>

      <Route path="voting">
        <IndexRoute getComponent={Alumni('Voting')} />
        <Route path=":id" getComponent={Alumni('Voting/show')} />
      </Route>
    </Route>
  )
}
