import React, { PureComponent } from 'react'
import moment from 'moment'
import { string } from 'prop-types'

class EventDate extends PureComponent {
  static propTypes = {
    startDate: string,
    endDate: string,
  }

  constructor(props) {
    super(props)
    const { startDate, endDate } = props

    this.state = {
      startDate: moment(startDate).utcOffset(-4),
      endDate: moment(endDate).utcOffset(-4),
    }
  }

  isSameDay = () => {
    const { startDate, endDate } = this.state
    return startDate.isSame(endDate, 'day')
  }

  renderTime = time => {
    if (time.minute() === 0) {
      return time.format('ha')
    }
    return time.format('h:mm a')
  }

  renderYear = date => {
    const { startDate } = this.state
    if (moment().isSame(startDate, 'year')) {
      return ''
    }

    return `, ${date.format('YYYY')}`
  }

  renderDate = () => {
    const { startDate, endDate } = this.state

    if (this.isSameDay()) {
      return `Event date: ${startDate.format('MMMM Do')}${this.renderYear(startDate)}
      from ${this.renderTime(startDate)} to ${this.renderTime(endDate)}`
    }

    return `Event dates: ${startDate.format('MMM Do')}${this.renderYear(
      startDate
    )} at ${this.renderTime(startDate)}
        to ${endDate.format('MMM Do')}${this.renderYear(endDate)} at ${this.renderTime(endDate)}`
  }

  render() {
    const { startDate, endDate } = this.state

    if (!startDate && !endDate) return null

    return <p className="m-b-2">{this.renderDate()}</p>
  }
}

export default EventDate
