import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router'

import { FaIcon } from 'components/utilities'

class Item extends React.Component {
  render() {
    const {
      isActive,
      icon,
      backgroundIcon,
      url,
      onClick,
      children,
      aside,
      asideType,
      multiLine,
    } = this.props

    const asideEl = aside ? (
      <span key="asideEl" className={`label label-${asideType || 'secondary'} pull-right`}>
        {aside}
      </span>
    ) : null

    const className = (isActive ? 'active' : '') + (multiLine ? ' multi-line' : '')
    const childNodes = multiLine ? <span key="childNodes">{children}</span> : children

    const iconEl =
      'backgroundIcon' in this.props ? (
        <span key="iconEl" className="fa-stack">
          <i className={`fa fa-stack-2x fa-${backgroundIcon}`}></i>
          <i className={`fa fa-stack-1x fa-${icon}`}></i>
        </span>
      ) : (
        <FaIcon key="iconEl" icon={icon} />
      )

    const itemChildren = [iconEl, childNodes, asideEl]

    let link
    if (!url) {
      link = <a onClick={onClick} children={itemChildren} className="c-pointer" />
    } else if (url.match(/^(mail|tel):/)) {
      link = <a href={url} children={itemChildren} />
    } else {
      link = <Link to={url} children={itemChildren} />
    }

    return <li className={className}>{link}</li>
  }
}

Item.propTypes = {
  isActive: PropTypes.bool,
  icon: PropTypes.string,
  url: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  aside: PropTypes.node,
  asideType: PropTypes.string,
  multiLine: PropTypes.bool,
}

function mapStateToProps(state, { isActive, id, children }) {
  return {
    isActive: isActive || state.get('sidebarActiveItem') === _.defaultTo(id, children),
  }
}

export default connect(mapStateToProps)(Item)
