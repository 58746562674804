import { apiRequest } from 'actions/use';
import apiWrapper, { generatePayload } from './helpers';

export default class PasswordReset extends apiWrapper('/password_resets') {

  static use({ data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'PUT',
      uri: '/api/password_resets/use',
      data: generatePayload({ data, query }),
      onSuccess,
      onFailure,
    });
  }

}
