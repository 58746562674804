/* global Rollbar */
import React from 'react';
import PropTypes from 'prop-types';

import { InputGroup, FormControl, Radio } from 'react-bootstrap';
import { FaIcon } from 'components/utilities';
import Field from 'components/form/field';

const { array, bool, func, number, object } = PropTypes;

export default class Charge extends React.Component {

  static propTypes = {
    accountId: number,
    dueDate: object,
    openModal: func.isRequired,
    formData: object,
    inputChange: func,
  }

  static contextTypes = {
    accounts: array.isRequired,
    moreOptions: bool.isRequired,
  }

  renderAccounts = () => {
    const { accounts } = this.context;
    const { accountId, formData } = this.props;

    if (_.size(accounts) === 0) {
      Rollbar.error('No accounts were found when rendering AddTransaction#Charge modal');
      return null;
    }

    if (_.size(accounts) === 1) {
      return (
        <input
          type="hidden"
          name="transaction.account_id"
          value={accounts[0].id}
        />
      );
    }

    const defaultAccount = _.last(accounts);
    let defaultAccountId = _.defaultTo(_.get(formData, 'transaction.account_id'), defaultAccount.id);
    defaultAccountId = _.toNumber(defaultAccountId);

    if (!accountId) {
      return (
        <Field name="transaction.account_id" label="Apply to">
          {_.map(accounts, ({ id, name }) => (
            <Radio
              key={id}
              id={id}
              name="transaction.account_id"
              value={id}
              defaultChecked={id === defaultAccountId}
              onChange={this.props.inputChange}
            >{name}</Radio>
          ))}
        </Field>
      );
    }

    let account = _.find(accounts, a => a.id === this.props.accountId);
    if (!account) {
      account = _.last(accounts);
    }

    return (
      <Field name="transaction.account_id" label="Applied to">
        <div className="m-t-05">
          {account.name}
        </div>
        <input
          type="hidden"
          name="transaction.account_id"
          value={account.id}
        />
      </Field>
    );
  }

  renderMoreOptions = () => {
    if (!this.context.moreOptions) {
      return null;
    }

    const { dueDate, openModal } = this.props;

    return (<div>
      <hr />

      <Field
        name="transaction.due_on"
        label="Due date"
        inputSize={5}
      >
        <InputGroup>

          <input
            type="hidden"
            name="transaction.due_on"
            value={dueDate ? dueDate.format('YYYY-MM-DD') : ''}
          />

          <FormControl
            id="transaction.due_on"
            type="text"
            value={dueDate ? dueDate.format('MMM D, YYYY') : ''}
            readOnly
          />
          <InputGroup.Addon onClick={openModal('DatePicker')} className="btn bg-white">
            <FaIcon calendar />
          </InputGroup.Addon>
        </InputGroup>
      </Field>

      <Field
        name="transaction.late_fee"
        label="Late fee"
        inputSize={5}
        disabled={_.isNull(dueDate)}
        money
      />

    </div>);
  }

  render = () => (
    <div>

      <Field
        name="transaction.description"
        label="Description"
        autoFocus
      />

      <Field
        name="transaction.amount"
        label="Amount"
        inputSize={5}
        money
      />

      {this.renderAccounts()}

      {this.renderMoreOptions()}

    </div>
  )

}
