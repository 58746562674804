import apiWrapper, { generatePayload } from './helpers';
import { apiRequest } from 'actions/use';

export default class PaymentCategory extends apiWrapper('/payment_categories') {

  static updateOrdering = ({ data, onSuccess, onFailure }) => {
    apiRequest({
      method: 'PUT',
      uri: '/api/payment_categories/update_ordering',
      data: generatePayload({ data }),
      on: {
        200: onSuccess,
      },
      onFailure,
    });
  };

}
