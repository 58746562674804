import T from '../actions/types';

export default (state = null, action) => {
  switch (action.type) {

    case T.TUTORIAL:
      return action.tutorial;

    default:
      return state;
  }
};
