import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Overlay, InputGroup, FormControl, Popover } from 'react-bootstrap'
import moment from 'moment'
import { FaIcon } from 'components/utilities'
import DatePickerPopover from './date-picker-popover'

const { func, string, oneOfType, bool } = PropTypes

class DatePickerPopoverInput extends Component {
  static propTypes = {
    value: string,
    onChange: func.isRequired,
    renderFormat: string,
    outFormat: string,
    label: string,
    id: oneOfType([string, bool]),
    className: string,
    placement: string,
    placeholder: string,
    inputClassName: string,
    disabled: bool,
  }

  static defaultProps = {
    renderFormat: 'MMM D, YYYY',
    outFormat: 'YYYY-MM-DD',
    label: '',
    className: '',
    placement: 'top',
  }

  state = {
    showPopover: false,
  }

  close = () => {
    this.setState({ showPopover: false })
  }

  onDateSelected = date => {
    const { onChange, outFormat } = this.props
    onChange(date && moment(date).format(outFormat))
  }

  renderLabel() {
    const { label, id } = this.props
    return (
      label && (
        <label className="control-label" htmlFor={id}>
          {label}
        </label>
      )
    )
  }

  render() {
    const {
      value,
      id,
      renderFormat,
      placement,
      placeholder,
      className,
      inputClassName,
      disabled,
    } = this.props
    const { showPopover } = this.state

    return (
      <FormGroup className={`datepicker-tooltip ${className}`}>
        {this.renderLabel()}

        <InputGroup
          onClick={() => {
            this.setState({ showPopover: !showPopover })
          }}
          style={{ cursor: 'pointer' }}
        >
          <FormControl
            id={id}
            value={value ? moment(value).format(renderFormat) : ''}
            type="text"
            placeholder={placeholder}
            readOnly
            style={{ cursor: 'pointer' }}
            className={inputClassName}
            disabled={disabled}
          />
          <InputGroup.Addon className="bg-white">
            <FaIcon calendar />
          </InputGroup.Addon>
        </InputGroup>
        <Overlay trigger="click" show={showPopover} target={this} placement={placement}>
          <Popover id={`date-time-popover-${id}`} className="date-time-popover" placement="top">
            <DatePickerPopover
              date={(value && moment(value)) || moment().add(1, 'day')}
              onDateSelected={this.onDateSelected}
              close={this.close}
            />
          </Popover>
        </Overlay>
      </FormGroup>
    )
  }
}

export default DatePickerPopoverInput
