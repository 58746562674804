import React from 'react';
import PropTypes from 'prop-types';
import { modal } from 'helpers';
import { User } from 'resources';

import { Modal, Button } from 'react-bootstrap';
import { RemoteSelect } from 'components/form';

const { arrayOf, func, object, number, string } = PropTypes;

class ManageAccessModal extends React.Component {

  static propTypes = {
    title: string.isRequired,
    info: string,
    button: string.isRequired,
    scope: object.isRequired,
    userIds: arrayOf(number).isRequired,
    onSaveAccess: func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      userIds: props.userIds,
    };
  }

  onChange = users => {
    const userIds = _.map(users, 'id');
    this.setState({ userIds });
  }

  onSaveAccess = () => {
    const { userIds } = this.state;
    this.props.onSaveAccess(userIds);
  }

  render() {
    const { closeModal } = this.props.actions;
    const { title, info, button, scope } = this.props;

    return (
      <Modal show onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {info ? (
            <h6 className="text-center m-b-1">{info}</h6>
          ) : null}

          <RemoteSelect
            resource={User}
            scope={scope}
            query={`
              user {
                first_name
                last_name
              }
            `}
            selectedIds={this.state.userIds}
            selectFirst={false}
            name="name"
            display="name"
            onChange={this.onChange}
            placeholder="Select users&hellip;"
            multiple
            mapListBy={{
              name: (_, user) => `${user.last_name}, ${user.first_name}`,
            }}
            sortListBy="name"
          />

        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={this.onSaveAccess}>{button}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}

export default modal('ManageAccess', ManageAccessModal);
