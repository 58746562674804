import React from 'react';
import { bool, func, object, oneOfType, string } from 'prop-types';

import Container from 'container';
import AsyncTypeahead from 'components/typeahead/AsyncTypeahead';

export default class AsyncRemoteSelect extends Container {

  static propTypes = {
    resource: func.isRequired,
    action: string.isRequired,
    onChange: func.isRequired,
    labelKey: oneOfType([
      string,
      func,
    ]),
    searchParam: string.isRequired,
    scope: object,
    query: string.isRequired,
    placeholder: string,
    multiple: bool,
    useCache: bool,
  }

  static defaultProps = {
    searchParam: 'term',
  }

  constructor(props) {
    super(props, {
      options: [],
      search: '',
    });

    this.resources.list = props.resource;
    this.listResourceAction = props.action || 'list';

    const funcs = ['filterListBy', 'mapListBy', 'sortListBy'];
    _.forEach(funcs, (fn) => {
      if (fn in this.props) {
        this[fn] = this.props[fn];
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    super.componentWillReceiveProps(nextProps);

    const funcs = ['filterListBy', 'mapListBy', 'sortListBy'];
    _.forEach(funcs, (fn) => {
      if (fn in nextProps) {
        this[fn] = nextProps[fn];
      }
    });
  }

  get listScope() {
    const { searchParam } = this.props;
    const { search } = this.state;

    const scope = _.merge(this.props.scope, {
      [searchParam]: search,
    });

    return scope;
  }

  get listQuery() {
    return this.props.query;
  }

  onSearch = search => this.setState({ search }, this.doResourceList.bind(this))

  onInputChange = () => this.listSet(null)

  render = () => {
    const omit = ['scope', 'query', 'resource', 'action', 'searchParam', 'filterListBy', 'mapListBy', 'sortListBy'];
    const rest = _.omit(this.props, omit);
    const options = this.getList();

    return (
      <AsyncTypeahead
        isLoading={_.isNull(options)}
        options={options || []}
        onSearch={this.onSearch}
        onInputChange={this.onInputChange}
        filterBy={() => true}
        defaultActiveIndex={0}
        {...rest}
      />
    );
  }

}
