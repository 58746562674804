/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import parseInput from './utils/parseInput.js';
import Calendar from './Calendar.js';
import PredefinedRanges from './PredefinedRanges.js';
import getTheme, { defaultClasses } from './styles.js';

class DateRange extends Component {

  constructor(props, context) {
    super(props, context);

    const { format, linkedCalendars, theme } = props;

    const startDate = parseInput(props.startDate, format, 'startOf');
    const endDate   = parseInput(props.endDate, format, 'endOf');

    this.state = {
      range     : { startDate, endDate },
      link      : linkedCalendars && endDate,
    }

    this.styles = getTheme(theme);
  }

  componentDidMount() {
    const { onInit } = this.props;
    onInit && onInit(this.state.range);
  }

  orderRange(range) {
    const { startDate, endDate } = range;
    const swap = startDate.isAfter(endDate);

    if (!swap) return range;

    return {
      startDate : endDate,
      endDate   : startDate
    }
  }

  setRange(range, source, name) {
    const { onChange } = this.props
    range = this.orderRange(range);

    this.setState({ range });

    onChange && onChange(range, source, name);
  }

  handleSelect = identifier => (date, source, name) => {

    // A pre-defined range was set
    if (date.startDate && date.endDate) {
      return this.setRange(date, source, name);
    }

    let { startDate, endDate } = this.state.range;

    if (identifier === 0) {
      startDate = date;
    } else {
      endDate = date;
    }

    const range = { startDate, endDate };

    this.setRange(range, source, name);
  }

  handleLinkChange(direction) {
    const { link } = this.state;

    this.setState({
      link : link.clone().add(direction, 'months')
    });
  }

  componentWillReceiveProps(newProps) {
    const format       = newProps.format || this.props.format;
    const startDate    = newProps.startDate   && parseInput(newProps.startDate, format, 'startOf');
    const endDate      = newProps.endDate     && parseInput(newProps.endDate, format, 'endOf');
    const oldStartDate = this.props.startDate && parseInput(this.props.startDate, format, 'startOf');
    const oldEndDate   = this.props.endDate   && parseInput(this.props.endDate, format, 'endOf');

    // Whenever date props changes, update state with parsed variant
    if (newProps.startDate || newProps.endDate) {
      if (!startDate.isSame(oldStartDate) || !endDate.isSame(oldEndDate)) {
        this.setRange({
          startDate: startDate || oldStartDate,
          endDate: endDate || oldEndDate
        });
      }
    }
  }

  isSameMonth(dateOne, dateTwo) {
    return dateOne.clone().startOf('month').isSame(dateTwo.clone().startOf('month'));
  }

  isThisMonth(date) {
    return moment().startOf('month').isSame(date.clone().startOf('month'));
  }

  render() {
    const { ranges, format, linkedCalendars, style, calendars, firstDayOfWeek, minDate, maxDate, classNames, onlyClasses, lang, disableDaysBeforeToday, offsetPositive, shownDate, showMonthArrow } = this.props;
    const { range, link } = this.state;
    const { styles } = this;

    const classes = { ...defaultClasses, ...classNames };

    const calendarMonths = [];
    if (!this.isSameMonth(range.startDate, range.endDate)) {
      // 1. months are different (show each)
      calendarMonths.push(range.startDate.clone());
      calendarMonths.push(range.endDate.clone());
    } else if (this.isSameMonth(range.startDate, range.endDate)) {
      // 2. months are same and months is same as maxDate (show last month and maxDate month)
      calendarMonths.push(range.startDate.clone().subtract(1, 'month'));
      calendarMonths.push(range.endDate.clone());
    } else {
      // 3. everything else (show last month and this month)
      calendarMonths.push(moment().clone().subtract(1, 'month'));
      calendarMonths.push(moment().clone());
    }

    return (
      <div style={onlyClasses ? undefined : { ...styles['DateRange'], ...style }} className={classes.dateRange}>
        { ranges && (
          <PredefinedRanges
            format={ format }
            ranges={ ranges }
            range={ range }
            theme={ styles }
            onSelect={ this.handleSelect() }
            onlyClasses={ onlyClasses }
            classNames={ classes }
          />
        )}

        <div className="rdr-Wrapper">
          {(()=>{
            const _calendars = [];
            _.times(2, (i) => {
              _calendars.push(
                <Calendar
                  showMonthArrow={ showMonthArrow }
                  shownDate={ calendarMonths[i] }
                  disableDaysBeforeToday={ disableDaysBeforeToday }
                  lang={ lang }
                  key={i}
                  link={ linkedCalendars && link }
                  linkCB={ this.handleLinkChange.bind(this) }
                  range={ range }
                  format={ format }
                  firstDayOfWeek={ firstDayOfWeek }
                  theme={ styles }
                  minDate={ minDate }
                  maxDate={ maxDate }
                  onlyClasses={ onlyClasses }
                  classNames={ classes }
                  onChange={ this.handleSelect(i) }
                />
              );
            });
            return _calendars;
          })()}
        </div>

      </div>
    );
  }
}

DateRange.defaultProps = {
  linkedCalendars : false,
  theme           : {},
  format          : 'DD/MM/YYYY',
  calendars       : 2,
  onlyClasses     : false,
  offsetPositive  : false,
  classNames      : {}
}

DateRange.propTypes = {
  format          : PropTypes.string,
  firstDayOfWeek  : PropTypes.number,
  calendars       : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  startDate       : PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
  endDate         : PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
  minDate         : PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
  maxDate         : PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
  dateLimit       : PropTypes.func,
  ranges          : PropTypes.object,
  linkedCalendars : PropTypes.bool,
  theme           : PropTypes.object,
  onInit          : PropTypes.func,
  onChange        : PropTypes.func,
  onlyClasses     : PropTypes.bool,
  offsetPositive  : PropTypes.bool,
  classNames      : PropTypes.object
}

export default DateRange;
