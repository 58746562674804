import React, { useCallback } from 'react';
import { Button } from 'react-bootstrap';

const Next = () => {
  const handleClick = useCallback(() => {
    localStorage.removeItem('dontTransfer')
    const payload = btoa(JSON.stringify(localStorage))
    window.location.href = `https://next.greekcapitalmanagement.com${window.location.pathname}?ls=${payload}`
  }, [])

  return <Button className="m-t-1 m-r-1" onClick={handleClick}>Go to Next</Button>
}

export default Next
