import apiWrapper, { list, updateMethod } from './helpers';

export default class Poll extends apiWrapper('/polls') {

  static endVoting = updateMethod('/polls', 'end_voting')

  static listByUser(opts) {
    list('/polls/list_by_user', opts);
  }

}
