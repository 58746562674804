import { apiRequest } from 'actions/use';
import apiWrapper, { generatePayload } from './helpers';

export default class QuickPay extends apiWrapper('/quick_pay') {

  static lookup({ search, onSuccess, onFailure }) {
    apiRequest({
      method: 'GET',
      uri: `/api/members/quick_pay_lookup?${_.toQueryString({ search })}`,
      on: {
        200: onSuccess,
        404: onFailure,
      },
    });
  }

  static creditCard({ data, query, onSuccess, onFailure }) {
    apiRequest({
      method: 'POST',
      uri: '/api/braintree/payment',
      data: generatePayload({ data, query }),
      on: {
        201: onSuccess,
      },
      onFailure,
    });
  }

}
