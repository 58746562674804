import { apiRequest } from 'actions/use';
import apiWrapper, { generatePayload } from './helpers';

export default class OrganizationAdvisor extends apiWrapper('/organization_advisors') {

  static listByOrganization({ data, query, onSuccess, onFailure }) {
    apiRequest({
      uri: '/api/organization_advisors/list_by_organization',
      data: generatePayload({ data, query }),
      method: 'GET',
      on: {
        200: onSuccess,
      },
      onFailure,
    });
  }

  static listByAlumni({ data, query, onSuccess, onFailure }) {
    apiRequest({
      uri: '/api/organization_advisors/list_by_alumni',
      data: generatePayload({ data, query }),
      method: 'GET',
      on: {
        200: onSuccess,
      },
      onFailure,
    });
  }

  static delete({ data, onSuccess, onFailure }) {
    apiRequest({
      uri: '/api/organization_advisors',
      data,
      method: 'DELETE',
      on: {
        204: onSuccess,
      },
      onFailure,
    });
  }

}
