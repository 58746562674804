export function getFieldErrors(cardType, errors, validityErrors) {
  const ERRMSG_NUMBER_EMPTY = 'A credit card number is required';
  const ERRMSG_NUMBER_INVALID = 'That credit card number appears to be incorrect';
  const ERRMSG_EXPDATE_EMPTY = 'Expiration is required';
  const ERRMSG_EXPDATE_INVALID = 'Expiration appears invalid';
  const ERRMSG_CVV_EMPTY = 'CVV is required';
  const ERRMSG_CVV_INVALID = 'CVV is invalid';
  const ERRMSG_POSTAL_CODE = 'Postal code is invalid';

  const fieldErrors = {};

  if (errors) {
    const { code } = errors;

    if (code === 'HOSTED_FIELDS_FIELDS_EMPTY') {
      fieldErrors.number = ERRMSG_NUMBER_EMPTY;
      fieldErrors.expirationDate = ERRMSG_EXPDATE_EMPTY;
      fieldErrors.cvv = ERRMSG_CVV_EMPTY;
      fieldErrors.postalCode = ERRMSG_POSTAL_CODE;
    } else if (code === 'HOSTED_FIELDS_FIELDS_INVALID') {
      const invalidFieldKeys = _.get(errors, 'details.invalidFieldKeys');

      if (_.includes(invalidFieldKeys, 'number')) {
        fieldErrors.number = ERRMSG_NUMBER_EMPTY;
      }
      if (_.includes(invalidFieldKeys, 'expirationDate')) {
        fieldErrors.expirationDate = ERRMSG_EXPDATE_EMPTY;
      }
      if (_.includes(invalidFieldKeys, 'cvv')) {
        fieldErrors.cvv = ERRMSG_CVV_EMPTY;
      }
      if (_.includes(invalidFieldKeys, 'postalCode')) {
        fieldErrors.postalCode = ERRMSG_POSTAL_CODE;
      }
    }
  }

  if (validityErrors.number) {
    fieldErrors.number = ERRMSG_NUMBER_INVALID;
  }
  if (validityErrors.expirationDate) {
    fieldErrors.expirationDate = ERRMSG_EXPDATE_INVALID;
  }
  if (validityErrors.cvv) {
    fieldErrors.cvv = ERRMSG_CVV_INVALID;
  }
  if (validityErrors.postalCode) {
    fieldErrors.postalCode = ERRMSG_POSTAL_CODE;
  }

  if (cardType === 'american-express') {
    fieldErrors.number = "Sorry, we don't accept American Express";
  }

  return fieldErrors;
}
